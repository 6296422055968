<template>
	<div>
		<PageHeader title="Beállítások" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';

export default {
    components: { PageHeader },
    data() {
        return {
        }
    },
	
    created() {
				
    },
    methods: {
			
    }
};
</script>

<style>
</style>