<template>
	<span>
		<i class="fa fa-question-circle" :class="iconClass" :id="'help'+_uid"></i>
		<b-popover :target="'help'+_uid" triggers="hover" :placement="placement ? placement : 'left'">
			<template #title>{{title}}</template>
			<slot name="text">
				{{text}}
			</slot>
			<slot name="dev-notes" v-if="dev_notes_on == 'true'">
				<span v-if="devNotes">
					<hr>
					<h5>Dev notes:</h5>
					{{devNotes}}
				</span>
			</slot>
		</b-popover>
	</span>
</template>

<script>
export default {
	props: { title: String, iconClass: String, text: String, devNotes: String, placement: String },
	data(){
		return {
			dev_notes_on:process.env.VUE_APP_DEV_NOTES,
		}
	},
};
</script>
