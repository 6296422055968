import Api from "./Api";
import Csrf from "./Csrf";

export default {

  async add(array) {
    await Csrf.getCookie();

    return Api.post("/inventories",array);
  },

  async show(id) {
    await Csrf.getCookie();

    return Api.get("/inventories/" + id);
  },

  async saveItem(id,data) {
    await Csrf.getCookie();

    return Api.post("/inventories/" + id + "/actual-quantity",data)
  },

  async findBarcode(id,array) {
    await Csrf.getCookie();

    return Api.post("/inventories/"+id+"/find_barcode",array);
  },

  async finish(id) {
    await Csrf.getCookie();

    return Api.get("/inventories/"+id+"/finish");
  },

  async delete(id) {
    await Csrf.getCookie();

    return Api.get("/inventories/"+id+"/delete");
  },
    
};