import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/storage_places");
  },
    
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/storage_places", form);
  },
    
  async show(id) {
    await Csrf.getCookie();

    return Api.get("/storage_places/" + id);
  },
    
  async update(id, form) {
    await Csrf.getCookie();

    return Api.patch("/storage_places/" + id, form);      
      
  },
    
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/storage_places/" + id);      
      
  }
    
};