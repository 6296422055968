import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/counter_samples");
  },
	
  async closeToExpire() {
    await Csrf.getCookie();

    return Api.get("/counter_samples/1");
  },
	
  async delete(id) {
    await Csrf.getCookie();

    return Api.get("/counter_samples/"+id+"/delete");
  },
    
};