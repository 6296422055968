import Api from "./Api";
import Csrf from "./Csrf";

export default {

    async storage(product_id) {
        await Csrf.getCookie();

        return Api.get("/development/storage/"+product_id);
    },

    async store(data) {
        await Csrf.getCookie();

        return Api.post("/development/store",data);
    },

    async show(id) {
        await Csrf.getCookie();

        return Api.get("/development/"+id);
    }

}