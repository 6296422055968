import Api from "./Api";
import Csrf from "./Csrf";

export default {
	async index() {
		await Csrf.getCookie();

    return Api.get("/filling");
	},	
  async show(id) {
    await Csrf.getCookie();

    return Api.get("/filling/" + id );
  },
  async delete(id) {
    await Csrf.getCookie();

    return Api.get("/filling/delete/" + id );
  },
  async createHistory(array) {
    await Csrf.getCookie();

    return Api.post("/filling/create_history",array);
  },
  async deleteHistory(array) {
    await Csrf.getCookie();

    return Api.post("/filling/delete_history",array);
  },
	async finish(id,array) {
		await Csrf.getCookie();
		
		return Api.post('/filling/' + id + '/finish',array);
	},
	async getActual() {
		await Csrf.getCookie();
		
		return Api.get('/filling/actual_filling');
	},
    
};