<template>
	<div>
		<PageHeader v-if="filling" :title="filling.name+' | '+filling.filled_products.map(fp => fp.name+': '+fp.expected_quantity+' db').join()+ ' töltése'" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-content">
							<div class="text-center mw-20" v-if="filling.barcode && !polcform && !fill_form && !placing_guide">
								<i class="fa fa-tint fa-2x"></i>
								<h3>Olvasd be a </h3>
								<h2>{{filling.barcode}}</h2>
								<h3>Vonalkódú félkész termék vonalkódját a(z)</h3>
								<h2>{{filling.storage_place_name}}</h2>
								<h3>raktárhelyről</h3>
								<input type="text" class="tetx-right form-control" @focus="$event.target.select()" @change="validateBarcode($event)">
								<button class="btn btn-danger mt-4" @click="deleteFilling()"><i class="fa fa-times"></i> Töltés törlése</button>
							</div>
							<div v-if="polcform">
								<h2>Vegye el az alábbi termékeket a raktárhelyekről</h2>
								<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th>Megnevezés</th>
											<th>Raktárhely</th>
											<th>darabszám</th>
											<th class="text-right">megvan</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(wrapping, id) in wrappings" :key="id">
											<td>{{wrapping.name}}</td>
											<td>{{wrapping.storage_place}}</td>
											<td class="text-right">{{wrapping.quantity}}</td>
											<td class="text-right"><input @change="wrappingFound(wrapping)" type="checkbox" v-model="wrapping.status"></td>
										</tr>
									</tbody>
								</table>
								</div>
								<div class="text-center" v-if="button_show">
									<button class="btn btn-primary" @click="showFillForm"> tovább a töltésre</button>
								</div>
							</div>
							<div v-if="fill_form">
									<form @submit.prevent="finishFilling" >
										<div class="d-none d-md-block">
											<div class="row p-sm border-top-bottom font-bold border-left-right">
												<div class="col-md-5">Megnevezés</div>
												<div class="col-md-2 border-left-right">Várt mennyiség</div>
												<div class="col-md-4">Felhasznált mennyiség</div>
												<div class="col-md-1">Ellenminta félretéve <help title="ellenminta" text="amennyiben bejelöli egy méret mellett az ellenmintát, abból a méretből 1el kevesebb lesz a várt és teljesített mennyiség is és egy darab bekerül az ellenminta raktárba." devNotes="nincs külön ellenminta raktár csak a filled_product expected quantityját csökkentjük és 1 re írjuk a counter samplet nullarol kesobb onnan listazzuk withtrashed el ellenminta torlesnel visszairjuk 0ra"></help></div>
											</div>	
										</div>
										<div v-for="(filled_product, id) in filling.filled_products" :key="id">
											<div class="row bg-primary p-sm border-top-bottom">
												<div class="col-6 col-md-5">{{filled_product.name}}</div>
												<div class="col-6 col-md-2 text-right">{{filled_product.expected_quantity}}</div>
												<div class="col-12 col-md-4"><input class="form-control text-right text-dark" v-model="finish_array.sizes[finish_array.sizes.map(data => data.size_id).indexOf(filled_product.size_id)].quantity" type="number" @wheel="$event.target.blur()" style="min-width: 100px;"></div>
												<div class="col-md-1 text-right">
													<div class="form-check abc-checkbox form-check-inline m-l-md p-1">
														<input type="checkbox" class="form-check-input" v-model="finish_array.sizes[finish_array.sizes.map(data => data.size_id).indexOf(filled_product.size_id)].counter_sample" :true-value="1" :false-value="0"> <!-- @change="checkboxUnchecker(filled_product.size_id)" -->
														<label class="form-check-label text-white d-block d-sm-block d-md-none">Ellenminta félretéve</label>
													</div>
												</div>
											</div>
											<div class="row bg-muted p-sm border-top-bottom" v-for="(wrapping, id) in filled_product.wrappings" :key="id">
												<div class="col-6 col-md-5">{{wrapping.name}}</div>
												<div class="col-6 col-md-2 text-right">{{filled_product.expected_quantity}}</div>
												<div class="col-12 col-md-4"><input class="form-control text-right" type="number" @wheel="$event.target.blur()" v-model="finish_array.sizes[finish_array.sizes.map(data => data.size_id).indexOf(filled_product.size_id)].wrappings[finish_array.sizes[finish_array.sizes.map(data => data.size_id).indexOf(filled_product.size_id)].wrappings.map(data => data.wrapping_id).indexOf(wrapping.id)].quantity" style="min-width: 100px;"></div>
												<div class="d-none d-md-block col-md-1"></div>
											</div>
										</div>
										<div class="row d-flex justify-content-center border-left-right border-bottom p-xs">
											<div class="col-md-4 col-6 text-center">
												<h5>Mennyiség töltés indításakor: <b>{{ filling.quantity }}</b></h5>
												<h4>Maradék {{filling.name}}:</h4> 
												<input type="number" @wheel="$event.target.blur()" v-model="finish_array.intermediate_product_remaining_quantity" step="1" class="form-control text-right m-xs" required>
												<loading-button :loading="loading"><i class="fa fa-check"></i> Befejezés</loading-button>
											</div>
										</div>
									</form>
							</div>
							<div v-if="placing_guide">
								<h4 class="mb-2">Helyezze a töltött termékeket az alábbi raktárhelyekre:</h4>
								<div class="row" v-for="(filled_product, id) in filled_products" :key="id">
									<div class="col-4 py-2">{{filled_product.name}}</div>
									<div class="col-4 text-center border-left-right py-2">{{filled_product.quantity}} db</div>
									<div class="col-4 text-right py-2">{{filled_product.place_name}}</div>
								</div>
								<div class="row mt-2">
									<div class="col text-center">
										<router-link :to="'/filling'" class="btn btn-success"><i class="fa fa-check"></i> Befejezés</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Filling from "../../apis/Filling";
	
export default {
    components: { PageHeader },
    data() {
			return {
				filling: false,
				wrappings:[],
				polcform: false,
				button_show: false,
				fill_form: false,
				placing_guide: false,
				finish_array: {
					intermediate_product_remaining_quantity: '',
					sizes: [],
				},
				filled_products: {},
				error:0,
				problematic_wrappings: [],
				loading:false,
			}
    },
		computed: {
			to_do_counter: function () {
				return this.wrappings.filter( obj => {
					return obj.status != true
				}).length
			}
		},
    created() {
			Filling.show(this.$route.params.id)
			.then( response => {
				this.filling = response.data;
				this.filling.filled_products.forEach(filled_product => {
					let i = {
						size_id: filled_product.size_id,
						quantity: filled_product.expected_quantity,
						expected: filled_product.expected_quantity,
						size: filled_product.size,
						wrappings:[],
						name: filled_product.name,
						counter_sample:0,
					} 
					filled_product.wrappings.forEach(wrapping => {
						//console.log('szar',wrapping);
						i.wrappings.push({
							wrapping_id: wrapping.id,
							quantity: filled_product.expected_quantity,
							in_stock: parseInt(wrapping.quantity), 
							name: wrapping.name
						});
						var counter = this.wrappings.filter( obj => {
							return obj.id == wrapping.id
						}).length;
						if(counter == 0){
							if(filled_product.expected_quantity != 0){
								wrapping.quantity = filled_product.expected_quantity;
								this.wrappings.push(wrapping);
							}
						}else{
							this.wrappings[this.wrappings.map(data => data.id).indexOf(wrapping.id)].quantity += 1;
						}
					});
					this.finish_array.sizes.push(i);
				});
				if(this.to_do_counter == 0){
					this.fill_form = true;
					//console.log(this.to_do_counter);
					this.button_show = true;
				}else{
					if(this.to_do_counter < this.wrappings.length){
						this.polcform = true;
					}
					this.button_show = false;
				}
			})
    },
    methods: {
			checkboxUnchecker(size_id){
				this.finish_array.sizes.forEach(fs => {
					if(fs.size_id != size_id){
						if(this.finish_array.sizes.find(s => s.size_id == size_id).counter_sample == 1){
							fs.counter_sample = 0;
						}
					}
				})
				//console.log(this.finish_array.sizes.find(s => s.size_id == size_id).counter_sample);
			},
			deleteFilling(){
				Filling.delete(this.$route.params.id)
				.then(response => {
					this.$router.push({path: response.headers['content-location'] })
				});
			},
			validateBarcode(event){
				if(event.target.value == this.filling.barcode )
				{
					this.polcform = true;
					//console.log(this.wrappings);
				}else{
					this.$toast.open({
							message: 'A vonalkód nem egyezik',
							type: 'error',
							position: 'top-right', 
					});
				}
			},
			buttonshow(){
				if(this.to_do_counter == 0){
					//console.log(this.to_do_counter);
					this.button_show = true;
				}else{
					this.button_show = false;
				}
			},
			wrappingFound(wrapping){
				//console.log(wrapping);
				if(wrapping.status == true){
					Filling.createHistory({filling_id:this.$route.params.id,wrapping_id:wrapping.id}).then(() => {
						this.buttonshow();
					}).catch(() => {
						wrapping.status = !wrapping.status;
					});
				}else{
					Filling.deleteHistory({filling_id:this.$route.params.id,wrapping_id:wrapping.id}).then(() => {
						this.buttonshow();
					}).catch(() => {
						wrapping.status = !wrapping.status;
					});
				}
			},
			showFillForm(){
				//console.log(this.filling);
				this.polcform = false;
				this.fill_form = true;
			},
			finishFilling(){
				this.loading = true;
				if(!(this.finish_array.intermediate_product_remaining_quantity >= 0 && this.finish_array.intermediate_product_remaining_quantity <= this.filling.quantity)){
					this.$toast.open({
						'type' : 'error',
						'message' : '0 és ' + this.filling.quantity + ' közti értéket adhat meg maradéknak',
						'position' : 'top-right'
					}); 
					this.loading = false;
				}else{
					//console.log(this.finish_array);
					//console.log(this.filling);
					let expected_used = 0;
					this.finish_array.sizes.forEach(size => {
						expected_used += size.size * size.quantity
					});
					if(this.finish_array.intermediate_product_remaining_quantity > this.filling.quantity - expected_used){
						this.error = 1;
						this.$toast.open({
								'type' : 'error',
								'message' : 'A megadott töltéssel maximum ' + (this.filling.quantity - expected_used) + ' mennyiségű ' + this.filling.name + ' maradhat',
								'position' : 'top-right'
							}); 
							this.loading = false;
					}
					
					this.finish_array.sizes.forEach(size => {
						if(size.quantity > size.expected){
							this.error = 1;
							this.$toast.open({
								'type' : 'error',
								'message' : size.name + ' maximális tölthető mennyisége ' + size.expected,
								'position' : 'top-right'
							}); 
							this.loading = false;
						}else{
							size.wrappings.forEach(wrapping => {
								if(parseInt(wrapping.quantity) < parseInt(size.quantity) || parseInt(wrapping.quantity) > parseInt(wrapping.in_stock)){
									console.log(parseInt(wrapping.quantity), wrapping.in_stock);
									this.error = 1;
									this.$toast.open({
										'type' : 'error',
										'message' : size.quantity + ' és ' + wrapping.in_stock + ' közt lehet a felhasznált ' + wrapping.name + ' száma',
										'position' : 'top-right'
									}); 
									this.loading = false;
								}else{
									if(this.problematic_wrappings.filter( obj => {return obj.id == wrapping.wrapping_id}).length == 0){
										this.problematic_wrappings.push({
											id: wrapping.wrapping_id,
											quantity : parseInt(wrapping.quantity),
											in_stock: wrapping.in_stock,
											name: wrapping.name
										});
									}else{
										this.problematic_wrappings[this.problematic_wrappings.map(data => data.id).indexOf(wrapping.wrapping_id)].quantity += parseInt(wrapping.quantity);
									}
								}
							});
						}
					});
					this.problematic_wrappings.forEach(wrapping => {
						if(parseInt(wrapping.quantity) > wrapping.in_stock){
							this.error = 1;
							this.$toast.open({
								'type' : 'error',
								'message' : wrapping.quantity + ' ' + wrapping.name + ' felhasználása nem lehetséges mert ' + wrapping.in_stock + ' van raktáron',
								'position' : 'top-right'
							}); 
							this.loading = false;
						}
					});
					//console.log(this.error);
					//console.log(this.problematic_wrappings);
					if(this.error != 1){
						Filling.finish(this.$route.params.id, this.finish_array)
						.then( response => {
							console.log(response.data);
							this.filled_products = response.data;
							this.fill_form = false;
							this.placing_guide = true;
							
						}).finally(() => {
							this.loading = false;
						});
					}
					
					this.error = 0;
					this.problematic_wrappings = [];
				}
			}
    }
};
</script>

<style>
</style>