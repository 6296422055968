<template>
	<div>
		<PageHeader title="Csomagolt termék selejtezés" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Csomagolt termékek:</h5>
						</div>
						<div class="ibox-content">
							<div v-if="this.products.length != 0">
								<input type="text" v-model="search" placeholder="keresés.." />
								<div class="tableFixHeadLarge">
									<table class="table table-striped">
										<thead class="thead-dark">
											<tr>
												<th @click="sort('product_name')" class="text-nowrap pointer">
													Termék
													<i v-if="currentSort == 'product_name' && currentSortDir == 'asc'"
														class="fa fa-sort-alpha-down"></i>
													<i v-if="currentSort == 'product_name' && currentSortDir == 'desc'"
														class="fa fa-sort-alpha-up-alt"></i>
												</th>
												<th class="d-none d-sm-none d-md-table-cell">Csomagolva</th>
												<th class="d-none d-sm-none d-md-table-cell">Mennyiség</th>
												<th class="d-none d-sm-none d-md-table-cell">Lejárati dátum</th>
												<th>Selejtezés</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(product, id) in filteredProducts" :key="id">
												<td>{{ product.product_name }} - {{ product.size_name }}</td>
												<td class="d-none d-sm-none d-md-table-cell">{{ product.packaging_date }}
												</td>
												<td class="d-none d-sm-none d-md-table-cell">{{ product.quantity }}</td>
												<td class="d-none d-sm-none d-md-table-cell">{{ product.best_before }}</td>
												<td><button @click="openModal(product)" class="btn btn-danger"
														title="Selejtezés"><i class="fa fa-trash-alt"></i></button></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div class="widget  p-lg text-center" v-if="this.products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek csomagolt termékek
									</h3>
									<small>csomagoljon töltött terméket</small><br>
									<router-link :to="'/packaging'" class="btn btn-xs btn-primary mt-1"><i
											class="fa fa-location-arrow"></i> csomagoláshoz</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5><i class="fa fa-undo"></i> Selejtezések</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="discardings === false">
								<div class="col-12 text-center">
									<h3>Selejtezések betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="discardings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-undo fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek selejtezések
									</h3>
								</div>
							</div>
							<div v-if="discardings.length != 0 && discardings !== false && eventlogs !== false">
								<input type="text" v-model="search2" placeholder="keresés.." />
								<div class="tableFixHeadLarge">
									<table class="table table-striped w-100">
										<thead class="thead-dark">
											<th>Termék</th>
											<th>Leírás</th>
											<th>Dátum</th>
											<th class="text-center">Mennyiség</th>
											<th class="text-center">Műveletek</th>
										</thead>
										<tbody>
											<tr v-for="(discarding, id) in filteredDiscardings" :key="id">
												<td>{{ discarding.product_name }} - {{ discarding.size_name }}</td>
												<td>
													<span v-if="discarding.model_name == 'packaged_product'">{{
														eventlogs.find(log => log.history_id == discarding.id && log.table
															== 'packagings')?.user_description
													}}</span>
													<span v-else>{{
														eventlogs.find(log => log.history_id == discarding.id &&
															log.table == 'third_party_supply')?.user_description
													}}</span>

												</td>
												<td>{{ discarding.created_date }}</td>
												<td class="text-right">{{ discarding.quantity }}</td>
												<td class="text-right">
													<button class="btn btn-warning" title="visszavonás"
														@click="openRestoreModal(discarding)"
														v-if="discarding.expired !== true"><i
															class="fa fa-undo"></i></button>
													<button v-else disabled style="cursor:default;" class="btn btn-danger"
														title="lejárt tárolót nem állíthat vissza"><i
															class="fa fa-exclamation-triangle"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal">
			<modal-vue modalSize="l" @on-close="modalClose" name="packaged_product_modal"
				:headerOptions="{ title: packaged_product.product_name + ' - ' + packaged_product.size_name + ' selejtezése', }"
				:footerOptions="{
					btn1: 'Mégse',
					btn2: 'Selejtezés',
					btn2OnClick: () => {
						discardPackagedProduct();
					},
					btn1OnClick: () => {
						$vm2.close('packaged_product_modal');
					},
				}">
				<div align="center" class="col-12">
					<form @submit.prevent="discardPackagedProduct">
						<div class="form-group">
							<label>Adja meg a selejtezni kívánt mennyiséget:</label>
							<div class="input-group m-b">
								<div class="input-group-prepend">
									<span class="input-group-addon">összes <input type="checkbox"
											v-model="all_select"></span>
								</div>
								<input type="text" class="form-control text-right" v-model="formPackagedProduct.quantity"
									:disabled="all_select">
							</div>
							<span class="label label-danger" v-if="errors.quantity">
								{{ errors.quantity[0] }}
							</span>
						</div>
						<label>Megjegyzés:</label>
						<textarea v-model="formPackagedProduct.user_description" class="form-control"></textarea>
					</form>
				</div>
			</modal-vue>
		</div>
		<div id="restore_modal" v-if="discarding">
			<modal-vue modalSize="l" @on-close="$vm2.close('restore_modal');" name="restore_modal"
				:headerOptions="{ title: discarding.product_name + ' - ' + discarding.size_name + ' selejtezés visszavonása', }"
				:footerOptions="{
					btn1: 'Mégse',
					btn2: 'Visszavonás',
					btn2OnClick: () => {
						discardingRestore();
					},
					btn1OnClick: () => {
						$vm2.close('restore_modal');
					},
				}">
				<div align="center" class="col-12">
					<form @submit.prevent="discardingRestore">
						<div class="form-group">
							<label>Megjegyzés:</label>
							<textarea v-model="discarding.user_description" class="form-control" required></textarea>
							<span class="label label-danger" v-if="errors.user_description">
								{{ errors.user_description[0] }}
							</span>
						</div>
					</form>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Discard from "../../apis/Discard";

export default {
	components: { PageHeader },
	data() {
		return {
			products: [],
			packaged_product: {},
			product: {},
			formPackagedProduct: {
				type: 'packaged_product',
			},
			errors: [],
			all_select: false,
			discardings: false,
			eventlogs: false,
			discarding: false,
			search: '',
			search2: '',
			currentSort: 'product_name',
			currentSortDir: 'asc'
		}
	},
	watch: {
		all_select: function (all_select) {
			if (all_select == true) {
				this.$set(this.formPackagedProduct, 'quantity', this.packaged_product.quantity);
			}
		}
	},
	created() {
		Discard.getDiscardable('packaged_product')
			.then(response => {
				this.products = response.data;
			}).then(() => {
				Discard.getDiscardings('packaged_product')
					.then(response => {
						this.eventlogs = response.data.eventLogs;
						this.discardings = response.data.data;
					});
			});

	},
	computed: {
		filteredProducts: function () {
			return this.products.filter(product => {
				return product.product_name.toLowerCase().includes(this.search.toLowerCase())
			}).sort((a, b) => {
				let modifier = 1;
				if (this.currentSortDir === 'desc') modifier = -1;
				if (a[this.currentSort][0].localeCompare(b[this.currentSort][0], 'hu-HU') < 0) return -1 * modifier;
				if (a[this.currentSort][0].localeCompare(b[this.currentSort][0], 'hu-HU') > 0) return 1 * modifier;
				return 0;
			});
		},
		filteredDiscardings() {
			if (this.discardings) {
				return this.discardings.filter(d => d.product_name.toLowerCase().concat('', d.size_name.toLowerCase()).includes(this.search2));
			} else {
				return [];
			}
		}
	},
	methods: {
		sort: function (s) {
			//if s == current sort, reverse
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
		},

		openModal(product) {
			this.packaged_product = product
			this.formPackagedProduct.id = this.packaged_product.id;
			this.formPackagedProduct.type = product.is_third_party_supply ? 'third_party_supply' : 'packaged_product';
			this.$vm2.open('packaged_product_modal')
		},
		modalClose() {
			this.$vm2.close('packaged_product_modal');
			this.formPackagedProduct = {
				type: 'packaged_product',
			};
			this.all_select = false;
		},
		discardPackagedProduct() {
			if (this.formPackagedProduct.quantity < 1 || this.formPackagedProduct.quantity > this.packaged_product.quantity || !this.formPackagedProduct.quantity) {
				this.$toast.open({
					message: '1 és ' + this.packaged_product.quantity + ' közti mennyiséget selejtezhet',
					type: 'error',
					position: 'top-right'
				});
			} else {
				this.$confirm({
					message: `Biztos selejtezi a kiválasztott mennyiséget?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					/**
					* Callback Function
					* @param {Boolean} confirm
					*/
					callback: confirm => {
						if (confirm) {
							Discard.discard(this.formPackagedProduct)
								.then(() => {
									if (this.packaged_product.quantity - this.formPackagedProduct.quantity == 0) {
										//this.products.splice(this.products.map(product => product.id).indexOf(this.packaged_product.id), 1);
										this.products.splice(this.products.findIndex(product => product.id == this.packaged_product.id && product.is_third_party_supply == this.packaged_product.is_third_party_supply), 1);
									} else {
										//this.$set(this.products[this.products.map(product => product.id).indexOf(this.packaged_product.id)], 'quantity', this.packaged_product.quantity - this.formPackagedProduct.quantity);
										this.$set(this.products[this.products.findIndex(product => product.id == this.packaged_product.id && product.is_third_party_supply == this.packaged_product.is_third_party_supply)], 'quantity', this.packaged_product.quantity - this.formPackagedProduct.quantity);
									}
								}).then(() => {
									this.modalClose();
									Discard.getDiscardings('packaged_product')
										.then(response => {
											this.eventlogs = response.data.eventLogs;
											this.discardings = response.data.data;
										});
								})
						}
					}
				})
			}
		},
		openRestoreModal(discarding) {
			let vue = this;
			new Promise(function (resolve) {
				vue.discarding = discarding;
				resolve('finished')
			}).then(() => {
				this.$vm2.open('restore_modal')
			});
		},
		discardingRestore() {
			//console.log(this.discarding.model_name);

			if (this.discarding.user_description != null && this.discarding.user_description.length != 0) {
				Discard.restore(this.discarding.model_name, { id: this.discarding.id, user_description: this.discarding.user_description })
					.then(() => {
						this.discardings.splice(this.discardings.map(discarding => discarding.id).indexOf(this.discarding.id), 1);
						Discard.getDiscardable(this.discarding.model_name)
							.then(response => {
								this.products = response.data;
							});
					});
				delete this.errors.user_description;
				this.$forceUpdate(this.errors);
				this.$vm2.close('restore_modal');
			} else {
				this.$set(this.errors, 'user_description', ['töltse ki a megjegyzés mezőt']);
			}
		}
	}
};
</script>

<style></style>