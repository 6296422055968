import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/intermediate-products");
  },
  async sizes(id) {
    await Csrf.getCookie();

    return Api.get("/product/" + id + "/sizes");
  },
  async store(array) {
    await Csrf.getCookie();

    return Api.post("/filling/store",array);
  },
  async canFill(id) {
    await Csrf.getCookie();

    return Api.get("/filling/" + id + "/can_fill");
  },
  async thirdPartyIndex(){
    await Csrf.getCookie();
		
		return Api.get("/intermediate-products/third-party");
	},
  async thirdPartyFillings(){
    await Csrf.getCookie();
		
		return Api.get("/intermediate-products/third-party-fillings");
	},
  async thirdPartyFilling(id,form){
    await Csrf.getCookie();
		
		return Api.post('/intermediate-products/'+id+'/third-party-filling',form);
	},
  async thirdPartyFillingFinish(id,form){
    await Csrf.getCookie();
		
		return Api.post('intermediate-product/'+id+'/third-party-filling-finish',form);
	}
};