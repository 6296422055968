<template>
	<div>
		<PageHeader title="Partnerek" button_title="Új partner rögzítése" button_href="/partners/create" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="partners === false">
								<div class="col-12 text-center">
									<h3>Partnerek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="partners.length == 0">
								<div class="m-b-md">
									<i class="fa fa-user fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek partnerek
									</h3>
									<small>hozzon létre partnert</small>
								</div>
							</div>
							<div class="table-responsive" v-if="partners.length != 0 && partners != false">
								<input type="text" v-model="search" placeholder="keresés.."/>
								<table class="table table-hover">
									<thead>
										<th>Név</th>
										<th class="d-none d-sm-none d-md-table-cell">Irányítószám</th>
										<th class="d-none d-sm-none d-md-table-cell">Város</th>
										<th class="d-none d-sm-none d-md-table-cell">Utca</th>
										<th class="d-none d-sm-none d-md-table-cell">Közterület tipusa </th>
										<th class="d-none d-sm-none d-md-table-cell">Házszám</th>
										<th class="d-none d-sm-none d-md-table-cell">Ajtószám</th>
										<th class="d-none d-sm-none d-md-table-cell">Adószám</th>
										<th class="d-none d-sm-none d-md-table-cell">Típusa</th>
										<th class="d-none d-sm-none d-md-table-cell">Bizományi</th>
										<th class="d-none d-sm-none d-md-table-cell">Kategória</th>
										<th class="text-center">műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(partner, id) in filteredPartners" :key="id">
											<td>
												{{partner.name}}
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{partner.zip}}
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{partner.city}}
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{partner.street}}
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{partner.street_type}}
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{partner.number}}
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{partner.floor_door}}
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{partner.tax_number}}
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{partner.partner_type}}
											</td>
											<td class="text-center d-none d-sm-none d-md-table-cell">
												<span v-if="partner.commission == 1" class="label label-primary"><i class="fa fa-check"></i> igen</span>
												<span v-else class="label label-success"><i class="fa fa-times"></i> nem</span>
												
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{partner.partner_category}}
											</td>
											<td class="text-center" style="min-width:158px">
												<router-link :to="'partner/'+partner.id+'/edit'" class="btn btn-success mx-1 d-inline" title="szerkesztés"><i class="fa fa-pencil-alt"></i></router-link>
												<button class="btn btn-danger mx-1 d-inline" @click="deletePartner(partner.id)" title="törlés"><i class="fa fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Partner from "../../apis/Partner";

export default {
    components: { PageHeader },
    data() {
        return {
            partners: false,
						search:'',
        }
    },
		computed: {
			filteredPartners: function(){
				return this.partners.filter(partner => {
					return partner.name.toLowerCase().includes(this.search.toLowerCase())
				})
			},
		},
	
    created() {
			Partner.index()
			.then(response => {
				this.partners = response.data;
			});

    },
    methods: {
			deletePartner(id) { 
				this.$confirm({
					message: `Biztos törlöd ezt a partnert?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					/**
					* Callback Function
					* @param {Boolean} confirm
					*/
					callback: confirm => {
						if (confirm) {
							Partner.delete(id)
							.then(() => {
								let i = this.partners.map(data => data.id).indexOf(id);
								this.partners.splice(i, 1)
							})
						}
					}
				})
				
			}
    }
};
</script>

<style>
</style>