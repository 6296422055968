<template>
	<div>
		<PageHeader title="Göngyöleg" button_title="Új göngyöleg rögzítése" button_href="/wrappings/create" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="wrappings === false">
								<div class="col-12 text-center">
									<h3>Göngyölegek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="wrappings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek göngyölegek
									</h3>
									<small>hozzon létre göngyöleget</small>
								</div>
							</div>
							<div class="table-responsive" v-if="wrappings.length != 0 && wrappings !== false">
								<input type="text" v-model="search" placeholder="keresés.." />
								<table class="table table-striped w-100">
									<thead>
										<th @click="sort('name')" class="text-nowrap pointer">
											Megnevezés
											<i v-if="currentSort == 'name' && currentSortDir == 'asc'"
												class="fa fa-sort-alpha-down"></i>
											<i v-if="currentSort == 'name' && currentSortDir == 'desc'"
												class="fa fa-sort-alpha-up-alt"></i>
										</th>
										<th>Leírás</th>
										<th class="text-center">Raktárhely</th>
										<th class="text-center">Típus</th>
										<th class="text-right">Aktuális készlet</th>
										<th class="text-center">Műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(wrapping, id) in wrappings.filter(wrapping => {
											return wrapping.name.toLowerCase().includes(search.toLowerCase())
										}).sort((a, b) => {
											let modifier = 1;
											if (currentSortDir === 'desc') modifier = -1;
											if (a[currentSort][0].localeCompare(b[currentSort][0], 'hu-HU') < 0) return -1 * modifier;
											if (a[currentSort][0].localeCompare(b[currentSort][0], 'hu-HU') > 0) return 1 * modifier;
											return 0;
										})" :key="id">
											<td>
												{{ wrapping.name }}
											</td>
											<td class="ellipsis-150">
												{{ wrapping.description }}
											</td>
											<td class="text-center">
												{{ wrapping.storage_place_name }}
											</td>
											<td class="text-center">
												{{ wrapping.type_name }}
											</td>
											<td class="text-right">
												{{ wrapping.quantity }}
											</td>
											<td class="text-center buttons-wide-3">
												<router-link :to="'wrappings/' + wrapping.id + '/edit'"
													class="btn btn-success mx-1 d-inline" title="szerkesztés"><i
														class="fa fa-pencil-alt"></i></router-link>
												<button class="btn btn-success mx-1 d-inline" title="bevételezés"
													@click="openModal(wrapping.id)"
													v-if="$root.$data.user.role_id != 3 && wrapping.external_press != 1"><i
														class="fa fa-cubes"></i></button>
												<button @click="handleExternalPressButtonClick(wrapping)" class="btn mx-1"
													v-else-if="$root.$data.user.role_id != 3"
													:class="{ 'btn-warning': wrapping.ongoing_external_press_id == null, 'btn-info': wrapping.ongoing_external_press_id != null }">
													<i class="fa"
														:class="{ 'fa-fast-forward': wrapping.ongoing_external_press_id == null, 'fa-fast-backward': wrapping.ongoing_external_press_id != null }"></i>
												</button>
												<button class="btn btn-danger mx-1 d-inline"
													@click="deleteWrapping(wrapping.id)" title="törlés"><i
														class="fa fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="modal">
			<modal-vue modalSize="l" @on-close="modalClose" name="wrapping_modal"
				:headerOptions="{ title: wrapping.name + ' bevételezése', }" :footerOptions="{
					btn1: 'Mégse',
					btn2: 'Bevételezés',
					disableBtn2: loading,
					btn2OnClick: () => {
						addWrapping();
					},
					btn1OnClick: () => {
						$vm2.close('wrapping_modal');
					},
				}">
				<div align="center" class="col-12">
					<div class="row" v-if="partners === false">
						<div class="col-12 text-center">
							<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
						</div>
					</div>
					<div class="widget p-lg text-center" v-if="partners.length == 0">
						<div class="m-b-md">
							<i class="fa fa-user fa-4x"></i>
							<h1 class="m-xs"><i class="fa fa-times"></i></h1>
							<h3 class="font-bold no-margins">
								Nincsenek partnerek
							</h3>
							<small>Csak akkor bevételezhet göngyöleget ha van legalább 1 beszállító partner</small>
						</div>
					</div>
					<form @submit.prevent="addWrapping" v-if="partners !== false && partners.length != 0">
						<div class="form-group">
							<label>Mennyiség</label>
							<input type="text" class="form-control" v-model="formWrapping.quantity">
							<span class="label label-danger" v-if="errors.quantity">
								{{ errors.quantity[0] }}
							</span>
						</div>
						<div class="form-group">
							<label>Teljes mennyiség nettó ára</label>
							<div class="input-group">
								<input type="text" class="form-control" v-model="formWrapping.price">
								<span class="input-group-addon">
									<span>Ft</span>
								</span>
							</div>
							<span class="label label-danger" v-if="errors.price">
								{{ errors.price[0] }}
							</span>
						</div>
						<div class="form-group">
							<label>Beszállító</label>
							<v-select v-model="formWrapping.partner_id" :from="partners" as="name:id:id" tagging></v-select>
							<span class="label label-danger" v-if="errors.partner_id">
								{{ errors.partner_id[0] }}
							</span>
						</div>
					</form>
				</div>
			</modal-vue>
		</div>
		<div id="external_press_start" class="custom-modal">
			<modal-vue modalSize="l" @on-close="closeExternalPressStart" name="external_press_start"
				:headerOptions="{ title: wrapping_to_press.name + ' - külső nyomásra küldése', }" :footerOptions="{
					btn1: 'Mégse',
					btn2: 'Küldés külső nyomásra',
					disableBtn2: loading,
					btn2OnClick: () => {
						sendExternalPressStart();
					},
					btn1OnClick: () => {
						closeExternalPressStart();
					},
				}">
				<div align="center" class="col-12" v-if="!loading">
					<div class="form-group">
						<label>Nyomásra kiküldött alapanyag mennyiség</label>
						<div class="input-group">
							<input type="number" class="form-control text-right" v-model="wrapping_to_press.press_quantity">
							<div class="input-group-append">
								<span class="input-group-addon">db</span>
							</div>
						</div>
						<span class="label label-danger" v-if="errors.press_quantity">
							{{ errors.press_quantity[0] }}
						</span>
					</div>
				</div>
				<h3 class="text-center" v-else>
					<i class="fa fa-cog fa-spin"></i>
				</h3>
			</modal-vue>
		</div>
		<div id="external_press_stop" class="custom-modal">
			<modal-vue modalSize="l" @on-close="closeExternalPressStart" name="external_press_stop"
				:headerOptions="{ title: wrapping_to_press.name + ' - külső nyomásról visszahozása', }" :footerOptions="{
					btn1: 'Mégse',
					btn2: 'Visszahozás külső nyomásról',
					disableBtn2: loading,
					btn2OnClick: () => {
						sendExternalPressStop();
					},
					btn1OnClick: () => {
						closeExternalPressStop();
					},
				}">
				<div align="center" class="col-12" v-if="!loading">
					<div class="form-group">
						<label>Nyomás ára</label>
						<div class="input-group">
							<input type="number" class="form-control text-right" v-model="wrapping_to_press_return.price">
							<div class="input-group-append">
								<span class="input-group-addon">ft</span>
							</div>
						</div>
						<span class="label label-danger" v-if="errors.price">
							{{ errors.price[0] }}
						</span>
					</div>
				</div>
				<h3 class="text-center" v-else>
					<i class="fa fa-cog fa-spin"></i>
				</h3>
			</modal-vue>
		</div>
</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Wrapping from "../../apis/Wrapping";
import Partner from "../../apis/Partner";
import ExternalPress from "../../apis/ExternalPress";
import { vSelect } from '@desislavsd/vue-select'

export default {
	components: { PageHeader, vSelect },
	data() {
		return {
			wrappings: false,
			wrapping: {},
			wrapping_to_press: {},
			wrapping_to_press_return: {},
			formWrapping: {
				partner_id: null,
			},
			partners: false,
			errors: [],
			search: '',
			currentSort: 'name',
			currentSortDir: 'asc',
			loading: false,
		}
	},
	computed: {
		sortedWrappings: function () { // nem szamol ujra ezert fent van a returnje
			return this.wrappings.filter(wrapping => {
				return wrapping.name.toLowerCase().includes(this.search.toLowerCase())
			}).sort((a, b) => {
				let modifier = 1;
				if (this.currentSortDir === 'desc') modifier = -1;
				if (a[this.currentSort][0].localeCompare(b[this.currentSort][0], 'hu-HU') < 0) return -1 * modifier;
				if (a[this.currentSort][0].localeCompare(b[this.currentSort][0], 'hu-HU') > 0) return 1 * modifier;
				return 0;
			});
		}
	},
	created() {
		Wrapping.index()
			.then(response => {
				this.wrappings = response.data;
			});
		Partner.index(1, 2)
			.then(response => {
				this.partners = response.data;
			})
	},
	methods: {
		sort: function (s) {
			//if s == current sort, reverse
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
		},
		deleteWrapping(id) {
			this.$confirm({
				message: `Biztos törlöd ezt a göngyöleget?`,
				button: {
					no: 'Nem',
					yes: 'Igen'
				},
				/**
				* Callback Function
				* @param {Boolean} confirm
				*/
				callback: confirm => {
					if (confirm) {
						Wrapping.delete(id)
							.then(() => {
								let i = this.wrappings.map(data => data.id).indexOf(id);
								this.wrappings.splice(i, 1)
							})
					}
				}
			})

		},
		openModal(id) {
			Wrapping.show(id)
				.then(response => {
					this.wrapping = response.data
				}).then(() => {
					//console.log(this.wrapping);
					this.$set(this.formWrapping, 'price', this.wrapping.last_price);
					this.$set(this.formWrapping, 'partner_id', this.wrapping.last_partner_id);
					//console.log(this.formWrapping);
					this.$vm2.open('wrapping_modal')
				});
		},
		addWrapping() {
			//console.log(this.formWrapping);
			this.loading = true;
			Wrapping.supply(this.wrapping.id, this.formWrapping)
				.then(() => {
					let i = this.wrappings.map(data => data.id).indexOf(this.wrapping.id);
					this.wrappings[i].quantity = parseInt(this.wrappings[i].quantity) + parseInt(this.formWrapping.quantity);
					//this.wrappings[i].quantity += this.formWrapping.quantity.parseInt();
					this.modalClose();
				})
				.catch(error => {
					this.errors = error.response.data.errors;
				}).finally(() => {
					this.loading = false;
				})
		},
		modalClose() {
			this.$vm2.close('wrapping_modal');
			this.formWrapping = {};
		},
		handleExternalPressButtonClick(wrapping) {
			console.log('lefut a handle');
			if (wrapping.ongoing_external_press_id == null) {
				this.openExternalPressStart(wrapping);
			} else {
				this.openExternalPressStop(wrapping);
			}
		},
		openExternalPressStart(wrapping) {
			this.$set(this, 'wrapping_to_press', {
				wrapping_id: wrapping.id,
				name: wrapping.name,
				press_quantity: null,
			});
			this.$vm2.open('external_press_start');
		},
		openExternalPressStop(wrapping) {
			this.$set(this, 'wrapping_to_press_return', {
				wrapping_id: wrapping.id,
				name: wrapping.name,
				price: null,
			});
			this.$vm2.open('external_press_stop');
		},
		sendExternalPressStart() {
			this.loading = true;
			ExternalPress.add(this.wrapping_to_press)
				.then(response => {
					this.wrappings[this.wrappings.findIndex(w => w.id == response.data.wrapping.id)] = response.data.wrapping;
					this.wrappings[this.wrappings.findIndex(w => w.id == response.data.press_wrapping.id)] = response.data.press_wrapping;
					this.$forceUpdate(this.wrappings);
					this.$forceUpdate(this.sortedWrappings);
					this.closeExternalPressStart();
				}).catch(error => {
					this.errors = error.response.data.errors;
				}).finally(() => {
					this.loading = false;
				})
		},
		sendExternalPressStop() {
			this.loading = true;
			ExternalPress.return(this.wrapping_to_press_return)
				.then(response => {
					this.wrappings[this.wrappings.findIndex(w => w.id == response.data.id)] = response.data;
					this.$forceUpdate(this.wrappings);
					this.$forceUpdate(this.sortedWrappings);
					this.closeExternalPressStop();
				}).catch(error => {
					this.errors = error.response.data.errors;
				}).finally(() => {
					this.loading = false;
				})
		},
		closeExternalPressStart() {
			this.$set(this, 'wrapping_to_press', {});
			this.$vm2.close('external_press_start');
		},
		closeExternalPressStop() {
			this.$set(this, 'wrapping_to_press_return', {});
			this.$vm2.close('external_press_stop');
		},
	}
};
</script>

<style></style>