<template>
	<div>
		<PageHeader title="Alapanyagok" button_title="Új alapanyag rögzítése" button_href="/materials/create" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="materials === false">
								<div class="col-12 text-center">
									<h3>Alapanyagok betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="materials.length == 0">
								<div class="m-b-md">
									<i class="fa fa-balance-scale fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek Alapanyagok
									</h3>
									<small>Hozzon létre alapanyagot</small>
								</div>
							</div>
							<div class="table-responsive" v-if="materials.length != 0 && materials !== false">
								<input type="text" v-model="search" placeholder="keresés.."/>
								<table class="table table-striped w-100">
									<thead>
										<th @click="sort('name')" class="text-nowrap pointer">
												Megnevezés 
												<i v-if="currentSort == 'name' && currentSortDir == 'asc'" class="fa fa-sort-alpha-down"></i>
												<i v-if="currentSort == 'name' && currentSortDir == 'desc'" class="fa fa-sort-alpha-up-alt"></i>
										</th>
										<th class="d-none d-sm-none d-md-table-cell">INCI</th>
										<th class="text-center">Aktuális készlet</th>
										<th class="d-none d-sm-table-cell text-center">Mennyiségi egység</th>
										<th class="d-none d-sm-table-cell text-center">Raktári hely</th>
										<th class="text-center">műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(material, id) in filteredMaterials" :key="id" >
											<td>
												<i class="fa fa-exclamation-triangle text-danger" title="hiányzó gyártási tűrés" v-if="material.production_treshold == 0"></i> 
												{{material.name}}
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{material.inci}}
											</td>
											<td class="text-right">
												{{material.quantity}}
											</td>
											<td class="text-right d-none d-sm-table-cell">
												{{material.unit}}
											</td>
											<td class="text-right d-none d-sm-table-cell">
												{{material.storage_place_name}}
											</td>
											<td class="text-center buttons-wide-3">
												<router-link :to="'materials/'+material.id+'/edit'" class="btn btn-success mx-1 d-inline" title="szerkesztés"><i class="fa fa-pencil-alt"></i></router-link>
												<router-link :to="'material_containers/'+material.id+'/create'" class="btn btn-success mx-1 d-inline" title="bevételezés" v-if="$root.$data.user.role_id != 3"><i class="fa fa-cubes"></i></router-link>
												<button class="btn btn-danger mx-1 d-inline" @click="deleteMaterial(material.id)" title="törlés"><i class="fa fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader';
import Material from "../apis/Material";
export default {
    components: { PageHeader },
    data() {
        return {
            materials: false,
						search:'',
						currentSort:'name',
						currentSortDir:'asc'
        }
    },
		computed: {
			filteredMaterials: function(){
				return this.materials.filter(material => {
					return material.name.toLowerCase().includes(this.search.toLowerCase())
				}).sort((a,b) => {
					let modifier = 1;
					if(this.currentSortDir === 'desc') modifier = -1;
					if(a[this.currentSort][0].localeCompare(b[this.currentSort][0],'hu-HU') < 0) return -1 * modifier;
					if(a[this.currentSort][0].localeCompare(b[this.currentSort][0],'hu-HU') > 0) return 1 * modifier;
					return 0;
				});
			},
		},
	
    created() {
        Material.index()
				.then(response => {
					this.materials = response.data;
				})

    },
    methods: {
			sort:function(s) {
				//if s == current sort, reverse
				if(s === this.currentSort) {
					this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
				}
				this.currentSort = s;
			},
			deleteMaterial(id) { 
				this.$confirm({
					message: `Biztos törlöd ezt az alapanyagot?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					/**
					* Callback Function
					* @param {Boolean} confirm
					*/
					callback: confirm => {
						if (confirm) {
							Material.delete(id)
							.then(() => {
								let i = this.materials.map(data => data.id).indexOf(id);
								this.materials.splice(i, 1)
							})
							.catch(error => {
									this.errors = error.response.data.errors;
							})
						}
					}
				})
			}
    }
};
</script>

<style>
</style>