import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/packaging/packageable_products");
  },
  async indexAll() {
    await Csrf.getCookie();

    return Api.get("/packaging");
  },
  async sendSizeToPackage(array){
    await Csrf.getCookie();

    return Api.post("/packagings",array);
	},
	async show(id){
		await Csrf.getCookie();
		
		return Api.get("/packagings/" + id);
	},
	async createHistory(array){
		await Csrf.getCookie();
		
		return Api.post("/packaging/create_history",array);
	},
	async deleteHistory(array){
		await Csrf.getCookie();
		
		return Api.post("/packaging/delete_history",array);
	},
	async getActual(){
		await Csrf.getCookie();

    return Api.get("/packaging/actual_packaging");
	},
	async finish(array){
		await Csrf.getCookie();

    return Api.post("/packaging/finish",array);
	}
};