import axios from "axios";
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
Vue.use(VueToast);

let Api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

Api.interceptors.response.use(
    function(response){ 
        if(response.status == 204){
					//console.log(response);
            Vue.$toast.open({
                message: 'Sikeres törlés',
                type: 'success',
                position: 'top-right', 
            });
        }
        if(response.status == 201){
            Vue.$toast.open({
                message: 'Sikeres létrehozás',
                type: 'success',
                position: 'top-right', 
            });
        }
        if(response.status == 202){
            Vue.$toast.open({
                message: 'A művelet sikeres volt',
                type: 'success',
                position: 'top-right', 
            });
        }
        
        return response;
    },
    function(error) {
        if(error.response.status == 401){            
            localStorage.removeItem("auth");
            localStorage.removeItem("user");
            top.location.reload();
            throw error;           
        }
        else if(error.response.status == 404){
            Vue.$toast.open({
                message: error.response.data.message,
                type: 'error',
                position: 'top-right', 
            }) 
            throw error;           
        }
        else if(error.response.status == 422){

            let error_keys = Object.keys(error.response.data.errors)

            if(error_keys.length == 1){            
                Vue.$toast.open({
                    message: error.response.data.errors[error_keys[0]][0],
                    type: 'error',
                    position: 'top-right', 
                })                                
                throw error;
            }
            else{
                throw error;
            }        
        }
        else if(error.response.status == 500){
            Vue.$toast.open({
                message: 'Homokszem került a gépezetbe!',
                type: 'error',
                position: 'top-right', 
            })
            throw error;         
        }else if(error.response.status == 403){
            if(error.response.headers['content-location']){
                window.Vue.$router.push({path: error.response.headers['content-location'] });
            }else{
                throw error;
            }
        }
    }   
);

Api.defaults.withCredentials = true;

export default Api;