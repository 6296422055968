import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from './pages/DashboardPage'
import Profile from './pages/profile/Profile'
import User from './pages/user/User'

import Material from './pages/Material'
import CreateMaterial from './pages/CreateMaterial'
import EditMaterial from './pages/EditMaterial'

import Wrapping from './pages/wrappings/Wrapping'
import CreateWrapping from './pages/wrappings/CreateWrapping'
import EditWrapping from './pages/wrappings/EditWrapping'

import CreateMaterialContainer from './pages/containers/CreateMaterialContainer'
import EditMaterialContainer from './pages/containers/EditMaterialContainer'

import Recipe from './pages/recipes/Recipe'
import CreateRecipe from './pages/recipes/CreateRecipe'
import EditRecipe from './pages/recipes/EditRecipe'

import StoragePlace from './pages/storage_places/StoragePlace'
import CreateStoragePlace from './pages/storage_places/CreateStoragePlace'
import EditStoragePlace from './pages/storage_places/EditStoragePlace'

import Products from './pages/Products/Products'
import ProductDetails from './pages/Products/ProductDetails'
import EditProductSize from './pages/Products/EditProductSize'

import Production from './pages/production/Production'
import ProductionIndex from './pages/production/ProductionIndex'
import EditProductionParameters from './pages/production/EditProductionParameters'

import Partner from './pages/Partners/Partner'
import CreatePartner from './pages/Partners/CreatePartner'
import EditPartner from './pages/Partners/EditPartner'

import Filling from './pages/fillings/Filling'
import Fill from './pages/fillings/Fill'
import CreateFilling from './pages/fillings/CreateFilling'
import OutsideFillingIndex from './pages/fillings/OutsideFillingIndex'

import Supply from './pages/supply/Supply'

import Packaging from './pages/packaging/Packaging'
import Package from './pages/packaging/Package'

import PriceCategories from './pages/price_categories/PriceCategories'

import Settings from './pages/settings/Settings'

import Selling from './pages/selling/Selling'

import Inventory from './pages/inventory/Inventory'
import InventoryDetails from './pages/inventory/InventoryDetails'

import WrappingDiscard from './pages/discard/WrappingDiscard'
import MaterialDiscard from './pages/discard/MaterialDiscard'
import IntermediateProductDiscard from './pages/discard/IntermediateProductDiscard'
import FilledProductDiscard from './pages/discard/FilledProductDiscard'
import PackagedProductDiscard from './pages/discard/PackagedProductDiscard'

import WrappingLog from './pages/log/WrappingLog'
import MaterialLog from './pages/log/MaterialLog'
import IntermediateProductLog from './pages/log/IntermediateProductLog'
import FilledProductLog from './pages/log/FilledProductLog'
import PackagedProductLog from './pages/log/PackagedProductLog'
import OrderLog from './pages/log/OrderLog'
import InventoryLog from './pages/log/InventoryLog'

import PrintBarcode from './pages/print/PrintBarcode'

import PartnerCommission from './pages/commission/Partner'
import AdminCommission from './pages/commission/Admin'

import PartnerStorage from './pages/Partners/Storage'

import Bundle from './pages/bundles/Bundle.vue'
import EditBundle from './pages/bundles/EditBundle.vue'

import InventoryIndex from './pages/inventory/InventoryIndex.vue'
import FinishedProductInventory from './pages/inventory/FinishedProductInventory.vue'

import Development from './pages/development/Development.vue'

import Login from './components/layout/Login.vue'
import Admin from './components/layout/Admin.vue'

import Initial from './pages/Initial'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Initial,
        name: "Initial",
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4,5,6,7] }
    },
    {
        path: '/login',
        component: Login,
        name: "Login",
        meta: { layout: Login, guestOnly: true }
    },
    {
        path: '/profile',
        component: Profile,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4,5 ]}
    },
		{
        path: '/dashboard',
        component: Dashboard,
        name: "Dashboard",
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4,5,7 ] }
    },
		{
        path: '/inventory',
        component: InventoryIndex,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4,5,7 ] }
    },
		{
        path: '/inventory/finished_product',
        component: FinishedProductInventory,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4,5,7 ] }
    },
		{
        path: '/users',
        component: User,
        meta: { layout: Admin, authOnly: true ,roles:[1,2 ] }
    },
    {
        path: "/materials",
        component: Material,
        name: "Materials",
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/materials/create",
        component: CreateMaterial,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/materials/:id/edit",
        component: EditMaterial,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/wrappings",
        component: Wrapping,
        name: "Wrappings",
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/wrappings/create",
        component: CreateWrapping,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/wrappings/:id/edit",
        component: EditWrapping,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/recipes",
        component: Recipe,
        name: "Recipes",
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/recipes/:product_id/create",
        //path: "/recipes/create",
        component: CreateRecipe,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/recipes/:id/edit",
        component: EditRecipe,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/storage_places",
        component: StoragePlace,
        name: "StoragePlaces",
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/storage_places/create",
        component: CreateStoragePlace,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/storage_places/:id/edit",
        component: EditStoragePlace,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/material_containers/:material_id/create",
        component: CreateMaterialContainer,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/material_containers/:id/edit",
        component: EditMaterialContainer,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
		{
        path: "/partners",
        component: Partner,
        name: "Partners",
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4,5 ] }
    },
    {
        path: "/partners/create",
        component: CreatePartner,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4,5 ] }
    },
    {
        path: "/partner/:id/edit",
        component: EditPartner,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4,5 ] }
    },
		{
        path: "/products",
        component: Products,
        name: "Products",
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/products/:product_id/development",
        component: Development,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/product_sizes/:id",
        component: EditProductSize,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: '/product/:id?',
        component: ProductDetails,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: '/productions/:product_id',
        component: Production,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: '/productions/:id/parameters/edit',
        component: EditProductionParameters,
        meta: { layout: Admin, authOnly: true ,roles:[1,2 ] }
    },
    {
        path: '/productions',
        component: ProductionIndex,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: '/filling',
        component: Filling,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: '/outside-filling',
        component: OutsideFillingIndex,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/filling/:id",
        component: CreateFilling,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/fill/:id",
        component: Fill,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/supply",
        component: Supply,
        meta: { layout: Admin, authOnly: true ,roles:[1,2 ] }
    },
    {
        path: "/packaging",
        component: Packaging,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/package/:id",
        component: Package,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/price_categories",
        component: PriceCategories,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/settings",
        component: Settings,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/selling",
        component: Selling,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,5,7 ] }
    },
    {
        path: "/inventories",
        component: Inventory,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/inventories/:id",
        component: InventoryDetails,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3 ] }
    },
    {
        path: "/discarding/wrappings",
        component: WrappingDiscard,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/discarding/materials",
        component: MaterialDiscard,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/discarding/intermediate_products",
        component:  IntermediateProductDiscard,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/discarding/filled_products",
        component:  FilledProductDiscard,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/discarding/packaged_products",
        component:  PackagedProductDiscard,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,3,4 ] }
    },
    {
        path: "/commission/partner",
        component:  PartnerCommission,
        meta: { layout: Admin, authOnly: true ,roles:[6] }
    },
    {
        path: "/commission/admin",
        component:  AdminCommission,
        meta: { layout: Admin, authOnly: true ,roles:[1,2,5 ] }
    },
    {
        path: "/storage",
        component:  PartnerStorage,
        meta: { layout: Admin, authOnly: true ,roles:[6] }
    },
    {
        path: "/log/wrapping",
        component:  WrappingLog,
        meta: { layout: Admin, authOnly: true }
    },
    {
        path: "/log/raw_material",
        component:  MaterialLog,
        meta: { layout: Admin, authOnly: true }
    },
    {
        path: "/log/intermediate_product",
        component:  IntermediateProductLog,
        meta: { layout: Admin, authOnly: true }
    },
    {
        path: "/log/filled_product",
        component:  FilledProductLog,
        meta: { layout: Admin, authOnly: true }
    },
    {
        path: "/log/packaged_product",
        component:  PackagedProductLog,
        meta: { layout: Admin, authOnly: true }
    },
    {
        path: "/log/inventory",
        component:  InventoryLog,
        meta: { layout: Admin, authOnly: true }
    },
    {
        path: "/log/order",
        component:  OrderLog,
        meta: { layout: Admin, authOnly: true }
    },
    {
        path: "/bundles",
        component:  Bundle,
        meta: { layout: Admin, authOnly: true }
    },
    {
        path: "/bundles/:id/edit",
        component: EditBundle,
        meta: { layout: Admin, authOnly: true }
    },
    {
        path: "/print/:barcode/:name",
        component:  PrintBarcode,
        meta: { layout: Admin, authOnly: true }
    },
		
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
function isLoggedIn() {
  return localStorage.getItem("auth");
}

router.beforeEach((to, from, next) => {
	////console.log(root.data.user);
	if(to.path.substring(0,4) == '/api'){
		to.path = to.path.substring(4);
	}
	/*
	if(document.getElementById('modal')){
		Vue.$vm2.close('vue-modal-2');
		//console.log('ok');
	}
	else{
		//console.log('nincs modal');
	}
	*/
	var user = JSON.parse(localStorage.getItem('user'));
	if(to.meta.roles != undefined && user != null){
		if(!to.meta.roles.includes(user.role_id)){
			let pathstring = "/dashboard";
			if(user.role_id == 6){
				pathstring = "/commission/partner"
			}
			next({
        path: pathstring,
        query: { redirect: to.fullPath }
      });
			//console.log('nem');
		}else{
			//console.log('igen');
		}
	}
	
  if (to.matched.some(record => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next({
        path: "/dashboard",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
