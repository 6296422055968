import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index(type, category) {
    await Csrf.getCookie();
     
		if(typeof category != 'undefined'){
			return Api.get("/partnerlist/" +type +"/"+category);
		}
    if(typeof type == 'undefined'){
        return Api.get("/partners");
    }

    return Api.get("/partnerlist/" + type);
  },
    
  async add(form) {
		//console.log('form',form);
    await Csrf.getCookie();

    return Api.post("/partners", form);
  },
    
  async show(id) {
    await Csrf.getCookie();

    return Api.get("/partners/" + id);
  },
    
  async update(id, form) {
    await Csrf.getCookie();

    return Api.patch("/partners/" + id, form);      
      
  },
    
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/partners/" + id);      
      
  },
	
	async getStorage(id) {
		await Csrf.getCookie();
		
		return Api.get('/storages/commission_storage_show/'+id);
	},
	
	async useFromStorage(array) {
		await Csrf.getCookie();
			
		return Api.post('/storages/commission_storage_update',array);
	}
    
};