import Api from "./Api";
import Csrf from "./Csrf";

export default {
	
  async index() {
    await Csrf.getCookie();

    return Api.get("/price_categories");
  },
	
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/price_categories", form);
  },
	
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/price_categories/" + id);
  },  
	
  async update(id, array) {
    await Csrf.getCookie();

    return Api.patch("/price_categories/" + id, array);
  },  
};