<template>
	<div>
		<PageHeader title="Késztermék készlet" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Késztermékek</h5>
								<div class="ibox-tools">
									<button class="btn btn-info btn-sm" @click="downloadCsv">csv letöltése <i class="fa fa-download"></i></button>
								</div>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="products === false">
								<div class="col-12 text-center">
									<h3>Késztermékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-else-if="products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek késztermékek
									</h3>
								</div>
							</div>
							<div class="table-responsive" v-else>
								<input type="text" v-model="search" placeholder="Keresés...">
								<table class="table table-bordered">
									<tbody v-for="(product, p_id) in filteredProductList" :key="p_id">
										<tr class="bg-gray-400">
											<th>{{ product.name }}</th>
										</tr>
										<tr v-for="(size, s_id) in product.sizes" :key="s_id">
											<table class="table my-0">
												<tbody>
													<tr class="bg-gray-200">
														<td colspan="3" style="padding-left:20px;">{{ size.name }}</td>
													</tr>
													<tr v-if="size.items.length == 0">
														<td colspan="3" style="padding-left:40px"><i>nincs készleten</i>
														</td>
													</tr>
													<tr v-else v-for="(item, i_id) in size.items" :key="i_id">
														<td style="padding-left:40px"><i>{{ item.best_before }}</i></td>
														<td width="20%"><i>{{ item.remaining_quantity }} db</i></td>
														<td width="20%" class="text-right">
															{{ item.factory_cost ? item.factory_cost.toLocaleString('hu-HU')
																+ ' Ft/db' : 'nem szamolható' }}
															<button class="btn btn-info btn-xs ml-2" @click="openModal(item)"><i
																	class="fa fa-info"></i></button>
														</td>
													</tr>
												</tbody>
											</table>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal">
			<modal-vue name="detail_modal"
				:headerOptions="{ title: 'Bekerülési költség részletei', }"
				:footerOptions="{
					btn1: 'Ok',
					disableBtn2: true,
					btn1OnClick: () => {
						$vm2.close('detail_modal');
					},
				}">
				<div class="px-1">
					<table class="table" v-if="selected_product">
						<tbody>
							<tr>
								<th>Alapanyag</th>
								<td>{{ selected_product.factory_cost_details.raw_material.toFixed(2).toLocaleString('hu-HU') }} Ft</td>
							</tr>
							<tr>
								<th>Töltés</th>
								<td>{{ selected_product.factory_cost_details.filling.toFixed(2).toLocaleString('hu-HU') }} Ft</td>
							</tr>
							<tr>
								<th>Csomagolás</th>
								<td>{{selected_product.factory_cost_details.packaging.toFixed(2).toLocaleString('hu-HU')}} Ft</td>
							</tr>
							<tr class="bg-gray-200">
								<th>Összesen</th>
								<td>{{ selected_product.factory_cost.toLocaleString('hu-HU') }} Ft</td>
							</tr>
						</tbody>
					</table>
					<i class="fa fa-cog fa-spin mx-auto fa-3x" v-else></i>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Product from '../../apis/Product';

export default {
	components: {
		PageHeader
	},
	data() {
		return {
			products: false,
			search: '',
			selected_product: null,
		}
	},
	computed: {
		filteredProductList() {
			return this.products.filter(product => {
				return product.name.toLowerCase().includes(this.search.toLowerCase())
			})
		},
	},
	created() {
		Product.finishedInventory()
			.then(response => {
				this.products = response.data;
			}).catch(() => {
				this.products = [];
			})
	},
	methods:{
		openModal(item){
			this.selected_product = item;
			this.$vm2.open('detail_modal');
		},
		downloadCsv(){
			Product.finishedInventoryCsv()
				.then(response => {
					var blob = new Blob([response.data]);
					var link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = "kesztermek_keszlet.csv";
					link.click();
				})
		}
	}
}
</script>	
