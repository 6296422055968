<template>
	<div>
		<PageHeader title="Töltött termék selejtezés" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Töltött termékek töltés szerint:</h5>
						</div>
						<div class="ibox-content">
							<div v-if="this.products.length != 0">
								<input type="text" v-model="search" placeholder="keresés.." />
								<div class="tableFixHeadLarge">
									<table class="table">
										<thead class="thead-dark">
											<tr>
												<th>Termék</th>
												<th>Töltés dátuma</th>
												<th>Lejárati dátum</th>
											</tr>
										</thead>
										<tbody v-for="(product, id) in filteredProducts" :key="id">
											<tr class="bg-gray-200">
												<td><b>{{ product.product_name }}</b></td>
												<td>{{ product.filling_date }}</td>
												<td>{{ product.best_before }}</td>
											</tr>
											<tr v-for="(size, id) in product.sizes" :key="id">
												<td>{{ size.size_name }}</td>
												<td>{{ size.quantity }} db</td>
												<td>
													<button @click="openModal(size, product)" class="btn btn-danger" title="Selejtezés">
														<i class="fa fa-trash-alt"></i>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="widget  p-lg text-center" v-if="this.products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek töltött termékek
									</h3>
									<small>töltsön félkész terméket</small><br>
									<router-link :to="'/filling'" class="btn btn-xs btn-primary mt-1"><i
											class="fa fa-location-arrow"></i> töltéshez</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5><i class="fa fa-undo"></i> Selejtezések</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="discardings === false">
								<div class="col-12 text-center">
									<h3>Selejtezések betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="discardings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-undo fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek selejtezések
									</h3>
								</div>
							</div>
							<div v-if="discardings.length != 0 && discardings !== false">
								<input type="text" v-model="search2" placeholder="keresés.." />
								<div class="tableFixHeadLarge">
									<table class="table table-striped w-100">
										<thead class="thead-dark">
											<th>Termék</th>
											<th>Töltve</th>
											<th>Leírás</th>
											<th>Dátum</th>
											<th class="text-center">Mennyiség</th>
											<th class="text-center">Műveletek</th>
										</thead>
										<tbody>
											<tr v-for="(discarding, id) in filteredDiscardings" :key="id">
												<td>{{ discarding.product_name }} - {{ discarding.size_name }}</td>
												<td>{{ discarding.filling_date }}</td>
												<td>{{
													eventlogs[eventlogs.map(log =>
														log.history_id).indexOf(discarding.id)].user_description
												}}</td>
												<td>{{ discarding.created_date }}</td>
												<td class="text-right">{{ discarding.quantity }}</td>
												<td class="text-right">
													<button class="btn btn-warning" title="visszavonás"
														@click="openRestoreModal(discarding)"
														v-if="discarding.expired !== true"><i
															class="fa fa-undo"></i></button>
													<button v-else disabled style="cursor:default;" class="btn btn-danger"
														title="lejárt terméket nem állíthat vissza"><i
															class="fa fa-exclamation-triangle"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal">
			<modal-vue modalSize="l" @on-close="modalClose" name="filled_product_modal"
				:headerOptions="{ title: product.product_name + '-' + product.filling_date + ' selejtezése', }"
				:footerOptions="{
					btn1: 'Mégse',
					btn2: 'Selejtezés',
					btn2OnClick: () => {
						discardFilledProduct();
					},
					btn1OnClick: () => {
						$vm2.close('filled_product_modal');
					},
				}">
				<div align="center" class="col-12">
					<form @submit.prevent="discardFilledProduct">
						<div class="form-group">
							<label>Adja meg a selejtezni kívánt mennyiséget:</label>
							<div class="input-group m-b">
								<div class="input-group-prepend">
									<span class="input-group-addon">összes <input type="checkbox"
											v-model="all_select"></span>
								</div>
								<input type="text" class="form-control text-right" v-model="formFilledProduct.quantity"
									:disabled="all_select">
							</div>
							<span class="label label-danger" v-if="errors.quantity">
								{{ errors.quantity[0] }}
							</span>
						</div>
						<label>Megjegyzés:</label>
						<textarea v-model="formFilledProduct.user_description" class="form-control"></textarea>
					</form>
				</div>
			</modal-vue>
		</div>
		<div id="restore_modal" v-if="discarding">
			<modal-vue modalSize="l" @on-close="$vm2.close('restore_modal');" name="restore_modal"
				:headerOptions="{ title: discarding.product_name + ' - ' + discarding.size_name + ' - ' + discarding.filling_date + ' selejtezés visszavonása', }"
				:footerOptions="{
					btn1: 'Mégse',
					btn2: 'Visszavonás',
					btn2OnClick: () => {
						discardingRestore();
					},
					btn1OnClick: () => {
						$vm2.close('restore_modal');
					},
				}">
				<div align="center" class="col-12">
					<form @submit.prevent="discardingRestore">
						<div class="form-group">
							<label>Megjegyzés:</label>
							<textarea v-model="discarding.user_description" class="form-control" required></textarea>
							<span class="label label-danger" v-if="errors.user_description">
								{{ errors.user_description[0] }}
							</span>
						</div>
					</form>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Discard from "../../apis/Discard";

export default {
	components: { PageHeader },
	data() {
		return {
			products: [],
			filled_product: {},
			product: {},
			formFilledProduct: {
				type: 'filled_product',
			},
			errors: [],
			all_select: false,
			discardings: false,
			eventlogs: false,
			discarding: false,
			search:'',
			search2:'',
		}
	},
	watch: {
		all_select: function (all_select) {
			if (all_select == true) {
				this.$set(this.formFilledProduct, 'quantity', this.filled_product.quantity);
			}
		}
	},
	computed:{
		filteredProducts(){
			return this.products.filter(p => p.product_name.toLowerCase().includes(this.search.toLowerCase()));
		},
		filteredDiscardings(){
			return this.discardings.filter(d => d.product_name.toLowerCase().concat('',d.size_name.toLowerCase()).includes(this.search2.toLowerCase()));
		},
	},
	created() {
		Discard.getDiscardable('filled_product')
			.then(response => {
				this.products = response.data;
			}).then(() => {
				Discard.getDiscardings('filled_product')
					.then(response => {
						this.eventlogs = response.data.eventLogs;
						this.discardings = response.data.data;
					});
			});

	},
	methods: {

		openModal(filled_product, product) {
			this.filled_product = filled_product
			this.product = product
			this.formFilledProduct.id = this.filled_product.id;
			this.formFilledProduct.filling_id = this.product.filling_id;
			this.$vm2.open('filled_product_modal')
		},
		modalClose() {
			this.$vm2.close('filled_product_modal');
			this.formFilledProduct = {
				type: 'filled_product',
			};
			this.all_select = false;
		},
		discardFilledProduct() {
			if (this.formFilledProduct.quantity < 1 || this.formFilledProduct.quantity > this.filled_product.quantity || !this.formFilledProduct.quantity) {
				this.$toast.open({
					message: '1 és ' + this.filled_product.quantity + ' közti mennyiséget selejtezhet',
					type: 'error',
					position: 'top-right'
				});
			} else {
				this.$confirm({
					message: `Biztos selejtezi a kiválasztott mennyiséget?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					/**
					* Callback Function
					* @param {Boolean} confirm
					*/
					callback: confirm => {
						if (confirm) {
							Discard.discard(this.formFilledProduct)
								.then(() => {
									this.$set(this.products[this.products.map(product => product.filling_id).indexOf(this.product.filling_id)].sizes[this.products[this.products.map(product => product.filling_id).indexOf(this.product.filling_id)].sizes.map(container => container.id).indexOf(this.filled_product.id)], 'quantity', this.filled_product.quantity - this.formFilledProduct.quantity);
									this.modalClose();
									Discard.getDiscardings('filled_product')
										.then(response => {
											this.eventlogs = response.data.eventLogs;
											this.discardings = response.data.data;
										});
								})
						}
					}
				})
			}
		},
		openRestoreModal(discarding) {
			let vue = this;
			new Promise(function (resolve) {
				vue.discarding = discarding;
				resolve('finished')
			}).then(() => {
				this.$vm2.open('restore_modal')
			});
		},
		discardingRestore() {
			//console.log(this.discarding);
			if (this.discarding.user_description != null && this.discarding.user_description.length != 0) {
				Discard.restore('filled_product', { id: this.discarding.id, user_description: this.discarding.user_description })
					.then(() => {
						this.discardings.splice(this.discardings.map(discarding => discarding.id).indexOf(this.discarding.id), 1);
						Discard.getDiscardable('filled_product')
							.then(response => {
								this.products = response.data;
								//console.log(this.products);
							})
					});
				delete this.errors.user_description;
				this.$forceUpdate(this.errors);
				this.$vm2.close('restore_modal');
			} else {
				this.$set(this.errors, 'user_description', ['töltse ki a megjegyzés mezőt']);
			}
		}
	}
};
</script>

<style>

</style>