import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async register(form) {
    await Csrf.getCookie();

    return Api.post("/register", form);
  },

  async login(form) {
    await Csrf.getCookie();

    return Api.post("/login", form);
  },

  async logout() {
    await Csrf.getCookie();

    return Api.post("/logout");
  },

  auth() {
    return Api.get("/user");
  },    
    
  async update(form) {
    await Csrf.getCookie();

    return Api.patch("/user", form);
  }, 
    
  async updateRole(id,form) {
    await Csrf.getCookie();

    return Api.patch("/users/"+id+'/update-role', form);
  },
    
  async index() {
    await Csrf.getCookie();

    return Api.get("/users");
  },
    
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/users", form);
  },
    
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/users/" + id);      
      
  }
};