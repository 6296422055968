<template>
	<div>
		<PageHeader title="Összesítőpult" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6"  v-if="![4,5].includes($root.$data.user.role_id)">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Lejáró alapanyagok</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="close_to_expire_materials === false">
								<div class="col-12 text-center">
									<h3>Alapanyagok betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="close_to_expire_materials.length == 0">
								<div class="m-b-md">
									<i class="fa fa-balance-scale fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-check-circle"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek lejáró alapanyagok
									</h3>
								</div>
							</div>
							<div class="row" v-if="close_to_expire_materials.length > 0 && close_to_expire_materials !== false">
									<div class="col-12 tableFixHead">
										<table class="table table-striped">
											<thead class="thead-dark">
												<th>Vonalkód</th>
												<th>Megnevezés</th>
												<th>Raktárhely</th>
												<th class="text-right">Mennyiség</th>
												<th class="text-right">Lejárati dátum</th>
											</thead>
											<tbody>
												<tr v-for="(container, id) in close_to_expire_materials" :key="id">
													<td>{{container.barcode}}</td>
													<td>{{container.name}}</td>
													<td>{{container.storage_place_name}}</td>
													<td class="text-right">{{container.quantity}} {{container.unit}}</td>
													<td class="text-right">{{container.best_before}}</td>
												</tr>
											</tbody>
										</table>
									</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6"  v-if="![4,5].includes($root.$data.user.role_id)">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Lejáró félkész termékek</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="close_to_expire_intermediate_products === false">
								<div class="col-12 text-center">
									<h3>Félkész termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="close_to_expire_intermediate_products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-industry fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-check-circle"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek lejáró félkész termékek
									</h3>
								</div>
							</div>
							<div class="row" v-if="close_to_expire_intermediate_products.length > 0 && close_to_expire_intermediate_products !== false">
									<div class="col-12 tableFixHead">
										<table class="table table-striped">
											<thead class="thead-dark">
												<th>Vonalkód</th>
												<th>Megnevezés</th>
												<th>Raktárhely</th>
												<th class="text-right">Mennyiség</th>
												<th class="text-right">Lejárati dátum</th>
											</thead>
											<tbody>
												<tr v-for="(container, id) in close_to_expire_intermediate_products" :key="id">
													<td>{{container.barcode}}</td>
													<td>{{container.name}}</td>
													<td>{{container.intermediate_place_name}}</td>
													<td class="text-right">{{container.quantity}} {{container.unit}}</td>
													<td class="text-right">{{container.best_before}}</td>
												</tr>
											</tbody>
										</table>
									</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6"  v-if="![4,5].includes($root.$data.user.role_id)">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Lejáró kész termékek</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="close_to_expire_products === false">
								<div class="col-12 text-center">
									<h3>Késztermékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="close_to_expire_products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-check-circle"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek lejáró késztermékek
									</h3>
								</div>
							</div>
							<div class="row" v-if="close_to_expire_products.length > 0 && close_to_expire_products !== false">
									<div class="col-12 tableFixHead">
										<table class="table table-striped">
											<thead class="thead-dark">
												<th>Vonalkód</th>
												<th>Megnevezés</th>
												<th>Raktárhely</th>
												<th class="text-right">Mennyiség</th>
												<th class="text-right">Lejárati dátum</th>
											</thead>
											<tbody>
												<tr v-for="(container, id) in close_to_expire_products" :key="id">
													<td>{{container.barcode}}</td>
													<td>{{container.name}}</td>
													<td>{{container.intermediate_place_name}}</td>
													<td class="text-right">{{container.quantity}} {{container.unit}}</td>
													<td class="text-right">{{container.best_before}}</td>
												</tr>
											</tbody>
										</table>
									</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6"  v-if="![4,5].includes($root.$data.user.role_id)">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Lejáró harmadik fél által gyártott termékek</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="close_to_expire_tp_products === false">
								<div class="col-12 text-center">
									<h3>Lejáró harmadik fél által gyártott termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="close_to_expire_tp_products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-check-circle"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek lejáró harmadik fél által gyártott termékek
									</h3>
								</div>
							</div>
							<div class="row" v-if="close_to_expire_tp_products.length > 0 && close_to_expire_tp_products !== false">
									<div class="col-12 tableFixHead">
										<table class="table table-striped">
											<thead class="thead-dark">
												<th>Megnevezés</th>
												<th>Kiszerelés</th>
												<th>Batch szám</th>
												<th class="text-right">Mennyiség</th>
												<th class="text-right">Lejárati dátum</th>
											</thead>
											<tbody>
												<tr v-for="(tp_product, id) in close_to_expire_tp_products" :key="id">
													<td>{{tp_product.name}}</td>
													<td>{{tp_product.size_name}}</td>
													<td>{{tp_product.batch_number}}</td>
													<td class="text-right">{{tp_product.quantity}}</td>
													<td class="text-right">{{tp_product.best_before}}</td>
												</tr>
											</tbody>
										</table>
									</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6"  v-if="![4,5,7].includes($root.$data.user.role_id)">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Hiányosan dokumentált alapanyagok</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="bad_document_materials === false">
								<div class="col-12 text-center">
									<h3>Alapanyagok betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="bad_document_materials.length == 0">
								<div class="m-b-md">
									<i class="fa fa-file-invoice fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-check-circle"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek hiányosan dokumentált alapanyagok
									</h3>
								</div>
							</div>
							<div class="row" v-if="bad_document_materials.length > 0 && bad_document_materials !== false">
									<div class="col-12 tableFixHead">
										<table class="table table-striped">
											<thead class="thead-dark">
												<tr>
													<th>Vonalkód</th>
													<th>Megnevezés</th>
													<th>Mennyiség</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(container, id) in bad_document_materials" :key="id">
													<td>{{container.barcode}}</td>
													<td>{{container.raw_material_name}}</td>
													<td>{{container.quantity}} {{container.raw_material_unit}}</td>
													<td><button class="btn btn-primary" @click="documentationOk(container.id)"><i class="fa fa-check"></i></button></td>
												</tr>
											</tbody>
										</table>
									</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6"  v-if="![4,5,7].includes($root.$data.user.role_id)">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Dokumentációra váró tárolók</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="missing_document_containers === false">
								<div class="col-12 text-center">
									<h3>Tárolók betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="missing_document_containers.length == 0">
								<div class="m-b-md">
									<i class="fa fa-file-invoice fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-check-circle"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek dokumentációra váró tárolók
									</h3>
								</div>
							</div>
							<div class="row" v-if="missing_document_containers.length > 0 && missing_document_containers !== false">
								<div class="col-12">
									<div class="table-responsive">
										<vue-good-table
											styleClass="vgt-table striped w-100" 
											rowStyleClass="fs-12"
											:columns="missing_document_containers_columns" 
											:rows="missing_document_containers" 
											:search-options="{ enabled: false, placeholder: 'Keresés...' }" 
											:pagination-options="this.$vueGTPaginationOptions" max-height="200px" :fixed-header="true"
										>
											<template slot="table-row" slot-scope="props">
												<span v-if="props.column.field == 'quantity'">
													{{props.row.quantity}} {{props.row.raw_material_unit}}
												</span>
												<span v-else-if="props.column.field == 'actions'">
													<button class="btn btn-primary" @click="openModal(props.row)"><i class="fa fa-plus"></i></button>
												</span>
												<span v-else>
													{{props.formattedRow[props.column.field]}}
												</span>
											</template>
										</vue-good-table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6"  v-if="![4,5].includes($root.$data.user.role_id)">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Lejárt ellenminták</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="close_to_expire_counter_samples === false">
								<div class="col-12 text-center">
									<h3>Ellenminták betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="close_to_expire_counter_samples.length == 0">
								<div class="m-b-md">
									<i class="fa fa-balance-scale fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-check-circle"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek lejárt ellenminták
									</h3>
								</div>
							</div>
							<div class="row" v-if="close_to_expire_counter_samples.length > 0 && close_to_expire_counter_samples !== false">
									<div class="col-12 tableFixHead">
										<table class="table table-striped">
											<thead class="thead-dark">
												<th>Töltve</th>
												<th>Megnevezés</th>
												<th class="text-right">Lejárati dátum</th>
											</thead>
											<tbody>
												<tr v-for="(counter_sample, id) in close_to_expire_counter_samples" :key="id">
													<td>{{counter_sample.filled_at}}</td>
													<td>{{counter_sample.name}}</td>
													<td class="text-right">{{counter_sample.best_before}}</td>
												</tr>
											</tbody>
										</table>
									</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6"  v-if="![4,5].includes($root.$data.user.role_id)">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Fogyóban lévő félkész termékek</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="low_intermediate_product_inventory === false">
								<div class="col-12 text-center">
									<h3>Félkész termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="low_intermediate_product_inventory.length == 0">
								<div class="m-b-md">
									<i class="fa fa-balance-scale fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-check-circle"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek fogyóban lévő félkész termékek
									</h3>
								</div>
							</div>
							<div class="row" v-if="low_intermediate_product_inventory.length > 0 && low_intermediate_product_inventory !== false">
								<div class="col-12 tableFixHead">
										<table class="table table-striped">
											<thead class="thead-dark">
												<th>Vonalkód</th>
												<th>Megnevezés</th>
												<th>Raktárhely</th>
												<th class="text-right">Mennyiség</th>
												<th class="text-right">Lejárati dátum</th>
											</thead>
											<tbody>
												<tr v-for="(container, id) in low_intermediate_product_inventory" :key="id">
													<td>{{container.barcode}}</td>
													<td>{{container.name}}</td>
													<td>{{container.intermediate_place_name}}</td>
													<td class="text-right">{{container.quantity}} {{container.unit}}</td>
													<td class="text-right">{{container.best_before}}</td>
												</tr>
											</tbody>
										</table>
									</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6"  v-if="![4,5].includes($root.$data.user.role_id)">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Fogyóban lévő alapanyagok</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="low_rawmaterial_inventory === false">
								<div class="col-12 text-center">
									<h3>Alapanyagok betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="low_rawmaterial_inventory.length == 0">
								<div class="m-b-md">
									<i class="fa fa-balance-scale fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-check-circle"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek fogyóban lévő alapanyagok
									</h3>
								</div>
							</div>
							<div class="row" v-if="low_rawmaterial_inventory.length > 0 && low_rawmaterial_inventory !== false">
								{{low_rawmaterial_inventory}}
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-12" v-if="[4,5].includes($root.$data.user.role_id)">
					<div class="widget p-lg text-center">
						<div class="m-b-md">
							<i class="fa fa-chart-pie fa-5x"></i>
							<h1 class="m-xs">Még nincs</h1>
							<h3 class="font-bold no-margins">
									Megjeleníthető adat
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal" >
				<modal-vue modalSize="xl" @on-close="modalClose" name="document_modal" :headerOptions="{ title: container.raw_material_name + ' - ' + container.barcode + ' dokumentum, feltöltés', }" :footerOptions="{
        btn1: 'Mégse',
        disableBtn2: true,
        btn1OnClick: () => {
          $vm2.close('document_modal');
        },
      }">
					
						<ImageUpload :parent_model="parent_model" :model_id="container.id" upload_text="Kattints ide vagy dobj fájlokat erre a dobozra a feltöltéshez" />
					
				</modal-vue>
			</div>
	</div>
</template>

<script>
import PageHeader from '../components/PageHeader';
import Dashboard from '../apis/Dashboard';
import CounterSample from '../apis/CounterSample';
import ImageUpload from "../components/ImageUpload";
export default {
    components: { PageHeader,ImageUpload },
	data(){
		return { 
			close_to_expire_materials: false,
			close_to_expire_intermediate_products: false,
			close_to_expire_products: false,
			close_to_expire_tp_products: false,
			close_to_expire_counter_samples: false,
			low_intermediate_product_inventory: false,
			low_rawmaterial_inventory: false,
			low_inventory_intermediate_products: false,
			bad_document_materials: false,
			missing_document_containers: false,
			container:{},
			parent_model:"RawMaterialContainer",
			missing_document_containers_columns:[
						{
							label:'Vonalkód',
							field:'barcode',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Partner',
							field:'partner_name',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Lejárat',
							field:'best_before',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Gyártások',
							field:this.fieldFn,
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Megnevezés',
							field:'raw_material_name',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Mennyiség',
							field:'quantity',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label: 'Műveletek',
							field: 'actions',
							width: '15%',
							sortable: false,
							thClass: 'text-right fs-12',
							tdClass: 'text-right'
						}
					]
		}
	},
	created() {
		if(this.$root.$data.user.role_id == 6){
			this.$router.push({path:'/commission/partner'});
		}
		CounterSample.closeToExpire()
		.then(response => {
			this.close_to_expire_counter_samples = response.data;
		});
		Dashboard.close_to_expire()
		.then(response => {
			//console.log('close_to_expire response:');
			//console.log(response.data);
			this.close_to_expire_materials = response.data.raw_materials;
			this.close_to_expire_intermediate_products = response.data.intermediate_products;
			this.close_to_expire_products = response.data.products;
			this.close_to_expire_tp_products = response.data.tp_products;
			//console.log(this.close_to_expire_materials , this.close_to_expire_intermediate_products);
		});
		Dashboard.bad_document_type()
		.then(response => {
			this.bad_document_materials = response.data;
			//console.log(this.bad_document_materials);
		});
		Dashboard.missing_document()
		.then(response => {
			this.missing_document_containers = response.data;
			//console.log(this.missing_document_containers);
		});
		Dashboard.low_intermediate_product_inventory()
		.then(response => {
			this.low_intermediate_product_inventory = response.data
		});
		Dashboard.low_rawmaterial_inventory()
		.then(response => {
			this.low_rawmaterial_inventory = response.data
		});
	},
	methods: {
		fieldFn(rowObj) {
			return rowObj.productions.map(p => p.production_number).join(', ');
		},
		documentationOk(id){
			this.$confirm({
				message: `Biztos módosítja a dokumentáció státuszát?`,
				button: {
					no: 'Nem',
					yes: 'Igen'
				},
				/**
				* Callback Function
				* @param {Boolean} confirm
				*/
				callback: confirm => {
					if (confirm) {
						Dashboard.update_document_type(id)
						.then(() => {
							this.missing_document_containers.unshift(this.bad_document_materials[this.bad_document_materials.map(material => material.id).indexOf(id)]);
							this.bad_document_materials.splice(this.bad_document_materials.map(material => material.id).indexOf(id),1);
						});
					}
				}
			});
		},
		modalClose() {
			this.$vm2.close('document_modal');
			this.container = {};
		},
		openModal(container) {
			this.container = container;
			this.$vm2.open('document_modal');
		}
	}
};
</script>

<style>
</style>