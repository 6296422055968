import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/bundles");
  },
    
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/bundles", form);
  },
    
  async show(id) {
    await Csrf.getCookie();

    return Api.get("/bundles/" + id);
  },
    
  async update(id, form) {
    await Csrf.getCookie();

    return Api.patch("/bundles/" + id, form);      
      
  },
    
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/bundles/" + id);      
      
  },
	
	async supply(id, form) {
		await Csrf.getCookie();
		
		return Api.post("/bundles/" + id + "/supply", form);
	},
	
	async addSize(id,form) {
		await Csrf.getCookie();
		
		return Api.post("/bundles/" + id + "/store_recipe", form);
	},
	
	async deleteSize(id,form) {
		await Csrf.getCookie();
		
		return Api.post("/bundles/" + id + "/delete_recipe", form);
	},
	
	async avaibleProducts() {
		await Csrf.getCookie();
		
		return Api.get("/bundles/products");
	}
    
};