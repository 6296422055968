<template>
	<div>
		<PageHeader title="Rendelések" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="orders === false">
								<div class="col-12 text-center">
									<h3>Rendelések betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="orders.length == 0">
								<div class="m-b-md">
									<i class="fa fa-file-invoice-dollar fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek igénylések megrendelések
									</h3>
								</div>
							</div>
							<div class="tableFixHeadLarge" v-if="orders.length != 0 && orders !== false">
								<table class="table table-striped">
									<thead class="thead-dark">
										<th>Dátum</th>
										<th>Partner</th>
										<th>Bizományi</th>
										<th class="text-center">Tételek</th>
										<th class="text-right">Státusz</th>
										<th class="text-right">Műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(order, id) in orders" :key="id">
											<td>{{order.created_date}}</td>
											<td>{{order.partner.name}}</td>
											<td>
												<span v-if="order.partner.commission == 1" class="label label-primary"><i class="fa fa-check"></i> igen</span>
												<span v-else class="label label-success"><i class="fa fa-times"></i> nem</span>
											</td>
											<td class="text-right">{{order.items.length}} db</td>
											<td class="text-right"><span class="label" :class="statuses[statuses.map(status => status.id).indexOf(order.status)].label_class">{{statuses[statuses.map(status => status.id).indexOf(order.status)].name}}</span></td>
											<td class="text-right ">
												<button class="btn btn-primary btn-sm mx-1" @click="showOrder(order)" title="szerkesztés"><i class="fa fa-pen"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="order_edit_modal" class="custom-modal" v-if="this.order != null">
			<modal-vue modalSize="xl" @on-close="$vm2.close('order_edit_modal')" name="order_edit_modal" :headerOptions="{ title: 'Rendelés szerkesztése'}" :footerOptions="{
			btn1: 'Vissza',
			disableBtn2: true,
			btn1OnClick: () => {
				$vm2.close('order_edit_modal');
			},
		}">
				<div align="center" class="col-12">
					<table class="table">
						<tr class="py-0">
							<td class="py-0 text-center">
								<span class="font-weight-bold">Partner</span><br>
								<small>{{order.partner.name}}</small>
							</td>
							<td class="py-0 text-center">
								<span class="font-weight-bold">Dátum</span><br>
								<small>{{order.created_date}}</small>
							</td>
						</tr>
						<tr>
							<th class="text-center">Státusz</th>
							<td>
								<form @submit.prevent="updateOrderStatus(order)" v-if="![4,5].includes(orders[orders.map(obj => obj.id).indexOf(order.id)].status)">
									<div class="input-group">
										<select v-model="order.status" class="form-control">
											<option v-for="(status, id) in statuses" :value="status.id" :key="id">
												{{ status.name }}
											</option>
										</select>
										<div class="input-group-append" v-if="order.status != orders.find(o => o.id == order.id).status">
											<button type="submit" class="btn btn-primary"><i class="fa fa-save"></i></button>
										</div>
									</div>
								</form>
								<div v-else class="text-center">
									<span class="label" :class="statuses[statuses.map(status => status.id).indexOf(order.status)].label_class">{{statuses[statuses.map(status => status.id).indexOf(order.status)].name}}</span>
									<h5>{{order.updated_date}}</h5>
								</div>
							</td>
						</tr>
					</table>
					<h3>Tételek:</h3>
					<div class="tableFixHead">
						<table class="table table-striped">
							<thead class="thead-dark">
								<tr>
									<th>Megnevezés</th>
									<th class="text-center">Mennyiség</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, id) in order.items" :key="id">
									<td>{{item.product_name}} <span v-if="item.size_name != null"> - {{item.size_name}}</span></td>
									<td v-if="![4,5].includes(orders[orders.map(obj => obj.id).indexOf(order.id)].status)">
										<form @submit.prevent="updateOrderItemQuantity(item)">
											<div class="input-group">
												<input type="number" @wheel="$event.target.blur()" class="form-control text-right" v-model="item.quantity">
												<div class="input-group-append">
													<button type="submit" class="btn btn-primary"><i class="fa fa-save"></i></button>
												</div>
											</div>
										</form>
									</td>
									<td v-else class="text-right">{{item.quantity}} <span v-if="item.unit != null"> {{item.unit}}</span><span v-else> db</span></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../../components/PageHeader';
	import Order from '../../apis/Order';
	import Config from '../../apis/Config';

	export default {
		components: {
			PageHeader
		},
		data() {
			return {
				orders: false,
				statuses: null,
				order:null,
			}
		},
		
		computed: {
		},
		created() {
			Config.getConstant('order_status')
			.then(response =>{
				this.statuses = response.data; 
			});
			Order.getCommissions()
			.then(response => {
				this.orders = response.data;
			})
		},
		watch:{
			'order.status': function(){
				this.$forceUpdate(this.order);
			}
		},
		methods: {
			showOrder(order){
				let vue = this;
				new Promise(function(resolve){
					vue.order = JSON.parse(JSON.stringify(order));
					resolve('finished');
				}).then(() => {
					this.$vm2.open('order_edit_modal');
				});
			},
			updateOrderStatus(order){
				Order.updateStatus(order.id,{status:order.status})
				.then(() => {
					this.$set(this.orders[this.orders.map(order_obj => order_obj.id).indexOf(order.id)],'status',order.status);
				});
			},
			updateOrderItemQuantity(item){
				Order.updateItemQuantity(item.order_id,{id:item.id,quantity:item.quantity})
				.then(() => {
					Order.getCommissions()
					.then(response => {
						this.orders = response.data;
					}).then(() => {
						this.$forceUpdate(this.orders);
					});
				});
			}
		}
	};
</script>

<style>
</style>