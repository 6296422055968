<template>
	<div>
		<PageHeader title="Árkategóriák" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row">
								<div class="col">
									<div class="row">
										<div class="col-md-6">
											<h3>Új árkategória hozzáadása:</h3>
										</div>
										<div class="col-md-6">
											<form class="form-inline" @submit.prevent="addPriceCategory">
												<div class="input-group">
													<input type="text" class="form-control" v-model="price_category.name" required>
													<div class="input-group-append">
														<loading-button :loading="loading"><i class="fa fa-plus"></i> Hozzáadás</loading-button>
													</div>
												</div>
											</form>
										</div>
									</div>
									<div class="row" v-if="existing_price_categories === false">
										<div class="col-12 text-center">
											<h3>Árkategóriák betöltése</h3>
											<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
										</div>
									</div>
									<div class="widget p-lg text-center" v-if="existing_price_categories.length == 0">
										<div class="m-b-md">
											<i class="fa fa-dollar-sign fa-4x"></i>
											<h1 class="m-xs"><i class="fa fa-times"></i></h1>
											<h3 class="font-bold no-margins">
												Nincsenek árkategóriák
											</h3>
											<small>hozzon létre árkategóriát</small>
										</div>
									</div>
									<div v-if="existing_price_categories.length != 0" class="mt-2">
										<table class="table">
											<tbody>
												<tr v-for="(category, id) in existing_price_categories" :key="id">
													<td>
														<form @submit.prevent="updatePriceCategory(category)">
															<div class="input-group">
																<input type="text" class="form-control" v-model="category.name" required>
																<div class="input-group-append">
																	<button class="btn btn-primary"><i class="fa fa-save"></i> Mentés</button>
																</div>
															</div>
														</form>
													</td>
													<td>
														<loading-button class="btn btn-danger" :loading="loading" @click.native="deletePriceCategory(category)"><i class="fa fa-trash" v-if="!loading"></i></loading-button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import PriceCategory from '../../apis/PriceCategory';

export default {
    components: { PageHeader },
    data() {
        return {
					price_category: {},
					existing_price_categories: false,
					errors: [],
					loading:false,
        }
    },
	
    created() {
			PriceCategory.index()
			.then(response => {
				this.existing_price_categories = response.data;
			})
    },
    methods: {
			addPriceCategory(){
				this.loading = true;
				PriceCategory.add(this.price_category)
				.then(response => {
					this.existing_price_categories.push({
						id: response.data.id,
						name: this.price_category.name
					});
					this.price_category = {};
				})
				.catch(error => {
					this.errors = error.response.data.errors;
				}).finally(() => {
					this.loading = false;
				})
			},
			updatePriceCategory(category){
				PriceCategory.update(category.id, {name: category.name});
			},
			deletePriceCategory(category){
				this.loading = true;
				PriceCategory.delete(category.id)
				.then(() => {
					this.existing_price_categories.splice(this.existing_price_categories.map(data => data.id).indexOf(category.id),1);
				})
				.catch(error => {
					this.errors = error.response.data.errors; //nicht gut
				}).finally(() => {
					this.loading = false;
				})
			},
    }
};
</script>

<style>
</style>