<template>
	<div>
		<PageHeader title="Gyártás" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="products === false">
								<div class="col-12 text-center">
									<h3>Termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-cube fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek termékek
									</h3>
									<small>Csak akkor indíthat gyártást ha van legalább 1 termék</small><br>
										<router-link :to="'/products'" class="btn btn-xs btn-primary mt-1"><i class="fa fa-location-arrow"></i> termékekhez</router-link>
								</div>
							</div>
							<div class="table-responsive" v-if="products.length != 0 && products !== false">
								<div class="alert alert-danger" v-if="activeProduction">
									Van folyamatban lévő gyártás, csak az folytatható!
								</div>
								<input type="text" v-model="search" placeholder="keresés.."/>
								<table class="table table-striped w-100">
									<thead>
										<th @click="sort('name')" class="text-nowrap pointer">
												Megnevezés 
												<i v-if="currentSort == 'name' && currentSortDir == 'asc'" class="fa fa-sort-alpha-down"></i>
												<i v-if="currentSort == 'name' && currentSortDir == 'desc'" class="fa fa-sort-alpha-up-alt"></i>
										</th>
										<th>Leírás</th>
										<th class="text-right">műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(product, id) in filteredProducts" :key="id">
											<td>
												<p class="overflow-auto" style="max-height:4em;">
													{{product.name}}
												</p>
											</td>
											<td>
												<p class="overflow-auto" style="max-height:4em;">
													{{product.description}}
												</p>
											</td>
											<td class="text-right buttons-wide-3">
												<template  v-if="product.has_valid_recipe">
													<button  class="btn btn-primary mx-1 d-inline" @click="openProductionModal(product.id)" title="gyártás" v-if="activeProduction === false"><i class="fa fa-industry"></i></button>
													<button @click="continueActiveProduction" v-if="activeProduction && activeProduction.product_id == product.id" class="btn btn-primary mx-1 d-inline" title="gyártás folytatása"><i class="fa fa-industry"></i></button>
													<button v-if="activeProduction && activeProduction.product_id == product.id" class="btn btn-danger" @click="openDestroyProductionModal(product.id)"><i class="fa fa-trash"></i></button>
												</template>
												<router-link v-else :to="'product/'+ product.id" tag="a" class="btn btn-warning mx-1 d-inline" title="hiányos recept"><i class="fa fa-exclamation-triangle"></i></router-link>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div id="modal" >
				<modal-vue modalSize="l" 
				@on-close="$vm2.close('Production')"
					name="Production"
					:headerOptions="{
						title: 'Gyártás előkészítés',
					}"
				:footerOptions="{
        btn1: 'Mentés',
        btn2: 'Mégsem',
				btn1Style: {
          backgroundColor: '#1ab394',
          color: 'white',
        },
        btn2Style: {
          backgroundColor: '#ec4758',
        },
        btn2OnClick: () => {
					closeModal();
				},
        btn1OnClick: () => {
					checkProduction();
        },
      }"
			>
				<div class="col-10 modalBody">
					<form @submit.prevent="checkProduction()">
						<div class="form-group">
								<label>Gyártandó mennyiség grammban</label>
								<input @focus="$event.target.select()" type="text" ref="expected_quantity" class="form-control expected_quantity focus-on-me" v-model="productProduction.expected_quantity">
								<span class="text-danger" v-if="errors.expected_quantity">
									{{ errors.expected_quantity[0] }}
								</span>
						</div>
					</form>
				</div>
			</modal-vue>
			</div>
		
		<div id="destroymodal" >
				<modal-vue modalSize="l" 
				@on-close="$vm2.close('destroymodal')"
					name="destroymodal"
					:headerOptions="{
						title: 'Gyártás törlése',
					}"
				:footerOptions="{
        btn1: 'Mentés',
        btn2: 'Mégsem',
				btn1Style: {
          backgroundColor: '#1ab394',
          color: 'white',
        },
        btn2Style: {
          backgroundColor: '#ec4758',
        },
        btn2OnClick: () => {
					closeDestroyModal();
				},
        btn1OnClick: () => {
					destroyProduction();
        },
      }"
			>
				<div class="col-10 modalBody">
					<form @submit.prevent="destroyProduction()">
						<div class="form-group">
								<label>Törlés oka:</label>
							<textarea ref="destroy_description" class="form-control" required id="" cols="30" rows="10" v-model="productToDestroy.description"></textarea>
						</div>
					</form>
				</div>
			</modal-vue>
			</div>

			<div id="validate_modal" >
				<modal-vue modalSize="xl" style="z-index:9999999"
				@on-close="$vm2.close('validate_modal')"
					name="validate_modal"
					:headerOptions="{
						title: 'Gyártás előzetes ellenőrzése',
					}"
				:footerOptions="{
        btn1: 'Indítás',
        btn2: 'Mégsem',
				btn1Style: {
          backgroundColor: '#1ab394',
          color: 'white',
        },
        btn2Style: {
          backgroundColor: '#ec4758',
        },
        btn2OnClick: () => {
					closeValidateModal();
				},
        btn1OnClick: () => {
					createProduction();
        },
      }"
			>
				<div class="col-10 modalBody">
					<i class="fa fa-cog fa-3x" v-if="!validated_materials"></i>
					<div class="tableFixHead" v-else>
						<table class="table">
							<thead class="thead-light">
								<th>Alapanyag</th>
								<th class="text-right d-none d-md-table-cell">Készlet</th>
								<th class="text-right d-none d-md-table-cell">Gyártáshoz szükséges</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-for="(material,id) in validated_materials" :key="id">
									<td>
										{{material.name}}
										<span class="d-table-cell d-md-none">{{material.quantity}} / {{ (parseFloat(material.pivot.percentage)/100*parseInt(productProduction.expected_quantity)*(1+parseFloat(material.production_treshold)/100)).toFixed(2) }}</span>
									</td>
									<td class="text-right d-none d-md-table-cell">{{material.quantity}}</td>
									<td class="text-right d-none d-md-table-cell">{{ (parseFloat(material.pivot.percentage)/100*parseInt(productProduction.expected_quantity)*(1+parseFloat(material.production_treshold)/100)).toFixed(2) }}</td>
									<td class="text-right">
										<span class="badge badge-primary" v-if="parseFloat(material.pivot.percentage)/100*parseInt(productProduction.expected_quantity)*(1+parseFloat(material.production_treshold)/100)*1.1 <= material.quantity">
											<i class="fa fa-check-circle"></i> Elegendő készlet
										</span>
										<span v-else>
											<div class="bg-danger p-1 d-table-cell d-md-none text-center">
											<i class="fa fa-exclamation-triangle"></i> A biztonságos gyártáshoz {{(parseFloat(material.pivot.percentage)/100*parseInt(productProduction.expected_quantity)*(1+parseFloat(material.production_treshold)/100)*1.1).toFixed(2)}} {{material.unit}} szükséges</div>
											<span class="d-none d-md-table-cell badge badge-danger">
											<i class="fa fa-exclamation-triangle"></i> A biztonságos gyártáshoz {{(parseFloat(material.pivot.percentage)/100*parseInt(productProduction.expected_quantity)*(1+parseFloat(material.production_treshold)/100)*1.1).toFixed(2)}} {{material.unit}} szükséges</span>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</modal-vue>
			</div>
		
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Product from "../../apis/Product";
import Production from "../../apis/Production";

export default {
    components: { PageHeader },
    data() {
        return {
					products: false,
					productProduction:{},
					productToDestroy:{},
					activeProduction:false,
					validated_materials:false,
					errors: [],
					search:'',
					currentSort:'name',
					currentSortDir:'asc'
        }
    },
	//button hoz v-if="!activeProduction"
    created() {
			this.initializer();
    },
	computed: {
			filteredProducts: function(){
				return this.products.filter(product => {
					return product.name.toLowerCase().includes(this.search.toLowerCase()) && product.is_third_party != 1
				}).sort((a,b) => {
					let modifier = 1;
					if(this.currentSortDir === 'desc') modifier = -1;
					if(a[this.currentSort][0].localeCompare(b[this.currentSort][0],'hu-HU') < 0) return -1 * modifier;
					if(a[this.currentSort][0].localeCompare(b[this.currentSort][0],'hu-HU') > 0) return 1 * modifier;
					return 0;
				});
			},
		},
    methods: {
			initializer(){
				Product.index()
				.then(response => {
					this.products = response.data;

					this.products.forEach(element => {
						if(element.productions.length != 0 )
						{
							this.activeProduction = element.productions[0];
						}
					});
				});
			},
			continueActiveProduction(){
				Production.canContinue(this.activeProduction.id)
				.then(response => {
					this.$router.push({path: response.headers['content-location'] })
				});
			},
			checkProduction(){
				Production.validateQuantities(this.productProduction)
				.then( response => {
					this.validated_materials = response.data;
					this.$vm2.open('validate_modal');
					this.$vm2.close('Production');
				});
			},
			sort:function(s) {
				//if s == current sort, reverse
				if(s === this.currentSort) {
					this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
				}
				this.currentSort = s;
			},
			openProductionModal(id){	
				this.$vm2.open('Production');
				setTimeout(() => this.$refs.expected_quantity.focus(),500);
				this.productProduction.product_id = id			
			},
			destroyProduction(){
				Production.destroy(this.activeProduction.id,{description: this.productToDestroy.description}).then(() => {
					this.closeDestroyModal();
					this.activeProduction = false;
					this.initializer();
				});
			},
			openDestroyProductionModal(id){	
				this.$vm2.open('destroymodal');
				setTimeout(() => this.$refs.destroy_description.focus(),500);
				this.productToDestroy.product_id = id			
			},
			createProduction(){
				Production.store(this.productProduction)
				.then( response => {
					let vue = this;
					new Promise(function(resolve) { 
						vue.$vm2.close('Production');
						resolve('finished')
					}).then(() => {
						this.$router.push({path: response.headers['content-location'] })
					});
				})
				.catch(error => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
						this.$refs.expected_quantity.focus()
					}
				})
				.finally(() => this.loading = false)
			},
			closeValidateModal(){
				this.$vm2.close('validate_modal');
				this.$vm2.open('Production');
				this.validated_materials = false;
			},
			closeModal(){
				this.$vm2.close('Production');
				this.productProduction = {}
			},
			closeDestroyModal(){
				this.$vm2.close('destroymodal');
				this.productToDestroy = {}
			}
		},
};
	
	
</script>

<style>
#modal {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
	.expected_quantity{
		text-align:right
	}
</style>