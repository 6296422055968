import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async log(array) {
    await Csrf.getCookie();

    return Api.post("/log/find",array);
  },
	async wrapping() {
		await Csrf.getCookie();
		
		return Api.get("/log/wrapping");
	},
	async inventory() {
		await Csrf.getCookie();
		
		return Api.get("/log/inventory");
	},
	async raw_material() {
		await Csrf.getCookie();
		
		return Api.get("/log/raw_material");
	},
	async intermediate_product() {
		await Csrf.getCookie();
		
		return Api.get("/log/intermediate_product");
	},
	async filled_product() {
		await Csrf.getCookie();
		
		return Api.get("/log/filled_product");
	},
	async packaged_product() {
		await Csrf.getCookie();
		
		return Api.get("/log/packaged_product");
	},
	async order() {
		await Csrf.getCookie();
		
		return Api.get("/log/order");
	},
	async order_log_excel(data) {
		await Csrf.getCookie();
		
		return Api.post("/log/order_log_excel",data);
	},
    
};