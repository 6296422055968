<template>
	<div>
		<PageHeader title="Leltár napló" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="inventories === false">
								<div class="col-12 text-center">
									<h3>leltárak betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="inventories.length == 0">
								<div class="m-b-md">
									<i class="fa fa-search fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek leltárak
									</h3>
								</div>
							</div>
							<div class="table-responsive" v-if="inventories.length != 0 && inventories !== false">
								<vue-good-table
									styleClass="vgt-table striped" 
									rowStyleClass="fs-12"
									:columns="columns" 
									:rows="inventories" 
									:search-options="{ enabled: false, placeholder: 'Keresés...' }" 
									:pagination-options="this.$vueGTPaginationOptions"
								>
									<template slot="table-row" slot-scope="props">
										<span v-if="props.column.field == 'quantity'">
											{{props.row.quantity}} db
										</span>
										<span v-else-if="props.column.field == 'actions'">
											<button @click="downloadXlsx(props.row.id)" class="btn btn-success mx-1 d-inline" title="letöltés" ><i class="fa fa-download"></i></button>
										</span>
										<span v-else>
											{{props.formattedRow[props.column.field]}}
										</span>
									</template>
								</vue-good-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Log from "../../apis/Log";

export default {
    components: { PageHeader },
    data() {
        return {
					inventories: false,
					errors: [],
					columns:[
						{
							label:'Leltárhely',
							field:'storage_name',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Nyitva',
							field:'created_at',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Lezárva',
							field:'finished_at',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Tételek száma',
							field:'item_count',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label: 'Műveletek',
							field: 'actions',
							width: '15%',
							sortable: false,
							thClass: 'text-right fs-12',
							tdClass: 'text-right'
						}
					]
        }
    },
	
    created() {
			Log.inventory()
			.then(response => {
				this.inventories = response.data;
			});	
    },
    methods: {
		downloadXlsx(id){
			var link = document.createElement('a');
			link.href = process.env.VUE_APP_PRINT_URL+'/api/inventories/'+id+'/download-excel';
			link.download = id+'_leltar_adatok.xlsx';
			link.click();
		}
    }
};
</script>

<style>
</style>