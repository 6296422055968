<template>
	<div class="row border-bottom">
		<nav class="navbar navbar-static-top py-2" role="navigation">
			<div class="col-12 text-right">
				<button class="btn btn-default d-flex align-items-center float-right" @click.prevent="showUserContextMenu = !showUserContextMenu">
					<i class="fa mr-2" :class="showUserContextMenu ? 'fa-caret-up' : 'fa-caret-down'"></i>
					<span class="font-bold mr-2">{{ this.$root.$data.user.name }}</span>
					<img class="rounded-circle border" width="26" height="26" :src="this.$root.$data.user.profile_image" v-if="this.$root.$data.user.profile_image"/>
					<i class="fa fa-user-circle fa-2x" v-else></i>
				</button>
				
				<ul v-if="showUserContextMenu" style="margin-top:40px;">
					<li class="border-bottom">
						<router-link tag="a" to="/profile" class="text-dark">
							<i class="fa fa-user-circle"></i> Profilom
						</router-link>
					</li>
					<li class="text-right">
						<a href="#" @click.prevent="logout()" class="btn btn-xs btn-danger">
							<i class="fa fa-sign-out-alt"></i> Kijelentkezés
						</a>
					</li>
				</ul>
			</div>
		</nav>
        </div>
</template>

<script>
import User from "../../apis/User";
export default {
    name:'Topbar', 
	data(){
		return{
			showUserContextMenu: false
		}
	},
	methods:{
		logout(){
			User.logout().then(() =>  {
				localStorage.removeItem("auth");
				localStorage.removeItem("user");
				this.$router.push({name:"Login"});
			})
		}
	}
}
</script>

<style>
	.cursor-pointer{cursor:pointer}
	.navbar ul{position:absolute; right:2.5em; border:1px solid #e7eaec; z-index:10; background:#fff; border-radius:3px; padding:0 0.2em 0 0.2em; list-style:none;}
	.navbar ul li{border-radius: 3px; color: inherit; line-height: 25px; margin: 0.75em; text-align: right; font-weight: normal; padding:0 1em 0 2em}
	.navbar ul li:hover{color: #262626; text-decoration: none; background-color: #f5f5f5;}
	.navbar ul li a{display:block;}
</style>