<template>
	<div id="app">
		<vue-confirm-dialog></vue-confirm-dialog>
		<component :is="this.$route.meta.layout || 'div'">
			<router-view />
		</component>
	</div>
</template>

<script>
export default {
	name: "App",
};
</script>
