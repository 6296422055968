<template>
		<div>
				<PageHeader title="Partner létrehozása" />
				<div class="row">
						<div class="col-lg-6">
								<div class="ibox">
										<div class="ibox-content">
											<div class="row" v-if="price_categories === false">
												<div class="col-12 text-center">
													<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
												</div>
											</div>
											<div class="widget p-lg text-center" v-if="price_categories.length == 0">
												<div class="m-b-md">
													<i class="fa fa-dollar-sign fa-4x"></i>
													<h1 class="m-xs"><i class="fa fa-times"></i></h1>
													<h3 class="font-bold no-margins">
														Nincsenek árkategóriák
													</h3>
													<small>Csak akkor hozhat létre partnert ha van legalább 1 árkategória</small>
												</div>
											</div>
												<form @submit.prevent="addPartner" v-if="price_categories !== false && price_categories.length != 0">
													<div class="row">
													<div class="col-md-6">
														<div class="form-group">
																<label>Név</label>
																<input type="text" class="form-control" v-model="partner.name">
																<span class="label label-danger" v-if="errors.name">
																		{{ errors.name[0] }}
																</span>
														</div>
														<div class="form-group">
																<label>Irányítószám</label>
																<input type="number" class="form-control" v-model="partner.zip">
																<span class="label label-danger" v-if="errors.zip">
																		{{ errors.zip[0] }}
																</span>
														</div>
														<div class="form-group">
																<label>Város</label>
																<input type="text" class="form-control" v-model="partner.city">
																<span class="label label-danger" v-if="errors.city">
																		{{ errors.city[0] }}
																</span>
														</div>
														<div class="form-group">
																<label>Utca</label>
																<input type="text" class="form-control" v-model="partner.street">
																<span class="label label-danger" v-if="errors.street">
																		{{ errors.street[0] }}
																</span>
														</div>
														<div class="form-group">
																<label>Közterület tipusa</label>
																<select class="form-control" v-model="partner.street_type">
																	<option :value="undefined" disabled>Válassz közterület típust</option>
																	<option v-for="(street_type,id) in street_types" :value="id" :key="id">
																		{{street_type}}
																	</option>
																</select>
																<span class="label label-danger" v-if="errors.street_type">
																		{{ errors.street_type[0] }}
																</span>
														</div>
														<div class="form-group" v-if="partner.type == 2">
															<label>Bizományi:</label>
															<div class="input-group">
																<div class="input-group-prepend">
																	<div class="input-group-addon">
																		<input type="checkbox" v-model="partner.commission" true-value="1" false-value="0">
																	</div>
																</div>
																<span class="form-control mw-25 overflow-hidden"> Bizományi értékesítés</span>
																<div class="input-group-append">
																	<span class="input-group-addon" :class="{'bg-danger': partner.commission == 0,'bg-primary': partner.commission == 1}"><i class="fa" :class="{'fa-times': partner.commission == 0,'fa-check': partner.commission == 1}"></i></span>
																</div>
															</div>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group">
																<label>Házszám</label>
																<input type="text" class="form-control" v-model="partner.number">
																<span class="label label-danger" v-if="errors.number">
																		{{ errors.number[0] }}
																</span>
														</div>
														<div class="form-group">
																<label>Ajtószám</label>
																<input type="number" class="form-control" v-model="partner.floor_door">
																<span class="label label-danger" v-if="errors.floor_door">
																		{{ errors.floor_door[0] }}
																</span>
														</div>
														<div class="form-group">
																<label>Adószám</label>
																<input type="text" class="form-control" v-model="partner.tax_number">
																<span class="label label-danger" v-if="errors.tax_number">
																		{{ errors.tax_number[0] }}
																</span>
														</div>

														<div class="form-group">
																<label>Partner típusa</label>
																<select class="form-control no-cut" v-model="partner.type" @change="noCategory">
																	<option :value="null" disabled>Válassz típust</option>
																	<option v-for="(type,id) in types" :value="id" :key="id">
																		{{ type}}
																	</option>
																</select>
																<span class="label label-danger" v-if="errors.type">
																		{{ errors.type[0] }}
																</span>
														</div>
														
														<div class="form-group">
																<label>Partner kategória</label>
																<select class="form-control no-cut" v-model="partner.category">
																	<option :value="null" disabled>Válassz kategóriát</option>
																	<option v-for="(category,id) in categories" :value="category.id" :key="id" v-show="category.partner_type_id == partner.type">
																		{{ category.name }}
																	</option>
																</select>
																<span class="label label-danger" v-if="errors.category">
																		{{ errors.category[0] }}
																</span>
														</div>
														
														<div class="form-group" v-if="partner.type == 2">
																<label>Árkategória</label>
																<select class="form-control no-cut" v-model="partner.price_category_id">
																	<option :value="null" disabled>Válassz kategóriát</option>
																	<option v-for="(price_category,id) in price_categories" :value="price_category.id" :key="id">
																		{{ price_category.name }}
																	</option>
																</select>
																<span class="label label-danger" v-if="errors.price_category_id">
																		{{ errors.price_category_id[0] }}
																</span>
														</div>
													</div>
														</div>
														<loading-button :loading="loading">Mentés</loading-button>
														<router-link :to="{path:'/partners'}" class="btn btn-danger mx-2">Mégsem</router-link>			
												</form>
										</div>
								</div>
						</div>
				</div>
		</div>
</template>
 
<script>
import PageHeader from '../../components/PageHeader';
import Partner from "../../apis/Partner"; 
import Config from "../../apis/Config"; 
import PriceCategory from "../../apis/PriceCategory"; 

		export default {
				components: {PageHeader},
				data() {
						return {
							categories : false,
							price_categories : false,
							types : false,
							street_types : false,
							partner: {
								type: null,
								price_category_id:null,
								category:null,
							},
							errors: [],
							loading:false,
						}
				},
				created(){
					this.$set(this.partner,'commission',0);
					Config.getConstant('partner_categories')
					.then(response => {
						this.categories = response.data;
					})
					Config.getConstant('type')
					.then(response => {
						this.types = response.data;
					})
					Config.getConstant('street_types')
					.then(response => {
						this.street_types = response.data;
					})
					PriceCategory.index()
					.then(response => {
						this.price_categories = response.data
						//console.log(this.price_categories);
					})
				},
				methods: {
					addPartner() {
						this.loading = true;
						//console.log(this.partner);
						Partner.add(this.partner)
							.then(() => {
								this.$router.push({ name: 'Partners' })
							})
							.catch(error => {
								this.errors = error.response.data.errors;
							}).finally(() => {
								this.loading = false;
							})
					},
					noCategory(){
						this.partner.category = null;
						this.partner.price_category_id = null;
					}
				}
		}
</script>