<template>
		<div>
			<PageHeader :title="title" />
				<div class="row">
					<div class="col-lg-5">
						<div class="ibox" v-if="product">
							<div class="ibox-title">
								<h5>Alapadatok</h5>
							</div>
							<div class="ibox-content">
								<div class="row" v-if="intermediate_product_storage_places === false || filled_product_storage_places === false || finished_product_storage_places === false">
									<div class="col-12 text-center">
										<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
									</div>
								</div>
								<div class="widget p-lg text-center" v-if="intermediate_product_storage_places.length == 0 || filled_product_storage_places.length == 0 || finished_product_storage_places.length == 0">
									<div class="m-b-md">
										<i class="fa fa-gift fa-4x"></i>
										<h1 class="m-xs"><i class="fa fa-times"></i></h1>
										<h3 class="font-bold no-margins">
											Nincs <span v-if="finished_product_storage_places.length == 0">Késztermék, </span><span v-if="intermediate_product_storage_places.length == 0">Félkész termék, </span><span v-if="filled_product_storage_places.length == 0">Csomagolásra váró </span> raktárhely!
										</h3>
										<small>kész, félkész és csomagolásra váró típusú raktárhely is szükséges termék létrehozáshoz!</small>
									</div>
								</div>
								<form @submit.prevent="product.id ? updateProduct() : addProduct() " v-if="intermediate_product_storage_places.length != 0 && filled_product_storage_places.length != 0 && finished_product_storage_places.length != 0 && intermediate_product_storage_places !== false && filled_product_storage_places !== false && finished_product_storage_places !== false">
										<div class="form-group">
												<label>Megnevezés</label>
												<input type="text" class="form-control mb-2" v-model="product.name">
												<span class="label label-danger" v-if="errors.name">
														{{ errors.name[0] }}
												</span>
										</div>
										<div class="form-group">
												<label>Leírás</label>
												<textarea rows="5" type="text" class="form-control mb-2" v-model="product.description"></textarea>
												<span class="label label-danger" v-if="errors.description">
														{{ errors.description[0] }}
												</span>
										</div>
										<div class="form-group">
											<label>Mértékegység</label>
											<select v-model="product.unit" class="form-control mb-2">
												<option :value="undefined" disabled>Válassz mértékegységet</option>
												<option v-for="(unit,id) in units" :value="unit.value" :key="id">
													{{ unit.text }}
												</option>
											</select>
											<span class="label label-danger" v-if="errors.unit">
													{{ errors.unit[0] }}
											</span>
										</div>
										<div class="form-group">
											<label>Harmadik féltől származó:</label>
											<div class="input-group">
												<div class="input-group-prepend">
													<div class="input-group-addon">
														<input type="checkbox" v-model="product.is_third_party" :true-value="1" :false-value="0">
													</div>
												</div>
												<span class="form-control mw-25 overflow-hidden"> Harmadik féltől származó termék</span>
												<div class="input-group-append">
													<span class="input-group-addon" :class="{'bg-danger': product.is_third_party == 0,'bg-primary': product.is_third_party == 1}"><i class="fa" :class="{'fa-times': product.is_third_party == 0,'fa-check': product.is_third_party == 1}"></i></span>
												</div>
											</div>
										</div>
										<div class="form-group">
											<label>LIFO:</label>
											<div class="input-group">
												<div class="input-group-prepend">
													<div class="input-group-addon">
														<input type="checkbox" v-model="product.lifo" :true-value="1" :false-value="0">
													</div>
												</div>
												<span class="form-control mw-25 overflow-hidden"> LIFO sorrendet igényel</span>
												<div class="input-group-append">
													<span class="input-group-addon" :class="{'bg-danger': product.lifo == 0,'bg-primary': product.lifo == 1}"><i class="fa" :class="{'fa-times': product.lifo == 0,'fa-check': product.lifo == 1}"></i></span>
												</div>
											</div>
										</div>
										<div class="form-group" v-if="product.is_third_party != 1">
											<label>Félkész termék raktárhely</label>
											<select v-model="product.intermediate_product_storage_place_id" class="form-control mb-2">
												<option :value="undefined" disabled>Válassz félkész termék raktárhelyet</option>
												<option v-for="(storage_place, id) in intermediate_product_storage_places" :value="storage_place.id" :key="id">
													{{ storage_place.name }}
												</option>
											</select>
											<span class="label label-danger" v-if="errors.unit">
													{{ errors.intermediate_product_storage_place_id[0] }}
											</span>
										</div>
										<div class="form-group" v-if="product.is_third_party != 1">
											<label>Csomagolásra váró raktárhely</label>
											<select v-model="product.filled_product_storage_place_id" class="form-control mb-2">
												<option :value="undefined" disabled>Válassz csomagolásra váró raktárhelyet</option>
												<option v-for="(storage_place, id) in filled_product_storage_places" :value="storage_place.id" :key="id">
													{{ storage_place.name }}
												</option>
											</select>
											<span class="label label-danger" v-if="errors.unit">
													{{ errors.filled_product_storage_place_id[0] }}
											</span>
										</div>
										<div class="form-group">
											<label>Késztermék raktárhely</label>
											<select v-model="product.finished_product_storage_place_id" class="form-control mb-2">
												<option :value="undefined" disabled>Válassz késztermék raktárhelyet</option>
												<option v-for="(storage_place, id) in finished_product_storage_places" :value="storage_place.id" :key="id">
													{{ storage_place.name }}
												</option>
											</select>
											<span class="label label-danger" v-if="errors.unit">
													{{ errors.finished_product_storage_place_id[0] }}
											</span>
										</div>
										<div class="form-group">
											<label>Félkész termék lejárati figyelmeztetés</label>
											<div class="input-group">
												<div class="input-group-prepend">
													<span class="input-group-addon">lejárat elött</span>
												</div>
												<input type="number" max="180" v-model="product.warning_at" class="form-control text-right">
												<div class="input-group-append">
													<span class="input-group-addon">nappal</span>
												</div>
											</div>
											<span class="label label-danger" v-if="errors.warning_at">
													{{ errors.warning_at[0] }}
											</span>
										</div>
										<button type="submit" class="btn btn-primary">Mentés</button>
										<router-link :to="{path:'/products'}" class="btn btn-danger mx-2">Mégsem</router-link>
								</form>
							</div>
						</div>
						<div class="ibox" v-if="product.id">
							<div class="ibox-title">
								<h5>Árak</h5>
							</div>
							<div class="ibox-content">
								<form @submit.prevent="addPrice" class=" row" v-if="product.sizes.length > 0 && price_categories.length > 0">
									<div class="col-sm-4">
										<select v-model="price.price_category_id" class="form-control">
											<option :value="undefined" disabled>Válassz kategóriát</option>
											<option v-for="(price_category,id) in price_categories" :value="price_category.id" :key="id">
												{{ price_category.name }}
											</option>
										</select>
									</div>
									<div class="col-sm-4">
										<select v-model="price.size_id" class="form-control">
											<option :value="undefined" disabled>Válassz kiszerelést</option>
											<option v-for="(size,id) in product.sizes" :value="size.id" :key="id">
												{{size.name}}
											</option>
										</select>
										<span class="label label-danger" v-if="errors.unit">
												{{ errors.finished_product_storage_place_id[0] }}
										</span>
									</div>
									<div class="col-sm-3">
										<div class="input-group">
											<input type="number" max="2147483647" v-model="price.price" class="form-control text-right" placeholder="nettó ár">
											<div class="input-group-append">
												<span class="input-group-addon">Ft</span>
											</div>
										</div>
									</div>
									<div class="col-sm-1 text-center">
										<loading-button :loading="loading"><i class="fa fa-plus" v-if="!loading"></i></loading-button>
									</div>
								</form>
								<div class="widget style1 yellow-bg" v-if="product.sizes <= 0">
									<div class="row vertical-align">
										<div class="col-3">
											<i class="fa fa-exclamation-triangle fa-2x"></i>
										</div>
										<div class="col-9 text-right">
											<h3>Csak akkor hozhat létre árakat ha vannak kiszerelések!</h3>
										</div>
									</div>
								</div>
								<div class="widget style1 yellow-bg" v-if="price_categories.length <= 0">
									<div class="row vertical-align">
										<div class="col-3">
											<i class="fa fa-exclamation-triangle fa-2x"></i>
										</div>
										<div class="col-9 text-right">
											<h3>Csak akkor hozhat létre árakat ha vannak árkategóriák!</h3>
										</div>
									</div>
								</div>
								<div class="table-responsive" v-if="prices_exist">
									<table class="table" >
										<thead>
											<tr>
												<th>kategória</th>
												<th>kiszerelés</th>
												<th>nettó ár</th>
												<th>törlés</th>
											</tr>
										</thead>
										<tbody v-for="(size,id) in product.sizes" :key="id">
											<tr v-for="(price, id) in size.prices" :key="id">
												<td>{{price.category_name}}</td>
												<td>{{price.size_name}}</td>
												<td class="text-right">{{price.price.toLocaleString('hu-HU')}} Ft</td>
												<td class="text-right">
													<button class="btn btn-danger btn-xs" @click="deletePrice(price)"><i class="fa fa-trash"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="!prices_exist">
									<div class="widget  p-lg text-center">
										<div class="m-b-md">
											<i class="fa fa-dollar-sign fa-4x"></i>
											<h1 class="m-xs"><i class="fa fa-times"></i></h1>
											<h3 class="font-bold no-margins"> A termékhez nem tartoznak árak </h3>
											<small>adjon hozzá árat</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				<div	class="col-lg-7"	v-if="product.id">
					<div	class="ibox" v-if="product.is_third_party != 1">
						<div class="ibox-title">
							<h5>Termék receptjei</h5>	
							<div class="ibox-tools">
								<router-link :to="{path:'/recipes/'+product.id+'/create' }"	 class="btn	btn-primary btn-xs" v-if="notDevCount == 0"><i class="fa fa-plus" ></i> új recept</router-link>
							</div>
						</div>
						<div	class="ibox-content">
							<div class="input-group" v-if="product.development">
								<div class="input-group-prepend">
									<span class="input-group-addon">
										Termék fejlesztési költsége:
									</span>
								</div>	
								<span class="form-control text-right">{{parseFloat(product.product_development_cost).toFixed(2).toLocaleString('hu-HU')}}</span>
								<div class="input-group-append">
									<span class="input-group-addon">
										Ft
									</span>
									<button class="btn btn-success" @click="showDevelopmentData"><i class="fa fa-info"></i></button>
								</div>	
							</div>
							<div class="table-responsive">
								<table	class="table table-striped w-100" v-if="product.recipes.length > 0">
									<thead>
										<th>Létrehozás dátuma</th>
										<th>Megnevezés</th>
										<th>Gyártások száma</th>
										<th>1{{ product.unit }} ára</th>
										<th class="text-center">műveletek</th>
									</thead>
									<tbody>
										<tr	v-for="(recipe,	id)	in	product.recipes"	:key="id">
											<td class="project-title" :class="{'text-danger font-bold': recipe.deleted}">
												{{recipe.created_date}}
											</td>
											<td	class="project-title" :class="{'text-danger font-bold': recipe.deleted}">
												{{recipe.name}}
											</td>
											<td	class="project-title" :class="{'text-danger font-bold': recipe.deleted}">
												{{recipe.has_manufacturing}}
											</td>
											<td	class="project-title" :class="{'text-danger font-bold': recipe.deleted}">
												{{recipe.one_unit_price}} Ft
											</td>
											<td	class="text-center buttons-wide-3" >
												<button @click="openModal(recipe.id)" 	class="btn btn-warning mx-1 d-inline" title="megtekíntés"><i class="fa fa-eye"></i></button>
												<router-link v-if="recipe.deleted == 0"	:to="'/recipes/'+recipe.id+'/edit'"	class="btn	btn-primary mx-1 d-inline"	title="szerkesztés"><i	class="fa	fa-pencil-alt"></i></router-link>
												<button v-if="recipe.has_manufacturing != 0 "	@click="reproductionRecipe(recipe.id)"	class="btn	btn-success mx-1 d-inline"	title="másolás"><i class="fa fa-copy"></i></button>
												<button	v-if="recipe.has_manufacturing == 0 && !recipe.deleted" class="btn	btn-danger mx-1 d-inline"	@click="deleteRecipes(recipe.id)"	title="törlés"><i	class="fa	fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
								<div v-if="product.recipes.length <= 0">
									<div class="widget  p-lg text-center">
										<div class="m-b-md">
											<i class="fa fa-map fa-4x"></i>
											<h1 class="m-xs"><i class="fa fa-times"></i></h1>
											<h3 class="font-bold no-margins"> A termékhez nem tartoznak receptek </h3>
											<small>adjon hozzá receptet</small><br>
											<router-link v-if="!product.development" :to="{path:'/products/'+product.id+'/development' }" class="btn btn-success text-white btn-xs" style="color:white !important;"><i class="fa fa-puzzle-piece"></i> fejlesztés</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div	class="ibox">
						<div class="ibox-title">
							<h5>Termék kiszerelései</h5>
						</div>	
						<div	class="ibox-content">
							<div class="row d-flex justify-content-center mb-2">
								<form @submit.prevent="addProductSize()" class="form-inline">
									<div class="form-group">
										<input type="text" class="form-control form-control-sm" v-model="size.name" placeholder="Megnevezés">
									</div>
									<div class="form-group">
										<input type="number" max="2147483647" step="0.1" class="form-control form-control-sm" v-model="size.size" placeholder="töltési átlagtömeg (g)">
									</div>
									<div class="form-group">
										<button type="submit" class="btn btn-primary btn-sm"><i class="fa fa-save"></i> Mentés</button>	
									</div>
								</form>
										<span class="label label-danger col-3" v-if="errors.name">
											{{ errors.name[0] }}
										</span>
										<span class="label label-danger col-3" v-if="errors.size">
											{{ errors.size[0] }}
										</span>
							</div>
							<div class="table-responsive" v-if="product.sizes.length > 0">
								<table	class="table table-striped w-100">
									<thead>
										<th>Megnevezés</th>
										<th>Töltési átlagtömeg (g)</th>
										<th>Legutóbbi gyártás ára</th>
										<th>Aktuális bekerülési ár</th>
										<th class="text-center">műveletek</th>
									</thead>
									<tbody>
										<tr	v-for="(size,	id)	in	product.sizes" :key="id">
											<td	class="project-title">
												{{size.name}}
											</td>
											<td	class="project-title">
												{{size.size}} {{product.unit}}
											</td>
											<td>
												{{size.factory_cost ? size.factory_cost.toLocaleString('hu-HU')+' Ft' : 'nem számolható'}}
											</td>
											<td>
												{{ (size.wrapping_price + (product.recipes.find(r => r.deleted == 0) ? product.recipes.find(r => r.deleted == 0).one_unit_price*size.size : 0)).toFixed(2).toLocaleString('hu-HU')  }} Ft
											</td>
											<td	class="text-center buttons-wide-3">
												<router-link	class="btn btn-primary mx-1 d-inline"	:to="'/product_sizes/'+size.id"	title="Termék kiszerelés szerkesztése" v-if="product.is_third_party == 0"><i class="fa fa-inbox"></i></router-link>
												<button	class="btn btn-danger mx-1 d-inline"	@click="deleteSize(size.id)"	title="törlés"><i	class="fa	fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-if="product.sizes.length <= 0">
								<div class="widget  p-lg text-center">
									<div class="m-b-md">
										<i class="fa fa-shopping-basket fa-4x"></i>
										<h1 class="m-xs"><i class="fa fa-times"></i></h1>
										<h3 class="font-bold no-margins"> A termékhez nem tartoznak kiszerelések </h3>
										<small>adjon hozzá kiszerelést</small>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="product.id">
						<div class="ibox">
							<div class="ibox-title"><h5>Képfeltöltés</h5></div>
							<div class="ibox-content">
								<ImageUpload :parent_model="parent_model" :model_id="product.id" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="modal" >
				<modal-vue modalSize="lg" 
				@on-close="$vm2.close('modal-1')"
					name="modal-1"
					:headerOptions="{
						title: 'Recept részletei',
					}"
				noFooter
			>
				<div class="col-10 modalBody">
					<table class="table">
						<tr>
							<td>{{recipe.name}} <span v-if="recipe.development">fejlesztői recept</span></td>
							<td>Recept dátuma {{ recipe.created_date }}</td>
						</tr>
						<tr>
							<td><span class="label_name">Gyártások száma:</span> {{recipe.has_manufacturing}}</td>
							<td><span class="label_name">Gyártott mennyiség:</span> {{recipe.manufactured_quantity}} {{product.unit}}</td>
						</tr>
						<tr>
							<td>
								<p style="max-height:400px;" class="overflow-auto">
									{{recipe.description}}
								</p>
							</td>
							<td>
								<div style="max-height:400px;" class="overflow-auto">
									<div v-for="(raw_material,id) in raw_materials" :key="id"> 
											{{ raw_material.name }} {{ raw_material.pivot.percentage}} %
									</div>
								</div>
							</td>
						</tr>
					</table>
				</div>
			</modal-vue>
			</div>
			<div id="modal2" >
				<modal-vue modalSize="lg" 
				@on-close="$vm2.close('modal-2')"
					name="modal-2"
					:headerOptions="{
						title: 'Fejlesztés részletei',
					}"
				noFooter
			>
				<div class="col-10 modalBody mb-5">
					<div class="col-12 text-center" v-if="!development_data">
						<div class="spinner-border" style="margin-left:auto;margin-right:auto;"></div>
					</div>
					<div class="tableFixHead" v-else>
						<table class="table">
							<thead class="thead-dark">
								<tr>
									<th>Alapanyag</th>
									<th>Vonalkód</th>
									<th>Felhasznált mennyiség</th>
									<th>Ára</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(container,id) in development_data" :key="id">
									<td>{{ container.material_name }}</td>
									<td>{{ container.barcode }}</td>
									<td class="text-right">{{ container.used }} {{ product.unit }}</td>
									<td class="text-right">{{ parseFloat(container.used_price).toLocaleString('hu-HU') }} Ft</td>
								</tr>
							</tbody>
							<tfoot class="thead-dark">
								<tr>
									<th colspan="3">Összesen</th>
									<th>{{ product.development.price }} Ft</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</modal-vue>
			</div>
	</div> 
</template>

<script>
	import PageHeader from '../../components/PageHeader';
	import Product from "../../apis/Product";
	import ProductSize from "../../apis/ProductSize";
	import Recipe from "../../apis/Recipe";
	import ImageUpload from "../../components/ImageUpload";
	import StoragePlace from "../../apis/StoragePlace";
	import PriceCategory from "../../apis/PriceCategory";
	import Price from "../../apis/Price";
	import Storage from "../../apis/Storage";
	import Config from "../../apis/Config"; 
	import Development from "../../apis/Development"; 

	export default {
		components: {
			PageHeader,
			ImageUpload
		},
		data() {
			return {
				product: {
					is_third_party:0,
					lifo:0,
					prices: [],
					recipes: [],
					sizes:[],
				},
				size: {},
				errors: [],
				units: false,
				parent_model: "Product",
				recipe: {},
				raw_materials: [],
				intermediate_product_storage_places:false,
				filled_product_storage_places:false,
				finished_product_storage_places:false,
				price_categories:[],
				price:{},
				packings:false,
				loading:false,
				development_data:false,
			}
		},
		computed: {
			title() {
				if(!this.product){
					return 'Nincs ilyen termék!';
				}
				
				return this.product.id ? this.product.name + ' szerkesztése' : 'Termék létrehozása'
			},
			prices_exist(){
				if(this.product){
					var price_count = this.product.sizes.reduce(function(prev, cur) {
						if(cur.prices != undefined){
							return prev + cur.prices.length;
						}
						return prev;
					}, 0);
					
					return price_count;
				}
				else return false;
			},
			notDevCount(){
				return this.product.recipes.filter(recipe => {
					return recipe.development == 0;
				}).length
			}
		},
		created() {
			if(typeof this.$route.params.id != 'undefined'){ 
				Product.show(this.$route.params.id)
					.then(response => {
						this.product = response.data;
						this.price.product_id = this.product.id;
					//console.log(this.product);
					})
					.catch(() => {
						this.product = false;
					});
			}
			StoragePlace.index()
				.then(response => {
					this.storage_places = response.data;
				});
			PriceCategory.index()
				.then(response => {
					this.price_categories = response.data;
				});
			Config.getConstant('units')
			.then(response => {
				this.units = response.data;
			})
			Config.getConstant('product_packing')
			.then(response => {
				this.packings = response.data;
			})
			Storage.places(3)
			.then(response => {
				this.intermediate_product_storage_places = response.data;
			})
			Storage.places(5)
			.then(response => {
				this.filled_product_storage_places = response.data;
			})
			Storage.places(2)
			.then(response => {
				this.finished_product_storage_places = response.data;
			})
		},

		methods: {
			showDevelopmentData(){
				this.development_data = false;
				this.$vm2.open('modal-2');
				Development.show(this.product.development.id).then(response => {
					this.development_data = response.data.data;
				})
			},
			addProduct() {
				Product.add(this.product)
					.then(response => {
						if(typeof response != 'undefined'){
							this.$set(this.product, 'id', response.data.id);
						}
					})
					.catch(error => {
						this.errors = error.response.data.errors;
					})
			},
			updateProduct() {
				Product.update(this.product.id, this.product)
					.then(() => (
						this.$router.push({
							name: 'Products'
						})
					))
					.catch(error => {
						this.errors = error.response.data.errors;
					})
			},
			deleteSize(id) {
				this.$confirm({
					message: `Biztos törlöd ezt a kiszerelést?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					/**
					* Callback Function
					* @param {Boolean} confirm
					*/
					callback: confirm => {
						if (confirm) {
							ProductSize.delete(id)
							.then(() => {
								let i = this.product.sizes.map(data => data.id).indexOf(id);
								this.product.sizes.splice(i, 1)
							});
						}
					}
				})
				
			},
			deleteRecipes(id) {
				this.$confirm({
					message: `Biztos törlöd ezt a receptet?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					/**
					* Callback Function
					* @param {Boolean} confirm
					*/
					callback: confirm => {
						if (confirm) {
							Recipe.delete(id)
							.then(() => {
								let i = this.product.recipes.map(data => data.id).indexOf(id);
								this.product.recipes.splice(i, 1)
							});
						}
					}
				})
				
			},

			addProductSize() {
				this.size.product_id = this.product.id;
				
				ProductSize.add(this.size)
					.then(response => {
						this.product.sizes.unshift({
							id: response.data,
							name: this.size.name,
							product_id: this.product.id,
							size: this.size.size,
							prices: []
						});
					})
					.catch(error => {
						this.errors = error.response.data.errors;
					})
			},
			reproductionRecipe(id) {
				Recipe.reproduction(id)
					.then(response => {
						this.product.recipes.push(response.data)
					})
			},
			openModal(id) {
				this.$vm2.open('modal-1')
				Recipe.show(id)
					.then(response => {
						this.recipe = response.data;
						this.raw_materials = this.recipe.raw_materials;
					})
			},
			addPrice() {
				this.loading = true;
				Price.add(this.price)
					.then(response => {
					
						let sizeIndex = this.product.sizes.map(size => size.id).indexOf(this.price.size_id);
					
						var size = this.product.sizes[sizeIndex];
					
						if(typeof size != 'undefined'){
							if(size.prices != undefined){
								
								let filtered = size.prices.filter(
									price => price.price_category_id == this.price.price_category_id
								);

								if(filtered.length > 0){
									this.product.sizes[sizeIndex].prices.splice(this.product.sizes[sizeIndex].prices.map(price => price.id).indexOf(filtered[0].id),1)
								}
								
							}
							
							this.product.sizes[sizeIndex].prices.unshift(response.data);
						}

					}).finally(() => {
						this.loading = false;
					});
			},
			deletePrice(price){
				this.$confirm({
					message: `Biztos törlöd ezt a árat?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					/**
					* Callback Function
					* @param {Boolean} confirm
					*/
					callback: confirm => {
						if (confirm) {
							Price.delete(price.id)
							.then(() => {
								this.product.sizes[this.product.sizes.map(size => size.id).indexOf(price.size_id)].prices.splice(this.product.sizes[this.product.sizes.map(size => size.id).indexOf(price.size_id)].prices.map(price => price.id).indexOf(price.id),1);						
							});
						}
					}
				})
				
			}
		},
	}
</script>

<style>
	#modal {
		font-family: "Avenir", Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}

	.label_name {
		margin-top: 5px;
		font-weight: 600;
		font-size: 14px;
		margin-right: 15px;
	}

	.modalBody {
		margin-left: auto;
		margin-right: auto;
	}

	.modalBody .row {
		margin-bottom: 15px;
	}
</style>