<template>
		<div>
			<form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
				<div class="dropbox">
					<input type="hidden" :parent_model="parent_model">
					<input type="hidden" :model_id="model_id">
					<input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="image/*" class="input-file">
					<p v-if="isInitial">
						{{upload_text}}
					</p>
					<p v-if="isSaving">
						{{ fileCount }} kép feltöltése folyamatban...
					</p>
				</div>
			</form>
			<div v-if="isSuccess">
				<h2>{{ fileCount }} fájl sikeresen feltöltve.</h2>
				<p><a href="javascript:void(0)" @click="reset()">Töltök fel még</a></p>

			</div>
			<!--FAILED-->
			<div v-if="isFailed">
				<h2>Sikertelen feltöltés!</h2>
				<p>
					<a href="javascript:void(0)" @click="reset()">Töltök fel mást</a>
				</p>
				<pre>{{ uploadError }}</pre>
			</div>
			<div class="uploaded-images">
				<div class="square" v-for="(item,id) in uploadedFiles" :key="id" v-bind:style="{ 'background-image': 'url(' + item.url + ')' }">
					<div class="middle">
						<div class="btn btn-danger" @click.stop="deleteImage(item)">Törlés</div>
					</div>
				</div>
			</div>
		</div>
</template>

<script>

import FileManager from '../apis/FileManager.js';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
	name: 'app',
	props: {
		parent_model: String,
		model_id: Number, 
		upload_text: {
			type:String,
			default: 'Kattints ide vagy dobj képeket erre a dobozra a feltöltéshez'
		}
	},
	data() {
		return {
			uploadedFiles: [],
			uploadError: null,
			currentStatus: null,
			uploadFieldName: 'photos[]'
		}
	},
	computed: {
		isInitial() {
			return this.currentStatus === STATUS_INITIAL;
		},
		isSaving() {
			return this.currentStatus === STATUS_SAVING;
		},
		isSuccess() {
			return this.currentStatus === STATUS_SUCCESS;
		},
		isFailed() {
			return this.currentStatus === STATUS_FAILED;
		}
	},
	methods: {
		reset() {
			// reset form to initial state
			this.currentStatus = STATUS_INITIAL;
			//this.uploadedFiles = [];
			this.uploadError = null;
		},
		save(formData) {
			// upload data to the server
			this.currentStatus = STATUS_SAVING;

			FileManager.upload(formData)
				.then(x => {
					console.log(x);
					this.uploadedFiles = this.uploadedFiles.concat(x);	
					this.currentStatus = STATUS_SUCCESS;
					
				})
				.catch(errors => {
					let er = errors.response.data.errors;
					let ov = Object.values(er);
					this.uploadError = ov.join('');

					this.currentStatus = STATUS_FAILED;
				});
		},
		filesChange(fieldName, fileList) {
			// handle file changes
			const formData = new FormData();

			if (!fileList.length) return;

			// append the files to FormData
			Array
				.from(Array(fileList.length).keys())
				.map(x => {
					formData.append(fieldName, fileList[x], fileList[x].name);
				});
			
			formData.append('parent_model',this.parent_model);
			formData.append('model_id',this.model_id);
			
			// save it
			this.save(formData);
		},
		deleteImage(item){
			this.$confirm({
				message: `Biztos törlöd ezt a képet?`,
				button: {
					no: 'Nem',
					yes: 'Igen'
				},
				/**
				* Callback Function
				* @param {Boolean} confirm
				*/
				callback: confirm => {
					if (confirm) {
						FileManager.delete(this.parent_model, this.model_id, item.id)
							.then(()=>{
								let i = this.uploadedFiles.map(data => data.id).indexOf(item.id);
								this.uploadedFiles.splice(i, 1);
							})
					}
				}
			})
		}
	},
	watch: {
		model_id: function(model_id){
			if(model_id){
				FileManager.show(this.parent_model, this.model_id).then(
					response => {
						this.uploadedFiles = [].concat(response);
					}
				)
			}
		}
	},
	created(){
		if(this.model_id){
			FileManager.show(this.parent_model, this.model_id).then(
				response => {
					this.uploadedFiles = [].concat(response);
				}
			)
		}
	},
	mounted() {
		this.reset();
	},
}
</script>

<!-- SASS styling -->
<style lang="scss">
	.dropbox {
		outline: 2px dashed grey; /* the dash box */
		outline-offset: -10px;
		background: lightcyan;
		color: dimgray;
		padding: 10px 10px;
		min-height: 200px; /* minimum height */
		position: relative;
		cursor: pointer;
	}

	.input-file {
		opacity: 0; /* invisible but it's there! */
		width: 100%;
		height: 200px;
		position: absolute;
		cursor: pointer;
	}

	.dropbox:hover {
		background: lightblue; /* when mouse over to the drop zone, change color */
	}

	.dropbox p {
		font-size: 1.2em;
		text-align: center;
		padding: 50px 0;
	}
	
	.uploaded-images{overflow:auto;}
	
	.square {
		float:left;
		position: relative;
		width: 23.5%;
		padding-bottom : 23.5%; /* = width for a 1:1 aspect ratio */
		margin:1%;
		background-position:center center;
		background-repeat:no-repeat;
		background-size:cover; /* you change this to "contain" if you don't want the images to be cropped */
		border:1px solid #777;
		
	}
	
	.square:hover::before {
		content: "";
		position: absolute;
		top: 0; 
		left: 0;
		width: 100%; 
		height: 100%;  
		background:#fff;
		opacity: .4; 
		z-index: 1;
	}
	
	.square:nth-child(4n-3){margin-left:0;}
	.square:nth-child(4n){margin-right:0;}
	
	.middle {
		transition: .25s ease;
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		text-align: center;
		z-index:10;
	}
	
	.square:hover .middle {opacity: 1;}
</style>
