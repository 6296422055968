<template>
		<div>
				<PageHeader title="Recept szerkesztése" />
				<div class="row">
						<div class="col">
								<div class="ibox">
										<div class="ibox-content">
											<form @submit.prevent="refreshRecipe">
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label>Megnevezés</label>
															<input type="text" class="form-control" v-model="recipe.name" :disabled="recipe.has_manufacturing != 0">
															<span class="text-danger" v-if="errors.name">
																{{ errors.name[0] }}
															</span>
														</div>
														<div class="form-check abc-checkbox abc-checkbox-success">
															<input class="form-check-input" v-model="recipe.ph" id="checkbox_ph" type="checkbox" true-value="1" false-value="0" :disabled="recipe.has_manufacturing != 0">
															<label class="form-check-label" for="checkbox_ph">
																PH mérés szükséges
															</label>
														</div>
														<div class="form-group mt-2">
															<label>Termék szavatosság:</label>
															<select class="form-control" v-model="recipe.best_before">
																<option value="undefined">Válassz szavatosságot</option>
																<option value="12">12 hónap</option>
																<option value="18">18 hónap</option>
															</select>
															<span class="text-danger" v-if="errors.best_before">
																{{ errors.best_before[0] }}
															</span>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group">
																<label>Leírás</label>
																<textarea class="form-control" rows="3" v-model="recipe.description" :disabled="recipe.has_manufacturing != 0">
																</textarea>
																<span class="text-danger" v-if="errors.description">
																		{{ errors.description[0] }}
																</span>
														</div>
													</div>
												</div>
												<h3 >Alapanyag arányok:</h3>
												<span class="text-danger" v-if="errors.materials">
													{{ errors.materials[0] }}
												</span>
												<div class="row">
													<div class="col-md-6">
														<h5>Összes alapanyag</h5>
														<!-- <input type="text" v-model="search" placeholder="keresés.."/> -->
														<input type="text" v-on:keyup="search = $event.target.value" placeholder="keresés..."/>
														<draggable :clone="MaterialClone" v-model="filteredList" group="materials" @start="drag=true" @end="drag=false" @change="updateRawMaterialList" class="border border-light pt-2" style="min-height:200px" :disabled="recipe.has_manufacturing != 0">
															<div v-for="raw_material in filteredList" class="btn m-1 b-r-xl border-dark grab bg-success" :key="raw_material.id" :style="{'pointer-events: none;':recipe.has_manufacturing != 0}">{{raw_material.name}}</div>
														</draggable>
													</div>
													<div class="col-md-6 mt-4">
														<h5>Kiválasztott alapanyagok</h5>
														<draggable v-model="selected_materials" group="materials" @start="drag=true" @end="drag=false" @change="updateMaterialList" class="border border-light pt-2" style="min-height:200px" :disabled="recipe.has_manufacturing != 0">
															<div v-for="(selected_material,id) in selected_materials" :key="id" class="row grab border border-dark mb-2 b-r-xl mx-1 px-1" :style="{'pointer-events: none;':recipe.has_manufacturing != 0}">
																<div class="col-md-6 text-center form-control px-0 b-r-xl bg-success">
																	<div class="my-auto">{{selected_material.name}}</div>
																</div>
																<div class="col-md-3 text-center px-0 border-left-0">
																	<div class="input-group">
																		<select class="form-control" v-model="selected_material.phase" :disabled="recipe.has_manufacturing != 0">
																			<option value="undefined" disabled>Válassz fázist</option>
																			<option v-for="(material,id) in selected_materials" :key="id+1" :value="id+1">{{String.fromCharCode(96 + id+1).toUpperCase()}}</option>
																		</select>
																		<span class="input-group-append b-r-xl">
																			<div class="btn btn-white b-r-xl">fázis</div>
																		</span>
																	</div>
																</div>
																<div class="col-md-3 text-center px-0 border-left-0">
																	<div class="input-group">
																		<input type="number" :disabled="recipe.has_manufacturing != 0" @wheel="$event.target.blur()" step=".001" min="0.001" max="100" @focus="$event.target.select()" class="form-control b-r-xl text-right border-left-0" v-model="selected_materials[id].percentage">
																		<span class="input-group-append b-r-xl">
																			<div class="btn btn-white b-r-xl">%</div>
																		</span>
																	</div>
																</div>
															</div>
														</draggable>
														<div v-if="selected_materials.length > 0">
															<h5>Egy egységre vetített bekerülési ár:</h5>
															<table class="table">
																<tbody>
																	<tr v-for="(selected_material, sm_id) in selected_materials" :key="sm_id">
																		<td>{{selected_material.name}}</td>
																		<th>
																			{{ (parseFloat(selected_material.last_price_one_unit) * (parseFloat(selected_material.percentage)/100)).toLocaleString() }} Ft
																			<i class="fa fa-exclamation-triangle text-danger float-right" title="nem található bevételezési ár" v-if="selected_material.last_price_one_unit == 0"></i>
																		</th>
																	</tr>
																	<tr class="table-active">
																		<th>Összesen</th>
																		<th>{{ selected_sum }} Ft</th>
																	</tr>
																</tbody>
															</table>
														</div>
														<div class="mt-2">
															<button type="submit" class="btn btn-primary">Mentés</button>
															<router-link :to="{path:'/product/' + recipe.product_id}" class="btn btn-danger mx-2">Mégsem</router-link>			
														</div>
													</div>
												</div>
												</form>
										</div>
								</div>
						</div>
				</div>
		</div>
</template>
 
<script>
import PageHeader from '../../components/PageHeader';
import Recipe from "../../apis/Recipe";
import Material from "../../apis/Material";
import draggable from 'vuedraggable'
import Config from "../../apis/Config";
	
		export default {
				components: {PageHeader,draggable},
				data() {
						return {
								units: false,
								recipe: {
								},
								raw_materials: [],
								selected_materials: [],
								search: '',
								errors: [],
								//filteredList:[],
							
						}
				},
				computed: {
					filteredList(){
						return this.raw_materials.filter(material =>  material.name.toLowerCase().includes(this.search.toLowerCase()))
					},
					selected_sum(){
						return this.selected_materials.reduce((a,b) => {
							return a + (parseFloat(b.last_price_one_unit) * (parseFloat(b.percentage)/100))
						},0).toFixed(3);
					}
				},
				created() {
					Config.getConstant('units')
					.then(response => {
						this.units = response.data;
					})
					Material.index()
						.then(response => {
							console.log(response.data.length);
							this.raw_materials = response.data;
							console.log(this.raw_materials.length);
							this.$forceUpdate(this.filteredList);
						})
					.then(() => {
					Recipe.show(this.$route.params.id)
						.then(response => {
							this.recipe = response.data;
						}).then(() => {
							this.recipe.raw_materials.forEach(element => {
								this.selected_materials.push(element);
								this.$set(this.selected_materials[this.selected_materials.indexOf(element)],'percentage',element.pivot.percentage);
								this.$set(this.selected_materials[this.selected_materials.indexOf(element)],'phase',element.pivot.phase);
							});
						})
					});
					
				},
				methods: {
						MaterialClone(obj){
							return JSON.parse(JSON.stringify(obj))
						},
						refreshRecipe() {
							let duplicate_in_same_phase = 0;
							this.selected_materials.forEach(sm => {
								//console.log(this.selected_materials.filter(s => (s.id == sm.id && s.phase == sm.phase)).length)
								if(this.selected_materials.filter(s => (s.id == sm.id && s.phase == sm.phase)).length != 1){
									duplicate_in_same_phase++;	 
								}
							});
							/*let percentage = this.selected_materials.reduce(function (r, a) {
								return r + parseFloat(a.percentage);
							},0 ).toPrecision(5);*/
							let max = Math.max.apply(Math, this.selected_materials.map(material => material.phase));
							//console.log('max: '+max);
							let maxarray = Array.from({length: max}, (_, i) => i + 1);
							//console.log('maxarray: '+maxarray);
							let needed = [];
							maxarray.forEach(maxarray_element => {
								if(!this.selected_materials.map(material => material.phase).includes(maxarray_element)){
									needed.push(maxarray_element);
								}
							});
							//console.log(needed);
							//console.log(duplicate_in_same_phase);
							if(duplicate_in_same_phase != 0){
								this.$toast.open({
										message: 'Egy alapanyag egy fázisban csak egyszer szerepelhet!',
										type: 'error',
										position: 'top-right', 
								});
							}/*else if(percentage != 100){
								console.log(percentage);
									this.$toast.open({
											message: 'Az alapanyagok arányának összege: '+ percentage + '% nem 100%!',
											type: 'error',
											position: 'top-right', 
									});
							}*/else if(this.selected_materials.filter( selected_material => (selected_material.phase == null || !maxarray.includes(selected_material.phase))).length != 0){
								this.$toast.open({
											message: 'Minden alapanyaghoz válasszon fázist!',
											type: 'error',
											position: 'top-right', 
									});
							}else if(needed.length != 0){
								let message_text = 'Nincs: '
								needed.forEach(neededelement => {
									message_text = message_text+(String.fromCharCode(96 + neededelement).toUpperCase()+',');
								});
								this.$toast.open({
											message: message_text+' fázis!',
											type: 'error',
											position: 'top-right', 
									});
							}else{
								this.updateRecipe()
							}
						},
					
					updateRecipe() {
								this.recipe.materials = this.selected_materials
                Recipe.update(this.$route.params.id, this.recipe)
                    .then(() => (
                        this.$router.push({ path: '/product/'+ this.recipe.product_id })
                    ))
                    .catch(error => {
                        this.errors = error.response.data.errors;
                    })
            },
					
					updateRawMaterialList(/*event*/){
						/*if(typeof event.removed != 'undefined'){
							this.raw_materials.splice(this.raw_materials.map(material => material.id).indexOf(event.removed.element.id), 1);
						}else{
							this.raw_materials.push(event.added.element);
						}*/ //nem kell  
						//console.log(event);
					},
					
					updateMaterialList(event){
						if(typeof event.added != 'undefined'){
							//console.log('-------------------------');
							//console.log(this.selected_materials[event.added.newIndex]);
							//console.log(event.added.newIndex);
							//console.log('-------------------------');
							this.$set(this.selected_materials[event.added.newIndex],'percentage',0);
						}
					},
					reproductionRecipe(id){
						Recipe.reproduction(id)
							.then( response => {
								//this.$set(this.recipes,'id',response.data);
							//console.log(response.data.id);
								this.$router.push({ path: '/recipes/' + response.data.id + '/edit' })
							})
							.catch(error => {
									this.errors = error.response.data.errors;
							})
					}
				}
		}
	
	
</script>