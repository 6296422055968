import Api from "./Api";
import Csrf from "./Csrf";

export default {
	
  async getConstant(array_name,key,index) {
    await Csrf.getCookie();
		
		if(typeof key == 'undefined' && typeof index == 'undefined'){
			return Api.get("/config/constants." + array_name);
		}else{
			return Api.get("/config/constants." + array_name + "/" + key + "/" + index);
		}
		
    
  },
};