import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/recipes");
  },
    
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/recipes", form);
  },
    
  async show(id) {
    await Csrf.getCookie();

    return Api.get("/recipes/" + id);
  },
    
  async update(id, form) {
    await Csrf.getCookie();

    return Api.patch("/recipes/" + id, form);      
      
  },
    
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/recipes/" + id);      
      
  },
    
  async reproduction(id) {
    await Csrf.getCookie();

    return Api.get("/recipes/" + id +"/reproduction");      
      
  }
    
};