<template>
	<div>
		<PageHeader title="Töltött termék napló" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="fillings === false">
								<div class="col-12 text-center">
									<h3>Töltött termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="fillings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek töltött termékek
									</h3>
									<small>hozzon létre töltött terméket</small>
								</div>
							</div>
							<div class="table-responsive" v-if="fillings.length != 0 && fillings !== false">
								<vue-good-table
									styleClass="vgt-table striped" 
									rowStyleClass="fs-12"
									:columns="columns" 
									:rows="fillings" 
									:search-options="{ enabled: false, placeholder: 'Keresés...' }" 
									:pagination-options="this.$vueGTPaginationOptions"
								>
									<template slot="table-row" slot-scope="props">
										<span v-if="props.column.field == 'sizes'">
											{{props.row.sizes.map(size => size.name + ' - ' + size.quantity).join(',')}}
										</span>
										<span v-else-if="props.column.field == 'actions'">
											<button class="btn btn-success mx-1 d-inline" title="napló" @click="openModal(props.row)"><i class="fa fa-book-open"></i></button>
										</span>
										<span v-else>
											{{props.formattedRow[props.column.field]}}
										</span>
									</template>
								</vue-good-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal" >
			<modal-vue modalSize="xl" @on-close="modalClose()" name="filling_modal" :headerOptions="{ title: filling.name + ' napló', }" :footerOptions="{
			btn1: 'Bezár',
			disableBtn2: true,
			btn1OnClick: () => {
				$vm2.close('filling_modal');
			},
		}">
				<div align="center" class="col-12">
					<div class="row" v-if="histories === false">
						<div class="col-12 text-center">
							<h3>Napló betöltése</h3>
							<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
						</div>
					</div>
					<div class="row" v-if="histories !== false">
						<div class="col-md-4">
							<h5>Adatok:</h5>
							<table class="table">
								<thead class="thead-light">
									<tr>
										<th>Megnevezés</th>
										<th>Leírás</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{filling.name}}</td>
										<td>{{filling.finished_at}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-8">
							<h5>Töltött termékek:</h5>
							<table class="table table-striped">
								<thead class="thead-light">
									<tr>
										<th><i class="fa fa-eye"></i></th>
										<th>Kiszerelés</th>
										<th>Tervezett mennyiség</th>
										<th>Töltött mennyiség</th>
										<th>Mennyiség</th>
										<th>1 db ára</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(filled_product, id) in histories.filled_products" :key="id">
										<td>
											<input type="checkbox" v-model="filled_product.show">
										</td>
										<td>{{filled_product.size_name}}</td>
										<td>{{filled_product.expected_quantity}}</td>
										<td>{{filled_product.actual_quantity}}</td>
										<td>{{filled_product.remaining_quantity}}</td>
										<td>{{filled_product.filling_cost ? filled_product.filling_cost.toLocaleString('hu-HU')+' Ft' : 'nem számolható'}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-12">
							<button class="btn btn-primary btn-outline" type="button" :class="{'active' : active == 1}" @click="active = 1">Napló</button>
							<button class="btn btn-primary btn-outline" type="button" :class="{'active' : active == 2}" @click="active = 2">Műveletek</button>
							<button class="btn btn-primary btn-outline" type="button" :class="{'active' : active == 3}" @click="active = 3">Töltési műveletek</button>
							<div class="table-responsive" v-if="this.active == 2" style="height:200px">
							<table class="table table-striped mt-4">
								<thead class="thead-dark">
									<tr>
										<th>Időpont</th>
										<th>Esemény</th>
										<th>Tároló</th>
										<th>Mennyiség</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(history,id) in histories.histories" :key="id" v-show="histories.filled_products[histories.filled_products.map(filled_product => filled_product.id).indexOf(history.filled_product_id)].show">
										<td>{{history.created_date}}</td>
										<td>{{history.event_type}}</td>
										<td>{{histories.filled_products[histories.filled_products.map(filled_product => filled_product.id).indexOf(history.filled_product_id)].id}}</td>
										<td>{{history.quantity}}</td>
									</tr>
								</tbody>
							</table>
							</div>
							<div class="table-responsive" v-if="this.active == 1" style="height:200px">
								<table class="table table-striped mt-4">
									<thead class="thead-dark">
										<tr>
											<th>Időpont</th>
											<th>Esemény</th>
											<th>Felhasználó</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(log,id) in histories.logs" :key="id">
											<td>{{log.created_date}}</td>
											<td>{{log.description}}</td>
											<td>{{log.user_name}}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="table-responsive" v-if="this.active == 3" style="height:200px">
								<table class="table table-striped mt-4">
									<thead class="thead-dark">
										<tr>
											<th>Időpont</th>
											<th>Esemény</th>
											<th>Göngyöleg</th>
											<th>Mennyiség</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(wph,id) in histories.wrappingHistories" :key="id"> <!--  v-show="histories.filled_products[histories.filled_products.map(filled_product => filled_product.filling_id).indexOf(wph.related_model_id)].show" -->
											<td>{{wph.created_date}}</td>
											<td>{{wph.event_type}}</td>
											<td>{{wph.name}}</td>
											<td>{{wph.quantity}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Log from "../../apis/Log";

export default {
    components: { PageHeader },
    data() {
        return {
					fillings: false,
					filling: {},
					errors: [],
					histories:false,
					active: 1,
					columns:[
						{
							label:'Megnevezés',
							field:'name',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Töltve',
							field:'finished_at',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Töltés elemei',
							field:'sizes',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label: 'Műveletek',
							field: 'actions',
							width: '15%',
							sortable: false,
							thClass: 'text-right fs-12',
							tdClass: 'text-right'
						}
					]
        }
    },
	
    created() {
			Log.filled_product()
			.then(response => {
				this.fillings = response.data;
			});	
    },
    methods: {
			openModal(filling) {
				this.filling = filling
				this.$vm2.open('filling_modal')
				Log.log({type:'filled_product',id:filling.id})
				.then(response => {
					this.histories = response.data;
					this.histories.filled_products.forEach(filled_product => {
						this.$set(filled_product,'show',true);
					});
					//console.log('histories:',this.histories);
					//console.log('filling:',this.filling);
				});
			},
			modalClose() {
				this.$vm2.close('filling_modal');
				this.histories = false;
			}
    }
};
</script>

<style>
</style>