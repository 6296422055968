<template>
	<div>
		<PageHeader title="Göngyöleg módosítása" />
		<div class="row">
			<div class="col-md-6">
				<div class="ibox">
					<div class="ibox-content">
						<div class="row" v-if="storage_places === false">
							<div class="col-12 text-center">
								<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
							</div>
						</div>
						<form @submit.prevent="updateWrapping"
							v-if="storage_places !== false && storage_places.length != 0">
							<div class="form-group">
								<label>Megnevezés</label>
								<input type="text" class="form-control" v-model="wrapping.name">
								<span class="label label-danger" v-if="errors.name">
									{{ errors.name[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Leírás</label>
								<textarea class="form-control" rows="5" v-model="wrapping.description">
																</textarea>
								<span class="label label-danger" v-if="errors.description">
									{{ errors.description[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Raktárhely</label>
								<select class="form-control no-cut" v-model="wrapping.storage_place_id">
									<option :value="undefined" disabled>Válassz raktárhelyet</option>
									<option v-for="(storage_place, id) in storage_places" :value="storage_place.id"
										:key="id">
										{{ storage_place.name }}
									</option>
								</select>
								<span class="label label-danger" v-if="errors.storage_place_id">
									{{ errors.storage_place_id[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Típus</label>
								<select class="form-control no-cut" v-model="wrapping.type" disabled>
									<option :value="undefined" disabled>Válassz típust</option>
									<option v-for="(type, id) in wrapping_types" :value="id" :key="id">
										{{ type }}
									</option>
								</select>
								<span class="label label-danger" v-if="errors.type">
									{{ errors.type[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Külsőleg nyomott</label>
								<div class="input-group">
									<span class="input-group-prepend">
										<div class="input-group-addon">
											<input type="checkbox" :true-value="1" :false-value="0"
												v-model="wrapping.external_press" class="form-check">
										</div>
									</span>
									<span class="form-control mw-25 overflow-hidden"> Külsőleg nyomott</span>
									<div class="input-group-append">
										<span class="input-group-addon"
											:class="{ 'bg-danger': wrapping.external_press == 0, 'bg-primary': wrapping.external_press == 1 }"><i
												class="fa"
												:class="{ 'fa-times': wrapping.external_press == 0, 'fa-check': wrapping.external_press == 1 }"></i></span>
									</div>
								</div>
							</div>
							<div class="form-group" v-if="wrapping.external_press">
								<label>Nyomási alapanyag</label>
								<select class="form-control no-cut" v-model="wrapping.press_wrapping_id" v-if="wrappings">
									<option :value="null" disabled>Válassz nyomási alapanyagot</option>
									<option v-for="(wrapping, id) in wrappings" :value="wrapping.id" :key="id">
										{{ wrapping.name }}
									</option>
								</select>
								<i class="fa fa-cog fa-spin" v-else></i>
								<span class="label label-danger" v-if="errors.press_wrapping_id">
									{{ errors.press_wrapping_id[0] }}
								</span>
							</div>
							<button type="submit" class="btn btn-primary">Mentés</button>
							<router-link :to="{ path: '/wrappings' }" class="btn btn-danger mx-2">Mégsem</router-link>
						</form>
					</div>
				</div>
			</div>
		</div>
</div>
</template>
 
<script>
import PageHeader from '../../components/PageHeader';
import Wrapping from "../../apis/Wrapping";
import Storage from "../../apis/Storage";
import Config from "../../apis/Config";

export default {
	components: { PageHeader },
	data() {
		return {
			units: false,
			storage_places: false,
			wrapping_types: false,
			wrapping: {},
			wrappings: false,
			errors: []
		}
	},
	created() {
		Wrapping.index()
			.then(response => {
				this.wrappings = response.data;
			});
		Config.getConstant('units')
			.then(response => {
				this.units = response.data;
			})
		Config.getConstant('wrapping_types')
			.then(response => {
				this.wrapping_types = response.data;
			})
		Storage.places(4)
			.then(response => {
				this.storage_places = response.data;
			})
		Wrapping.show(this.$route.params.id)
			.then(response => {
				this.wrapping = response.data;
			});
	},
	watch:{
		'wrapping.external_press'(val){
			if(val == 0){
				this.wrapping.press_wrapping_id = null;
			}
		}
	},
	methods: {

		updateWrapping() {
			Wrapping.update(this.$route.params.id, this.wrapping)
				.then(response => {
					this.$router.push({ path: response.headers['content-location'] })
				})
				.catch(error => {
					this.errors = error.response.data.errors;
				})
		}
	}
}
</script>