<template>
	<div>
		<PageHeader title="Csomagok" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-title tall-ibox-title">
							<div class="ibox-tools">
								<button class="btn btn-primary" @click="openModal()" >Új Csomag</button>
							</div>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="bundles === false">
								<div class="col-12 text-center">
									<h3>Csomagok betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="bundles.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek Csomagok
									</h3>
									<small>hozzon létre Csomagot</small>
								</div>
							</div>
							<div class="table-responsive" v-if="bundles.length != 0 && bundles !== false">
								<input type="text" v-model="search" placeholder="keresés.."/>
								<table class="table table-striped w-100">
									<thead>
										<th @click="sort('name')" class="text-nowrap pointer">
												Megnevezés 
												<i v-if="currentSort == 'name' && currentSortDir == 'asc'" class="fa fa-sort-alpha-down"></i>
												<i v-if="currentSort == 'name' && currentSortDir == 'desc'" class="fa fa-sort-alpha-up-alt"></i>
										</th>
										<th class="text-center">Műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(bundle, id) in sortedBundles" :key="id">
											<td>
												{{bundle.name}}
											</td>
											<td class="text-center buttons-wide-3">
												<router-link :to="'bundles/'+bundle.id+'/edit'" class="btn btn-success mx-1 d-inline" title="szerkesztés"><i class="fa fa-pencil-alt"></i></router-link>
												<button class="btn btn-danger mx-1 d-inline" @click="deleteBundle(bundle.id)" title="törlés"><i class="fa fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
			<div id="modal" >
				<modal-vue modalSize="l" @on-close="modalClose" name="bundle_modal" :headerOptions="{ title:'Új Csomag' }" :footerOptions="{
        btn1: 'Mégse',
        btn2: 'Hozzáadás',
		disableBtn2:loading,
        btn2OnClick: () => {
          addBundle();
        },
        btn1OnClick: () => {
          $vm2.close('bundle_modal');
        },
      }">
					<div align="center" class="col-12">
						<form @submit.prevent="addBundle">
							<div class="form-group">
								<label>Megnevezés</label>
								<input type="text" class="form-control" v-model="formBundle.name">
								<span class="label label-danger" v-if="errors.name">
										{{ errors.name[0] }}
								</span>
							</div>
						</form>
					</div>
				</modal-vue>
			</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Bundle from "../../apis/Bundle";

export default {
    components: { PageHeader},
    data() {
        return {
            bundles: false,
						bundle: {},
						formBundle: {
							name: null,
						},
						errors: [],
						search:'',
						currentSort:'name',
						currentSortDir:'asc',
						loading:false,
        }
    },
		computed: {
			sortedBundles: function() {
				return this.bundles.filter(bundle => {
					return bundle.name.toLowerCase().includes(this.search.toLowerCase())
				}).sort((a,b) => {
					let modifier = 1;
					if(this.currentSortDir === 'desc') modifier = -1;
					if(a[this.currentSort][0].localeCompare(b[this.currentSort][0],'hu-HU') < 0) return -1 * modifier;
					if(a[this.currentSort][0].localeCompare(b[this.currentSort][0],'hu-HU') > 0) return 1 * modifier;
					return 0;
				});
			}
		},
	
    created() {
			Bundle.index()
			.then(response => {
				this.bundles = response.data;
			});
    },
    methods: {
			sort:function(s) {
				//if s == current sort, reverse
				if(s === this.currentSort) {
					this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
				}
				this.currentSort = s;
			},
			deleteBundle(id) { 
				this.$confirm({
					message: `Biztos törlöd ezt a Csomagot?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					/**
					* Callback Function
					* @param {Boolean} confirm
					*/
					callback: confirm => {
						if (confirm) {
							Bundle.delete(id)
							.then(() => {
								let i = this.bundles.map(data => data.id).indexOf(id);
								this.bundles.splice(i, 1)
							})
						}
					}
				})
				
			},
			openModal() {
				this.$set(this.formBundle,'name',null);
				this.$vm2.open('bundle_modal');
			},
			addBundle() {
				this.loading = true;
				Bundle.add(this.formBundle)
				.then(response => {
					this.bundles.push(response.data)
					this.modalClose();
				})
				.catch(error => {
						this.errors = error.response.data.errors;
				}).finally(() => {
					this.loading = false
				})
			},
			modalClose() {
				this.$vm2.close('bundle_modal');
					this.formBundle = {};
			}
    }
};
</script>

<style>
</style>