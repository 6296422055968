<template>
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-sm-8">
			<h2>{{title}} <slot/></h2>
			<ol class="breadcrumb">
				<li class="breadcrumb-item" :class="{'active':page.link}" v-for="(page, index) in breadcumbs" :key="index">
					<a v-if="page.link" :href="page.link">{{page.name}}</a>
					<strong v-else>{{page.name}}</strong>
				</li>
			</ol>			
		</div>
		<div class="col-sm-4 text-right">
				<router-link v-if="button_title" :to="{path:button_href}" class="btn btn-primary pull-right mt-3">{{ button_title }}</router-link>			
		</div>
	</div>
</template>

<script>
export default {
	props: { title: String, breadcumbs:Array , button_title: String, button_href: String}
};
</script>
