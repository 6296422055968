<template>
		<div>
			<PageHeader title="Felhasználók" />
				<div class="row">
					<div class="col-12 col-md-6">
						<div class="ibox">
							<div class="ibox-title">
								<h5>Felhasználó létrehozása</h5>
							</div>
							<div class="ibox-content">
								<form @submit.prevent="addUser">
										<div class="form-group">
												<label>Név</label>
												<input type="text" class="form-control mb-2" v-model="user.name" required>
												<span class="label label-danger" v-if="errors.name">
														{{ errors.name[0] }}
												</span>
										</div>
										<div class="form-group">
												<label>Email</label>
												<input type="text" class="form-control mb-2" v-model="user.email" required>
												<span class="label label-danger" v-if="errors.email">
														{{ errors.email[0] }}
												</span>
										</div>
										<div class="form-group">
											<label>Jogosultság</label>
											<select v-model="user.role_id" class="form-control mb-2" required>
												<option :value="undefined" disabled>Válassz jogosultságot</option>
												<option v-for="(role,id) in roles_to_select" :value="role.role" :key="id">
													{{ role.name }}
												</option>
											</select>
											<span class="label label-danger" v-if="errors.role_id">
												{{ errors.role_id[0] }}
											</span>
										</div>
										<button type="submit" class="btn btn-primary">Mentés</button>
								</form>
							</div>
						</div>
						
					</div>
					<div class="col-12 col-md-6">
						<div class="ibox">
							<div class="ibox-title">
								Felhasználók
							</div>
							<div class="ibox-content">
								<div class="table-responsive">
									<table class="table table-striped">
										<thead>
											<tr>
												<td>Név</td>
												<td>Email</td>
												<td>Jogosultság</td>
												<td>Partner</td>
												<td>Létrehozás dátuma</td>
												<td>&nbsp;</td>
											</tr>
										</thead>
										<tbody>
											<tr v-for="user in users" :key="user.id">
												<td>{{ user.name }}</td>
												<td>{{ user.email }}</td>
												<td>
													<select v-if="roles_to_select.findIndex(r => r.role == user.role_id) != -1" v-model="user.role_id" class="form-control mb-2" @change="updateUser(user)">
														<option :value="undefined" disabled>Válassz jogosultságot</option>
														<option v-for="(role,id) in roles_to_select" :value="role.role" :key="id">
															{{ role.name }}
														</option>
													</select>
													<span v-else>
														{{ roles[roles.map(role => role.role).indexOf(user.role_id)].name }}
													</span>
												</td>
												<td>{{ user.partner_name }}</td>
												<td>{{ user.created_date }}</td>
												<td>
													<button class="btn btn-danger" @click.prevent="deleteUser(user.id)">
														<i class="fa fa-trash"></i>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
			</div>
			
	</div> 
</template>

<script>
	import PageHeader from '../../components/PageHeader';
	import User from "../../apis/User";
	import Config from "../../apis/Config"; 

	export default {
		components: {
			PageHeader
		},
		data() {
			return {
				user: {},
				users: [],
				roles: false,
				roles_to_select: false,
				errors: []
			}
		},
		created() {		
			/*if(this.$root.$data.user.role_id != 1){
				this.roles.splice(1,1);
			}*/
			Config.getConstant('roles')
			.then(response => {
				this.roles = JSON.parse(JSON.stringify(response.data));
				//console.log('response',response.data);
				this.roles_to_select = JSON.parse(JSON.stringify(response.data));
				//console.log(this.$root.$data.user.role_id);
				this.roles_to_select.splice(this.roles_to_select.map(r => r.role).indexOf(6),1);
				
				if(this.$root.$data.user.role_id != 1){
					this.roles_to_select.splice(this.roles_to_select.map(r => r.role).indexOf(1),1);
				}
				//console.log('roles_to_select',this.roles_to_select);
				//console.log('roles',this.roles);
			});
			User.index()
				.then(response => {
					this.users = response.data;
				});
		},

		methods: {
			addUser() {
				User.add(this.user)
					.then(response => {
						this.users.push(response.data);
						this.user = {role_id: 10};
					})
					.catch(error => {
						this.errors = error.response.data.errors;
					})
			},
			deleteUser(id) {
				User.delete(id)
					.then(() => {
						this.users.splice(this.users.map(user => user.id).indexOf(id),1);
					})
			},
			updateUser(user){
				User.updateRole(user.id,{role_id:user.role_id});
			}
		},
	}
</script>

<style>
	
</style>