import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/material_containers");
  },
    
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/material_containers", form);
  },
    
  async show(id) {
    await Csrf.getCookie();

    return Api.get("/material_containers/" + id);
  },
    
  async update(id, form) {
    await Csrf.getCookie();

    return Api.patch("/material_containers/" + id, form);      
      
  },
    
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/material_containers/" + id);      
      
  },
    
  async moveMaterial(id,array) {
    await Csrf.getCookie();
		//console.log(array);
    return Api.post("/material_containers/" + id + "/move_material",array);      
      
  },
	async zip(id) {
    await Csrf.getCookie();
    return Api.get("/material_containers/"+id+"/zip"); //,{'responseType': 'blob'} 
  },
    
};