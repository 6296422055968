<template>
    <div>
        <PageHeader :title="raw_material.name + ' - ' + material_container.barcode +' tároló módosítása'" />
        <div class="row">
            <div class="col-md-8 col-lg-6">
                <div class="ibox">
                    <div class="ibox-content">
                       <form @submit.prevent="updateMaterialContainer">
                          <div class="form-group">
														<label>Beszállító</label>
														<v-select v-model="material_container.partner_id" :from="partners" as="name:id:id" tagging></v-select>
														<span class="label label-danger" v-if="errors.partner_id">
															{{ errors.partner_id[0] }}
														</span>
													</div>
													<div class="form-group">
														<label>Lejárati dátum</label>
														<div class="input-group date">
															<span class="input-group-addon"><i class="fa fa-calendar"></i></span>
															<input type="date" :min="currentDate()" max="9999-12-31" class="form-control" v-model="material_container.best_before">
														</div>
														<span class="label label-danger" v-if="errors.best_before">
															{{ errors.best_before[0] }}
														</span>
													</div>
													<div class="form-group">
														<label>Batch szám</label>
														<input type="text" class="form-control" ref="batch_number" v-model="material_container.batch_number">
														<span class="label label-danger" v-if="errors.batch_number">
															{{ errors.batch_number[0] }}
														</span>
													</div>
													<div class="form-group">
														<label>Mennyiség</label>
														<div class="input-group">
															<input type="number" @wheel="$event.target.blur()" min="1" class="form-control" v-model="material_container.quantity">
															<span class="input-group-addon">
																	<span>{{raw_material.unit}}</span>
															</span>
														</div>
														<span class="label label-danger" v-if="errors.quantity">
															{{ errors.quantity[0] }}
														</span>
													</div>
													<div class="form-group">
														<label>Teljes mennyiség nettó ára</label>
														<div class="input-group">
															<input type="number" @wheel="$event.target.blur()" max="2147483647" min="0" class="form-control" v-model="material_container.price">
															<span class="input-group-addon">
																	<span>Ft</span>
															</span>
														</div>
														<span class="label label-danger" v-if="errors.price">
															{{ errors.price[0] }}
														</span>
													</div>
													<div class="form-group">
														<label>Szállítási költség</label>
														<div class="input-group">
															<input type="number" @wheel="$event.target.blur()" max="2147483647" min="0" class="form-control" v-model="material_container.shipping_price">
															<span class="input-group-addon">
																	<span>Ft</span>
															</span>
														</div>
														<span class="label label-danger" v-if="errors.shipping_price">
															{{ errors.shipping_price[0] }}
														</span>
													</div>
													<div class="form-group">
														<div class="input-group">
															<div class="input-group-prepend">
																<div class="input-group-addon">
																	<input type="checkbox" v-model="material_container.document_type" true-value="1" false-value="0">
																</div>
															</div>
															<span class="form-control mw-25 overflow-hidden"> Dokumentáció rendben van</span>
															<div class="input-group-append">
																<span class="input-group-addon" :class="{'bg-danger': material_container.document_type == 0,'bg-primary': material_container.document_type == 1}"><i class="fa" :class="{'fa-times': material_container.document_type == 0,'fa-check': material_container.document_type == 1}"></i></span>
															</div>
														</div>
													</div>
													<div class="form-group">
														<div class="input-group">
															<div class="input-group-prepend">
																<div class="input-group-addon">
																	<input type="checkbox" v-model="material_container.sample" true-value="1" false-value="0">
																</div>
															</div>
															<span class="form-control mw-25 overflow-hidden"> Minta</span>
															<div class="input-group-append">
																<span class="input-group-addon" :class="{'bg-danger': material_container.sample == 0,'bg-primary': material_container.sample == 1}"><i class="fa" :class="{'fa-times': material_container.sample == 0,'fa-check': material_container.sample == 1}"></i></span>
															</div>
														</div>
													</div>
													<button type="submit" class="btn btn-primary">Mentés</button>
													<button type="button" @click="back()" class="btn btn-danger mx-2">Mégsem</button>	
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 
<script>
import PageHeader from '../../components/PageHeader';
import MaterialContainer from "../../apis/MaterialContainer";
import Partner from "../../apis/Partner";
import Material from "../../apis/Material";
import { vSelect } from '@desislavsd/vue-select'

    export default {
        components: {PageHeader, vSelect},
        data() {
            return {               
                material_container: {},
								raw_material: {},
								partners: false,
                errors: []
            }
        },	
        created() {
            MaterialContainer.show(this.$route.params.id)
                .then(response => {
                    this.material_container = response.data;
                }).then(() => {
							Material.show(this.material_container.raw_material_id)
							.then(response => {
								this.raw_material = response.data;
								//console.log(response.data);
							});
						});
						Partner.index(1,1)
							.then(response => {
								this.partners = response.data;
							});
        },
        methods: {
					currentDate() {
						const current = new Date();
						const date = `${current.getFullYear()}-${('00'+ (current.getMonth()+1)).slice(-2)}-${ ('00'+ current.getDate()).slice(-2)}`;
						return date;
					},
					updateMaterialContainer() {
						MaterialContainer.update(this.$route.params.id, this.material_container)
							.then(() => (
									this.$router.push({ path: '/material_containers/'+ this.raw_material.id +'/create' })
							))
							.catch(error => {
								this.errors = error.response.data.errors;
							})
					},
					back(){
						window.history.back();
					}
        }
    }
</script>