<template>
	<div>
		<PageHeader title="Alapanyag napló" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="materials === false">
								<div class="col-12 text-center">
									<h3>Alapanyagok betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-else-if="materials.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek alapanyagok
									</h3>
									<small>hozzon létre alapanyagot</small>
								</div>
							</div>
							<div class="table-responsive" v-else>
								<vue-good-table
									styleClass="vgt-table striped" 
									rowStyleClass="fs-12"
									:columns="columns" 
									:rows="materials" 
									:search-options="{ enabled: false, placeholder: 'Keresés...' }" 
									:pagination-options="this.$vueGTPaginationOptions"
								>
									<template slot="table-row" slot-scope="props">
										<span v-if="props.column.field == 'quantity'">
											{{props.row.quantity}} {{props.row.unit}}
										</span>
										<span v-else-if="props.column.field == 'actions'">
											<button class="btn btn-success mx-1 d-inline" title="napló" @click="openModal(props.row)"><i class="fa fa-book-open"></i></button>
										</span>
										<span v-else>
											{{props.formattedRow[props.column.field]}}
										</span>
									</template>
								</vue-good-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal" >
			<modal-vue modalSize="xl" @on-close="modalClose()" name="material_modal" :headerOptions="{ title: material.name + ' napló', }" :footerOptions="{
			btn1: 'Bezár',
			disableBtn2: true,
			btn1OnClick: () => {
				$vm2.close('material_modal');
			},
		}">
				<div align="center" class="col-12">
					<div class="row" v-if="histories === false">
						<div class="col-12 text-center">
							<h3>Napló betöltése</h3>
							<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
						</div>
					</div>
					<div class="row" v-if="histories !== false">
						<div class="col-md-6 mx-auto">
							<h5>Adatok:</h5>
							<table class="table">
								<thead class="thead-light">
									<tr>
										<th>Megnevezés</th>
										<th>Raktárhely</th>
										<th>INCI</th>
										<th>Készlet</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{material.name}}</td>
										<td>{{material.storage_place_name}}</td>
										<td>{{material.inci}}</td>
										<td>{{material.quantity}} {{material.unit}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="row">	
						<div class="col-md-10 mx-auto">
							<h5>Tárolók:</h5>
							<div class="tableFixHead">
								<table class="table table-striped">
									<thead class="thead-light">
										<tr>
											<th><i class="fa fa-eye"></i></th>
											<th>Vonalkód</th>
											<th>Raktárhely</th>
											<th>Lejárati dátum</th>
											<th>Bevételezve</th>
											<th>Beszállító</th>
											<th>Tartalom</th>
											<th>Dokumentáció</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(container, id) in histories.containers" :key="id">
											<td>
												<input type="checkbox" v-model="container.show">
											</td>
											<td>{{container.barcode}}</td>
											<td>{{container.storage_place_name}}</td>
											<td>{{container.best_before}}</td>
											<td>{{container.supplied_at}}</td>
											<td>{{container.partner_name}}</td>
											<td>{{container.quantity}} {{container.raw_material_unit}}</td>
											<td class="text-center">
												<a :href="base_url + '/api/material_containers/' + container.id +'/zip'" target="_blank" class="btn btn-warning btn-sm" title="letöltés"><i class="fa fa-file-archive"></i></a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="col-12">
							<button class="btn btn-primary btn-outline my-2" type="button" :class="{'active' : !active}" @click="active = false">Napló</button>
							<button class="btn btn-primary btn-outline my-2" type="button" :class="{'active' : active}" @click="active = true">Műveletek</button>
							<div class="tableFixHead" v-if="this.active == true">
								<table class="table table-striped mt-4">
									<thead class="thead-dark">
										<tr>
											<th>Időpont</th>
											<th>Esemény</th>
											<th>Ár</th>
											<th>Tároló</th>
											<th>Mennyiség</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(history,id) in histories.histories" :key="id" v-show="histories.containers[histories.containers.map(container => container.id).indexOf(history.raw_material_container_id)].show">
											<td>{{history.created_date}}</td>
											<td>{{history.event_type}}</td>
											<td>{{history.price ? history.price.toLocaleString('hu-HU')+' Ft' : '-'}}</td>
											<td>{{histories.containers[histories.containers.map(container => container.id).indexOf(history.raw_material_container_id)].barcode}}</td>
											<td>{{history.quantity}}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="tableFixHead" v-if="this.active == false">
								<table class="table table-striped mt-4">
									<thead class="thead-dark">
										<tr>
											<th>Időpont</th>
											<th>Esemény</th>
											<th>Tároló</th>
											<th>Felhasználó</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(log,id) in histories.logs" :key="id" v-show="histories.containers[histories.containers.map(container => container.id).indexOf(parseInt(log.table_id))].show">
											<td>{{log.created_date}}</td>
											<td>{{log.description}}</td>
											<td>{{histories.containers[histories.containers.map(container => container.id).indexOf(parseInt(log.table_id))].barcode}}</td>
											<td>{{log.user_name}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Log from "../../apis/Log";
import MaterialContainer from "../../apis/MaterialContainer";

export default {
    components: { PageHeader },
    data() {
        return {
					materials: false,
					material: {},
					errors: [],
					histories:false,
					active: false,
					base_url :process.env.VUE_APP_PRINT_URL,
					columns:[
						{
							label:'Megnevezés',
							field:'name',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'INCI',
							field:'inci',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Aktuális készlet',
							field:'quantity',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							tdClass: 'text-right',
							thClass: 'fs-12'
						},
						{
							label:'Raktári hely',
							field:'storage_place_name',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							tdClass: 'text-right',
							thClass: 'fs-12'
						},
						{
							label: 'Műveletek',
							field: 'actions',
							width: '15%',
							sortable: false,
							thClass: 'text-right fs-12',
							tdClass: 'text-right'
						}
					]
        }
    },
	
    created() {
			Log.raw_material()
			.then(response => {
				this.materials = response.data;
			});	
    },
    methods: {
			openModal(material) {
				this.material = material
				this.$vm2.open('material_modal')
				Log.log({type:'raw_material',id:material.id})
				.then(response => {
					this.histories = response.data;
					this.histories.containers.forEach(container => {
						this.$set(container,'show',true);
					});
					//console.log('histories:',this.histories);
					//console.log('material:',this.material);
					//let naplok = this.histories.logs.map(log => parseInt(log.table_id));
					//let containerek = this.histories.containers.map(container => container.id);
					//console.log('naplok',naplok);
					//console.log('containerek',containerek);
					/*console.log('kulonbseg',
						naplok.filter(x => !containerek.includes(x))
					);*/
				});
			},
			modalClose() {
				this.$vm2.close('material_modal');
				this.histories = false;
			},
			downloadZip(id){
				MaterialContainer.zip(id)
				/*.then(response => {
					console.log(decodeURI(response.data.file));
				})*/
			},
    }
};
</script>

<style>
</style>