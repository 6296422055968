<template>
	<div>
		<PageHeader title="Igénylés" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-title">
							<h3>Igénylések</h3>
						</div>
						<div class="ibox-content">
							<div class="tableFixHead" v-if="orders != null && orders.length != 0">
								<table class="table table-striped">
									<thead class="thead-dark">
										<th>Dátum</th>
										<th class="text-center">Tételek</th>
										<th class="text-right">Státusz</th>
										<th class="text-right">Műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(order, id) in orders" :key="id">
											<td>{{order.created_date}}</td>
											<td class="text-right">{{order.items.length}} db</td>
											<td class="text-right"><span class="label" v-if="statuses" :class="statuses[statuses.map(status => status.id).indexOf(order.status)].label_class">{{statuses[statuses.map(status => status.id).indexOf(order.status)].name}}</span></td>
											<td class="text-right buttons-wide-3">
												<button class="btn btn-warning btn-sm mx-1" @click="showOrder(order)" title="megtekintés"><i class="fa fa-eye"></i></button>
												<button v-if="order.status == 1" class="btn btn-danger btn-sm mx-1" title="lemondás" @click="orderCancel(order)"><i class="fa fa-ban"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="widget p-lg text-center" v-else>
									<div class="m-b-md">
										<i class="fa fa-gift fa-4x"></i>
										<h1 class="m-xs"><i class="fa fa-times"></i></h1>
										<h3 class="font-bold no-margins">
											Nincs megjeleníthető igénylés
										</h3>
										<small>adjon le igénylést a listázáshoz!</small>
									</div>
								</div>
						</div>
					</div>
				</div>
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-title">
							<h3>Új igénylés</h3>
						</div>
						<div class="ibox-content">
							<div class="widget p-lg text-center" v-if="product_exists === false">
								<div class="m-b-md">
									<i class="fa fa-cube fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek termékek
									</h3>
									<small>Legalább 1 termék kell az értékesítéshez</small>
								</div>
							</div>
							<div v-else>
								<div class="row m-sm d-none d-md-flex">
									<div class="col-lg-12 text-right">
										<button class="btn btn-warning" @click="showBasket"><i class="fa fa-shopping-basket fa-2x"></i> <span class="h4">Kosár</span></button>

									</div>
								</div>
								<div class="row m-sm d-block d-md-none">
									<div class="col-12 text-center">
										<button class="btn btn-warning" @click="showBasket"><i class="fa fa-shopping-basket fa-2x"></i> <span class="h4">Kosár</span></button>
									</div>
								</div>
								<div class="row" v-if="products.length == 0">
									<div class="col-12 text-center">
										<h3>Termékek betöltése</h3>
										<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
									</div>
								</div>
								<div class="row" v-if="products.length != 0">
									<div class="col-12 m-sm">
										<input type="text" v-model="product_search" placeholder="keresés..">
									</div>
									<div class="col-lg-3 col-sm-6 col-12" v-for="(product, id) in filteredProductList" :key="id" v-show="product.sizes.filter(s => s.prices[0]).length > 0">
										<div class="panel panel-success">
											<div class="panel-heading">
												<i class="fa fa-cube float-right my-auto fa-3x d-none d-sm-none d-md-none d-lg-block"></i>
												<h3>{{product.name}}</h3>
											</div>
											<div class="panel-body">
												<ul class="list-group">
													<!-- <li class="list-group-item">
														<div class="row">
															<div class="col-lg-5">
																Kabin
															</div>
															<div class="col-lg-7">
																<form @submit.prevent="addToBasketBig(product)">
																	<div class="input-group">
																		<input type="number" @wheel="$event.target.blur()" class="form-control text-right" v-model="product.selling_quantity">
																		<div class="input-group-append">
																			<span class="input-group-addon">{{product.unit}}</span>
																			<button type="submit" class=" btn btn-success"><i class="fa fa-shopping-cart"></i></button>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</li> -->
													<li class="list-group-item" v-for="(size, id) in product.sizes" :key="id" v-show="size.prices[0]">
														<div class="row">
															<div class="col-lg-5">
																{{size.name}}
															</div>
															<div class="col-lg-7">
																<form @submit.prevent="addToBasket(size)" v-if="size.prices[0]">
																	<div class="input-group">
																		<input type="number" @wheel="$event.target.blur()" class="form-control text-right" v-model="size.selling_quantity" style="min-width:60px;">
																		<div class="input-group-append">
																			<span class="input-group-addon"><span v-if="!$root.$data.user.commission">/{{size.packaged_quantity}} </span>db</span>
																			<button type="submit" class=" btn btn-success"><i class="fa fa-shopping-cart"></i></button>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="show_order_modal" class="custom-modal" v-if="this.order != null">
			<modal-vue modalSize="md" @on-close="closeOrderModal()" name="show_order_modal" :headerOptions="{ title: 'Rendelés részletei'}" :footerOptions="{
			btn1: 'vissza',
			disableBtn2: true,
			btn1OnClick: () => {
				closeOrderModal();
			},
		}">
				<div align="center" class="col-12">
					<table class="table table-hover">
						<tr>
							<th>Létrehozás dátuma</th>
							<td>{{order.created_date}}</td>
						</tr>
						<tr>
							<th>Státusz</th>
							<td><span class="label" v-if="statuses" :class="statuses[statuses.map(status => status.id).indexOf(order.status)].label_class">{{statuses[statuses.map(status => status.id).indexOf(order.status)].name}}</span></td>
						</tr>
					</table>
					<h3>Tételek:</h3>
					<div class="tableFixHead">
						<table class="table table-striped">
							<thead class="thead-dark">
								<tr>
									<th class="text-center">Megnevezés</th>
									<th class="text-center">Mennyiség</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, id) in order.items" :key="id">
									<td>{{item.product_name}} <span v-if="item.size_name != null"> - {{item.size_name}}</span></td>
									<td class="text-right">{{item.quantity}} <span v-if="item.unit != null"> {{item.unit}}</span><span v-else> db</span></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</modal-vue>
		</div>
		<div id="basket_modal" class="custom-modal" v-if="basket.partner_id != null">
			<modal-vue modalSize="xl" @on-close="$vm2.close('basket_modal')" name="basket_modal" :headerOptions="{ title: 'Kosár'}" :footerOptions="{
			btn1: 'vissza',
			btn2: 'Igénylés leadása',
			disableBtn2:loading,
			btn2OnClick: () => {
				finishSelling();		
			},
			btn1OnClick: () => {
				$vm2.close('basket_modal');
			},
		}">
				<div align="center" class="col-12">
					<div v-if="basket.items.length != 0">
						<h3>Termékek</h3>
						<div class="tableFixHead">
							<table class="table table-striped">	
								<thead class="thead-dark">
									<tr class="table-active">
										<th>Sorszám</th>
										<th>Termék</th>
										<th>Mennyiség</th>
										<th>Egységár</th>
										<th>Érték</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item,id) in basket.items" :key="id">
										<td>{{id+1}}</td>
										<td>{{item.full_name}}</td>
										<td>{{item.selling_quantity}} db</td>
										<td>{{item.prices[0].price.toLocaleString('hu-HU')}} Ft</td>
										<td>{{(item.prices[0].price * item.selling_quantity).toLocaleString('hu-HU')}} Ft</td>
										<td><button class="btn btn-xs btn-danger" title="törlés" @click="deleteFromBasket(id)"><i class="fa fa-trash"></i></button></td>
									</tr>
								</tbody>
								<tfoot>
									<tr class="table-active">
										<th>Összesen</th>
										<td></td>
										<td>{{basket_db}} db</td>
										<td></td>
										<td>{{basket_price.toLocaleString('hu-HU')}} Ft</td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
					<div v-if="basket.mass_items.length != 0">
						<h3>Kabin termékek</h3>
						<div class="tableFixHead">
							<table class="table table-striped table-hover">	
								<thead class="thead-dark">
									<tr class="table-active">
										<th>Sorszám</th>
										<th>Termék</th>
										<th>Mennyiség</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item,id) in basket.mass_items" :key="id">
										<td>{{id+1}}</td>
										<td>{{item.full_name}}</td>
										<td>{{item.selling_quantity}} {{item.unit}}</td>
										<td><button class="btn btn-xs btn-danger" title="törlés" @click="deleteFromBasketMass(id)"><i class="fa fa-trash"></i></button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="row">
						<div class="col-6 font-bold form-control text-left py-auto" form-control>Szállítási cím:</div>
						<div class="col-6 form-control text-right py-auto">{{partner.city}}, {{partner.street}} {{partner.street_type}}, {{partner.number}}</div>
					</div>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../../components/PageHeader';
	import Product from '../../apis/Product';
	import Order from '../../apis/Order';
	import Partner from '../../apis/Partner';
	import Config from '../../apis/Config';

	export default {
		components: {
			PageHeader
		},
		data() {
			return {
				partner: null,
				size_for_price: {},
				products: [],
				orders: null,
				order: null,
				statuses: null,
				basket: {
					partner_id: null,
					items: [],
					mass_items:[],
				},
				product_search: '',
				product_exists: null,
				loading:false,
			}
		},
		
		computed: {
			basket_db: {
				get: function(){
					return this.basket.items.reduce(function (r, a) {
						return r + parseInt(a.selling_quantity);
					},0 );
				},
				set: function(barmi){
					return barmi;
				}
			},
			basket_price: {
				get: function(){
					return this.basket.items.reduce(function (r, a) {
						return r + (parseInt(a.prices[0].price) * a.selling_quantity);
					},0 );
				},
				set: function(barmi){
					return barmi;
				}
			},
			filteredProductList: {
				get: function(){
					return this.products.filter(product => {
						return product.name.toLowerCase().includes(this.product_search.toLowerCase())
					})
				},
				set: function(barmi){
					return barmi;
				}
			},
		},
		created() {
			if(this.$root.$data.user.partner_id){
				this.initializer();
			}
			
			Config.getConstant('order_status')
			.then(response =>{
				this.statuses = response.data; 
			});
		},
		watch:{
			'$root.$data.user.partner_id': function(val){
				if(val){
					this.initializer();
				}
			}
		},
		methods: {
			initializer(){
				Partner.show(this.$root.$data.user.partner_id)
				.then(response => {
					this.partner = response.data
					this.basket.partner_id = this.partner.id
				});
				Product.partnerIndex(this.$root.$data.user.partner_id)
				.then(response => {
					//console.log('partner_index_response:');
					//console.log(response.data);
					if(response.data.length > 0){
					//console.log('befut az ifbe');
						this.product_exists = true;
						this.products = response.data;
					}else{
						this.product_exists = false;
					}
				});
				Order.showPartnerCommission(this.$root.$data.user.partner_id)
				.then(response => {
					this.orders = response.data;
				});
			},
			addToBasket(size){
				let existing_basket_item = this.basket.items[this.basket.items.map(data => data.id).indexOf(size.id)];
				if(size.selling_quantity <= 0 || size.selling_quantity == null ){
					this.$toast.open({
						message: 'minimum 1 et kosárba kell tennie!',
						type: 'error',
						position: 'top-right'
					});
				}if((size.selling_quantity < 0 || size.selling_quantity == null || size.selling_quantity > size.packaged_quantity) && !this.$root.$data.user.commission){
					this.$toast.open({
						message: '1 és ' + size.packaged_quantity + ' közti értéket adhat meg!',
						type: 'error',
						position: 'top-right'
					});
				}else{
					if(existing_basket_item == null){
							size.full_name = this.products[this.products.map(data => data.id).indexOf(size.product_id)].name + ' - ' + size.name;
							this.basket.items.push(size);
							this.$toast.open({
								message: size.selling_quantity + ' db ' + size.full_name + ' hozzáadva a kosárhoz!',
								type: 'success',
								position: 'top-right'
							});
					}else{
						if(size.selling_quantity == 0){
							this.basket.items.splice(this.basket.items.indexOf(existing_basket_item),1);
							this.$toast.open({
								message: existing_basket_item.full_name + ' törölve a kosárból',
								type: 'error',
								position: 'top-right'
							});
						}else{
							//console.log(size.selling_quantity);
							this.$set(existing_basket_item,'selling_quantity',size.selling_quantity);
							//console.log(this.basket.items);
							this.$toast.open({
								message: existing_basket_item.full_name + ' frissítve, kosárban: ' + existing_basket_item.selling_quantity + ' db',
								type: 'success',
								position: 'top-right'
							});
						}
					}
				}
				//console.log(this.basket);
			},
			addToBasketBig(product){
				let existing_basket_item = this.basket.mass_items[this.basket.mass_items.map(data => data.id).indexOf(product.id)];
				if(product.selling_quantity <= 0 || product.selling_quantity == null ){
					this.$toast.open({
						message: 'minimum 1 et kosárba kell tennie!',
						type: 'error',
						position: 'top-right'
					});
				}else{
					if(existing_basket_item == null){
							product.full_name = 'Lédig - ' + product.name;
							this.basket.mass_items.push(product);
							this.$toast.open({
								message: product.selling_quantity + ' db ' + product.full_name + ' hozzáadva a kosárhoz!',
								type: 'success',
								position: 'top-right'
							});
					}else{
						if(product.selling_quantity == 0){
							this.basket.mass_items.splice(this.basket.mass_items.indexOf(existing_basket_item),1);
							this.$toast.open({
								message: existing_basket_item.full_name + ' törölve a kosárból',
								type: 'error',
								position: 'top-right'
							});
						}else{
							//console.log(product.selling_quantity);
							this.$set(existing_basket_item,'selling_quantity',product.selling_quantity);
							//console.log(this.basket.mass_items);
							this.$toast.open({
								message: existing_basket_item.full_name + ' frissítve, kosárban: ' + existing_basket_item.selling_quantity + ' db',
								type: 'success',
								position: 'top-right'
							});
						}
					}
				}
				//console.log(this.basket);
			},
			showBasket(){
				if(this.basket.items.length == 0 && this.basket.mass_items.length == 0){
					this.$toast.open({
						message: 'A kosár üres',
						type: 'warning',
						position: 'top-right'
					});
				}else{
					this.$forceUpdate(this.basket);
					this.$vm2.open('basket_modal'); 
					//console.log('products:',this.products);
					//console.log('partner:',this.partner);
					//console.log('basket:',this.basket);
				}
			},
			showOrder(order){
				let vue = this;
				new Promise(function(resolve){
					vue.order = order;
					resolve('finished');
				}).then(() => {
					this.$vm2.open('show_order_modal');
				});
			},
			closeOrderModal(){
				let vue = this;
				new Promise(function(resolve){
					vue.$vm2.close('show_order_modal');
					resolve('finished');
				}).then(() => {
					this.order = null;
				});
			},
			finishSelling(){
				this.loading = true;
				var basket_submit = {
					partner_id: this.basket.partner_id,
					items:[]
				}
				this.basket.items.forEach(item => {
					basket_submit.items.push({
						product_id: item.product_id,
						size_id: item.id,
						quantity: item.selling_quantity,
					}); 
				});
				this.basket.mass_items.forEach(item => {
					basket_submit.items.push({
						product_id: item.id,
						quantity: item.selling_quantity,
					}); 
				});
				Order.addCommission(basket_submit)
				.then(() => {
					this.$vm2.close('basket_modal');
					//console.log(response.data);
				}).then(() => {
					this.basket.items = [];
					Order.showPartnerCommission(this.$root.$data.user.partner_id)
					.then(response => {
						this.orders = response.data;
					});
				}).finally(() => {
					this.loading = false;
				});
			},
			deleteFromBasket(id){
				this.basket.items.splice(id,1);
				if(this.basket.items.length == 0 && this.basket.mass_items.length == 0){
					this.$vm2.close('basket_modal');
				}
			},
			deleteFromBasketMass(id){
				this.basket.mass_items.splice(id,1);
				if(this.basket.mass_items.length == 0 && this.basket.items.length == 0){
					this.$vm2.close('basket_modal');
				}
			},
			orderCancel(order){
				this.$confirm({
					message: `Biztos lemondod ezt az igénylést?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					callback: confirm => {
						if (confirm) {
							Order.updateStatus(order.id,{status:5})
							.then(() => {
								this.$set(order,'status',5);
							});
						}
					}
				})
			}
		}
	};
</script>

<style>
</style>