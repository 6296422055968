<template>
    <button class="btn btn-primary" :disabled="loading">
        <i class="fa fa-cog fa-spin" v-if="loading"></i><span v-if="loading">&nbsp;</span>
        <slot></slot>
    </button>
</template>

<script>
export default {
    props:{
        loading:Boolean,
    }
}
</script>