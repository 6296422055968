<template>
	<div>
		<PageHeader :title="intermediate_product.name + ' töltése'" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-content">
							<h2>Rendelkezésre álló félkész termék: {{intermediate_product.quantity}}</h2>
							<h3 class="mb-4">Adja meg a tölteni kívánt mennyiségeket:</h3>
							<span class="label label-danger" v-if="errors.intermediate_product_id">
								{{ errors.intermediate_product_id[0] }}
							</span>
							<span class="label label-danger" v-if="errors.sizes">
								{{ errors.sizes[0] }}
							</span>
							<form @submit.prevent="addFilling" v-if="intermediate_product.sizes.length != 0 && intermediate_product.sizes !== false">
								<div class="row mt-2 pt-3 border-top" v-for="(size, id) in intermediate_product.sizes" :key="id">
									<div class="col-sm-9">{{size.name}}:</div>
									<div class="col-sm-3 text-center">
										<input type="number" @wheel="$event.target.blur()" @focus="$event.target.select()" class="form-control text-right" v-model="intermediate_product.sizes[id].expected_quantity" required v-if="(size.has_recipe === true && size.is_third_party_filling == 0)">
										<span class="label label-danger" v-if="size.has_recipe === false"><i class="fa fa-exclamation-triangle"></i> hiányzó recept</span>
										<span class="label label-danger" v-if="(size.is_third_party_filling == 1)"><i class="fa fa-exclamation-triangle"></i> külsőleg töltött</span>
									</div>
								</div>
								<div class="row mt-2">
									<div class="col text-right">
										<loading-button :loading="loading">Mentés</loading-button>
									</div>
								</div>
							</form>
							<div class="widget  p-lg text-center" v-if="intermediate_product.sizes.length == 0">
								<div class="m-b-md">
										<i class="fa fa-shopping-basket fa-4x"></i>
										<h1 class="m-xs"><i class="fa fa-times"></i></h1>
										<h3 class="font-bold no-margins">
												Nincsenek kiszerelések
										</h3>
										<small>Adjon hozzá kiszereléseket hogy tölthessen</small><br>
										<router-link :to="'/product/'+intermediate_product.product_id" class="btn btn-xs btn-primary mt-1"><i class="fa fa-location-arrow"></i> a termékhez</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import IntermediateProduct from "../../apis/IntermediateProduct";

export default {
    components: { PageHeader },
    data() {
        return {
					sizes_array: [],
					submit_object: {},
					intermediate_product:{
						name: '',
						sizes: false
					},
					errors: [],
					loading:false,
        }
    },
	
    created() {
			IntermediateProduct.sizes(this.$route.params.id)
			.then(response => {
				this.intermediate_product = response.data;
				//console.log(this.intermediate_product);
			}).then(() => {
				this.intermediate_product.sizes.forEach(element => {
					this.$set(element,'expected_quantity',0);
				});
			});
    },
    methods: {
			addFilling(){
				this.loading = true;
				var count = 0;
				this.intermediate_product.sizes.forEach(element => {
					let i = {};
					count += element.expected_quantity;
					if(element.expected_quantity != 0){
						i.size_id = element.id;
						i.expected_quantity = element.expected_quantity;
						this.sizes_array.push(i);
						this.submit_object.intermediate_product_id = this.$route.params.id;
						this.submit_object.sizes = this.sizes_array;
					}
					
				});
				if(count == 0){
					this.$toast.open({
						'type': 'error',
						'message': 'Minimum egyet kell töltenie',
						'position': 'top-right'
					});
					this.loading = false;
				}else{
					IntermediateProduct.store(this.submit_object)
					.then(response => {					
						this.$router.push({path: response.headers['content-location'] })
						//console.log(response);
					})
					.catch( () => {
						//console.log('asd');
						this.sizes_array = [];
						this.submit_object.sizes = this.sizes_array;
						//console.log('submit object',this.submit_object);
					}).finally(() => {
						this.loading = false;
					});
				}
			}
    }
};
</script>

<style>
</style>