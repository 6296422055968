import Api from "./Api";
import Csrf from "./Csrf";

export default {

  async add(data) {
    await Csrf.getCookie();

    return Api.post("/external_press",data);
  },
  
  async return(data) {
    await Csrf.getCookie();

    return Api.post("/external_press/return",data);
  },
    
};