<template>
		<div>
				<PageHeader title="Gyártási dokumentáció módosítása" />
				<div class="row">
						<div class="col-lg-12" align="center">
							<div class="ibox">
								<form  @submit.prevent="parametersSave()">
									<div class="ibox-title px-5">
										<h2 class="mx-auto">Gyártási Dokumentáció:</h2>
									</div>
									<div class="ibox-content">
										<div id="app">
											<label>Gyártási paraméterek:</label>
											<vue-editor v-model="production.production_parameters"></vue-editor>
											<span class="label label-danger" v-if="errors.production_parameters">
													{{ errors.production_parameters[0] }}
												</span>
										</div>
										<div class="row mt-3">
											<div class="col-md-4">
												<div class="form-group">
													<label>Gyártási szám:</label>
													<input type="text" class="form-control" v-model="production.production_number">
													<span class="label label-danger" v-if="errors.production_number">
														{{ errors.production_number[0] }}
													</span>
												</div>
												<div class="form-group">
													<label>Mikrobiológia indult:</label>
													<input type="date" class="form-control" v-model="production.microbiology_start" >
													<span class="label label-danger" v-if="errors.microbiology_start">
														{{ errors.microbiology_start[0] }}
													</span>
												</div>
												<!-- <div class="form-group">
													<label>Mikrobiológia vége:</label>
													<input type="date" class="form-control" v-model="production.microbiology_stop">
													<span class="label label-danger" v-if="errors.microbiology_stop">
														{{ errors.microbiology_stop[0] }}
													</span>
												</div> -->
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label>Gyártást végezte</label>
													<v-select v-model="production.production_users" :from="users" as="name:id:id" tagging multiple></v-select>
													<span class="label label-danger" v-if="errors.production_users">
														{{ errors.production_users[0] }}
													</span>
												</div>
												<div class="form-group">
													<label>Mintavételt végezte</label>
													<v-select v-model="production.product_sampled_by" :from="users" as="name:id:id" tagging></v-select>
													<span class="label label-danger" v-if="errors.product_sampled_by">
														{{ errors.product_sampled_by[0] }}
													</span>
												</div>
												<div class="form-group">
													<label>Termék pH:</label>
													<input type="number" @wheel="$event.target.blur()" step=".01" v-model="production.product_ph" class="form-control">
													<span class="label label-danger" v-if="errors.product_ph">
														{{ errors.product_ph[0] }}
													</span>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label>Az edények gyártásra alkalmas állapotát ellenőrizte</label>
													<v-select v-model="production.container_checked_by" :from="users" as="name:id:id" tagging></v-select>
													<span class="label label-danger" v-if="errors.container_checked_by">
														{{ errors.container_checked_by[0] }}
													</span>
												</div>
												<div class="form-group">
													<label>Átmeneti tárolót ellenőrizte</label>
													<v-select v-model="production.storage_inspected_by" :from="users" as="name:id:id" tagging></v-select>
													<span class="label label-danger" v-if="errors.storage_inspected_by">
														{{ errors.storage_inspected_by[0] }}
													</span>
												</div>
												<div class="form-group">
													<label>Az edények és eszközök takarítását és fertőtlenítését végezte:</label>
													<v-select v-model="production.cleaned_by" :from="users" as="name:id:id" tagging></v-select>
													<span class="label label-danger" v-if="errors.cleaned_by">
														{{ errors.cleaned_by[0] }}
													</span>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-6">
												<label>Termék megjelenés:</label>
												<textarea v-model="production.product_features" class="form-control" rows="3"></textarea>
												<span class="label label-danger" v-if="errors.product_features">
													{{ errors.product_features[0] }}
												</span>
											</div>
											<div class="col-md-6">
												<label>Gyártási leirattól eltérő esemény:</label>
												<textarea v-model="production.production_special_event" class="form-control" rows="3"></textarea>
												<span class="label label-danger" v-if="errors.production_special_event">
													{{ errors.production_special_event[0] }}
												</span>
											</div>
										</div>
										<button class="btn btn-primary mt-3 mx-auto" type="submit">Mentés</button>
									</div>
								</form>
							</div>
						</div>
				</div>
		</div>
</template>
 
<script>
import PageHeader from '../../components/PageHeader';
import Production from "../../apis/Production";
import User from "../../apis/User";
import { VueEditor } from "vue2-editor";
import { vSelect } from '@desislavsd/vue-select';

	export default {
		components: {PageHeader, VueEditor, vSelect},
		data() {
			return {
				production: {},
				users: false,
				errors: []
			}
		},	
		created() {
			User.index()
			.then( response => {
				this.users = response.data.filter(function(user){
					return [2,3].includes(parseInt(user.role_id));
				});
				//console.log(this.users);
			}).then(() => {
				Production.parameters(this.$route.params.id)
				.then(response => {
						this.production = response.data;
					//console.log(this.production.production_users);
				});
			});
		},
		methods: {
			parametersSave(){ // Gyártási leírat 
				Production.update(this.production.id,this.production)
				.then(()=>{
					window.history.back();
				})
				.catch(error => {
					this.errors = error.response.data.errors;
				})
			},
			loggg(){console.log(this.production.production_users)}
		}
	}
</script>