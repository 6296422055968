import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/products");
  },
  async inventory_index() {
    await Csrf.getCookie();

    return Api.get("/products/marketing_products");
  },
  async clean_index() {
    await Csrf.getCookie();

    return Api.get("/products/clean_products");
  },
  async partnerIndex(partner_id) {
    await Csrf.getCookie();

    return Api.get("/products/" + partner_id + "/partner");
  },
    
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/products", form);
  },
    
  async show(id) {
    await Csrf.getCookie();

    return Api.get("/products/" + id);
  },
    
  async update(id, form) {
    await Csrf.getCookie();

    return Api.patch("/products/" + id, form);      
      
  },
    
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/products/" + id);      
      
  },
    
  async supply(form) {
    await Csrf.getCookie();

    return Api.post("/products/supply", form);      
      
  },
	
	async finishedInventory() {
		await Csrf.getCookie();
		
		return Api.get("/products/finished_inventory");
	},
	
	async finishedInventoryCsv() {
		await Csrf.getCookie();
		
		return Api.get("/products/finished_inventory_csv");
	}
    
};