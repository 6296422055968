<template>
	<div>
		<PageHeader title="Készlet" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Alapanyagok</h5>
							<div class="ibox-tools">
								<input type="text" v-model="material_search" placeholder="keresés.."/>
							</div>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="materials === false">
								<div class="col-12 text-center">
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="materials.length == 0">
								<div class="m-b-md">
									<i class="fa fa-boxes fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek Alapanyagok
									</h3>
								</div>
							</div>
							<div class="tableFixHead" v-if="materials.length != 0 && materials !== false">
								<table class="table table-striped table-hover">
									<thead class="thead-light">
										<tr>
											<th>Név</th>
											<th>INCI</th>
											<th>Raktárhely</th>
											<th>Készlet</th>
											<th>Műveletek</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(material,id) in filteredMaterials" :key="id">
											<td>{{ material.name }}</td>
											<td>{{ material.inci }}</td>
											<td>{{ material.storage_place_name }}</td>
											<td class="buttons-wide-3">{{ material.quantity }} {{ material.unit }}</td>
											<td class="buttons-wide-3 text-end">
												<router-link :to="'material_containers/'+material.id+'/create'" tag="button" class="btn btn-success btn-xs mx-1 d-inline" title="bevételezés"><i class="fa fa-cubes"></i><span class="d-none"> Bevételezés</span></router-link>
												<button class="btn btn-warning btn-xs mx-1 d-inline" title="kivételezés" @click="openTakeOutModal(material)"><i class="fas fa-hand-lizard fa-rotate-270"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Göngyölegek</h5>
							<div class="ibox-tools">
								<input type="text" v-model="wrapping_search" placeholder="keresés.."/>
							</div>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="wrappings === false">
								<div class="col-12 text-center">
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="wrappings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-boxes fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek Göngyölegek
									</h3>
								</div>
							</div>
							<div class="tableFixHead" v-if="wrappings.length != 0 && wrappings !== false">
								<table class="table table-striped table-hover">
									<thead class="thead-light">
										<tr>
											<th>Név</th>
											<th>Raktárhely</th>
											<th>Készlet</th>
											<th>Műveletek</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(wrapping,id) in filteredWrappings" :key="id">
											<td>{{ wrapping.name }}</td>
											<td>{{ wrapping.place_name }}</td>
											<td>{{ wrapping.quantity }} </td>
											<td class="buttons-wide-3 text-end">
												<button class="btn btn-xs btn-success mx-1 d-inline" title="bevételezés" @click="openModal(wrapping.id)"><i class="fa fa-cubes"></i> <span class="d-none">Bevételezés</span></button>
												<button class="btn btn-warning btn-xs mx-1 d-inline" title="kivételezés" @click="openTakeOutModal(wrapping)"><i class="fas fa-hand-lizard fa-rotate-270"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Termékek</h5>
							<div class="ibox-tools">
								<input type="text" v-model="product_search" placeholder="keresés.."/>
							</div>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="products === false">
								<div class="col-12 text-center">
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-boxes fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek Termékek
									</h3>
								</div>
							</div>
							<div class="tableFixHead" v-if="products.length != 0 && products !== false">
								<table class="table table-striped table-hover">
									<thead class="thead-light">
										<tr>
											<th>Név</th>
											<th class="text-center">Félkész termék</th>
											<th class="text-center">Késztermék</th>
											<th class="text-right">Készlet</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(product,id) in filteredProducts" :key="id">
											<td>{{ product.name }}</td>
											<td class="text-center">
												<i class="fa fa-times-circle text-danger fa-2x" v-if="product.intermediate_products.length == 0"></i>
												<i class="fa fa-check-circle text-green fa-2x" v-else></i>
											</td>
											<td class="text-center">
												<i class="fa fa-times-circle text-danger fa-2x" v-if="product.sizes.filter(s => s.packaged_quantity != 0).length == 0"></i>
												<i class="fa fa-check-circle text-green fa-2x" v-else></i>
											</td>
											<td class="text-right">
												<button class="btn btn-primary mx-1" @click="openDetails(product)" title="részletek"><i class="fa fa-eye"></i></button>
												<button class="btn btn-success mx-1" v-if="product.is_third_party == 1" title="bevételezés"  @click="openProductSupply(product)"><i class="fa fa-cubes"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Ellenminták</h5>
							<div class="ibox-tools">
							</div>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="counter_samples === false">
								<div class="col-12 text-center">
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="counter_samples.length == 0">
								<div class="m-b-md">
									<i class="fa fa-boxes fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek Ellenminták
									</h3>
								</div>
							</div>
							<div class="tableFixHead" v-if="counter_samples.length != 0 && counter_samples !== false">
								<table class="table table-striped table-hover">
									<thead class="thead-light">
										<tr>
											<th>Név</th>
											<th>Töltve</th>
											<th>Lejárati dátum</th>
											<th>Selejtezés</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(counter_sample,id) in counter_samples" :key="id">
											<td>{{counter_sample.name}}</td>
											<td>{{counter_sample.filled_at}}</td>
											<td>{{counter_sample.best_before}}</td>
											<td>
												<button class="btn btn-xs btn-danger" @click="deleteCounterSample(counter_sample.id,id)"><i class="fa fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="detailModal" class="custom-modal">
				<modal-vue modalSize="xl" 
				@on-close="closeDetails"
					name="detailModal"
					:headerOptions="{
						title: 'Készlet adatok',
					}"
				:footerOptions="{
        btn1: 'OK',
        btn1OnClick: () => {
					closeDetails();
        },
				disableBtn2: true,
      }"
			>
				<div class="col-10 modalBody">
					<div v-if="active_product != null">
					<h5>Félkész termékek:</h5>
					<div class="tableFixHead" v-if="wrappings.length != 0 && wrappings !== false">
						<table class="table table-striped table-hover">
							<thead class="thead-light">
								<tr>
									<th>Tároló</th>
									<th>Lejárat</th>
									<th>Készlet</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="active_product.intermediate_products.length == 0"><td colspan="3" rowspan="3" class="text-center"><h2>Nincsenek megjeleníthető félkész termékek</h2></td></tr>
								<tr v-for="(i,id) in active_product.intermediate_products" :key="id">
									<td>{{ i.barcode }}</td>
									<td>{{ i.best_before }}</td>
									<td>{{ i.quantity }} </td>
								</tr>
							</tbody>
						</table>
					</div>
					<h5>Kiszerelések:</h5>
					<div class="tableFixHead" v-if="wrappings.length != 0 && wrappings !== false">
						<table class="table table-striped table-hover">
							<thead class="thead-light">
								<tr>
									<th>Név</th>
									<th>Kiszerelés</th>
									<th>Csomagolásra vár</th>
									<th>Csomagolt</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="active_product.sizes.length == 0"><td colspan="4" rowspan="3" class="text-center"><h2>Nincsenek megjeleníthető kiszerelések</h2></td></tr>
								<tr v-for="(s,id) in active_product.sizes" :key="id">
									<td>{{ s.name }}</td>
									<td>{{ s.size }}</td>
									<td>{{ s.filled_quantity }} </td>
									<td>{{ s.packaged_quantity }} </td>
								</tr>
							</tbody>
						</table>
					</div>
					</div>
				</div>
			</modal-vue>
		</div>
		
		<div id="wrapping_modal" >
			<modal-vue modalSize="l" @on-close="modalClose" name="wrapping_modal" :headerOptions="{ title: wrapping.name + ' bevételezése', }" :footerOptions="{
			btn1: 'Mégse',
			btn2: 'Bevételezés',
			btn2OnClick: () => {
				addWrapping();
			},
			btn1OnClick: () => {
				$vm2.close('wrapping_modal');
			},
		}">
				<div align="center" class="col-12">
						<div class="row" v-if="partners === false">
							<div class="col-12 text-center">
								<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
							</div>
						</div>
						<div class="widget p-lg text-center" v-if="partners.length == 0">
							<div class="m-b-md">
								<i class="fa fa-user fa-4x"></i>
								<h1 class="m-xs"><i class="fa fa-times"></i></h1>
								<h3 class="font-bold no-margins">
									Nincsenek partnerek
								</h3>
								<small>Csak akkor bevételezhet göngyöleget ha van legalább 1 beszállító partner</small>
							</div>
						</div>
						<form @submit.prevent="addWrapping" v-if="partners !== false && partners.length != 0">
							<div class="form-group">
								<label>Mennyiség</label>
								<input type="text" class="form-control" v-model="formWrapping.quantity">
								<span class="label label-danger" v-if="errors.quantity">
										{{ errors.quantity[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Teljes mennyiség nettó ára</label>
								<div class="input-group">
									<input type="text" class="form-control" v-model="formWrapping.price">
									<span class="input-group-addon">
											<span>Ft</span>
									</span>
								</div>
								<span class="label label-danger" v-if="errors.price">
										{{ errors.price[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Szállítási díj</label>
								<div class="input-group">
									<input type="text" class="form-control" v-model="formWrapping.shipping_price">
									<span class="input-group-addon">
											<span>Ft</span>
									</span>
								</div>
								<span class="label label-danger" v-if="errors.shipping_price">
										{{ errors.shipping_price[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Beszállító</label>
								<v-select v-model="formWrapping.partner_id" :from="partners" as="name:id:id" tagging></v-select>
								<span class="label label-danger" v-if="errors.partner_id">
										{{ errors.partner_id[0] }}
								</span>
							</div>
						</form>
					</div>
			</modal-vue>
		</div>
		
		<div id="product_supply" >
			<modal-vue modalSize="l" @on-close="closeProductSupply" name="product_supply" :headerOptions="{ title: product_to_supply.name + ' bevételezése', }" :footerOptions="{
			btn1: 'Mégse',
			btn2: 'Bevételezés',
			btn2OnClick: () => {
				sendProductSupply();
			},
			btn1OnClick: () => {
				$vm2.close('product_supply');
			},
		}">
				<div align="center" class="col-12 overflow-auto" style="max-height:400px;">
						<div class="row" v-if="product_partners === false">
							<div class="col-12 text-center">
								<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
							</div>
						</div>
						<div class="widget p-lg text-center" v-else-if="product_partners.length == 0">
							<div class="m-b-md">
								<i class="fa fa-user fa-4x"></i>
								<h1 class="m-xs"><i class="fa fa-times"></i></h1>
								<h3 class="font-bold no-margins">
									Nincsenek partnerek
								</h3>
								<small>Csak akkor bevételezhet terméket ha van legalább 1 beszállító partner</small>
							</div>
						</div>
						<div class="widget p-lg text-center" v-else-if="!product_to_supply.sizes || product_to_supply.sizes.length == 0">
							<div class="m-b-md">
								<i class="fa fa-shopping-basket fa-4x"></i>
								<h1 class="m-xs"><i class="fa fa-times"></i></h1>
								<h3 class="font-bold no-margins">
									Nincsenek kiszerelések
								</h3>
								<small>Csak akkor bevételezhet terméket ha van legalább 1 kiszerelése</small>
							</div>
						</div>
						<form @submit.prevent="addWrapping" v-else>
							<div class="form-group">
								<label>Beszállító</label>
								<v-select v-model="product_to_supply.partner_id" :from="product_partners" as="name:id:id" tagging></v-select>
								<span class="label label-danger" v-if="errors.partner_id">
									{{ errors.partner_id[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Lejárati dátum</label>
								<div class="input-group date">
									<span class="input-group-addon"><i class="fa fa-calendar"></i></span>
									<input type="date" :min="currentDate()" max="9999-12-31" class="form-control" v-model="product_to_supply.best_before">
								</div>
								<span class="label label-danger" v-if="errors.best_before">
									{{ errors.best_before[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Batch szám</label>
								<input type="text" class="form-control" ref="batch_number" v-model="product_to_supply.batch_number">
								<span class="label label-danger" v-if="errors.batch_number">
									{{ errors.batch_number[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Mennyiség</label>
								<div class="input-group">
									<input @wheel="$event.target.blur()" type="number" class="form-control" v-model="product_to_supply.quantity" min="1">
								</div>
								<span class="label label-danger" v-if="errors.quantity">
									{{ errors.quantity[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Teljes mennyiség nettó ára</label>
								<div class="input-group">
									<input @wheel="$event.target.blur()" type="number" min="0" max="2147483647" class="form-control" v-model="product_to_supply.price">
									<span class="input-group-addon">
											<span>Ft</span>
									</span>
								</div>
								<span class="label label-danger" v-if="errors.price">
									{{ errors.price[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Szállítási költség</label>
								<div class="input-group">
									<input @wheel="$event.target.blur()" type="number" max="2147483647" min="0" class="form-control" v-model="product_to_supply.shipping_price">
									<span class="input-group-addon">
											<span>Ft</span>
									</span>
								</div>
								<span class="label label-danger" v-if="errors.shipping_price">
									{{ errors.shipping_price[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Kiszerelés:</label>
								<v-select v-model="product_to_supply.size_id" :from="product_to_supply.sizes" as="name:id:id" tagging></v-select>
								<span class="label label-danger" v-if="errors.size_id">
									{{ errors.size_id[0] }}
								</span>
							</div>
						</form>
					</div>
			</modal-vue>
		</div>
		
		<div id="takeout_modal" >
			<modal-vue modalSize="l" @on-close="takeoutClose" name="takeout_modal" :headerOptions="{ title: takeout_object.name + ' kivételezése', }" :footerOptions="{
			btn1: 'Mégse',
			btn2: 'Kivételezés',
			disableBtn2:loading,
			btn2OnClick: () => {
				takeOutSend();
			},
			btn1OnClick: () => {
				$vm2.close('takeout_modal');
			},
		}">
				<div align="center" class="col-12">
					<form @submit.prevent="takeOutSend()">
						<label>Kivételezendő mennyiség:</label>
						<input @wheel="$event.target.blur()" type="number" class="form-control" v-model="takeout_object.takeout_quantity" required ref="takeout_quantity_input">
						<span class="label label-danger" v-if="errors.takeout_quantity">
							{{ errors.takeout_quantity[0] }}
						</span>
					</form>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../../components/PageHeader';
	import Material from "../../apis/Material";
	import Wrapping from "../../apis/Wrapping";
	import Product from "../../apis/Product";
	import Partner from "../../apis/Partner";
	import CounterSample from "../../apis/CounterSample";
	import { vSelect } from '@desislavsd/vue-select'

	export default {
		components: {
			PageHeader, vSelect
		},
		data() {
			return {
				wrappings: false,
				materials: false,
				products: false,
				counter_samples:false,
				active_product: null,
				material_search:'',
				wrapping_search:'',
				product_search:'',
				wrapping: {},
				formWrapping: {
					partner_id: null,
					shipping_price: 0,
				},
				partners: false,
				product_partners: false,
				errors:[],
				takeout_object:{},
				product_to_supply:{},
				loading: false,
			}
		},
		computed: {
			filteredMaterials: function(){
				return this.materials.filter(material => {
					return material.name.toLowerCase().includes(this.material_search.toLowerCase())
				});
			},
			filteredWrappings: function(){
				return this.wrappings.filter(wrapping => {
					return wrapping.name.toLowerCase().includes(this.wrapping_search.toLowerCase())
				});
			},
			filteredProducts: function(){
				return this.products.filter(product => {
					return product.name.toLowerCase().includes(this.product_search.toLowerCase())
				});
			},
		},
		created() {
			CounterSample.index()
			.then(response => {
				this.counter_samples = response.data;
			});
			Material.index()
			.then(response => {
				this.materials = response.data;
			});
			Wrapping.index()
			.then(response => {
				this.wrappings = response.data;
			});
			Product.inventory_index()
			.then(response => {
				this.products = response.data;
			});
			Partner.index(1,2)
			.then(response => {
				this.partners = response.data;
			})
			Partner.index(1,3)
			.then(response => {
				this.product_partners = response.data;
			})
		},
		methods:{
			deleteCounterSample(id,index){
				this.$confirm({
					message: `Biztos törlöd az ellenmintát? törlés után nem állítható vissza!`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					callback: confirm => {
						if (confirm) {
							CounterSample.delete(id)
							.then(() => {
								this.counter_samples.splice(index,1);
							})
						}
					}
				});
			},
			sendProductSupply(){
				Product.supply({
					partner_id: this.product_to_supply.partner_id,
					best_before: this.product_to_supply.best_before,
					batch_number: this.product_to_supply.batch_number,
					quantity: this.product_to_supply.quantity,
					price: this.product_to_supply.price,
					shipping_price: this.product_to_supply.shipping_price,
					product_id: this.product_to_supply.id,
					size_id: this.product_to_supply.size_id,
				})
				.then(() => {
					this.closeProductSupply();
				}).catch(error => {
					this.errors =  error.response.data.errors;
				});
			},
			currentDate() {
				const current = new Date();
				const date = `${current.getFullYear()}-${('00'+ (current.getMonth()+1)).slice(-2)}-${ ('00'+ current.getDate()).slice(-2)}`;
				return date;
			},
			takeOutSend(){
				this.loading = true;
				if(parseInt(this.takeout_object.takeout_quantity) < 1 || !this.takeout_object.takeout_quantity){
					this.$set(this.errors,'takeout_quantity',['minimum egyet kell kivételeznie!']);
					this.loading = false;
				}else if(parseInt(this.takeout_object.takeout_quantity) > this.takeout_object.quantity){
					this.$set(this.errors,'takeout_quantity',['Maximum '+this.takeout_object.quantity+'-t kivételezhet']);
					this.loading = false;
				}else if(this.takeout_object.inci){
					Material.takeOut(this.takeout_object.id,{quantity:this.takeout_object.takeout_quantity}).catch(error => {
						this.errors = error.response.data.errors;
					})
					.then(() => {
						this.errors = [];
						this.takeoutClose();
						this.materials = false;
						Material.index()
						.then(response => {
							this.materials = response.data;
						});
					}).finally(() => {
						this.loading = false;
					});
				}else{
					Wrapping.takeOut(this.takeout_object.id,{quantity:this.takeout_object.takeout_quantity}).catch(error => {
						this.errors = error.response.data.errors;
					})
					.then(() => {
						this.errors = [];
						this.takeoutClose();					
						this.wrappings = false;
						Wrapping.index()
						.then(response => {
							this.wrappings = response.data;
						});
					}).finally(() => {
						this.loading = false;
					});
				}
			},
			openTakeOutModal(object){
				this.takeout_object = object;
				let vue = this;
				new Promise(resolve => {
					vue.$vm2.open('takeout_modal');
					resolve('finished');
				}).then(() => {
					this.$refs.takeout_quantity_input.focus();
				});
			},
			takeoutClose(){
				this.errors = [];
				this.takeout_object = {};
				this.$vm2.close('takeout_modal');
			},
			openProductSupply(object){
				this.product_to_supply = JSON.parse(JSON.stringify(object));
				this.$vm2.open('product_supply');
			},
			closeProductSupply(){
				this.errors = [];
				this.product_to_supply = {};
				Product.inventory_index()
				.then(response => {
					this.products = response.data;
				});
				this.$vm2.close('product_supply');
			},
			openModal(id) {
				Wrapping.show(id)
				.then(response => {
					this.wrapping = response.data
				}).then(() => {
					//console.log(this.wrapping);
					this.$set(this.formWrapping,'price',this.wrapping.last_price);
					this.$set(this.formWrapping,'partner_id',this.wrapping.last_partner_id);
					//console.log(this.formWrapping);
					this.$vm2.open('wrapping_modal')
				});
			},
			addWrapping() {
				//console.log(this.formWrapping);
				/*if(this.formWrapping.price > 2147483647 || this.formWrapping.price <= 0){
					this.$set(this.errors,'price',['100 és 2147483647 közti értéket adhat meg!']);
				}else*/ if(this.formWrapping.quantity > 2147483647 || this.formWrapping.quantity <= 0){
					this.$set(this.errors,'quantity',['1 és 2147483647 közti értéket adhat meg!']);
				}else{
					Wrapping.supply(this.wrapping.id, this.formWrapping)
					.then(() => {
						let i = this.wrappings.map(data => data.id).indexOf(this.wrapping.id);
						this.wrappings[i].quantity = parseInt(this.wrappings[i].quantity) + parseInt(this.formWrapping.quantity);
						//this.wrappings[i].quantity += this.formWrapping.quantity.parseInt();
						this.modalClose();
					})
					.catch(error => {
							this.errors = error.response.data.errors;
					})
				}
				
			},
			modalClose() {
				this.$vm2.close('wrapping_modal');
					this.formWrapping = {};
			},
			openDetails(product){
				this.active_product = product;
				this.$vm2.open('detailModal');
			},
			closeDetails(event){
				if(event){
					console.log(event);
				}
				this.active_product = null;
				this.$vm2.close('detailModal');
			}
		},
		beforeRouteLeave (to, from , next) {
			new Promise(resolve => {
				this.$vm2.close('detailModal');
				resolve();
			}).then(() => {
				next();
			})
		}
	};
</script>

<style>
</style>