<template>
	<div>
		<PageHeader title="Töltés" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="alert alert-danger" v-if="actual !== false">
								<i class="fa fa-exclamation-triangle"></i>	Van folyamatban lévő töltés, csak az folytatható! 
							</div>
							<input class="mb-2" v-if="products && products.length != 0" type="text" v-model="search" placeholder="keresés.."/>
							<div v-for="(product, id) in filteredProducts" :key="id">
								<h3>{{product.name}}:</h3>
								<div class="table-responsive">
									<table class="table">
										<thead>
											<tr>
												<th><i class="fa fa-barcode"></i></th>
												<th>Vonalkód</th>
												<th>Lejárati dátum</th>
												<th>Mennyiség</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(intermediate_product, id) in product.containers" :key="id">
												<td><button @click="printBarcode(intermediate_product,product.name)" class="btn btn-primary" :title="intermediate_product.barcode+' újranyomtatása'"><i class="fa fa-barcode"></i></button></td>
												<td>{{intermediate_product.barcode}}</td>
												<td>{{intermediate_product.best_before}}</td>
												<td>{{intermediate_product.quantity}}</td>
												<td>
													<button @click="fill(intermediate_product.id)" class="btn btn-success" title="töltés" v-if="!actual || actual.intermediate_product_id == intermediate_product.id" :disabled="actual != false && actual.intermediate_product_id != intermediate_product.id"><i class="fa fa-tint"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div id="container_barcode" class="d-none">
									<div style="width:100%; text-align:center">
										<span style="font-size:0.6em">{{ barcode_div.name }}</span>
										<br>
										<div style="display:inline-block;width:30px;height:74px;clear:both;background-color:white;"><span style="color:white">....</span></div>
										<barcode v-if="barcode_div.barcode" v-bind:value="barcode_div.barcode" width="1" height="35" fontSize="7" style="display:inline-block;" marginTop="1">
											Hiba történt a vonalkód generálás során
										</barcode>
										<div style="display:inline-block;width:30px;height:74px;clear:both;background-color:white;"><span style="color:white">....</span></div>
										<div class="print-invisible">
											<button onclick="window.print();" class="btn mx-auto" style="color: #fff; background-color: #1ab394; border-color:#1ab394;">Újranyomtatás</button>
											<button onclick="window.close();" class="btn mx-auto" style="color: #fff; background-color: #ed5565; border-color: #ed5565;">Bezár</button>
										</div>
									</div>
								</div>
							</div>
							<div class="row" v-if="products === false">
								<div class="col-12 text-center">
									<h3>Tölthető termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget  p-lg text-center" v-if="products.length == 0">
								<div class="m-b-md">
										<i class="fa fa-tint fa-4x"></i>
										<h1 class="m-xs"><i class="fa fa-times"></i></h1>
										<h3 class="font-bold no-margins">
												Nincsenek tölthető termékek
										</h3>
										<small>gyártson félkész terméket a töltéshez</small><br>
										<router-link :to="'/productions'" class="btn btn-xs btn-primary mt-1"><i class="fa fa-location-arrow"></i> gyártáshoz</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import IntermediateProduct from "../../apis/IntermediateProduct";
import Filling from "../../apis/Filling";
import VueBarcode from 'vue-barcode';

export default {
    components: { PageHeader, 'barcode' : VueBarcode },
    data() {
        return {
			search:'',
			products: false,
			actual: false,
			barcode_div:{
				name:'',
				barcode:'',
			},
        }
    },
	computed:{
		filteredProducts(){
			if(this.products){
				return this.products.filter(product => {
					return product.name.toLowerCase().includes(this.search.toLowerCase())
				});
			}else{
				return [];
			}
		}
	},
    created() {
		Filling.getActual()
			.then(response => {
				if(Object.keys(response.data).length != 0){
					this.actual = response.data
				}
			});
			IntermediateProduct.index()
			.then(response => {
				this.products = response.data;
				//console.log('products',this.products);
			})
			
    },
    methods: {
			fill(intermediate_product_id){
				if(this.actual != false && this.actual.intermediate_product_id == intermediate_product_id){
					this.$router.push('fill/'+ this.actual.id);
				}else{
					IntermediateProduct.canFill(intermediate_product_id)
					.then(response => {
						this.$router.push({path: response.headers['content-location'] })
					})
					.catch(error => {
							this.errors = error.response.data.errors;
					})
				}
			},
			printBarcode(intermediate_product,product_name){
				this.setBarcodeDiv(product_name,intermediate_product.barcode).then(() =>{
					let printData = document.getElementById("container_barcode").innerHTML;
					let newTab = window.open();

					newTab.document.body.innerHTML = printData+'<style>.btn {padding:1em; font-size:2em; margin:2em; display:block;}.btn:focus { box-shadow: none;} @media print {.print-invisible{display:none;}}.mx-auto {  margin-right: auto !important;margin-left: auto !important;}</style>';
					newTab.print();
				})
			},
			setBarcodeDiv(name,barcode){
				let vue = this;
				return new Promise(function(resolve) { 
					vue.$set(vue.barcode_div,'name',name);
					vue.$set(vue.barcode_div,'barcode',barcode);
					resolve('finished')
				}); 
			},
    }
};
</script>

<style>
</style>