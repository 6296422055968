<template>
	<div>
		<PageHeader title="Termékek" button_title="Új termék létrehozása" button_href="/product" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="products === false">
								<div class="col-12 text-center">
									<h3>Termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-cube fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek termékek
									</h3>
									<small>hozzon létre terméket</small>
								</div>
						</div>
							<div class="table-responsive" v-if="products.length != 0 && products !== false">
								<input type="text" v-model="search" placeholder="keresés.."/>
								<table class="table table-striped w-100">
									<thead>
										<th @click="sort('name')" class="text-nowrap pointer">
												Megnevezés 
												<i v-if="currentSort == 'name' && currentSortDir == 'asc'" class="fa fa-sort-alpha-down"></i>
												<i v-if="currentSort == 'name' && currentSortDir == 'desc'" class="fa fa-sort-alpha-up-alt"></i>
										</th>
										<th>Leírás</th>
										<th class="text-right">műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(product, id) in filteredProducts" :key="id">
											<td class="project-title">
												{{product.name}}
											</td>
											<td class="project-title">
												{{product.description}}
											</td>
											<td class="text-right buttons-wide-3">
												<router-link :to="'product/'+product.id" class="btn btn-success mx-1 d-inline" title="szerkesztés"><i class="fa fa-pencil-alt"></i></router-link>
												<button class="btn btn-danger mx-1 d-inline" @click="deleteProduct(product.id)" title="törlés"><i class="fa fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Product from "../../apis/Product";

export default {
		components: { PageHeader },
		data() {
			return {
				products: false,
				errors: [],
				search: '',
				currentSort:'name',
				currentSortDir:'asc'
			}
		},
		computed: {
			filteredProducts: function(){
				return this.products.filter(product => {
					return product.name.toLowerCase().includes(this.search.toLowerCase())
				}).sort((a,b) => {
					let modifier = 1;
					if(this.currentSortDir === 'desc') modifier = -1;
					if(a[this.currentSort][0].localeCompare(b[this.currentSort][0],'hu-HU') < 0) return -1 * modifier;
					if(a[this.currentSort][0].localeCompare(b[this.currentSort][0],'hu-HU') > 0) return 1 * modifier;
					return 0;
				});
			},
		},
		created() {
			Product.clean_index()
			.then(response => {
				this.products = response.data;
			});

		},
		methods: {
			sort:function(s) {
				//if s == current sort, reverse
				if(s === this.currentSort) {
					this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
				}
				this.currentSort = s;
			},
		deleteProduct(id) { 
			Product.delete(id)
			.then(() => {
				let i = this.products.map(data => data.id).indexOf(id);
				this.products.splice(i, 1)
			})
			/*.catch((error) => {
				console.log(error);
			})*/
		}
	},
};
	
	
</script>