<template>
	<div>
		<PageHeader title="Göngyöleg" button_title="Új göngyöleg rögzítése" button_href="/wrappings/create" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<table class="table table-hover" v-if="wrappings">
								<thead>
									<td class="col-4">Megnevezés</td>
									<td class="col-2">Leírás</td>
									<td class="col-1">Aktuális készlet</td>
									<td class="col-2">Méret</td>
									<td class="col-1">Mennyiségi egység</td>
									<td class="col-1 text-center">szerkesztés</td>
									<td class="col-1 text-center">törlés</td>
								</thead>
								<tbody>
									<tr v-for="(wrapping, id) in wrappings" :key="id">
										<td>
											<a :href="'/wrapping/' + wrapping.id">{{wrapping.name}}</a>
										</td>
										<td class="ellipsis-150">
											{{wrapping.description}}
										</td>
										<td class="text-right">
											{{wrapping.quantity}}
										</td>
										<td class="text-right">
											{{wrapping.size}}
										</td>
										<td>
											{{wrapping.unit}}
										</td>
										<td class="text-center">
											<router-link :to="'wrappings/'+wrapping.id+'/edit'" class="btn btn-success" title="szerkesztés"><i class="fa fa-pencil-alt"></i></router-link>
										</td>
										<td class="text-center">
											<button class="btn btn-danger" @click="deleteWrapping(wrapping.id)" title="törlés"><i class="fa fa-trash"></i></button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Wrapping from "../../apis/Wrapping";

export default {
    components: { PageHeader },
    data() {
        return {
            wrappings: []
        }
    },
	
    created() {
        Wrapping.index()
			.then(response => {
				this.wrappings = response.data;
			});

    },
    methods: {
		deleteWrapping(id) { 
				Wrapping.delete(id)
				.then(() => {
					let i = this.wrappings.map(data => data.id).indexOf(id);
					this.wrappings.splice(i, 1)
				})
				.catch(() => {
					alert('Nem sikerült!');
				});
            }
    }
};
</script>

<style>
</style>