<template>
	<div id="container_barcode" v-if="barcode">
		<div style="width:100%; text-align:center">
			<span style="font-size:1.5em;">{{ name }}</span>
			<barcode v-bind:value="barcode" >
				Hiba történt a vonalkód generálás során
			</barcode>
			<div class="print-invisible">
				<button onclick="window.print();" class="btn mx-auto" style="color: #fff; background-color: #1ab394; border-color:#1ab394;">Újranyomtatás</button>
				<button onclick="window.close();" class="btn mx-auto" style="color: #fff; background-color: #ed5565; border-color: #ed5565;">Bezár</button>
			</div>
		</div>
	</div>
</template>


<script>
import VueBarcode from 'vue-barcode';
	export default {
		components: {'barcode' : VueBarcode
		},
		data() {
			return {
				barcode : this.$route.params.barcode,
				name : this.$route.params.name,
			}
		},
		created() {
		},

		methods: {
		},
	}
</script>

<style>
	
</style>