<template>
	<div>
		<PageHeader v-if="production"
			:title="production.product.name + ' ' + production.expected_quantity + ' ' + production.product.unit + ' gyártása'">
			<span>
				<help title="Ebben a lépésben összeszedjük a gyártáshoz szükséges alapanyagokat."
					text="A szoftver sorban kijelzi a soron következő alapanyag nevét, raktárhelyét és vonalkódját. Az alapanyag tároló megtalálását követően le kell olvasni annak vonalkódját. Amennyiben a megfelelő vonlakód került beolvasásra, a rendszer kijelzi a gyártáshoz szükséges mennyiséget. Ennek kimérését követően vissza kell mérni a megmaradt alapanyagot és a beviteli mezőbe írni a mennyiségét. A rendszer továbblép a következő alapanyagra.">
				</help>
			</span>
		</Pageheader>
		<div class="row d-flex justify-content-center" v-if="production && storage_guide">
			<div class="col-md-8 text-center">
				<div class="ibox">
					<div class="ibox-title text-center px-5">
						<h2>Alapanyagok összegyűjtése</h2>
					</div>
					<div class="ibox-content">
						<div class="table-responsive">
							<table class="table table-striped">
								<thead>
									<tr>
										<th>Vonalkód</th>
										<th>Megnevezés</th>
										<th>Tartalom</th>
										<th>Megtalálva</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(container, id) in containers_unique" :key="id">
										<td>{{ container.barcode }}</td>
										<td>{{ container.raw_material.name }}</td>
										<td>{{ container.quantity }}</td>
										<td><input type="checkbox" v-model="container.found"></td>
									</tr>
								</tbody>
							</table>
						</div>
						<button class="btn btn-primary" v-if="to_do_counter == 0" @click="beginProduction()">Tovább a
							gyártásra</button>
					</div>
				</div>

			</div>
		</div>
		<div align="center" v-if="production && !storage_guide">
			<div v-if="production.containers.length > 0" class="col-10">
				<hooper ref="carousel" :settings="hooperSettings">
					<slide v-for="(container, id) in production.containers" :key="id">
						<div class="ibox">
							<div class="ibox-title px-5">
								<h1><strong>{{ String.fromCharCode(96 + container.phase).toUpperCase() }}</strong> fázis
								</h1>
								<h2>{{ container.raw_material.name }} </h2>
							</div>
							<div class="ibox-content">
								<div class="row">
									<div class="col">
										<button @click.prevent="slidePrev" class="btn btn-link float-left"
											v-if="id != 0"><i class="fas fa-chevron-circle-left fa-2x"></i></button>
									</div>
									<div class="col">
										<small>raktárhely:</small>
										<h3>{{ container.raw_material.storage_place.name }}</h3>
										<small>vonalkód:</small>
										<h3>{{ container.barcode }}</h3>
									</div>
									<div class="col">
										<button @click.prevent="slideNext" class="btn btn-link float-right"
											v-if="container.id != active_container.id"><i
												class="fas fa-chevron-circle-right fa-2x"></i></button>
									</div>
								</div>
								<input :ref="'barcode_' + id" @change="validateBarcode($event, container.barcode)"
									@focus="$event.target.select()" type="text" class="form-control col-5 text-center"
									placeholder="olvasd le az alapanyagon lévő vonalkódot" style="min-width:200px;">
								<div v-if="quantity_visible || container.id != active_container.id">
									<hr class="hr-line-solid">
									<h2 v-if="container.id == active_container.id">Vegyél ki
										{{ container.takeout_quantity.toFixed(2) }} {{ container.raw_material.unit }} alapanyagot
									</h2>
									<div>
										<form @submit.prevent="takeoutQuantity(id)">
											<div class="row d-flex justify-content-center">
												<div class="col-md-3">
													<div class="form-group">
														<label for="actual_quantity">Felhasznált mennyiség:</label>
														<div class="input-group">
															<input :ref="'used_input_' + container.barcode"
																@focus="$event.target.select()" type="number"
																@wheel="$event.target.blur()" step=".01"
																class="form-control quantity text-right"
																v-model="container.used_quantity"
																style="min-width:120px;"
																:disabled="container.id != active_container.id">
															<div class="input-group-append">
																<span class="input-group-addon">{{
																	container.raw_material.unit
																}}</span>
															</div>
														</div>
													</div>
													<div class="form-group">
														<label for="actual_quantity">Mérd vissza a megmaradt alapanyagot
															és add meg a mennyiségét:</label>
														<div class="input-group">
															<input @focus="$event.target.select()" type="number"
																@wheel="$event.target.blur()" step=".01" min="0"
																ref="remaining_quantity"
																class="form-control quantity text-right"
																v-model="container.remaining_quantity"
																style="min-width:120px;"
																:disabled="container.id != active_container.id">
															<div class="input-group-append">
																<span class="input-group-addon">{{
																	container.raw_material.unit
																}}</span>
															</div>
														</div>
													</div>
												</div>
											</div>
											<loading-button v-if="container.id == active_container.id"
												:loading="loading">
												Mentés
											</loading-button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</slide>
					<hooper-progress slot="hooper-addons"></hooper-progress>
				</hooper>
			</div>
			<div v-else>

				<div v-if="!production.parameters_saved">
					<div class="ibox">
						<form @submit.prevent="parametersSave()">
							<div class="ibox-title px-5">
								<h2>Gyártási Dokumentáció:</h2>
							</div>
							<div class="ibox-content">
								<div id="app">
									<label>Gyártási paraméterek:</label>
									<vue-editor v-model="production.production_parameters"></vue-editor>
									<span class="label label-danger" v-if="errors.production_parameters">
										{{ errors.production_parameters[0] }}
									</span>
								</div>
								<div class="row mt-3">
									<div class="col-md-4">
										<div class="form-group">
											<label>Gyártási szám:</label>
											<input type="text" class="form-control"
												v-model="production.production_number">
											<span class="label label-danger" v-if="errors.production_number">
												{{ errors.production_number[0] }}
											</span>
										</div>
										<div class="form-group">
											<label>Mikrobiológia indult:</label>
											<input type="date" class="form-control"
												v-model="production.microbiology_start" :min="now" max="2100-12-31">
											<span class="label label-danger" v-if="errors.microbiology_start">
												{{ errors.microbiology_start[0] }}
											</span>
										</div>
										<div class="form-group">
											<label>Szavatosság:</label>
											<input type="date" v-model="production.best_before" class="form-control"
												:min="now" max="2100-12-31">
										</div>
										<!-- <div class="form-group">
											<label>Mikrobiológia vége:</label>
											<input type="date" class="form-control" v-model="production.microbiology_stop">
											<span class="label label-danger" v-if="errors.microbiology_stop">
												{{ errors.microbiology_stop[0] }}
											</span>
										</div> -->
									</div>
									<div class="col-md-4">
										<div class="form-group">
											<label>Gyártást végezte</label>
											<v-select v-model="production.production_users" :from="users"
												as="name:id:id" multiple></v-select>
											<span class="label label-danger" v-if="errors.production_users">
												{{ errors.production_users[0] }}
											</span>
										</div>
										<div class="form-group">
											<label>Mintavételt végezte</label>
											<v-select v-model="production.product_sampled_by" :from="users"
												as="name:id:id" multiple></v-select>
											<span class="label label-danger" v-if="errors.product_sampled_by">
												{{ errors.product_sampled_by[0] }}
											</span>
										</div>
										<div class="form-group" v-if="production.ph == 1">
											<label>Mért pH:</label>
											<input type="number" @wheel="$event.target.blur()" step=".01"
												v-model="production.measured_ph" class="form-control" required>
											<span class="label label-danger" v-if="errors.measured_ph">
												{{ errors.measured_ph[0] }}
											</span>
										</div>
										<div class="form-group" v-if="production.ph == 1">
											<label>Beállított pH:</label>
											<input type="number" @wheel="$event.target.blur()" step=".01"
												v-model="production.adjusted_ph" class="form-control" required>
											<span class="label label-danger" v-if="errors.adjusted_ph">
												{{ errors.adjusted_ph[0] }}
											</span>
										</div>
										<div class="form-group" v-if="production.ph == 1">
											<label>pH megjegyzés:</label>
											<textarea v-model="production.ph_description" class="form-control" cols="30"
												rows="5"></textarea>
											<span class="label label-danger" v-if="errors.ph_description">
												{{ errors.ph_description[0] }}
											</span>
										</div>
										<div class="form-group" v-if="production.ph == 0">
											<span class="label">A termék nem tartalmaz víz fázist, ezért a pH nem
												mérhető</span>
										</div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
											<label>Az edények gyártásra alkalmas állapotát ellenőrizte</label>
											<v-select v-model="production.container_checked_by" :from="users"
												as="name:id:id" multiple></v-select>
											<span class="label label-danger" v-if="errors.container_checked_by">
												{{ errors.container_checked_by[0] }}
											</span>
										</div>
										<div class="form-group">
											<label>Átmeneti tárolót ellenőrizte</label>
											<v-select v-model="production.storage_inspected_by" :from="users"
												as="name:id:id" multiple></v-select>
											<span class="label label-danger" v-if="errors.storage_inspected_by">
												{{ errors.storage_inspected_by[0] }}
											</span>
										</div>
										<div class="form-group">
											<label>Az edények és eszközök takarítását és fertőtlenítését
												végezte:</label>
											<v-select v-model="production.cleaned_by" :from="users" as="name:id:id"
												multiple></v-select>
											<span class="label label-danger" v-if="errors.cleaned_by">
												{{ errors.cleaned_by[0] }}
											</span>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<label>Termék megjelenés:</label>
										<select v-model="production.product_features" class="form-control">
											<option value="undefined" disabled>Válassz a listából</option>
											<option value="megfelelő">megfelelő</option>
											<option value="nem felel meg">nem felel meg</option>
										</select>
										<span class="label label-danger" v-if="errors.product_features">
											{{ errors.product_features[0] }}
										</span>
									</div>
									<div class="col-md-6">
										<label>Gyártási leirattól eltérő esemény:</label>
										<textarea v-model="production.production_special_event" class="form-control"
											rows="3"></textarea>
										<span class="label label-danger" v-if="errors.production_special_event">
											{{ errors.production_special_event[0] }}
										</span>
									</div>
								</div>
								<loading-button :loading="loading">Mentés</loading-button>
							</div>
						</form>
					</div>
				</div>
				<div v-else>
					<div v-if="production.intermediate_product.barcode == false">
						<div class="ibox">
							<div class="ibox-title px-5">
								<h2>Gyártás zárás</h2>
							</div>
							<div class="ibox-content">
								<div class="row d-flex justify-content-center">
									<div class="col-md-4">
										<form @submit.prevent="productionFinish()">
											<div class="form-group">
												<label>Hány {{ production.product.unit }} termék keletkezett?</label>
												<div class="input-group">
													<input @focus="$event.target.select()" type="number"
														@wheel="$event.target.blur()" ref="actual_quantity"
														class="form-control actual_quantity text-right"
														v-model="production.actual_quantity" style="min-width:100px;">
													<div class="input-group-append">
														<span class="input-group-addon">{{
															production.product.unit
														}}</span>
													</div>
												</div>
											</div>
											<div class="form-group">
												<label>Hány tárolóba tetted?</label>
												<input type="number" @wheel="$event.target.blur()"
													@focus="$event.target.select()" class="form-control text-right"
													min="1" max="10" v-model="container_number">
											</div>
											<h3>Tárolók:</h3>
											<div class="form-group"
												v-for="(container, id) in production.finish_containers" :key="id">
												<label>{{++id}}. tároló nettó mennyisége:</label>
												<div class="input-group">
													<input type="number" @wheel="$event.target.blur()"
														@focus="$event.target.select()" class="form-control text-right"
														v-model="container.quantity">
													<div class="input-group-append">
														<span class="input-group-addon">{{
															production.product.unit
														}}</span>
													</div>
												</div>
											</div>
											<loading-button :loading="loading">Mentés</loading-button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<h2>A gyártás sikeresen befejeződött.</h2>
						<h2 v-if="barcodes.length == 1">Termék azonosító:</h2>
						<h2 v-else>Termék azonosítók:</h2>
						<h3 v-for="(barcode, id) in barcodes" :key="id">{{ barcode }}</h3>
						<h2>Termék raktárhelye: {{ production.intermediate_product.storage_place.name }}</h2>
						<button class="btn btn-warning mx-1 d-inline" title="pdf letöltés"
							@click="downloadPdf(production.id)"><i class="fa fa-print"></i> Gyártási leirat
							nyomtatása</button>
						<router-link to="/productions" class="btn btn-primary">vissza a gyártásokhoz</router-link>
						<div id="container_barcode" class="d-none">
							<div style="width:100%; text-align:center">
								<span style="font-size:0.6em">{{ production.product.name }}</span>
								<br>
								<div
									style="display:inline-block;width:30px;height:109px;clear:both;background-color:white;">
									<span style="color:white">....</span>
								</div>
								<barcode v-bind:value="production.intermediate_product.barcode" width="1" height="88"
									fontSize="7" style="display:inline-block;" marginTop="2">
									Hiba történt a vonalkód generálás során
								</barcode>
								<div
									style="display:inline-block;width:30px;height:109px;clear:both;background-color:white;">
									<span style="color:white">....</span>
								</div>
								<div class="print-invisible">
									<button onclick="window.print();" class="btn mx-auto"
										style="color: #fff; background-color: #1ab394; border-color:#1ab394;">Újranyomtatás</button>
									<button onclick="window.close();" class="btn mx-auto"
										style="color: #fff; background-color: #ed5565; border-color: #ed5565;">Bezár</button>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div id="descriptionModal">
			<modal-vue modalSize="xl" @on-close="$vm2.close('descriptionModal')" name="descriptionModal" :footerOptions="{
				disableBtn2: false,
				btn1: 'Tovább',
				btn2: 'Nyomtatás',
				btn1OnClick: () => { closeDescriptionModal() },
				btn2OnClick: () => { downloadDescription(production.id) }
			}" :noHeader="true" style="z-index:9999">
				<div class="col-10 modalBody">
					<form @submit.prevent="destroyProduction()">
						<div class="form-group text-center">
							<div v-if="previous_phase">
								<h1><strong>STOP</strong></h1>
								<i class="fa fa-stop-circle fa-3x" aria-hidden="true"></i>
								<h2>{{ previous_phase }} Fázis vége!</h2>
							</div>
							<p class="h3 overflow-auto" style="white-space: pre-line; max-height:400px;">
								{{ production.description }}
							</p>
						</div>
					</form>
				</div>
			</modal-vue>
		</div>
		<div id="productionErrorModal">
			<modal-vue modalSize="l" @on-close="closePreventer" name="productionErrorModal" :noHeader="true"
				:noFooter="true" style="z-index:9999">
				<div class="p-4 modalBody bg-danger text-center">
					<i class="fa fa-4x fa-exclamation-triangle"></i>
					<h5>Hiba a gyártás során!</h5>
					<div v-if="active_container">
						<router-link v-if="active_container.raw_material"
							:to="'/material_containers/' + active_container.raw_material.id + '/create'" tag="button"
							class="btn btn-light btn-outline"><i class="fa fa-cubes"></i> bevételezzen
							{{ active_container.raw_material.name }} alapanyagot</router-link>
						<h5>vagy</h5>
					</div>
					<router-link to="/productions" tag="button" class="btn btn-light btn-outline"><i
							class="fa fa-trash"></i> törölje a gyártást</router-link>
				</div>
			</modal-vue>
		</div>
	</div>
</template>




<script>
import {
	Hooper,
	Slide,
	Progress as HooperProgress,
} from 'hooper';
import 'hooper/dist/hooper.css';

import PageHeader from '../../components/PageHeader';
import Production from "../../apis/Production";
import User from "../../apis/User";
import { VueEditor } from "vue2-editor";
import VueBarcode from 'vue-barcode';
import { vSelect } from '@desislavsd/vue-select'
import moment from 'moment';

export default {
	components: { PageHeader, Hooper, Slide, HooperProgress, VueEditor, 'barcode': VueBarcode, vSelect },
	data() {
		return {
			hooperSettings: { keysControl: false, mouseDrag: false, touchDrag: false, wheelControl: false },
			errors: [],
			quantity_visible: false, // Vonalkód ellenőrzéshez
			production: false, // Gyártás adatai
			storage_guide: true,
			content: {}, // Gyártási leírás
			users: [],
			container_number: 1,
			barcodes: [],
			active_container: { phase: null },
			previous_phase: null,
			extra_needed: 0,
			loading: false,
			now: moment().format('YYYY-MM-DD'),
		}
	},
	watch: {
		container_number: function (number) {
			this.production.finish_containers = [];
			for (let i = 0; i < number; i++) {
				this.production.finish_containers.push({ quantity: 0 });
			}
		},
		'production.containers': function (val, prev) {
			if (val.length == 0 && prev && val.length != prev.length) {
				this.openDescriptionModal();
			}
		},
		'active_container.phase': function (val, prev) {
			let charval = 96 + prev;
			//console.log(charval);
			if (prev) {
				this.previous_phase = String.fromCharCode(charval).toUpperCase()
			}
			if (val != prev) {
				this.openDescriptionModal();
			}
		}
	},
	mounted() {
		this.openDescriptionModal();
	},
	created() {
		User.index()
			.then(response => {
				this.users = response.data.filter(function (user) {
					return [2, 3].includes(parseInt(user.role_id));
				});
				//console.log(this.users);
			}).then(() => {
				Production.show(this.$route.params.product_id)
					.then(response => {
						this.errors = []
						this.production = response.data
						this.extra_needed = response.data.extra_needed;
						this.production.containers = response.data.containers.sort(function (a, b) {
							if (a.phase < b.phase) {
								return -1;
							}
							if (a.phase > b.phase) {
								return 1;
							}
							return 0;
						});
						if (this.production.raw_materials_collected) {
							this.storage_guide = false;
							console.log(response.data);
							console.log(this.production.containers);
							console.log(this.production.containers[0]);
							this.active_container = this.production.containers[0];
						}
						this.$set(this.production, 'microbiology_start', this.currentDate());
						this.$forceUpdate(this.production);
						//console.log(this.production);
						if (this.production.containers.length == 0) {
							this.storage_guide = false;
						}
						this.production.finish_containers = [
							{ quantity: 0 }
						];
					})
					.then(() => {
						if (this.$refs.barcode) {
							this.$refs.barcode[0].focus();
						}
					})
			});
	},
	computed: {
		to_do_counter: function () {
			return this.production.containers.filter(obj => {
				return this.production.containers.filter(c => c.found == true && c.id == obj.id).length == 0;
			}).length
		},
		submit_array: function () {
			return { actual_quantity: this.production.actual_quantity, finish_containers: this.production.finish_containers.map(container => container.quantity), best_before: this.production.best_before }
		},
		containers_unique() {
			//return this.production.containers
			return [...new Set(this.production.containers.map(c => c.id))].map(cid => (this.production.containers.find(con => con.id == cid)))
		}
	},
	methods: {
		closePreventer(/*event*/) {
			//console.log(event);
		},
		slideNext() {
			this.$refs.carousel.slideNext();
		},
		slidePrev() {
			this.$refs.carousel.slidePrev();
		},
		openDescriptionModal() {
			//console.log('lefut');
			this.$vm2.open('descriptionModal');
		},
		closeDescriptionModal() {
			this.$vm2.close('descriptionModal');
		},
		beginProduction() {
			Production.RawMaterialsCollected(this.production.id);
			this.storage_guide = false;
			if (this.production.containers.length != 0) {
				this.active_container = this.production.containers[0];
				setTimeout(() => this.$refs['barcode_0'][0].focus(), 500);
			}
		},
		currentDate() {
			const current = new Date();
			const date = `${current.getFullYear()}-${('00' + (current.getMonth() + 1)).slice(-2)}-${('00' + current.getDate()).slice(-2)}`;
			return date;
		},
		validateBarcode(event, barcode) { // Vonalkód ellenőrzés
			if (event.target.value == barcode) {
				new Promise(resolve => {
					this.quantity_visible = true
					resolve('finished');
				}).then(() => {
					let next_id = 'used_input_' + barcode;
					//console.log(this.$refs[next_id]);
					this.$refs[next_id][0].focus();
					//this.$refs[next_id][0].scrollIntoView();
				});
			} else {
				this.$toast.open({
					message: 'A vonalkód nem egyezik',
					type: 'error',
					position: 'top-right',
				});
			}

		},
		sendUsedQuantity(id, existing_extra_container) {
			this.loading = true;
			Production.remaining(this.production.id, this.production.containers[id])
				.then((response) => {
					this.quantity_visible = false;
					if (this.production.containers.length - 1 == id) {
						this.production.containers = [];
						this.active_container = { phase: null };
					} else {
						new Promise(resolve => {
							this.$refs.carousel.slideNext();
							this.active_container = this.production.containers[id + 1];
							resolve('finished');
							if (response.data != 0) {
								this.production.containers.forEach((container, index) => {
									if (container.id == this.production.containers[id].id && index != id) {
										container.quantity = response.data;
									}
								})
							} else {
								if (this.production.containers.filter(c => c.id == this.production.containers[id].id).length > 1) {
									console.log('azert ker extrat hogy behelyettesitse a vele egyező id-juakat')
									if (existing_extra_container) {
										this.production.containers.forEach((container, index) => {
											if (index > id && container.id == this.production.containers[id].id && container.phase != this.production.containers[id].phase) {
												let og_takeout_quantity = parseFloat(this.production.containers[index].takeout_quantity,2);
												let og_phase = parseInt(this.production.containers[index].phase);
												this.production.containers[index] = JSON.parse(JSON.stringify(existing_extra_container));
												this.production.containers[index].takeout_quantity = og_takeout_quantity;
												this.production.containers[index].phase = og_phase;
											}
										})
										this.active_container = this.production.containers[id + 1];
										this.$refs.carousel.slideNext();
									} else {
										Production.extra_container(this.production.id, {
											raw_material_id: this.production.containers[id].raw_material.id,
											phase: this.production.containers[id].phase
										}).then(extra_container_response => {
											let new_container = extra_container_response.data;
											return new_container;
										}).then(extra_container => {
											if (extra_container) {
												this.production.containers.forEach((container, index) => {
													if (index > id && container.id == this.production.containers[id].id && container.phase != this.production.containers[id].phase) {
														let og_takeout_quantity = parseFloat(this.production.containers[index].takeout_quantity,2);
														let og_phase = parseInt(this.production.containers[index].phase);
														this.production.containers[index] = JSON.parse(JSON.stringify(extra_container));
														this.production.containers[index].takeout_quantity = og_takeout_quantity;
														this.production.containers[index].phase = og_phase;
													}
												})
												this.active_container = this.production.containers[id + 1];
												this.$refs.carousel.slideNext();
												resolve('finished');
											} else {
												this.$vm2.open('productionErrorModal');
											}
										})
									}
								}
							}
						}).then(() => {
							let next_id = 'barcode_' + (id + 1);
							setTimeout(() => this.$refs[next_id][0].focus(), 500);
						});
					}

				}).finally(() => {
					this.loading = false;
				});
		},
		addExtraContainer(id) {
			this.loading = true;
			Production.extra_container(this.production.id, {
				raw_material_id: this.production.containers[id].raw_material.id,
				phase: this.production.containers[id].phase
			}).then(response => {
				let new_container = response.data;
				console.log(new_container);
				if (new_container) {
					new_container.phase = this.production.containers[id].phase;
					if (parseFloat(this.production.containers[id].takeout_quantity,2) - parseFloat(this.production.containers[id].used_quantity,2) + parseFloat(this.extra_needed,2) > parseFloat(new_container.quantity,2)) {
						new_container.takeout_quantity = parseFloat(new_container.quantity,2);
						this.extra_needed = parseFloat(this.extra_needed,2) - parseFloat(new_container.quantity,2);
					} else {
						new_container.takeout_quantity = parseFloat(this.production.containers[id].takeout_quantity,2) - parseFloat(this.production.containers[id].used_quantity,2) + parseFloat(this.extra_needed,2);
						this.extra_needed = 0;
					}
				}
				return new_container;
			}).then(container => {
				if (container) {
					this.production.containers.forEach((existing_c, i) => {
						if (i > id && existing_c.id == this.production.containers[id].id && existing_c.phase != this.production.containers[id].phase) {
							let og_takeout_quantity = parseFloat(this.production.containers[i].takeout_quantity,2);
							let og_phase = parseInt(this.production.containers[i].phase);
							this.production.containers[i] = JSON.parse(JSON.stringify(container));
							this.production.containers[i].takeout_quantity = og_takeout_quantity;
							this.production.containers[i].phase = og_phase;
						}
					});
					this.production.containers.splice(id + 1, 0, container);
					this.sendUsedQuantity(id, container);
				} else {
					this.$vm2.open('productionErrorModal');
					Production.remaining(this.production.id, this.production.containers[id]);
				}
			}).finally(() => {
				this.loading = false;
			})
		},
		takeoutQuantity(id) { // Alapanyag tároló vissza mérése
			this.loading = true;
			if (parseFloat(this.production.containers[id].used_quantity,2) >= parseFloat(this.production.containers[id].takeout_quantity,2)) {
				if (parseFloat(this.production.containers[id].used_quantity,2) <= parseFloat(this.production.containers[id].quantity,2)) {
					if ((parseFloat(this.production.containers[id].used_quantity,2) + parseFloat(this.production.containers[id].remaining_quantity,2)) <= parseFloat(this.production.containers[id].quantity,2)) {
						if (this.extra_needed > 0 && (!this.production.containers[id + 1] || this.production.containers[id].raw_material.id != this.production.containers[id + 1].raw_material.id)) {
							console.log('azert ker extrat mert kell meg ebbol az anyagbol ebben a fazisban hiaba vett ki eleget');
							this.addExtraContainer(id);
						} else {
							this.sendUsedQuantity(id);
						}
					} else {
						//console.log(this.production.containers[id].used_quantity + this.production.containers[id].remaining_quantity)
						/*this.$toast.open({
							message: 'A felhasznált és maradék mennyiség összege meghaladja a tároló kapacitását!',
							type: 'error',
							position: 'top-right', 
						});
						this.loading = false;*/
						this.sendUsedQuantity(id);
					}
				} else {
					this.$toast.open({
						message: 'A felhasznált mennyiség meghaladja a tároló tartalmát! '+this.production.containers[id].used_quantity+' ! >= '+this.production.containers[id].takeout_quantity,
						type: 'error',
						position: 'top-right',
					});
					this.loading = false;
				}
			} else {
				if (parseFloat(this.production.containers[id].used_quantity,2) + parseFloat(this.production.containers[id].remaining_quantity,2) <= this.production.containers[id].quantity) {
					if (this.production.containers[id + 1] && this.production.containers[id + 1].raw_material.id == this.production.containers[id].raw_material.id && this.production.containers[id + 1].phase == this.production.containers[id].phase) {
						if (parseFloat(this.production.containers[id + 1].takeout_quantity,2) + parseFloat(this.production.containers[id].takeout_quantity,2) - parseFloat(this.production.containers[id].used_quantity,2) + parseFloat(this.extra_needed,2) > parseFloat(this.production.containers[id + 1].quantity,2)) {
							/*console.log(
								parseFloat(this.production.containers[id + 1].takeout_quantity,2),
								'+',
								parseFloat(this.production.containers[id].takeout_quantity,2),
								'-',
								parseFloat(this.production.containers[id].used_quantity,2),
								'+',
								parseFloat(this.extra_needed,2),
								'-',
								parseFloat(this.production.containers[id + 1].quantity,2),
								'=',
								parseFloat(this.production.containers[id + 1].takeout_quantity,2) + parseFloat(this.production.containers[id].takeout_quantity,2) - parseFloat(this.production.containers[id].used_quantity,2) + parseFloat(this.extra_needed,2) - parseFloat(this.production.containers[id + 1].quantity,2),
								'itt írja az extrát',
								this.production.containers[id],
								this.production.containers[id + 1]
								)*/
							this.extra_needed = parseFloat(this.production.containers[id + 1].takeout_quantity,2) + parseFloat(this.production.containers[id].takeout_quantity,2) - parseFloat(this.production.containers[id].used_quantity,2) + parseFloat(this.extra_needed,2) - parseFloat(this.production.containers[id + 1].quantity,2)
							this.production.containers[id + 1].takeout_quantity = parseFloat(this.production.containers[id + 1].quantity,2);
						} else {
							this.production.containers[id + 1].takeout_quantity += parseFloat(this.production.containers[id].takeout_quantity,2) - parseFloat(this.production.containers[id].used_quantity,2) + parseFloat(this.extra_needed,2);
							this.extra_needed = 0;
						}
						this.sendUsedQuantity(id);
					} else {
						console.log('azert ker extrat mert kell meg ebbol az anyagbol ebben a fazisban mivel keveset vett ki');
						this.addExtraContainer(id);
					}
				} else {
					console.log(
						parseFloat(this.production.containers[id].used_quantity,2) +
						parseFloat(this.production.containers[id].remaining_quantity,2),
						this.production.containers[id].quantity
					);
					this.$toast.open({
						message: 'A felhasznált és maradék mennyiség összege meghaladja a tároló kapacitását!',
						type: 'error',
						position: 'top-right',
					});
					this.loading = false;
				}
			}
			/*if(this.production.containers[id].quantity - this.production.containers[id].takeout_quantity >= this.production.containers[id].remaining_quantity)
			{
				Production.remaining(this.production.id,this.production.containers[id])
				.then( () => {
					this.quantity_visible = false;
					if(this.production.containers.length - 1 == id){
						this.production.containers = []
					}else{
						new Promise(resolve => {
							this.$refs.carousel.slideNext();
							resolve('finished');
						}).then(() => {
							let next_id = 'barcode_'+(id+1);
							this.$refs[next_id].focus();
						});
					}

				})
			}
			else{
				this.$toast.open({
						message: 'Keveset vettél ki',
						type: 'error',
						position: 'top-right', 
				});
			} */
		},
		parametersSave() { // Gyártási leírat 
			this.loading = true;
			Production.update(this.production.id, this.production)
				.then(() => {
					this.production.parameters_saved = true;
				})
				.catch(error => {
					this.errors = error.response.data.errors;
				}).finally(() => {
					this.loading = false;
				})
		},
		downloadPdf(id) {
			Production.pdf(id)
				.then(response => {
					var blob = new Blob([response.data], { type: 'application/pdf' });
					if (window.URL) {
						var data = window.URL.createObjectURL(blob);
						window.open(data);
					} else {
						var wdata = window.webkitURL.createObjectURL(blob);
						window.open(wdata);
					}
				})
		},
		downloadDescription(id) {
			Production.PrintRecipeDescription(id)
				.then(response => {
					var blob = new Blob([response.data], { type: 'application/pdf' });
					var data = window.URL.createObjectURL(blob);
					window.open(data, '_blank').focus();
				})
		},
		productionFinish() { // Késztermék összsúly 
			this.loading = true;
			if (this.production.actual_quantity == null) {
				this.$toast.open({
					message: 'Adjon meg keletkezett termék mennyiséget!',
					type: 'error',
					position: 'top-right'
				});
				this.loading = false;
			} else if (this.container_number == null) {
				this.$toast.open({
					message: 'Adja meg a tárolók számát',
					type: 'error',
					position: 'top-right'
				});
				this.loading = false;
			} else if (this.production.finish_containers.reduce(function (a, b) {
				return { quantity: parseFloat(a.quantity,2) + parseFloat(b.quantity,2) };
			}).quantity != this.production.actual_quantity) {
				/*console.log(this.production.finish_containers.reduce(function(a,b){
				return {quantity: parseInt(a.quantity) + parseInt(b.quantity)};
			}),this.production.actual_quantity)*/
				this.$toast.open({
					message: 'A tárolók összege meg kell egyezzen a gyártott mennyiség!',
					type: 'error',
					position: 'top-right'
				});
				this.loading = false;
			} else {
				Production.finish(this.production.id, this.submit_array)
					.then(response => {
						//console.log('lefut', response.data);
						this.production.intermediate_product.barcode = response.data.barcodes[0]
						this.barcodes = response.data.barcodes
						this.production.id = response.data.production_id
					})
					.then(() => {
						//console.log(this.barcodes);
						this.barcodes.forEach(barcode => {
							let vue = this;
							new Promise(function (resolve) {
								vue.production.intermediate_product.barcode = barcode;
								resolve('finished')
							}).then(() => {
								let printData = document.getElementById("container_barcode").innerHTML;
								let newTab = window.open();

								newTab.document.body.innerHTML = printData + '<style>.btn {padding:1em; font-size:2em; margin:2em; display:block;}.btn:focus { box-shadow: none;} @media print {.print-invisible{display:none;}}.mx-auto {  margin-right: auto !important;margin-left: auto !important;}</style>';
								newTab.print();
							});



						})
						/*if(this.production.intermediate_product.barcode){		
						}*/
					}).finally(() => {
						this.loading = false;
					})
			}
		},
		parametersAlert(alert_message) {
			this.$toast.open({
				message: alert_message,
				type: 'error',
				position: 'top-right',
			});
		}
	},
	beforeRouteLeave(to, from, next) {
		if (!this.production.parameters_saved || this.production.intermediate_product.barcode == false) {
			this.$confirm({
				title: 'Biztos elhagyja az oldalt?',
				message: `A nem mentett módosításai el fognak veszni!`,
				button: {
					no: 'Nem',
					yes: 'Igen'
				},
				callback: confirm => {
					if (confirm) {
						new Promise(r => {
							this.$vm2.close('productionErrorModal');
							r();
						}).then(() => {
							next();
						})
					} else {
						next(false)
					}
				}
			})
		} else {
			next();
		}
	}
};




</script>

<style>
.hooper {
	height: auto;
}
</style>