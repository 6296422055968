<template>
	<div>
		<PageHeader v-if="inventory" :title="(this.inventory.storage_name ? this.inventory.storage_name : 'Hibás')  + ' Leltározás'">
			<span>
				<help title="Leltározandó elemek" text="Teljes leltár esetén csak azon elemek jelennek meg amelyekből a redszer szerint van készleten."></help>
			</span>
		</PageHeader>
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row d-flex justify-content-center" v-if="!inventory">
								<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
							</div>
							<div class="widget p-lg text-center" v-else-if="inventory.notfound">
								<div class="m-b-md">
									<i class="fa fa-search fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nem található a leltár
									</h3>
									<router-link to="/inventories" class="btn btn-default btn-xs mt-4"><i class="fa fa-arrow-left"></i> vissza</router-link>
								</div>
							</div>
							<template v-else>
								<div class="widget p-lg text-center" v-if="inventory.items.length == 0">
									<div class="m-b-md">
										<i class="fa fa-search fa-4x"></i>
										<h1 class="m-xs"><i class="fa fa-times"></i></h1>
										<h3 class="font-bold no-margins">
											Nem találhatóak elemek a leltáron
										</h3>
										<small>Rögzíthet többletet vagy törölheti a leltárat</small>
									</div>
								</div>
								<form @submit.prevent="sendInventory" v-else>
									<div class="row">
										<div class="col-md-10 mx-auto">
											<h5 class="text-center">Jelmagyarázat:</h5>
											<div class="row mb-2">
												<div class="col">
													<div class="input-group mx-auto" style="min-width:120px;max-width:200px;">
														<div class="input-group-prepend">
															<span class="input-group-addon bg-danger text-white">
																<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
															</span>
														</div>
														<span class="form-control text-center bg-danger-light">hiányzó adat</span>
													</div>	
												</div>
												<div class="col">
													<div class="input-group mx-auto" style="min-width:120px;max-width:200px;">
														<div class="input-group-prepend">
															<span class="input-group-addon bg-orange text-white">
																<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
															</span>
														</div>
														<span class="form-control text-center bg-orange-light text-dark">0 az érték</span>
													</div>	
												</div>
												<div class="col">
													<div class="input-group mx-auto" style="min-width:120px;max-width:200px;">
														<div class="input-group-prepend">
															<span class="input-group-addon bg-yellow">
																<i class="fa fa-exclamation-circle" aria-hidden="true"></i>
															</span>
														</div>
														<span class="form-control text-center bg-yellow-light">eltérés</span>
													</div>	
												</div>
												<div class="col">
													<div class="input-group mx-auto" style="min-width:120px;max-width:200px;">
														<div class="input-group-prepend">
															<span class="input-group-addon bg-primary">
																<i class="fa fa-check" aria-hidden="true"></i>
															</span>
														</div>
														<span class="form-control text-center bg-primary-light">megegyezik</span>
													</div>	
												</div>
											</div>
										</div>
									</div>	
									<div class="row">
										<div class="col-md-10 mx-auto overflow-auto" style="max-height:600px;">
											<div class="table-responsive">
												<table class="table table-bordered">
													<tbody v-for="(item,id) in inventory.items" :key="id">
														<tr class="thead-dark">
															<th colspan="4">{{ item.name }}</th>
														</tr>
														<tr v-for="(child,id) in item.children" :key="id">
															<td class="pl-3">{{ child.storage_place_name }}</td>
															<td>{{ child.identifier }}</td>
															<td>{{ child.expected_quantity }}</td>
															<td>
																<div class="input-group mr-0 ml-auto" style="min-width:120px;max-width:200px;">
																	<div class="input-group-prepend">
																		<span class="input-group-addon" :class="{
																			'bg-danger text-white':!child.actual_quantity && parseFloat(child.actual_quantity) != 0,
																			'bg-primary':parseFloat(child.actual_quantity) == parseFloat(child.expected_quantity) && parseFloat(child.actual_quantity) != 0,
																			'bg-yellow':parseFloat(child.actual_quantity) != parseFloat(child.expected_quantity) && parseFloat(child.actual_quantity) != 0,
																			'bg-orange':parseFloat(child.actual_quantity) == 0,
																		}">
																			<i class="fa" aria-hidden="true" :class="{
																				'fa-exclamation-triangle':!child.actual_quantity || child.actual_quantity == 0,
																				'fa-check':parseFloat(child.actual_quantity) == parseFloat(child.expected_quantity) && child.actual_quantity != 0,
																				'fa-exclamation-circle':parseFloat(child.actual_quantity) != parseFloat(child.expected_quantity) && child.actual_quantity != 0,
																			}"></i>
																		</span>
																	</div>
																	<input type="number" min="0" :step="step" @wheel="$event.target.blur()" class="form-control text-dark text-right" v-model="child.actual_quantity" required @focus="$event.target.select()" :disabled="loading" :ref="'quantity_input_'+child.id" @blur="saveQuantity(child)" :class="{
																		'bg-danger-light text-white':!child.actual_quantity && parseFloat(child.actual_quantity) != 0,
																		'bg-primary-light':parseFloat(child.actual_quantity) == parseFloat(child.expected_quantity) && parseFloat(child.actual_quantity) != 0,
																		'bg-yellow-light':parseFloat(child.actual_quantity) != parseFloat(child.expected_quantity) && parseFloat(child.actual_quantity) != 0,
																		'bg-orange-light':parseFloat(child.actual_quantity) == 0,
																	}"/>
																</div>	
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>	
									<div class="row text-center mt-2">
										<div class="col-md-10 mx-auto text-center">
											<div class="mb-2">
												<span v-if="finishing" class="badge bg-danger rounded-pill">A leltárzárás akár több percet is igénybevehet! Kérjük ne hagyja el az oldalt!</span>
											</div>
											<button type="submit" class="btn btn-danger dim btn-lg btn-rounded" :disabled="loading">
												<i class="fa" :class="{
												'fa-lock': !loading,
												'fa-cog fa-spin': loading,
											}"></i> Lezárás
											</button>
										</div>
									</div>
								</form>
								<div class="row mt-3" v-if="!inventory.countable">
									<div class="col-md-6 mx-auto text-center">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-addon">Többlet rögzítés</span>
											</div>
											<input type="text" class="form-control text-center" @focus="$event.target.select()" :disabled="loading" @blur="validateBarcode" v-model="additional_barcode" ref="additional_barcode_input">
											<div class="input-group-append">
												<span class="input-group-addon"><i class="fa fa-barcode"></i></span>
											</div>
										</div>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-md-6 mx-auto text-center">
										<button class="btn btn-xs btn-default text-danger" @click="deleteInventory">
											<i class="fa" :class="{
												'fa-times': !loading,
												'fa-cog fa-spin': loading
											}"></i> Leltár törlése
										</button>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../../components/PageHeader';
	import Inventory from '../../apis/Inventory';

	export default {
		components: {
			PageHeader
		},
		data() {
			return {
				inventory: null,
				notfound: false,
				loading:false,
				finishing:false,
				errors: [],
				additional_barcode:null,
			}
		},
		created() {
			Inventory.show(this.$route.params.id)
			.then(response => {
				this.inventory = response.data;
			}).catch(() => {
				this.inventory = {notfound:true}
			});
		},
		computed:{
			step(){
				if(this.inventory && this.inventory.countable === false){
					return '.01';
				}else{
					return '1';
				}
			}
		},
		methods: {
			sendInventory(){
				this.$confirm({
					title: `Biztos lezárja a leltárt?`,
					message: 'A leltár lezárása módosítja a készletet az ön által megadott értékekre!',
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					callback: confirm => {
						if (confirm) {
							this.loading = true;
							this.finishing = true;
							Inventory.finish(this.inventory.id).then(() => {
								this.$router.push({path:'/inventories'});
							}).finally(() => {
								this.loading = false;
								this.finishing = false;
							});
						}
					}
				})
			},
			validateBarcode(){
				this.loading = true;
				if(!this.additional_barcode){
					this.loading = false;
				}else{
					let found = this.inventory.items.map(i => i.children).flat(1).find(c => c.identifier == this.additional_barcode);
					if(found){
						this.loading = false;
						this.$refs['quantity_input_'+found.id][0].focus();
					}else{
						Inventory.findBarcode(this.inventory.id,{barcode:this.additional_barcode}).then(response => {
							let new_child = {
								id: response.data.model_id,
								storage_place_name: response.data.storage_place_name,
								identifier: this.additional_barcode,
								expected_quantity: response.data.expected_quantity
							};
							let existing = this.inventory.items.find(item => item.id == response.data.parent_id);

							if(existing){
								existing.children.push(new_child);
							}else{
								let new_parent = {
									id: response.data.parent_id,
									name: response.data.parent_name,
									children:[]
								}
								new_parent.children.push(new_child);
								this.inventory.items.push(new_parent);
							}
						}).finally(() => {
							this.loading = false;
						})
					}
				}
			},
			deleteInventory(){
				this.$confirm({
					title: `Biztos törli a leltárt?`,
					message: 'A leltár törlésével a készlet nem módosul, a benne lévő adatok törlésre kerülnek!',
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					callback: confirm => {
						if (confirm) {
							this.loading = true;
							Inventory.delete(this.inventory.id).then(() => {
								this.$router.push({path:'/inventories'});
							}).finally(() => {
								this.loading = false;
							});
						}
					}
				})
			},
			saveQuantity(child){
				if(child.actual_quantity && child.actual_quantity >= 0){
					Inventory.saveItem(this.inventory.id,{
						model_id:child.id,
						actual_quantity:child.actual_quantity
					});
				}
			}
		}
	};
</script>

<style>
</style>