<template>
		<div>
				<PageHeader title="Termék kiszerelés" />
				<div class="row">
						<div class="col">
								<div class="ibox">
										<div class="ibox-content">
											<form @submit.prevent="updateProductSize">
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label>Megnevezés</label>
															<input type="text" class="form-control" v-model="product_size.name">
															<span class="text-danger" v-if="errors.name">
																{{ errors.name[0] }}
															</span>
														</div>
														<div class="form-group">
															<label>töltési átlagtömeg (g)</label>
															<div class="input-group">
																<input type="text" class="form-control" :disabled="product_size.has_filled == 1" v-model="product_size.size">
																<div class="input-group-append">
																	<span class="input-group-addon">{{product_size.unit}}</span>
																</div>
															</div>
															<span class="text-danger" v-if="errors.name">
																{{ errors.name[0] }}
															</span>
														</div>
														<div class="form-group">
															<label>Csomagolás:</label>
															<select v-model="product_size.packing" class="form-control">
																<option :value="null" disabled>Válassz csomagolási igényt</option>
																<option v-for="(packing,id) in packings" :value="id" :key="id">
																	{{ packing }}
																</option>
															</select>
															<span class="label label-danger" v-if="errors.packing">
																	{{ errors.packing[0] }}
															</span>
														</div>
														<div class="form-group">
															<label>Külső töltés:</label>
															<div class="input-group">
																<div class="input-group-prepend">
																	<div class="input-group-addon">
																		<input type="checkbox" v-model="product_size.is_third_party_filling" :true-value="1" :false-value="0">
																	</div>
																</div>
																<span class="form-control mw-25 overflow-hidden"> Külső töltést igényel</span>
																<div class="input-group-append">
																	<span class="input-group-addon" :class="{'bg-danger': product_size.is_third_party_filling == 0,'bg-primary': product_size.is_third_party_filling == 1}"><i class="fa" :class="{'fa-times': product_size.is_third_party_filling == 0,'fa-check': product_size.is_third_party_filling == 1}"></i></span>
																</div>
															</div>
														</div>
													</div>
												</div>
												<h2>Recept:</h2>
												<h3>Kiszerelés gönygölegei</h3>	
												<span v-if="product_size.is_third_party_filling == 0">
													<i class="fa fa-circle text-success"></i> <small>töltés</small>
												</span>
												<span v-if="product_size.packing == 1">
													<i class="fa fa-circle text-warning"></i> <small>csomagolás</small>
												</span>
												<div class="row" v-show="product_size.has_filled == 0">
													<div class="col-md-6">
														<h5>Összes göngyöleg</h5>
														<input type="text" v-model="search" placeholder="keresés.."/>
														<draggable v-model="filteredList" group="materials" @start="drag=true" @end="drag=false" class="border border-light pt-2" style="min-height:200px" @change="updateWrappingList">
															<div v-for="(wrapping,id) in filteredList" v-show="(wrapping.type == 1 && !product_size.is_third_party_filling) || (wrapping.type == 2 && product_size.packing == 1)" :key="id" :class="{'btn-success':wrapping.type == 1,'btn-warning':wrapping.type == 2}" class="btn m-1 b-r-xl">{{wrapping.name}} {{wrapping.size}}{{wrapping.unit}}</div>
														</draggable>
													</div>
													<div class="col-md-6">
														<h5>Kiválasztott göngyölegek</h5>
														<br class="mt-1">
														<draggable v-model="selected_wrappings" group="materials" @start="drag=true" @end="drag=false" class="border border-light pt-2" style="min-height:200px" @change="updateSelectedWrappingList">
															<div v-for="(selected_wrapping,id) in selected_wrappings" :key="id" :class="{'btn-success':selected_wrapping.type == 1,'btn-warning':selected_wrapping.type == 2}" class="btn m-1 b-r-xl">{{selected_wrapping.name}} {{selected_wrapping.size}}{{selected_wrapping.unit}}</div>
														</draggable>
													</div>
												</div>
												<div class="row" v-if="product_size.has_filled != 0">
													<div class="col-md-4">
														<ul class="list-group">
															<li v-for="(wrapping,id) in selected_wrappings" :key="id" class="list-group-item border border-light" :class="{'bg-success':wrapping.type == 1,'bg-warning':wrapping.type == 2}">{{wrapping.name}} <i class="float-right fa" :class="{'fa-tint':wrapping.type == 1,'fa-gift':wrapping.type == 2}"></i></li>
														</ul>
													</div>
												</div>
												<div class="mt-2">
														<loading-button class="float-right" :loading="loading">Mentés</loading-button>
														<router-link :to="{path:'/product/'+product_size.product_id}" class="btn btn-danger mx-2">Mégsem</router-link>			
												</div>
											</form>
										</div>
								</div>
						</div>
				</div>
		</div>
</template>
 
<script>
import PageHeader from '../../components/PageHeader';
	
/* KUKA */	
//import Material from "../../apis/Material";
	
import ProductSize from "../../apis/ProductSize";
import Wrapping from "../../apis/Wrapping";
import draggable from 'vuedraggable';
import Config from "../../apis/Config";
	
		export default {
				components: {PageHeader,draggable},
				data() {
						return {
								units: false,
								product_size: {},
								wrappings: [],
								selected_wrappings: [],
								search: '',
								errors: [],
								size:{},
								packings:false,
								loading:false,
						}
				},
				//
				watch: {
					'product_size.packing': function(val){
						if(val == 0){
							this.selected_wrappings.filter(sw => sw.type == 2).forEach(fsw => {
								this.selected_wrappings.splice(this.selected_wrappings.map(w => w.id).indexOf(fsw.id),1);
							});
						}
					},
					'product_size.is_third_party_filling': function(val){
						if(val == 1){
							this.selected_wrappings.filter(sw => sw.type == 1).forEach(fsw => {
								this.selected_wrappings.splice(this.selected_wrappings.map(w => w.id).indexOf(fsw.id),1);
							});
						}
					}
				},
			
				computed: {
					filteredList: {
						get: function(){
							return this.wrappings.filter(wrapping => {
								return wrapping.name.toLowerCase().includes(this.search.toLowerCase())
							})
						},
						set: function(barmi){
							return barmi;
						}
					}
				},
				
				created() {
					Config.getConstant('product_packing')
					.then(response => {
						this.packings = response.data;
					})
					Config.getConstant('units')
					.then(response => {
						this.units = response.data;
					})
					Wrapping.index()
						.then(response => {
							this.wrappings = response.data;
						//console.log('wrappings:',this.wrappings);
						})
					.then(() => {
					ProductSize.show(this.$route.params.id)
						.then(response => {
							this.product_size = response.data;
						//console.log(this.product_size);
						}).then(() => {
							this.product_size.wrappings.forEach(element => {
								this.selected_wrappings.push(element);
								let i = this.wrappings.map(data => data.id).indexOf(element.id);
								this.wrappings.splice(i, 1);
							});
						})
					});
					
				},
				methods: {
					updateWrappingList(event){
						if(typeof event.removed != 'undefined'){
							this.wrappings.splice(this.wrappings.map(material => material.id).indexOf(event.removed.element.id), 1);
						}else{
							this.wrappings.push(event.added.element);
						}
					},
					updateSelectedWrappingList(event){
						if(typeof event.added != 'undefined'){
							this.$set(this.selected_wrappings[event.added.newIndex]);
						}
					},
					updateProductSize() {
						this.loading = true;
						if((!this.product_size.is_third_party_filling || this.product_size.packing) && !this.selected_wrappings.length){
							this.$toast.open({
								message: 'Nem választott göngyölegeket',
								type: 'error',
								position: 'top-right', 
							});
							this.loading = false;
						}else{
							this.product_size.wrappings = this.selected_wrappings
							ProductSize.update(this.$route.params.id, this.product_size)
									.then(() => {
											this.$router.push({ path: '/product/'+ this.product_size.product_id })
									})
									.catch(error => {
										if (error.response.status === 422) {
											this.errors = error.response.data.errors;
										}
									})
									.finally(() => this.loading = false)
						}
					},
				}
		}
	
	
</script>