<template>
	<div>
		<PageHeader title="Raktár" button_title="Új Igénylés rögzítése" button_href="/commission/partner" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="items === false">
								<div class="col-12 text-center">
									<h3>Raktár betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="items.length == 0">
								<div class="m-b-md">
									<i class="fa fa-box-open fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek Raktáron tételei
									</h3>
									<small>hozzon létre új igénylést</small>
								</div>
							</div>
							<div class="row" v-if="items.length != 0 && items != false">
								<div class="col-12 m-sm">
									<input type="text" v-model="search" placeholder="keresés.."/>
								</div>
								<div class="col-lg-3 col-sm-6 col-12" v-for="(item, id) in filteredItems" :key="id">
									<div class="panel panel-success">
										<div class="panel-heading">
											<i v-bind:class="{'fa-tag': item.size_id, 'fa-cube': !item.size_id}" class="fa float-right my-auto fa-3x d-none d-sm-none d-md-none d-lg-block"></i>
											<h3><span>{{item.product_name}}</span><span v-if="item.size_name"> - {{item.size_name}}</span></h3>
											<h5>lejár: {{item.best_before}}</h5>
										</div>
										<div class="panel-body">
											<div class="input-group">
												<span class="form-control text-center">{{item.quantity}}</span> 
												<span class="input-group-append"> 
													<span class="input-group-addon">db</span>
													<button @click="openUseModal(item)" type="button" class="btn btn-danger" title="levétel készletről"><i class="fa fa-minus"></i></button> 
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="use_modal" class="custom-modal" v-if="this.item != null">
			<modal-vue modalSize="md" @on-close="$vm2.close('use_modal')" name="use_modal" :headerOptions="{ title: 'Levétel készletről'}" :footerOptions="{
			btn1: 'Vissza',
			btn2: 'Levétel készletről',
			btn2OnClick: () => {
				useFromStorage();
			},
			btn1OnClick: () => {
				$vm2.close('use_modal');
			},
		}">
				<div align="center" class="col-12">
					<h3><span>{{item.product_name}}</span><span v-if="item.size_name"> - {{item.size_name}}</span></h3>
					<small>Adja meg mennyit szeretne levenni raktárkészletről:</small>
					<div class="input-group w-75">
						<input @wheel="$event.target.blur()" type="number" class="form-control w-75 text-right" v-model="item.quantity">
						<span class="input-group-append"> 
							<span v-if="item.size_id" class="input-group-addon">db</span>
							<span v-else class="input-group-addon">{{item.unit}}</span>
						</span>
					</div>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Partner from "../../apis/Partner";

export default {
    components: { PageHeader },
    data() {
        return {
						search:'',
						items:false,
						item:null,
        }
    },
		computed: {
			filteredItems: function(){
				return this.items.filter(item => {
					return (item.product_name+item.size_name).toLowerCase().includes(this.search.toLowerCase())
				})
			},
		},
		watch:{
			'$root.$data.user.partner_id': function(val){
				if(val){
					this.initializer();
				}
			}
		},
    created() {
			if(this.$root.$data.user.partner_id){
				this.initializer();
			}
    },
    methods: {
			initializer(){
				Partner.getStorage(this.$root.$data.user.partner_id).then(response => {
					this.items = response.data;
				});
			},
			openUseModal(item){
				let vue = this;
				new Promise(function(resolve){
					vue.item = JSON.parse(JSON.stringify(item));
					vue.item.quantity = 0;
					resolve('finished');
				}).then(() => {
					this.$vm2.open('use_modal');
				});
			},
			useFromStorage(){
				if(this.item.quantity < 1){
					this.$toast.open({type:'error',message: 'Minimum 1 et tud levenni raktárról!',position:'top-right'});
				}else if(this.item.quantity > this.items[this.items.map(item => item.id).indexOf(this.item.id)].quantity){
					this.$toast.open({type:'error',message: 'Maximum '+this.items[this.items.map(item => item.id).indexOf(this.item.id)].quantity+' -t tud levenni raktárról!',position:'top-right'});
				}else{
					this.$confirm({
						message: 'Biztos levelszel '+this.item.quantity+' '+(this.item.size_id ? 'db' : this.item.unit) + ' ' + this.item.product_name + (this.item.size_name ? ' - '+ this.item.size_name : '') + ' -t raktárról?',
						button: {
							no: 'Nem',
							yes: 'Igen'
						},
					callback: confirm => {
						if(confirm){
							Partner.useFromStorage({
								id: this.item.id,
								quantity: this.item.quantity
							}).then(() =>{
								if(this.items[this.items.map(item => item.id).indexOf(this.item.id)].quantity - this.item.quantity == 0){
									this.items.splice(this.items.map(item => item.id).indexOf(this.item.id));
								}else{
									this.$set(this.items[this.items.map(item => item.id).indexOf(this.item.id)],'quantity',this.items[this.items.map(item => item.id).indexOf(this.item.id)].quantity - this.item.quantity);
								}
								this.$vm2.close('use_modal');
							});
						}
					}
					});
				}
			}
    }
};
</script>

<style>
</style>