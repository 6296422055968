<template>
	<div>
		<PageHeader title="Csomagolás" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Csomagolható töltések</h5>
						</div>
						<div class="ibox-content">
							<div class="alert alert-danger" v-if="actual !== false">
								<i class="fa fa-exclamation-triangle"></i> Van folyamatban csomagolás, csak azt lehetséges folytatni!
							</div>
							<div class=" d-none d-md-block">
								<div class="row p-xs" v-if="this.products.length != 0">
									<div class="col-4 font-bold">Termék</div>
									<div class="col-5 font-bold border-left-right text-center">Töltés dátuma</div>
									<div class="col-3 font-bold text-right">Lejárati dátum</div>
								</div>
							</div>
							<div v-for="(product, id) in products" :key="id">
								<div class="row p-xs bg-primary overflow-auto">
									<div class="col-md-4 col-12 text-left-md-center">{{product.product_name}}</div>
									<div class="col-md-5 col-12 text-center"><p class="d-block0 d-md-none">töltés dátuma: </p>{{product.filling_date}}</div>
									<div class="col-md-3 col-12 text-right-md-center"><p class="d-block0 d-md-none">Lejárati dátum: </p>{{product.best_before}}</div>
								</div>
								<div class="row p-xs bg-muted border-top-bottom overflow-auto" v-for="(size, id) in product.sizes" :key="id">
										<div class="col-md-4 col-12 text-left-md-center">{{size.size_name}}</div>
										<div class="col-md-5 col-12 text-right-md-center">
											<div class="input-group m-b" v-if="(actual !== false && (actual.filled_product.filling_id != product.filling_id || (actual.filled_product.filling_id == product.filling_id && actual.size_id != size.id))) || actual == false">
												<input type="text" :disabled="actual != false" class="form-control form-control-sm text-right" v-model="size.to_package_quantity" >
												<div class="input-group-append">
													<span class="input-group-addon">db</span>
												</div>
											</div>
											<span class="label label-warning" v-if="actual.size_id == size.id && actual.filled_product.filling_id == product.filling_id">{{actual.expected_quantity}} db folyamatban</span>
										</div>
										<div class="col-md-3 col-12 text-right-md-center">
											<loading-button class="btn btn-xs btn-warning" :loading="loading" :disabled="actual != false" @click.native="packageSize(size)" v-if="(actual !== false && (actual.filled_product.filling_id != product.filling_id || (actual.filled_product.filling_id == product.filling_id && actual.size_id != size.id))) || actual == false"><i class="fa fa-gift"></i> Csomagolás</loading-button>
											<router-link :to="'/package/'+actual.id" class="btn btn-xs btn-warning" v-if="actual.size_id == size.id && actual.filled_product.filling_id == product.filling_id"><i class="fa fa-gift"></i> Folytatás</router-link>
										</div>
								</div>
							</div>
							<div class="widget  p-lg text-center" v-if="this.products.length == 0">
								<div class="m-b-md">
										<i class="fa fa-gift fa-4x"></i>
										<h1 class="m-xs"><i class="fa fa-times"></i></h1>
										<h3 class="font-bold no-margins">
												Nincsenek csomagolható termékek
										</h3>
										<small>töltsön félkész terméket a csomagoláshoz</small><br>
										<router-link :to="'/filling'" class="btn btn-xs btn-primary mt-1"><i class="fa fa-location-arrow"></i> töltéshez</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Packaging from "../../apis/Packaging";

export default {
    components: { PageHeader },
    data() {
        return {
					products: [],
					actual: false,
					loading: false,
        }
    },
	
    created() {
			Packaging.getActual()
			.then(response => {
				//console.log(response.data);
				if(Object.keys(response.data).length != 0){
					this.actual = response.data;
					//this.$router.push({ path: '/package/'+ response.data.actualPackaging.id })
				}
				//console.log('actual:',this.actual);
			});
			Packaging.index()
			.then(response => {
				this.products = response.data;
			}).then(() => {
				this.products.forEach(product => {
					product.sizes.forEach(size => {
						this.$set(size,'to_package_quantity',size.quantity);
					});
				});
				//console.log(this.products);
			});
				
    },
    methods: {
			packageSize(size){
				this.loading = true;
				if(size.to_package_quantity > size.quantity || size.to_package_quantity < 1){
					this.$toast.open({
						message: '1 és ' + size.quantity + ' közti összeget csomagolhat',
						type:'error',
						position: 'top-right'
					});
					this.loading = false;
				}else{
					//console.log('küldi',size);
					Packaging.sendSizeToPackage({
						filled_product_id: size.filled_product_id,
						expected_quantity: size.to_package_quantity
					}).then(response => {
						//meg kell írni az APivalidatort, ha sikeres, akkor content-location-t adjon vissza
						this.$router.push({ path: '/package/' + response.data.id  })
						
					}).finally(() => {
						this.loading = false;
					});
				}
			}
    }
};
</script>

<style>
</style>