<template>
		<div>
				<PageHeader title="Raktárhely szerkesztése" />
				<div class="row">
						<div class="col">
								<div class="ibox">
										<div class="ibox-content">
											<form @submit.prevent="updateStoragePlace">
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label>Raktár</label>
															<select v-show="storage_place.storage_id != 6" class="form-control no-cut" v-model="storage_place.storage_id">
																<option v-for="storage in storages" :value="storage.id" :key="storage.id">
																{{ storage.name }}
																</option>
															</select>
															<span v-show="storage_place.storage_id == 6" class="form-control bg-muted">Bizományi</span>
															<span class="label label-danger" v-if="errors.storage_id">
																{{ errors.storage_id[0] }}
															</span>
														</div>
														<div class="form-group">
															<label>Megnevezés</label>
															<input type="text" class="form-control" v-model="storage_place.name" disabled>
															<span class="label label-danger" v-if="errors.name">
																{{ errors.name[0] }}
															</span>
														</div>
														<div class="form-group">
																<label>Leírás</label>
																<textarea class="form-control" rows="3" v-model="storage_place.description">
																</textarea>
																<span class="label label-danger" v-if="errors.description">
																		{{ errors.description[0] }}
																</span>
														</div>
														<div class="mt-2">
														<button type="submit" class="btn btn-primary">Mentés</button>
														<router-link :to="{path:'/storage_places'}" class="btn btn-danger mx-2">Mégsem</router-link>			
												</div>
													</div>
												</div>
											</form>
										</div>
								</div>
						</div>
				</div>
		</div>
</template>
 
<script>
import PageHeader from '../../components/PageHeader';
import StoragePlace from "../../apis/StoragePlace";
import Storage from "../../apis/Storage";
	
		export default {
				components: {PageHeader},
				data() {
						return {
								storages: [],
								storage_place: {},
								errors: []
							
						}
				},
				
				created() {
					Storage.index()
						.then(response => {
							this.storages = response.data;
							this.storages.splice(5,1);
						});
					StoragePlace.show(this.$route.params.id)
						.then(response => {
							this.storage_place = response.data;
						});
					
				},
				methods: {
					
					updateStoragePlace() {
						StoragePlace.update(this.$route.params.id,this.storage_place)
							.then(response => {
								this.$router.push({path: response.headers['content-location'] })
						})
							.catch(error => {
									this.errors = error.response.data.errors;
							})
					},
				}
		}
	
	
</script>