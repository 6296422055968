import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/storages");
  },
	
  async items(storage_id) {
    await Csrf.getCookie();

    return Api.get("/storages/" + storage_id + '/storage_items');
  },
	
  async places(storage_id) {
    await Csrf.getCookie();

    return Api.get("/storages/" + storage_id + '/storage_places');
  },
    
};