<template>
	<div>
		<PageHeader :title="raw_material.name + ' bevételezése'" />
		<div class="row">
			<div class="col-lg-6">
				<div class="ibox">
					<div class="ibox-title">
						<h5>Bevételezés</h5>
					</div>
					<div class="ibox-content">
						<div class="row" v-if="partners === false">
							<div class="col-12 text-center">
								<h3>Alapanyag betöltése</h3>
								<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
							</div>
						</div>
						<div class="widget p-lg text-center" v-if="partners.length == 0">
							<div class="m-b-md">
								<i class="fa fa-user fa-4x"></i>
								<h1 class="m-xs"><i class="fa fa-times"></i></h1>
								<h3 class="font-bold no-margins">
									Nincsenek Beszállítók
								</h3>
								<small>Minimum egy beszállító típusú partnerre van szükség bevételezéshez</small>
							</div>
						</div>
						<form @submit.prevent="addMaterialContainer" v-if="partners !== false && partners.length != 0">
							<div class="form-group">
								<label>Beszállító</label>
								<v-select v-model="material_container.partner_id" :from="partners" as="name:id:id"
									tagging></v-select>
								<span class="label label-danger" v-if="errors.partner_id">
									{{ errors.partner_id[0] }}
								</span>
							</div>
							<div class="form-group">
								<label class="form-label">Raktárhely</label>
								<v-select :from="storage_places" as="name:id:id" v-model="material_container.storage_place_id" class="mb-6"></v-select>
								<span class="label label-danger" v-if="errors.storage_place_id">
									{{ errors.storage_place_id[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Lejárati dátum</label>
								<div class="input-group date">
									<span class="input-group-addon"><i class="fa fa-calendar"></i></span>
									<input type="date" :min="currentDate()" max="9999-12-31" class="form-control"
										v-model="material_container.best_before">
								</div>
								<span class="label label-danger" v-if="errors.best_before">
									{{ errors.best_before[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Batch szám</label>
								<input type="text" class="form-control" ref="batch_number"
									v-model="material_container.batch_number">
								<span class="label label-danger" v-if="errors.batch_number">
									{{ errors.batch_number[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Mennyiség</label>
								<div class="input-group">
									<input type="number" @wheel="$event.target.blur()" class="form-control"
										v-model="material_container.quantity" min="1">
									<span class="input-group-addon">
										<span>{{ raw_material.unit }}</span>
									</span>
								</div>
								<span class="label label-danger" v-if="errors.quantity">
									{{ errors.quantity[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Teljes mennyiség nettó ára</label>
								<div class="input-group">
									<input type="number" @wheel="$event.target.blur()" min="0" max="2147483647"
										class="form-control" v-model="material_container.price">
									<span class="input-group-addon">
										<span>Ft</span>
									</span>
								</div>
								<span class="label label-danger" v-if="errors.price">
									{{ errors.price[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Szállítási költség</label>
								<div class="input-group">
									<input type="number" @wheel="$event.target.blur()" max="2147483647" min="0"
										class="form-control" v-model="material_container.shipping_price">
									<span class="input-group-addon">
										<span>Ft</span>
									</span>
								</div>
								<span class="label label-danger" v-if="errors.shipping_price">
									{{ errors.shipping_price[0] }}
								</span>
							</div>
							<div class="form-group">
								<div class="input-group">
									<div class="input-group-prepend">
										<div class="input-group-addon">
											<input type="checkbox" v-model="material_container.document_type"
												true-value="1" false-value="0">
										</div>
									</div>
									<span class="form-control mw-25 overflow-hidden"> Dokumentáció rendben van</span>
									<div class="input-group-append">
										<span class="input-group-addon"
											:class="{ 'bg-danger': material_container.document_type == 0, 'bg-primary': material_container.document_type == 1 }"><i
												class="fa"
												:class="{ 'fa-times': material_container.document_type == 0, 'fa-check': material_container.document_type == 1 }"></i></span>
									</div>
								</div>
							</div>
							<div class="form-group">
								<div class="input-group">
									<div class="input-group-prepend">
										<div class="input-group-addon">
											<input type="checkbox" v-model="material_container.sample" true-value="1"
												false-value="0">
										</div>
									</div>
									<span class="form-control mw-25 overflow-hidden"> Minta</span>
									<div class="input-group-append">
										<span class="input-group-addon"
											:class="{ 'bg-danger': material_container.sample == 0, 'bg-primary': material_container.sample == 1 }"><i
												class="fa"
												:class="{ 'fa-times': material_container.sample == 0, 'fa-check': material_container.sample == 1 }"></i></span>
									</div>
								</div>
							</div>
							<button type="submit" class="btn btn-primary" :disabled="loading"><i v-if="loading" class="fa fa-cog fa-spin mr-1"></i>Vonalkód nyomtatása</button>
							<button type="button" @click="back()" class="btn btn-danger mx-2">Mégsem</button>
						</form>
						<!-- style="display:none;" -->
						<div id="container_barcode" class="d-none">
							<div style="width:100%; text-align:center">
								<span style="font-size:1em">{{ barcode_div.name }}</span>
								<br>
								<div
									style="display:inline-block;width:30px;height:109px;clear:both;background-color:white;">
									<span style="color:white">....</span></div>
								<barcode v-bind:value="barcode_div.barcode" width="1" height="88" fontSize="7"
									style="display:inline-block;" marginTop="2">
									Hiba történt a vonalkód generálás során
								</barcode>
								<div
									style="display:inline-block;width:30px;height:109px;clear:both;background-color:white;">
									<span style="color:white">....</span></div>
								<div class="print-invisible">
									<button onclick="window.print();" class="btn mx-auto"
										style="color: #fff; background-color: #1ab394; border-color:#1ab394;">Újranyomtatás</button>
									<button onclick="window.close();" class="btn mx-auto"
										style="color: #fff; background-color: #ed5565; border-color: #ed5565;">Bezár</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="ibox">
					<div class="ibox-title">
						<h5>Tárolók</h5>
					</div>
					<div class="ibox-content">
						<div class="alert alert-info">
							<i class="fa fa-info-circle"></i> A fejlesztői raktárhelyeken szereplő tárolókat a rendszer figyelmenkívül hagyja gyártás, és készletszámítás esetén
						</div>
						<div class="row" v-if="material_containers === false">
							<div class="col-12 text-center">
								<h3>tárolók betöltése</h3>
								<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
							</div>
						</div>
						<div class="widget p-lg text-center" v-if="material_containers.length == 0">
							<div class="m-b-md">
								<i class="fa fa-cubes fa-4x"></i>
								<h1 class="m-xs"><i class="fa fa-times"></i></h1>
								<h3 class="font-bold no-margins">
									Nincsenek tárolók
								</h3>
								<small>bevételezzen</small>
							</div>
						</div>
						<div class="table-responsive"
							v-if="material_containers !== false && material_containers.length != 0">
							<table class="table table-striped w-100">
								<thead>
									<tr>
										<th><i class="fa fa-barcode"></i></th>
										<th>Mennyiség</th>
										<th>Lejárati dátum</th>
										<th class="d-none d-sm-table-cell">Raktárhely</th>
										<th class="d-none d-sm-table-cell">Bevételezve</th>
										<th class="d-none d-sm-table-cell">Beszállító</th>
										<th>Batch szám</th>
										<th class="text-center">Törlés</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(container, id) in material_containers" :key="id">
										<td>
											<button @click="printBarcode(container)" class="btn btn-primary btn-sm"
												:title="container.barcode + ' újranyomtratása'"><i
													class="fa fa-barcode"></i></button>
											<button @click="moveMaterial(container)" class="btn btn-warning btn-sm ml-1"
												title="anyagmozgatás"><i class="fa fa-people-carry"></i></button>
										</td>
										<td>
											{{ container.quantity }}
										</td>
										<td>
											{{ container.best_before }}
										</td>
										<td>
											{{ container.storage_place_name }}
										</td>
										<td class="d-none d-sm-table-cell">
											{{ container.supplied_at }}
										</td>
										<td class="d-none d-sm-table-cell">
											{{ container.partner_name }}
										</td>
										<td>
											{{ container.batch_number }}
										</td>
										<td class="text-right">
											<!-- <router-link :to="'/material_containers/' + container.id + '/edit'"
												class="btn btn-success btn-sm mx-1"
												@click="editMaterialContainer(container.id)" title="szerkesztés"
												v-if="container.has_production == false"><i class="fa fa-pen"></i>
											</router-link> -->
											<button class="btn btn-danger btn-sm"
												@click="deleteMaterialContainer(container.id)" title="törlés"><i
													class="fa fa-trash"></i></button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="modal" name="storage_place_modal">
			<modal-vue modalSize="md" @on-close="$vm2.close('storage_place_modal')" name="storage_place_modal"
				:headerOptions="{ title: 'Sikeres bevételezés', }" :footerOptions="{
				
					btn1: 'Vissza az előző oldalra',
					btn2: 'Bevételezek még egyet',
					btn1OnClick: () => {
						back()
					},
					btn2OnClick: () => {
				
				
						this.material_container.barcode = '';
						this.$refs.batch_number.focus();
						$vm2.close('storage_place_modal');
					},
					btn1Style: { marginTop: '1em' },
					btn2Style: { marginTop: '1em' }
				}">
				<div align="center" class="col-12">
					<h3 v-if="material_containers.length > 0">Tedd a(z) {{ material_containers[0].storage_place_name }} raktárhelyre</h3>
				</div>
			</modal-vue>
		</div>
		<div id="modal" name="move_modal">
			<modal-vue modalSize="md" @on-close="$vm2.close('move_modal')" name="move_modal"
				:headerOptions="{ title: container_to_move.barcode + ' - Anyagmozgatás', }" :footerOptions="{
				
					btn1: 'Mégsem',
					btn2: 'Mozgatás',
					btn1OnClick: () => {
						this.container_to_move = false;
						$vm2.close('move_modal')
					},
					btn2OnClick: () => {
						moveSubmit(container_to_move)
					},
					btn1Style: { marginTop: '1em' },
					btn2Style: { marginTop: '1em' },
					disableBtn2:loading,
				}">
				<div align="center" class="col-12" style="min-height:200px;">
					<form @submit.prevent="moveSubmit(container_to_move)">
						<div class="form-group">
							<label class="form-label">Adja meg mennyit szeretne átmozgatni egy új tárolóba:</label>
							<div class="input-group m-b">
								<div class="input-group-prepend">
									<span class="input-group-addon">összes <input type="checkbox"
											v-model="all_select"></span>
								</div>
								<input type="number" @wheel="$event.target.blur()" class="form-control text-right"
									v-model="container_to_move.move_quantity" :disabled="all_select">
							</div>
							<span class="label label-danger" v-if="errors.move_quantity">
								{{ errors.move_quantity[0] }}
							</span>
						</div>
						<div class="form-group mb-6">
							<label class="form-label">Válassza ki melyik raktárhelyre szeretné mozgatni</label>
							<v-select :from="storage_places" as="name:id:id" v-model="container_to_move.storage_place_id" class="mb-6"></v-select>
							<span class="label label-danger" v-if="errors.move_storage_place_id">
								{{ errors.move_storage_place_id[0] }}
							</span>
						</div>
					</form>
				</div>
			</modal-vue>
		</div>
	</div>
</template>
 
<style>
	.v-select-list {
		height:70px;
	}
</style>

<script>
import PageHeader from '../../components/PageHeader';
import MaterialContainer from "../../apis/MaterialContainer";
import Material from "../../apis/Material";
import Partner from "../../apis/Partner";
import Storage from "../../apis/Storage";
import VueBarcode from 'vue-barcode';
import { vSelect } from '@desislavsd/vue-select'

export default {
	components: { PageHeader, 'barcode': VueBarcode, vSelect },
	data() {
		return {
			material_container: {
				raw_material_id: this.$route.params.material_id,
				document_type: 0,
				shipping_price: 0,
				sample: 0,
			},
			material_containers: false,
			partners: false,
			raw_material: {},
			errors: [],
			barcode_div: {
				name: '',
				barcode: '',
			},
			container_to_move: false,
			all_select: false,
			storage_places:[],
			loading:false,
		}
	},
	watch: {
		all_select: function (all_select) {
			if (all_select == true) {
				this.$set(this.container_to_move, 'move_quantity', this.container_to_move.quantity);
			}
		}
	},
	created() {
		//console.log(new Date());
		Material.show(this.$route.params.material_id)
			.then(response => {
				this.raw_material = response.data;
				//console.log(response.data);
			}).then(() => {
				this.$set(this.material_container, 'partner_id', this.raw_material.last_partner_id);
				this.$set(this.material_container, 'price', this.raw_material.last_price);
				this.$set(this.material_container, 'name', this.raw_material.name);
			});
		Partner.index(1, 1)
			.then(response => {
				this.partners = response.data;
			});
		Material.get_containers(this.$route.params.material_id)
			.then(response => {
				//console.log('containers:',response.data);
				this.material_containers = response.data;
			});
		Storage.places(1)
			.then( response => {
				this.storage_places = this.storage_places.concat(response.data);
			});
		Storage.places(7)
			.then( response => {
				this.storage_places = this.storage_places.concat(response.data);
			});
	},
	methods: {
		currentDate() {
			const current = new Date();
			current.setDate(current.getDate() + 1)
			const date = `${current.getFullYear()}-${('00' + (current.getMonth() + 1)).slice(-2)}-${('00' + current.getDate()).slice(-2)}`;
			return date;
		},
		addMaterialContainer() {
			this.loading =true;
			MaterialContainer.add(this.material_container)
				.then(response => {
					this.openModal();

					this.material_container = this.jsonConcat(this.material_container, response.data);
					this.material_containers.unshift(response.data);
					this.errors = [];

				}).then(() => {
					if (this.raw_material.short_name != null) {
						this.setBarcodeDiv(this.raw_material.short_name, this.material_container.barcode);
					} else {
						this.setBarcodeDiv(this.raw_material.name, this.material_container.barcode);
					}
				}).then(() => {
					let printData = document.getElementById("container_barcode").innerHTML;
					let newTab = window.open();

					newTab.document.body.innerHTML = printData + '<style>.btn {padding:1em; font-size:2em; margin:2em; display:block;}.btn:focus { box-shadow: none;} @media print {.print-invisible{display:none;}}.mx-auto {  margin-right: auto !important;margin-left: auto !important;}</style>';
					newTab.print();

				})
				.catch(error => {
					this.errors = error.response.data.errors;
				}).finally(() => {
					this.loading = false;
				})
		},
		printBarcode(container) {
			let name = '';
			if (this.raw_material.short_name != null) {
				name = this.raw_material.short_name;
			} else {
				name = this.raw_material.name;
			}
			this.setBarcodeDiv(name, container.barcode).then(() => {
				let printData = document.getElementById("container_barcode").innerHTML;
				let newTab = window.open();

				newTab.document.body.innerHTML = printData + '<style>.btn {padding:1em; font-size:2em; margin:2em; display:block;}.btn:focus { box-shadow: none;} @media print {.print-invisible{display:none;}}.mx-auto {  margin-right: auto !important;margin-left: auto !important;}</style>';
				newTab.print();
			})
		},
		moveMaterial(container) {
			this.container_to_move = container;
			this.$vm2.open('move_modal');
		},
		moveSubmit(container) {
			this.loading = true;
			if(!container.storage_place_id){
				this.$toast.open({
					message: 'válassz raktárhelyet!',
					type: 'error',
					position: 'top-right',
				});
				this.loading = false;
			}else if (Number(container.move_quantity) >= 1 && Number(container.move_quantity) <= Number(container.quantity)) {
				MaterialContainer.moveMaterial(container.id, { quantity: container.move_quantity,storage_place_id: container.storage_place_id })
					.then(response => {
						this.material_containers.unshift(response.data);
						let new_quantity = this.material_containers.find(mc => mc.id == container.id).quantity - container.move_quantity;
						if(new_quantity == 0){
							this.material_containers.splice(this.material_containers.findIndex(mc => mc.id == container.id),1);
						}else{
							this.$set(this.material_containers.find(mc => mc.id == container.id), 'quantity',new_quantity);
						}
						this.$set(this,'all_select',false);
						this.$vm2.close('move_modal');
					}).finally(() => {
						this.loading = false;
					});
			} else {
				this.$toast.open({
					message: '1 és ' + container.quantity + ' közti értéket adhat meg',
					type: 'error',
					position: 'top-right',
				});
				this.loading = false;
			}
		},
		setBarcodeDiv(name, barcode) {
			let vue = this;
			return new Promise(function (resolve) {
				vue.$set(vue.barcode_div, 'name', name);
				vue.$set(vue.barcode_div, 'barcode', barcode);
				resolve('finished')
			});
		},
		openModal() {
			this.$vm2.open('storage_place_modal')

		},
		deleteMaterialContainer(id) {
			if (this.material_containers[this.material_containers.map(data => data.id).indexOf(id)].quantity == 0) {
				MaterialContainer.delete(id)
					.then(() => {
						let i = this.material_containers.map(data => data.id).indexOf(id);
						this.material_containers.splice(i, 1)
					})
					.catch(error => {
						this.errors = error.response.data.errors;
					})
			} else {
				this.$toast.open({
					message: 'Csak üres tárolót törölhet',
					type: 'error',
					position: 'top-right',
				});
			}
		},
		jsonConcat(o1, o2) {
			for (var key in o2) {
				o1[key] = o2[key];
			}
			return o1;
		},
		back() {
			let vue = this;
			new Promise(function (resolve) {
				vue.$vm2.close('storage_place_modal');
				resolve('finished');
			}).then(() => {
				window.history.back();
			});
		}
	}
}
</script>