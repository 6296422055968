import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/materials");
  },
    
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/materials", form);
  },
    
  async show(id) {
    await Csrf.getCookie();

    return Api.get("/materials/" + id);
  },
    
  async update(id, form) {
    await Csrf.getCookie();

    return Api.patch("/materials/" + id, form);      
      
  },
    
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/materials/" + id);      
      
  },
    
  async get_containers(id) {
    await Csrf.getCookie();

    return Api.get("/materials/" + id + "/get_containers");      
      
  },
    
  async takeOut(id,form) {
    await Csrf.getCookie();

    return Api.post("materials/"+id+"/take_out", form);
  },
	
	
    
};