<template>
	<div id="wrapper">
		<sidemenu class="vsm vsm_collapsed" style="z-index:300;" />
		<div id="page-wrapper" class="gray-bg" :class="{collapsed: collapsed}">
			<Topbar />
			<router-view style="padding-bottom:120px;" />
			<Footer />
		</div>
		<div id="no_internet" style="position: fixed; display: none; width: 100%; height: 100%; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0,0,0,0.5); z-index: 6000; cursor: pointer;">
				<div class="widget red-bg p-lg text-center" style="position: absolute;  top: 50%;  left: 50%;  transform: translate(-50%,-50%);  -ms-transform: translate(-50%,-50%);">
					<div class="m-b-md">
							<i class="fa fa-wifi fa-4x"></i>
							<h1 class="m-xs"><i class="fa fa-times"></i></h1>
							<h3 class="font-bold no-margins">
									Hálózati hiba
							</h3>
							<small>Ellenőrizze internetkapcsolatát!</small>
					</div>
				</div>
		</div>
	</div>
</template>

<script>
import Sidemenu from "./Sidemenu";
import Topbar from "./Topbar";
import Footer from "./Footer";

export default {
	name: "app",
	components: {
		Sidemenu,
		Topbar,
		Footer
	},
	data(){
		return {
			collapsed: false,
			onLine: navigator.onLine,
		}
	},
	methods: {
		updateOnlineStatus(e) {
			const { type } = e;
			this.onLine = type === 'online';
		}
  },
	watch: {
		onLine(online) {
			if(online) {
				document.getElementById("no_internet").style.display = "none";
			}else{
				document.getElementById("no_internet").style.display = "block";
			}
		}
	},
	mounted() {
    window.addEventListener('online',  this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online',  this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
	created(){
		this.$root.$on('toggleCollapse', () => {
			this.collapsed = !this.collapsed;
		});
	}
};
</script>