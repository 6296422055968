<template>
	<div>
		<div class="fa-3x">
			<i class="fas fa-spinner fa-pulse"></i>
		</div>
	</div>
</template>

<script>	
export default {
	data() {
		return {
		}
	},
	created(){
		this.$router.push({ name: "Dashboard" });
	}
}
</script>