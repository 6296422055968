<template>
	<div>
		<PageHeader title="Félkész termék napló" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="products === false">
								<div class="col-12 text-center">
									<h3>Félkész termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek félkész termékek
									</h3>
									<small>hozzon létre félkész terméket</small>
								</div>
							</div>
							<div class="table-responsive" v-if="products.length != 0 && products !== false">
								<vue-good-table styleClass="vgt-table striped" rowStyleClass="fs-12" :columns="columns"
									:rows="products" :search-options="{ enabled: false, placeholder: 'Keresés...' }"
									:pagination-options="this.$vueGTPaginationOptions">
									<template slot="table-row" slot-scope="props">
										<span v-if="props.column.field == 'actions'">
											<button class="btn btn-success mx-1 d-inline" title="napló"
												@click="openModal(props.row)"><i class="fa fa-book-open"></i></button>
										</span>
										<span v-else>
											{{ props.formattedRow[props.column.field] }}
										</span>
									</template>
								</vue-good-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal">
			<modal-vue modalSize="xl" @on-close="modalClose()" name="product_modal"
				:headerOptions="{ title: product.name + ' napló', }" :footerOptions="{
					btn1: 'Bezár',
					disableBtn2: true,
					btn1OnClick: () => {
						$vm2.close('product_modal');
					},
				}">
				<div align="center" class="col-12">
					<div class="row" v-if="histories === false">
						<div class="col-12 text-center">
							<h3>Napló betöltése</h3>
							<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
						</div>
					</div>
					<div class="row" v-if="histories !== false">
						<div class="col-lg-4">
							<h5>Adatok:</h5>
							<div class="tableFixHead">
								<table class="table">
									<thead class="thead-light">
										<tr>
											<th>Megnevezés</th>
											<th>Leírás</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{{ product.name }}</td>
											<td>{{ product.description }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="col-lg-8">
							<div class="row">
								<h5>Félkész termékek:</h5>
								<div class="tableFixHead">
									<table class="table table-striped">
										<thead class="thead-light">
											<tr>
												<th><i class="fa fa-eye"></i></th>
												<th>Vonalkód</th>
												<th>Lejárati dátum</th>
												<th>Tartalom</th>
												<th>Gyártva</th>
												<th>1 egység ára</th>
												<th>tech.veszteség</th>
												<th class="text-center">Dokumentáció</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(intermediate_product, id) in histories.intermediate_products"
												:key="id">
												<td>
													<input type="checkbox" v-model="intermediate_product.show">
												</td>
												<td>{{ intermediate_product.barcode }}</td>
												<td>{{ intermediate_product.best_before }}</td>
												<td>{{ intermediate_product.quantity }} {{ product.unit }}</td>
												<td>{{ intermediate_product.created_date }}</td>
												<td class="text-right">{{ intermediate_product.unit_price }} Ft</td>
												<td class="text-right">{{ intermediate_product.lost_quantity }}</td>
												<td class="text-right buttons-wide-3">
													<button v-if="intermediate_product.media >= 1"
														class="btn btn-success mx-1 d-inline" title="minden letöltése"
														@click="downloadZip(intermediate_product.production_id)"><i
															class="fa fa-file-archive"></i></button>
													<button v-else class="btn btn-danger" style="cursor: default;"
														disabled title="nincs dokumentáció"><i
															class="fa fa-exclamation-triangle"></i></button>
													<button @click="printBarcode(intermediate_product, product.name)"
														class="btn btn-primary mx-1"
														:title="intermediate_product.barcode + ' újranyomtatása'"><i
															class="fa fa-barcode"></i></button>
													<loading-button class="btn btn-warning mx-1 d-inline"
														title="pdf letöltés"
														@click.native="downloadPdf(intermediate_product.production_id)"
														:loading="loading"><i class="fa fa-print"></i></loading-button>

													<button v-if="[1, 2].includes($root.$data.user.role_id)"
														class="btn btn-primary mx-1 d-inline"
														title="dokumentáció szerkesztése"
														@click="editParameters(intermediate_product.production_id)"><i
															class="fa fa-pen"></i></button>

												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="row mt-2" v-if="histories.destroyProductions.length != 0">
								<h5>Leállított gyártások:</h5>
								<div class="tableFixHead w-100 px-2">
									<table class="table table-striped">
										<thead class="thead-light">
											<tr>
												<th><i class="fa fa-eye"></i></th>
												<th>Létrehozva</th>
												<th>Törölve</th>
												<th>Kívánt gyártási mennyiség</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(destroyProduction, id) in histories.destroyProductions"
												:key="id">
												<td>
													<input type="checkbox" v-model="destroyProduction.show">
												</td>
												<td>{{ destroyProduction.created_date }}</td>
												<td>{{ destroyProduction.finished_date }}</td>
												<td>{{ destroyProduction.expected_quantity }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="col-12 my-2">
							<button class="btn btn-primary btn-outline" type="button" :class="{ 'active': active == 1 }"
								@click="active = 1">Napló</button>
							<button class="btn btn-primary btn-outline" type="button" :class="{ 'active': active == 2 }"
								@click="active = 2">Műveletek</button>
							<button class="btn btn-primary btn-outline" type="button" :class="{ 'active': active == 3 }"
								@click="active = 3">Gyártási műveletek</button>
							<div class="tableFixHead" v-if="this.active == 2">
								<table class="table table-striped mt-4">
									<thead class="thead-dark">
										<tr>
											<th>Időpont</th>
											<th>Esemény</th>
											<th>Tároló</th>
											<th>Mennyiség</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(history, id) in histories.histories" :key="id"
											v-show="histories.intermediate_products[histories.intermediate_products.map(intermediate_product => intermediate_product.id).indexOf(history.intermediate_product_id)].show">
											<td>{{ history.created_date }}</td>
											<td>{{ history.commission == 1 ? 'Bizományi értékesítés' :
													history.event_type
											}}</td>
											<td>{{ histories.intermediate_products[histories.intermediate_products.map(intermediate_product	=> intermediate_product.id).indexOf(history.intermediate_product_id)].barcode
											}}
											</td>
											<td>{{ history.quantity }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="tableFixHead" v-if="this.active == 1">
								<table class="table table-striped mt-4">
									<thead class="thead-dark">
										<tr>
											<th>Időpont</th>
											<th>Esemény</th>
											<th>Tároló</th>
											<th>Felhasználó</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(log, id) in histories.logs" :key="id"
											v-show="histories.intermediate_products.map(intermediate_product => intermediate_product.production_id).indexOf(parseInt(log.table_id)) == -1 ? histories.destroyProductions[histories.destroyProductions.map(dp => dp.id).indexOf(parseInt(log.table_id))].show : histories.intermediate_products[histories.intermediate_products.map(intermediate_product => intermediate_product.production_id).indexOf(parseInt(log.table_id))].show">
											<td>{{ log.created_date }}</td>
											<td>{{ log.description }}</td>
											<td>{{ histories.intermediate_products.map(intermediate_product =>
													intermediate_product.production_id).indexOf(parseInt(log.table_id)) ==
													-1 ? 'Nem keletkezett félkész termék' :
													histories.intermediate_products[histories.intermediate_products.map(intermediate_product => intermediate_product.production_id).indexOf(parseInt(log.table_id))].barcode
											}}
												<div v-if="log.description == 'Gyártás megszakítva'">
													<strong>törlés oka: {{ JSON.parse(log.data).description }}</strong>
												</div>
											</td>
											<td>{{ log.user_name }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="tableFixHead" v-if="this.active == 3">
								<table class="table table-striped mt-4">
									<thead class="thead-dark">
										<tr>
											<th>Időpont</th>
											<th>Esemény</th>
											<th>Alapanyag Tároló</th>
											<th>Mennyiség</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(rmch, id) in histories.rawMaterialContainerHistories" :key="id"
											v-show="histories.intermediate_products.map(intermediate_product => intermediate_product.production_id).indexOf(rmch.related_model_id) == -1 ? histories.destroyProductions[histories.destroyProductions.map(dp => dp.id).indexOf(rmch.related_model_id)].show : histories.intermediate_products[histories.intermediate_products.map(intermediate_product => intermediate_product.production_id).indexOf(rmch.related_model_id)].show">
											<td>{{ rmch.created_date }}</td>
											<td>{{ rmch.event_type }}</td>
											<td>{{ rmch.barcode }}-{{ rmch.name }}</td>
											<td>{{ rmch.quantity }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</modal-vue>
		</div>
		<div id="container_barcode" class="d-none">
			<div style="width:100%; text-align:center">
				<span style="font-size:0.6em">{{ barcode_div.name }}</span>
				<br>
				<div style="display:inline-block;width:30px;height:74px;clear:both;background-color:white;"><span
						style="color:white">....</span></div>
				<barcode v-if="barcode_div.barcode" v-bind:value="barcode_div.barcode" width="1" height="35"
					fontSize="7" style="display:inline-block;" marginTop="1">
					Hiba történt a vonalkód generálás során
				</barcode>
				<div style="display:inline-block;width:30px;height:74px;clear:both;background-color:white;"><span
						style="color:white">....</span></div>
				<div class="print-invisible">
					<button onclick="window.print();" class="btn mx-auto"
						style="color: #fff; background-color: #1ab394; border-color:#1ab394;">Újranyomtatás</button>
					<button onclick="window.close();" class="btn mx-auto"
						style="color: #fff; background-color: #ed5565; border-color: #ed5565;">Bezár</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Log from "../../apis/Log";
import Production from "../../apis/Production";
import VueBarcode from 'vue-barcode';

export default {
	components: { PageHeader, 'barcode': VueBarcode },
	data() {
		return {
			loading: false,
			products: false,
			product: {},
			errors: [],
			histories: false,
			active: 1,
			link: {},
			barcode_div: {
				name: '',
				barcode: '',
			},
			columns: [
				{
					label: 'Megnevezés',
					field: 'name',
					filterOptions: {
						enabled: true,
						placeholder: 'Keresés',
					},
					thClass: 'fs-12'
				},
				{
					label: 'Leírás',
					field: 'description',
					filterOptions: {
						enabled: true,
						placeholder: 'Keresés',
					},
					thClass: 'fs-12'
				},
				{
					label: 'Műveletek',
					field: 'actions',
					width: '15%',
					sortable: false,
					thClass: 'text-right fs-12',
					tdClass: 'text-right'
				}
			]
		}
	},
	created() {
		Log.intermediate_product()
			.then(response => {
				this.products = response.data;
				//console.log(this.products);
			});
	},
	methods: {
		printBarcode(intermediate_product, product_name) {
			this.setBarcodeDiv(product_name, intermediate_product.barcode).then(() => {
				let printData = document.getElementById("container_barcode").innerHTML;
				let newTab = window.open();

				newTab.document.body.innerHTML = printData + '<style>.btn {padding:1em; font-size:2em; margin:2em; display:block;}.btn:focus { box-shadow: none;} @media print {.print-invisible{display:none;}}.mx-auto {  margin-right: auto !important;margin-left: auto !important;}</style>';
				newTab.print();
			})
		},
		setBarcodeDiv(name, barcode) {
			let vue = this;
			return new Promise(function (resolve) {
				vue.$set(vue.barcode_div, 'name', name);
				vue.$set(vue.barcode_div, 'barcode', barcode);
				resolve('finished')
			});
		},
		openModal(product) {
			this.product = product
			this.$vm2.open('product_modal')
			Log.log({ type: 'intermediate_product', id: product.id })
				.then(response => {
					this.histories = response.data;
					this.histories.intermediate_products.forEach(intermediate_product => {
						this.$set(intermediate_product, 'show', true);
					});
					this.histories.destroyProductions.forEach(destroyProduction => {
						this.$set(destroyProduction, 'show', true);
					});
					//console.log('histories:',this.histories);
					//console.log('product:',this.product);
				});
		},
		downloadPdf(id) {
			this.loading = true;
			Production.pdf(id)
				.then(response => {
					//console.log(response.data);
					var blob = new Blob([response.data], { type: 'application/pdf' });
					var link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = "gyartasi_leirat_" + id + ".pdf";
					link.click();
				}).finally(() => {
				this.loading = false;
			})
		},
		downloadZip(id) {
			Production.zip(id)
				.then(response => {
					//console.log(response.data);
					var blob = new Blob([response.data]);
					var link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = "gyartas_" + id + ".zip";
					link.click();
				})
		},
		modalClose() {
			this.$vm2.close('product_modal');
			this.histories = false;
		},
		editParameters(id) {
			let vue = this;
			new Promise(function (resolve) {
				vue.$vm2.close('product_modal');
				resolve('finished')
			}).then(() => {
				this.$router.push({ path: '/productions/' + id + '/parameters/edit' })
			});
		}
	}
};
</script>

<style>

</style>