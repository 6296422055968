<template>
<sidebar-menu :menu="menu" :width="width" :showOneChild="showOneChild" @toggle-collapse="onToggleCollapse" />
<!--
	<nav class="navbar-default navbar-static-side" role="navigation">
		<div class="sidebar-collapse">
			<ul class="nav metismenu" id="side-menu">
				<li class="nav-header">
					<div class="dropdown profile-element">
						<a data-toggle="dropdown" class="dropdown-toggle" href="#">
							<img class="rounded-circle" width="48" height="48" :src="this.$root.$data.user.profile_image" v-if="this.$root.$data.user.profile_image"/>
							<span class="block m-t-xs font-bold">{{ this.$root.$data.user.name }}</span>
							<span class="text-muted text-xs block">Profil <b class="caret"></b></span>
						</a>
						<ul class="dropdown-menu animated fadeInRight m-t-xs">
							<li><router-link tag="a" to="/profile">Adataim</router-link></li>
							<li><a href="#" @click.prevent="logout()">Kijelentkezés</a></li>
						</ul>
					</div>
				</li>
			</ul>
		</div>
	</nav>
-->
</template>

<script>
	import User from '../../apis/User';
	import { SidebarMenu } from 'vue-sidebar-menu'
	import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
	
	/*

	*/
	export default {
		name: 'Sidemenu',
		
		data(){
			return {
				menu:[],
				width: '220px',
				showOneChild: true,
						}
		},
		
		components: {
			SidebarMenu
		},
		created() {
			User.auth()
				.then(response => {
					this.$root.$data.user = response.data;
					//console.log('$root.$data.user:'+this.$root.$data.user);
					//console.log(this.$root.$data.user);
					if(this.$root.$data.user.role_id == 6){
						this.$router.push({path:'/commission/partner'});
					}
					localStorage.setItem('user',JSON.stringify(response.data));
				}).then(() => {
					this.menu =  [
										{
												header: true,
												title: '',
												hiddenOnCollapse: true
										},
										{
												href: '/dashboard',
												title: 'Összesítőpult',
												icon: 'fa fa-th-large',
												hidden: [1,2,3,4,5,7 ].includes(this.$root.$data.user.role_id) ? false : true
										},
										{
												href: '/supply',
												title: 'Bevételezés',
												icon: 'fa fa-cubes',
												hidden: true//[1,2 ].includes(this.$root.$data.user.role_id) ? false : true
										},
										{
												href: '/productions',
												title: 'Gyártás',
												icon: 'fa fa-industry',
												hidden: [1,2,3 ].includes(this.$root.$data.user.role_id) ? false : true
										},
										{
												href: '/filling',
												title: 'Töltés',
												icon: 'fa fa-tint',
												hidden: [1,2,3,4 ].includes(this.$root.$data.user.role_id) ? false : true
										},
										{
												href: '/outside-filling',
												title: 'Külsős Töltés',
												icon: 'fa fa-compass',
												hidden: [1,2,3,4 ].includes(this.$root.$data.user.role_id) ? false : true
										},
										{
												href: '/packaging',
												title: 'Csomagolás',
												icon: 'fa fa-gift',
												hidden: [1,2,3,4 ].includes(this.$root.$data.user.role_id) ? false : true
										},
										{
												href: '/selling',
												title: 'Értékesítés',
												icon: 'fa fa-money-bill-alt',
												hidden: [1,2,5,7 ].includes(this.$root.$data.user.role_id) ? false : true
										},
										{
												title: 'Készlet',
												icon: 'fa fa-archive',
												hidden: [1,2,3,4,5,7 ].includes(this.$root.$data.user.role_id) ? false : true,
												child: [
													{
														href: '/inventory',
														title: 'Összesítő',
														icon: 'fa fa-globe',
													},
													{
														href: '/inventory/finished_product',
														title: 'Késztermék',
														icon: 'fa fa-gift',
													}
												]
										},
										{
												href: '/commission/admin',
												title: 'Rendelések',
												icon: 'fa fa-hand-holding-usd',
												hidden: [1,2,5 ].includes(this.$root.$data.user.role_id) ? false : true
										},
										{
												href: '/commission/partner',
												title: 'Igénylés',
												icon: 'fa fa-hand-holding-usd',
												hidden: [6].includes(this.$root.$data.user.role_id) ? false : true
										},
										{
												href: '/storage',
												title: 'Raktár',
												icon: 'fa fa-warehouse',
												hidden: ([6].includes(this.$root.$data.user.role_id) && this.$root.$data.user.commission) ? false : true
										},
										{
												href: '/inventories',
												title: 'Leltározás',
												icon: 'fa fa-search',
												hidden: [1,2,3 ].includes(this.$root.$data.user.role_id) ? false : true
										},
										{
												title: 'Selejtezés',
												icon: 'fa fa-trash-alt',
												hidden: [1,2,3,4 ].includes(this.$root.$data.user.role_id) ? false : true,
												child: [
														{
																href: '/discarding/wrappings',
																title: 'Göngyöleg',
																icon: 'fa fa-gift',
														},
														{
																href: '/discarding/materials',
																title: 'Alapanyagok',
																icon: 'fa fa-balance-scale',
														},
														{
																href: '/discarding/intermediate_products',
																title: 'Félkész Termékek',
																icon: 'fa fa-industry',
														},
														{
																href: '/discarding/filled_products',
																title: 'Töltött Termékek',
																icon: 'fa fa-tint',
														},
														{
																href: '/discarding/packaged_products',
																title: 'Csomagolt Termékek',
																icon: 'fa fa-gift',
														},
												]
										},
										{
												title: 'Napló',
												icon: 'fa fa-book',
												hidden: [1,2,3,4,5 ].includes(this.$root.$data.user.role_id) ? false : true,
												child: [
														{
																href: '/log/wrapping',
																title: 'Göngyöleg',
																icon: 'fa fa-gift',
														},
														{
																href: '/log/raw_material',
																title: 'Alapanyagok',
																icon: 'fa fa-balance-scale',
														},
														{
																href: '/log/intermediate_product',
																title: 'Félkész Termékek',
																icon: 'fa fa-industry',
														},
														{
																href: '/log/filled_product',
																title: 'Töltött Termékek',
																icon: 'fa fa-tint',
														},
														{
																href: '/log/packaged_product',
																title: 'Csomagolt Termékek',
																icon: 'fa fa-gift',
														},
														{
																href: '/log/order',
																title: 'Értékesítések',
																icon: 'fa fa-money-bill-alt',
														},
														{
																href: '/log/inventory',
																title: 'Leltárak',
																icon: 'fa fa-search',
														},
												]
										},
										{
												title: 'Törzsadatok',
												icon: 'fa fa-sitemap',
												hidden: [1,2,3,4,5 ].includes(this.$root.$data.user.role_id) ? false : true,
												child: [
														{
																href: '/products',
																title: 'Termékek',
																icon: 'fa fa-cube',
																hidden: [1,2,3 ].includes(this.$root.$data.user.role_id) ? false : true
														},
														{
																href: '/materials',
																title: 'Alapanyagok',
																icon: 'fa fa-balance-scale',
																hidden: [1,2,3 ].includes(this.$root.$data.user.role_id) ? false : true
														},
														{
																href: '/wrappings',
																title: 'Göngyöleg',
																icon: 'fa fa-gift',
																hidden: [1,2,3,4 ].includes(this.$root.$data.user.role_id) ? false : true
														},
														{
																href: '/storage_places',
																title: 'Raktárhelyek',
																icon: 'fa fa-archive',
																hidden: [1,2,3 ].includes(this.$root.$data.user.role_id) ? false : true
														},
														{
																href: '/partners',
																title: 'Partnerek',
																icon: 'fa fa-user',
														},
														{
																href: '/price_categories',
																title: 'Árkategóriák',
																icon: 'fa fa-dollar-sign',
																hidden: [1,2,3 ].includes(this.$root.$data.user.role_id) ? false : true
														},
														{
																href: '/bundles',
																title: 'Csomagok',
																icon: 'fa fa-gift',
																hidden: [1,2,3,5 ].includes(this.$root.$data.user.role_id) ? false : true
														}
												]
										},
										{
												title: 'Rendszer',
												icon: 'fa fa-plug',
												hidden: [1,2 ].includes(this.$root.$data.user.role_id) ? false : true,
												child: [
														{
																href: '/users',
																title: 'Felhasználók',
																icon: 'fa fa-users',
														},
														{
																href: '/profile',
																title: 'Profilom',
																icon: 'fa fa-user-circle',
														},
												]
										}
								];
					
			});
		},
		mounted() {
			if(window.innerWidth < 576){
				document.getElementsByClassName('vsm--toggle-btn').forEach(button => {
					//console.log(button);
					button.click();
				});
			}
		},
		methods: {
			logout() {
				User.logout().then(() => {
					localStorage.removeItem("auth");
					localStorage.removeItem("user");
					this.$router.push({
						name: "Login"
					});
				})
			},
			
			onToggleCollapse(){
				this.$root.$emit('toggleCollapse');
			}
		}
	}
</script>

<style>	
	.vsm--header{margin:1em 0; background-image:url('~@/assets/img/vintage-beauty-logo.png'); height:3em; background-size:contain; background-repeat: no-repeat; background-position:50% 50%; filter:invert(1)}
	.v-sidebar-menu .vsm--link_level-2 {padding-left: 2em;} /* #1ab394 */
	.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active, .v-sidebar-menu .vsm--link_level-1.vsm--link_active{box-shadow: 3px 0px 0px 0px #1ab394 inset;}
	.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon{background-color:#1ab394}
	.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1{background-color:#1ab394}
</style>