<template>
	<div>
		<PageHeader title="Fejlesztés" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
                <div class="col-12">
                    <div class="ibox">
                        <div class="ibox-title">
                            <h5>Fejlesztés során felhasznált anyagok</h5>
                        </div>
                        <div class="ibox-content">
                            <div class="w-100 text-center" v-if="!storage_items">
                                <i class="fa fa-cog fa-spin fa-3x"></i>
                            </div>
                            <div class="widget  p-lg text-center" v-else-if="storage_items.length == 0">
                                <div class="m-b-md">
                                    <i class="fa fa-cubes fa-4x"></i>
                                    <h1 class="m-xs"><i class="fa fa-times"></i></h1>
                                    <h3 class="font-bold no-margins"> Üres a fejlesztői raktár!</h3>
                                    <small>bevételezzen vagy mozgasson át alapanyagokat</small><br>
                                    <router-link to="/materials" class="btn btn-success btn-xs mt-2"><i class="fa fa-paper-plane"></i> Alapanyagokhoz</router-link>
                                </div>
                            </div>
                            <div class="widget  p-lg text-center" v-else-if="typeof storage_items == 'string'">
                                <div class="m-b-md">
                                    <i class="fa fa-exclamation-triangle fa-4x"></i>
                                    <h1 class="m-xs"><i class="fa fa-times"></i></h1>
                                    <h3 class="font-bold no-margins"> {{storage_items}}</h3>
                                    <small>A fejlesztés nem engedélyezett</small><br>
                                    <button @click="back" class="btn btn-success btn-xs mt-2"><i class="fa fa-arrow-left"></i> Vissza</button>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-md-6">
                                    <h5>Fejlesztői raktáron lévő alapanyagok</h5>
                                    <input type="text" v-on:keyup="search = $event.target.value" placeholder="keresés..."/>
                                    <draggable :clone="MaterialClone" v-model="filteredList" group="materials" @start="drag=true" @end="drag=false" class="border border-light pt-2" style="min-height:200px">
                                        <div v-for="raw_material in filteredList" class="btn m-1 b-r-xl border-dark grab bg-success" :key="raw_material.id">{{raw_material.name}}</div>
                                    </draggable>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <h5>Kiválasztott alapanyagok</h5>
                                    <form @submit.prevent="sendDevelopment">
                                        <draggable v-model="selected_materials" group="materials" @start="drag=true" @end="drag=false" @change="updateMaterialList" class="border border-light pt-2" style="min-height:200px">
                                            <div v-for="selected_material in selected_materials" :key="selected_material.id" class="row grab border border-dark mb-2 b-r-xl mx-1 px-1">
                                                <div class="col-md-6 text-center form-control px-0 b-r-xl bg-success">
                                                    <div class="my-auto">{{selected_material.name}}</div>
                                                </div>
                                                <div class="col-md-6 text-center px-0 border-left-0">
                                                    <div class="input-group">
                                                        <input type="number" @wheel="$event.target.blur()" min="1" :max="selected_material.quantity" @focus="$event.target.select()" class="form-control b-r-xl text-right border-left-0" v-model="selected_material.used_quantity">
                                                        <span class="input-group-append b-r-xl">
                                                            <div class="btn btn-white b-r-xl">{{selected_material.unit}}</div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </draggable>
                                        <div class="w-100 text-right" v-if="selected_materials.length > 0">
                                            <button class="btn btn-primary"><i class="fa fa-save"></i> Rögzítés</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Development from '../../apis/Development';
import draggable from 'vuedraggable'
export default {
    components: { PageHeader,draggable },
	data(){
		return { 
            storage_items:false,
            selected_materials:[],
            search:''
        }
    },
    created(){
        Development.storage(this.$route.params.product_id)
        .then(response => {
            this.storage_items = response.data;
        }).catch(e => {
            this.storage_items = e.response.data.errors.containers[0];
        });
    },
    computed:{
        filteredList:{
            get(){
                if(!this.storage_items || typeof this.storage_items == 'string'){
                    return this.storage_items;
                }else{
                    return this.storage_items.filter(s => {
                        return s.name.toLowerCase().includes(this.search.toLocaleLowerCase()) && !this.selected_materials.map(m => m.id).includes(s.id);
                    });
                }
            },
            set(val){
                return val;
            }
        },
        sendObject(){
            return {
                product_id: this.$route.params.product_id,
                used_materials: this.selected_materials.map(s => ({
                    raw_material_id: s.id,
                    quantity: s.used_quantity,
                }))
            }
        }
    },
    methods:{
        MaterialClone(obj){
            return JSON.parse(JSON.stringify(obj))
        },
        updateMaterialList(event){
            if(typeof event.added != 'undefined'){
                this.$set(this.selected_materials[event.added.newIndex],'used_quantity',0);
            }
        },
        sendDevelopment(){
            Development.store(this.sendObject)
            .then(() => {
                window.history.back();
            });
        },
        back(){
            window.history.back();
        }
    }
}
</script>