<template>
	<div>
		<PageHeader title="Raktárhelyek" button_title="Új raktárhely rögzítése" button_href="/storage_places/create" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="storage_places === false">
								<div class="col-12 text-center">
									<h3>Raktárhelyek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="storage_places.length == 0">
								<div class="m-b-md">
									<i class="fa fa-archive fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek raktárhelyek
									</h3>
									<small>hozzon létre raktárhelyet</small>
								</div>
							</div>
							<div class="table-responsive" v-if="storage_places.length != 0 && storage_places !== false">
								<input type="text" v-model="search" placeholder="keresés.."/>
								<table class="table table-striped w-100">
									<thead>
										<th>Megnevezés</th>
										<th>Leírás</th>
										<th>Raktár</th>
										<th class="text-center">Műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(storage_place, id) in filteredStoragePlaces" :key="id">
											<td>
												{{storage_place.name}}
											</td>
											<td class="ellipsis-150">
												{{storage_place.description}}
											</td>
											<td>
												{{storage_place.storage_name}}
											</td>
											<td class="text-center" style="min-width:158px">
												<router-link :to="'storage_places/'+storage_place.id+'/edit'" class="btn btn-success mx-1 d-inline" title="szerkesztés"><i class="fa fa-pencil-alt"></i></router-link>
												<button v-if="storage_place.storage_id != 6" class="btn btn-danger mx-1 d-inline" @click="deleteStoragePlace(storage_place.id)" title="törlés"><i class="fa fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import StoragePlace from "../../apis/StoragePlace";

export default {
    components: { PageHeader },
    data() {
        return {
            storage_places: false,
						search:'',
        }
    },
		computed: {
			filteredStoragePlaces: function(){
				return this.storage_places.filter(place => {
					return place.name.toLowerCase().includes(this.search.toLowerCase())
				})
			},
		},
	
    created() {
			StoragePlace.index()
			.then(response => {
				this.storage_places = response.data;
			});

    },
    methods: {
		deleteStoragePlace(id) { 
			this.$confirm({
				message: `Biztos törlöd ezt a raktárhelyet?`,
				button: {
					no: 'Nem',
					yes: 'Igen'
				},
				/**
				* Callback Function
				* @param {Boolean} confirm
				*/
				callback: confirm => {
					if (confirm) {
						StoragePlace.delete(id)
						.then(() => {
							let i = this.storage_places.map(data => data.id).indexOf(id);
							this.storage_places.splice(i, 1)
						})
						.catch(error => {
								this.errors = error.response.data.errors;
						});
					}
				}
			})
				
			}
    }
};
</script>

<style>
</style>