import Api from "./Api";
import Csrf from "./Csrf";

export default {
	async upload(form) {
		
		await Csrf.getCookie();
		
		return Api.post('/photos/upload', form)
			// get data
			.then(x => x.data);
	},
	async show(parent_model,model_id) {
		
		await Csrf.getCookie();
		
		return Api.get('/photos/show/'+parent_model+'/'+model_id)
			// get data
			.then(x => x.data);
	},
	async delete(parent_model, model_id, image_id) {
		
		await Csrf.getCookie();
		
		return Api.get('/photos/delete/'+parent_model+'/'+model_id+'/'+image_id)
			// get data
			.then(x => x.data);
	}
}
