<template>
    <div class="footer row">
        <div class="col-12 text-right">
            ProduceIT &copy; 2022 K3Net Kft.
        </div>
        <!-- <div class="col-6 text-right">
            Peace cannot be kept by force; it can only be achieved by understanding <strong>Albert Einstein</strong>
        </div> -->
        
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style>

</style>