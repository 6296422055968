<template>
	<div>
		<div class="row wrapper border-bottom white-bg page-heading">
			<div class="col-sm-8">
				<h2> Értékesítés</h2>	
			</div>
			<div class="col-sm-4">
				<h2 v-if="partner != null">A választott partner: {{partner.name}} <button class="btn btn-danger mx-2 btn-sm" @click="noPartner"><i class="fa fa-times"></i> mégsem</button></h2>
			</div>
		</div>
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="partner_exists === null || product_exists === null">
								<div class="col-12 text-center">
									<h3>Partnerek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="product_exists === false">
								<div class="m-b-md">
									<i class="fa fa-cube fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek termékek
									</h3>
									<small>Legalább 1 termék kell az értékesítéshez</small><br>
										<router-link :to="'/products'" class="btn btn-xs btn-primary mt-1"><i class="fa fa-location-arrow"></i> termékekhez</router-link>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="partner_exists === false">
								<div class="m-b-md">
									<i class="fa fa-user fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek Vásárlók
									</h3>
									<small>Legalább 1 vásárló típusú partner kell az értékesítéshez</small><br>
										<router-link :to="'/partners'" class="btn btn-xs btn-primary mt-1"><i class="fa fa-location-arrow"></i> partnerekhez</router-link>
								</div>
							</div>
							<div class="row" v-if="(partner == null && partners.length != 0 && product_exists === true && partner_categories)">
								<div class="col-12 m-sm">
									<input type="text" v-model="partner_search" placeholder="keresés.."><br>
									<label v-for="(category, id) in partner_categories" :key="id" class="m-xs">
										<input type="checkbox" class="m-sm" v-model="category.selected" @change="partnerFilter()">
										<span :class="category.label_class"><i :class="category.icon_class"></i> {{category.name}} </span>
									</label>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-4 col-12" v-show="displayedCategoryIds.includes(partner.category)" v-for="(partner, id) in filteredPartnerList" :key="id">
									<div :class="partner_categories[partner_categories.map(data => data.id).indexOf(partner.category)].panel_class + ' panel'" @click="selectPartner(partner)">
										<div class="panel-heading">
											<i :class="partner_categories[partner_categories.map(data => data.id).indexOf(partner.category)].icon_class + ' float-right my-auto fa-3x d-none d-sm-none d-md-none d-lg-block'"></i>
											<h3>{{partner.name}}</h3>
										</div>
										<div class="panel-body py-0">
											<div class="row bg-muted">
												<div class="col-4"><h4 class="my-2">cím:</h4></div>
												<div class="col-8"><p class="my-2 font-italic">{{partner.city}}, {{partner.street}} {{partner.street_type}}, {{partner.number}}</p></div>
											</div>
											<div class="row bg-info">
												<div class="col-12"><h4 class="my-2">vásárlások száma: {{partner.order_sum}}</h4></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row m-sm d-none d-md-flex" v-if="partner != null">
								<div class="col-lg-5 col-sm-10">
									<div class="row">
										<div class="col-12 text-left">
											<button class="btn mx-2" @click="selling_mode = 1" :class="{'btn-white': selling_mode != 1, 'btn-primary border border-warning': selling_mode == 1}">Termék értékesítés</button>
											<button class="btn mx-2" @click="selling_mode = 2" :class="{'btn-white': selling_mode != 2, 'btn-primary border border-warning': selling_mode == 2}">Csomag értékesítés</button>
										</div>
									</div>
									<div class="row">
										<div class="col-12 text-left">
											<span class="label label-info"><i class="fa fa-info-circle"></i> Az értékesítési mód megváltoztatása törli a kosár tartalmát!</span>
										</div>
									</div>
									<!-- <h2 class="mx-2 d-inline-block">A választott partner:</h2>
									<h2 class="font-bold mx-2 d-inline-block">{{partner.name}}</h2>
									<button class="btn btn-danger py-auto mx-2 d-inline-block" @click="noPartner"><i class="fa fa-times"></i> mégsem</button> -->
								</div>
								<div class="col-lg-7 col-sm-2 text-right">
									<button class="btn btn-warning" @click="showBasket"><i class="fa fa-shopping-basket fa-2x"></i> <span class="h4">Kosár</span></button>

								</div>
							</div>
							<div class="row m-sm d-block d-md-none" v-if="partner != null">
								<!-- <div class="col-12 text-center">
									<p>A választott partner:</p>
								</div>
								<div class="col-12 text-center">
									<h4 class="font-bold">{{partner.name}}</h4>
								</div>
								<div class="col-12 text-center">
									<button class="btn btn-danger my-2 mx-2 btn-xs" @click="noPartner"><i class="fa fa-times"></i> mégsem</button>
								</div> -->
								
								<div class="col-12" v-if="partner != null && products.length != 0">
									<div class="row">
										<div class="col-12 text-center">
											<button class="btn mx-2" @click="selling_mode = 1" :class="{'btn-white': selling_mode != 1, 'btn-primary border border-warning': selling_mode == 1}">Termék értékesítés</button>
											<button class="btn mx-2" @click="selling_mode = 2" :class="{'btn-white': selling_mode != 2, 'btn-primary border border-warning': selling_mode == 2}">Csomag értékesítés</button>
										</div>
									</div>
									<div class="row">
										<div class="col-12 text-center">
											<span class="label label-info"><i class="fa fa-info-circle"></i> Az értékesítési mód megváltoztatása </span><br>
											<span class="label label-info">törli a kosár tartalmát!</span>
										</div>
									</div>
								</div>
								<div class="col-12 text-center">
									<button class="btn btn-warning" @click="showBasket"><i class="fa fa-shopping-basket fa-2x"></i> <span class="h4">Kosár</span></button>
								</div>
							</div>
							<div class="row" v-if="partner != null && products.length == 0">
								<div class="col-12 text-center">
									<h3>Termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="row" v-if="partner != null && products.length != 0">
								<div class="col-12">
									<div class="row" v-if="selling_mode == 1">
										<div class="col-12 m-sm">
											<div class="row">
												<div class="col-md-4">
													<h2>Termékek:</h2>
												</div>
												<div class="col-md-8">
													<div class="row">
														<div class="col-2">
															<div class="from-group">
																<input type="checkbox" true-value="1" false-value="0" v-model="only_with_stock">
																<label class="pl-2"> Csak raktáron lévő termékek</label>
															</div>
														</div>
														<div class="col-2">
															<div class="from-group">
																<input type="checkbox" true-value="1" false-value="0" v-model="only_with_price">
																<label class="pl-2"> Csak árral rendelkező termékek</label>
															</div>
														</div>
														<div class="col-8">
															<div class="input-group">
																<div class="input-group-prepend">
																	<span class="input-group-addon"><i class="fa fa-search"></i></span>
																</div>
																<input type="text" v-model="product_search" class="form-control form-control-lg font-weight-bold" placeholder="keresés..">
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-sm-6 col-12" v-for="(product, id) in filteredProductList" :key="id" v-show="productCardShow(product)">
											<div class="panel panel-success">
												<div class="panel-heading">
													<i class="fa fa-cube float-right my-auto fa-3x d-none d-sm-none d-md-none d-lg-block"></i>
													<h3>{{product.name}}</h3>
												</div>
												<div class="panel-body">
													<div class="widget  p-lg text-center" v-if="product.sizes.length == 0">
														<div class="m-b-md">
																<i class="fa fa-shopping-basket fa-4x"></i>
																<h1 class="m-xs"><i class="fa fa-times"></i></h1>
																<h3 class="font-bold no-margins">
																		Nincsenek kiszerelések
																</h3>
																<small>Hozzon létre kiszereléseket hogy értékesíteni tudjon</small>
														</div>
													</div>
													<ul class="list-group">
														<li class="list-group-item" v-for="(size, id) in product.sizes" :key="id" v-show="(only_with_price == 0 || size.prices[0]) && (only_with_stock == 0 || size.packaged_quantity != 0)">
															<div class="row">
																<div class="col-lg-5">
																	{{size.name}}
																</div>
																<div class="col-lg-7">
																	<form @submit.prevent="addToBasket(size)" v-if="size.prices[0]">
																		<div class="input-group">
																			<input type="number" @wheel="$event.target.blur()" class="form-control text-right" v-model="size.selling_quantity">
																			<div class="input-group-append">
																				<span class="input-group-addon">/ {{size.packaged_quantity}}</span>
																				<button type="submit" class=" btn btn-success" v-bind:disabled="size.packaged_quantity === 0"><i class="fa fa-shopping-cart"></i></button>
																			</div>
																		</div>
																	</form>
																	<div class="input-group" v-if="!size.prices[0]">
																		<input type="number" @wheel="$event.target.blur()" disabled class="form-control text-right" v-model="size.selling_quantity">
																		<div class="input-group-append">
																			<span class="input-group-addon">/ {{size.packaged_quantity}}</span>
																			<button class="btn btn-warning" @click="priceSet(size,product.name)"><i class="fa fa-exclamation-triangle"></i> <i class="fa fa-dollar-sign"></i></button>
																		</div>
																	</div>
																</div>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div class="row" v-else>
										<div class="col-12 m-sm">
											<h2>Csomagok:</h2>
										</div>
										<div class="col-lg-3 col-sm-6 col-12" v-for="(bundle, id) in bundles" :key="id">
											<div class="panel panel-success">
												<div class="panel-heading">
													<i class="fa fa-gift float-right my-auto fa-3x d-none d-sm-none d-md-none d-lg-block"></i>
													<h3>{{bundle.name}}</h3>
												</div>
												<div class="panel-body">
													<div class="widget  p-lg text-center" v-if="bundle.size_ids.length == 0">
														<div class="m-b-md">
																<i class="fa fa-shopping-basket fa-4x"></i>
																<h1 class="m-xs"><i class="fa fa-times"></i></h1>
																<h3 class="font-bold no-margins">
																		Nincsenek tételek
																</h3>
																<small>Adjon termékeket a csomaghoz hogy értékesíteni tudjon</small>
														</div>
													</div>
													<ul class="list-group">
														<li class="list-group-item" v-for="(size, id) in bundle.size_ids" :key="id">
															<div class="row">
																<div class="col-lg-6">
																	{{size.product_full_name}}
																</div>
																<div class="col-lg-6">
																		<div class="input-group">
																			<div class="input-group-prepend">
																				<span class="input-group-addon" :class="{'bg-primary': bundle.selling_quantity <= size.packaged_quantity, 'bg-danger': bundle.selling_quantity > size.packaged_quantity}"><i class="fa" :class="{'fa-check': bundle.selling_quantity <= size.packaged_quantity, 'fa-times': bundle.selling_quantity > size.packaged_quantity}"></i></span>
																			</div>
																			<span class="form-control text-center ">{{bundle.selling_quantity}}</span>
																			<div class="input-group-append">
																				<span class="input-group-addon"> / {{size.packaged_quantity}}</span>
																			</div>
																		</div>
																</div>
															</div>
														</li>
														<li class="list-group-item border border-success px-3" v-if="bundle.size_ids.length != 0">
															<form @submit.prevent="addBundleToBasket(bundle)">
																<div class="row px-3 text-center">
																	<h4>Csomagból értékesíteni kívánt mennyiség:</h4>
																</div>
																<div class="row px-3 text-center">
																	<div class="input-group">
																		<input type="text" v-model="bundle.selling_quantity" class="form-control text-dark text-right" required>
																		<input type="text" class="d-none">
																		<div class="input-group-append">
																			<span class="input-group-addon text-dark">db</span>
																		</div>
																	</div>
																</div>
																<div class="row px-3 text-center">
																	<button type="submit" v-if="bundle.prices.filter(p => p.price_category_id == partner.price_category_id ).length != 0" class="btn btn-success w-100" :disabled="bundle.size_ids.filter(s => s.packaged_quantity < bundle.selling_quantity).length != 0"><i class="fa fa-shopping-cart"></i></button>
																	<button type="button" @click="bundlePriceSet(bundle)" v-else class="btn btn-warning w-100 text-white"><i class="fa fa-exclamation-triangle"></i> <i class="fa fa-dollar-sign"></i></button>
																</div>
															</form>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="basket_modal" v-if="basket.partner_id != null">
			<modal-vue modalSize="xl" @on-close="$vm2.close('basket_modal')" name="basket_modal" :headerOptions="{ title: 'Kosár'}" :footerOptions="{
			btn1: 'vissza',
			btn2: 'Eladás',
			disableBtn2: loading,
			btn2OnClick: () => {
				finishSelling();
			},
			btn1OnClick: () => {
				$vm2.close('basket_modal');
			},
		}">
				<div align="center" class="col-12" v-show="basket.items.length > 0">
					<h3>Termékek:</h3>
					<table class="table table-striped table-hover">	
						<thead>
							<tr class="table-active">
								<th>Sorszám</th>
								<th>Termék</th>
								<th>Mennyiség</th>
								<th>Beker. egységár</th>
								<th>Egységár</th>
								<th>Beker. érték</th>
								<th>Érték</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item,id) in basket.items" :key="id">
								<td>{{id+1}}</td>
								<td>{{item.full_name}}</td>
								<td>{{item.selling_quantity}} / {{item.packaged_quantity}} db</td>
								<td>{{ item.factory_cost.toLocaleString('hu-HU') }} Ft</td>
								<td>{{item.prices[0].price.toLocaleString('hu-HU')}} Ft</td>
								<td>{{item.factory_cost * item.selling_quantity}} Ft</td>
								<td>{{item.prices[0].price * item.selling_quantity}} Ft</td>
								<td><button class="btn btn-xs btn-danger" title="törlés" @click="deleteFromBasket(id)"><i class="fa fa-trash"></i></button></td>
							</tr>
						</tbody>
						<tfoot>
							<tr class="table-active">
								<th>Összesen</th>
								<td></td>
								<td>{{basket_db}} db</td>
								<td colspan="3"></td>
								<td>{{basket_price.toLocaleString('hu-HU')}} Ft</td>
								<td></td>
							</tr>
						</tfoot>
					</table>
					<div class="row">
						<div class="col-6 font-bold form-control text-left py-auto">Partner:</div>
						<div class="col-6 form-control text-right py-auto">{{partner.name}}</div>
					</div>
					<div class="row">
						<div class="col-6 font-bold form-control text-left py-auto" form-control>Szállítási cím:</div>
						<div class="col-6 form-control text-right py-auto">{{partner.city}}, {{partner.street}} {{partner.street_type}}, {{partner.number}}</div>
					</div>
				</div>
				<div align="center" class="col-12" v-show="basket.bundles.length > 0">
					<h3>Csomagok:</h3>
					<table class="table table-striped table-hover">	
						<thead>
							<tr class="table-active">
								<th>Sorszám</th>
								<th>Termék</th>
								<th>Mennyiség</th>
								<th>Egységár</th>
								<th>Érték</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item,id) in basket.bundles" :key="id">
								<td>{{id+1}}</td>
								<td>{{item.name}}</td>
								<td>{{item.selling_quantity}} db</td>
								<td>{{item.prices[item.prices.map(p => p.price_category_id).indexOf(partner.price_category_id)].price.toLocaleString('hu-HU')}} Ft</td>
								<td>{{item.prices[item.prices.map(p => p.price_category_id).indexOf(partner.price_category_id)].price * item.selling_quantity}} Ft</td>
								<td><button class="btn btn-xs btn-danger" title="törlés" @click="deleteFromBasket(id)"><i class="fa fa-trash"></i></button></td>
							</tr>
						</tbody>
						<tfoot>
							<tr class="table-active">
								<th>Összesen</th>
								<td></td>
								<td>{{basket_db}} db</td>
								<td></td>
								<td>{{basket_price}} Ft</td>
								<td></td>
							</tr>
						</tfoot>
					</table>
					<div class="row">
						<div class="col-6 font-bold form-control text-left py-auto">Partner:</div>
						<div class="col-6 form-control text-right py-auto">{{partner.name}}</div>
					</div>
					<div class="row">
						<div class="col-6 font-bold form-control text-left py-auto" form-control>Szállítási cím:</div>
						<div class="col-6 form-control text-right py-auto">{{partner.city}}, {{partner.street}} {{partner.street_type}}, {{partner.number}}</div>
					</div>
				</div>
			</modal-vue>
		</div>
		<div id="price_set_modal" >
			<modal-vue modalSize="sm" @on-close="$vm2.close('price_set_modal')" name="price_set_modal" :headerOptions="{ title: 'Ár megadás'}" :footerOptions="{
			btn1: 'vissza',
			btn2: 'Ár beállítása',
			btn2OnClick: () => {
				addPrice();
			},
			btn1OnClick: () => {
				$vm2.close('price_set_modal');
			},
		}">
				<div align="center" class="col-12">
					<h3>{{size_for_price.product_name}}</h3>
					<h5>{{size_for_price.name}} ára:</h5>
					<div class="input-group">
						<input type="number" @wheel="$event.target.blur()" class="form-control text-right" v-model="size_for_price.price">
						<div class="input-group-append">
							<span class="input-group-addon">Ft</span>
						</div>
					</div>
				</div>
			</modal-vue>
		</div>
		<div id="bundle_price_set_modal" >
			<modal-vue modalSize="sm" @on-close="$vm2.close('bundle_price_set_modal')" name="bundle_price_set_modal" :headerOptions="{ title: 'Ár megadás'}" :footerOptions="{
			btn1: 'vissza',
			btn2: 'Ár beállítása',
			btn2OnClick: () => {
				addBundlePrice();
			},
			btn1OnClick: () => {
				$vm2.close('bundle_price_set_modal');
			},
		}">
				<div align="center" class="col-12">
					<h3>{{bundle_for_price.name}} ára:</h3>
					<div class="input-group">
						<input type="number" @wheel="$event.target.blur()" class="form-control text-right" v-model="bundle_for_price.price">
						<div class="input-group-append">
							<span class="input-group-addon">Ft</span>
						</div>
					</div>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
	import Partner from '../../apis/Partner';
	import Product from '../../apis/Product';
	import Order from '../../apis/Order';
	import Config from '../../apis/Config';
	import Price from '../../apis/Price';
	import Bundle from '../../apis/Bundle';

	export default {
		data() {
			return {
				partners: [],
				partner_categories: [],
				partner: null,
				size_for_price: {},
				bundle_for_price: {},
				products: [],
				basket: {
					partner_id: null,
					items: [],
					bundles: [],
				},
				partner_search: '',
				product_search: '',
				product_exists: null,
				partner_exists: null,
				bundles: null,
				selling_mode: 1,
				only_with_stock: 1,
				only_with_price: 1,
				loading:false,
			}
		},
		watch:{
			selling_mode: function(){
				this.basket.items = [];
				this.basket.bundles = [];
			}
		},
		computed: {
			basket_db: {
				get: function(){
					if(this.basket.items.length > 0){
						return this.basket.items.reduce(function (r, a) {
							return r + parseInt(a.selling_quantity);
						},0 );
					}else{
						return this.basket.bundles.reduce(function (r, a) {
							return r + parseInt(a.selling_quantity);
						},0 );
					}
				},
				set: function(barmi){
					return barmi;
				}
			},
			basket_price: {
				get: function(){
					if(this.basket.items.length > 0){
						return this.basket.items.reduce(function (r, a) {
							return r + (parseInt(a.prices[0].price) * a.selling_quantity);
						},0 );
					}else{
						var vue = this;
						return this.basket.bundles.reduce(function (r, a) {
							return r + (parseInt(a.prices[a.prices.map(p => p.price_category_id).indexOf(vue.partner.price_category_id)].price) * a.selling_quantity);
						},0 );
					}
				},
				set: function(barmi){
					return barmi;
				}
			},
			filteredPartnerList: {
				get: function(){
					return this.partners.filter(partner => {
						return partner.name.toLowerCase().includes(this.partner_search.toLowerCase())
					})
				},
				set: function(barmi){
					return barmi;
				}
			},
			filteredProductList: {
				get: function(){
					return this.products.filter(product => {
						return product.name.toLowerCase().includes(this.product_search.toLowerCase())
					})
				},
				set: function(barmi){
					return barmi;
				}
			},
			displayedCategoryIds: {
				get: function(){
					return this.partner_categories.filter(category => {
						return category.selected == true;
					}).map(data => data.id);
				},
				set: function(barmi){
					return barmi;
				}
			},
		},
		created() {
			Config.getConstant('partner_categories')
				.then(response => {
					this.partner_categories = response.data.filter(data => {
						return data.partner_type_id == 2;
					});
					this.partner_categories.forEach(category => {
						this.$set(category,'selected',true);
					});
				});
			Partner.index(2)
				.then(response => {
					this.partners = response.data;
					//console.log('partnerö',this.partners);
					if(response.data.length > 0){
						this.partner_exists = true;
					}else{
						this.partner_exists = false;
					}
				});
			Product.clean_index()
			.then(response => {
				if(response.data.length > 0){
					this.product_exists = true;
				}else{
					this.product_exists = false;
				}
			});
			Bundle.index()
			.then(response => {
				this.bundles = response.data
				this.bundles.forEach(bundle => {
					this.$set(bundle,'selling_quantity',0);
				});
			});
		},
		methods: {
			productCardShow(product){
				if(product.sizes.length == 0){
					return false;
				}else if(this.only_with_price == 1 && product.sizes.filter(s => s.prices.find(p => p.price_category_id == this.partner.price_category_id)).length == 0){
					return false;
				}else if(this.only_with_stock == 1 && product.sizes.filter(s => s.packaged_quantity != 0).length == 0){
					return false;
				}else if(this.only_with_price == 1 && this.only_with_stock == 1 && product.sizes.filter(s => (s.packaged_quantity != 0 && s.prices.filter(p => p.price_category_id == this.partner.price_category_id).length != 0) ).length == 0 ){
					return false;
				}else{
					return true;
				}
			},
			selectPartner(partner){
				this.partner = partner;
				this.basket.partner_id = this.partner.id;
				Product.partnerIndex(this.partner.id)
				.then(response => {
					//console.log(response.data);
					this.products = Object.values(response.data);
					//console.log('productö',this.products);
				});
			},
			noPartner(){
				this.partner = null;
				this.basket.partner_id = null;
				this.basket.items = [];
				this.products = [];
				this.product_list = false;
			},
			addBundleToBasket(bundle){
				let existing_basket_item = this.basket.bundles[this.basket.bundles.map(data => data.id).indexOf(bundle.id)];
				if(bundle.selling_quantity < 0 || bundle.selling_quantity == null){
					this.$toast.open({
						message: 'Ennyit nem adhat a kosárhoz!',
						type: 'error',
						position: 'top-right'
					});
				}else{
					if(existing_basket_item == null){
						if(bundle.selling_quantity == 0){
							this.$toast.open({
								message: 'Kosárba  helyezéshez adjon meg menyiséget​!',
								type: 'error',
								position: 'top-right'
							});
						}else{
							this.basket.bundles.push(bundle);
							this.$toast.open({
								message: bundle.selling_quantity + ' db ' + bundle.name + ' hozzáadva a kosárhoz!',
								type: 'success',
								position: 'top-right'
							});
						}
					}else{
						if(bundle.selling_quantity == 0){
							this.basket.bundles.splice(this.basket.bundles.indexOf(existing_basket_item),1);
							this.$toast.open({
								message: existing_basket_item.name + ' törölve a kosárból',
								type: 'error',
								position: 'top-right'
							});
						}else{
							//console.log(bundle.selling_quantity);
							this.$set(existing_basket_item,'selling_quantity',bundle.selling_quantity);
							//console.log(this.basket.items);
							this.$toast.open({
								message: existing_basket_item.name + ' frissítve, kosárban: ' + existing_basket_item.selling_quantity + ' db',
								type: 'success',
								position: 'top-right'
							});
						}
					}
				}
				//console.log(this.basket);
			},
			addToBasket(size){
				let existing_basket_item = this.basket.items[this.basket.items.map(data => data.id).indexOf(size.id)];
				if(size.selling_quantity < 0 || size.selling_quantity == null || size.selling_quantity > size.packaged_quantity){
					this.$toast.open({
						message: '1 és ' + size.packaged_quantity + ' közti értéket adhat meg!',
						type: 'error',
						position: 'top-right'
					});
				}else{
					if(existing_basket_item == null){
						if(size.selling_quantity == 0){
							this.$toast.open({
								message: 'Kosárba  helyezéshez adjon meg menyiséget!',
								type: 'error',
								position: 'top-right'
							});
						}else{
							size.full_name = this.products[this.products.map(data => data.id).indexOf(size.product_id)].name + ' - ' + size.name;
							this.basket.items.push(size);
							this.$toast.open({
								message: size.selling_quantity + ' db ' + size.full_name + ' hozzáadva a kosárhoz!',
								type: 'success',
								position: 'top-right'
							});
						}
					}else{
						if(size.selling_quantity == 0){
							this.basket.items.splice(this.basket.items.indexOf(existing_basket_item),1);
							this.$toast.open({
								message: existing_basket_item.full_name + ' törölve a kosárból',
								type: 'error',
								position: 'top-right'
							});
						}else{
							//console.log(size.selling_quantity);
							this.$set(existing_basket_item,'selling_quantity',size.selling_quantity);
							//console.log(this.basket.items);
							this.$toast.open({
								message: existing_basket_item.full_name + ' frissítve, kosárban: ' + existing_basket_item.selling_quantity + ' db',
								type: 'success',
								position: 'top-right'
							});
						}
					}
				}
				//console.log(this.basket);
			},
			showBasket(){
				if(this.basket.items.length == 0 && this.basket.bundles.length == 0){
					this.$toast.open({
						message: 'A kosár üres',
						type: 'warning',
						position: 'top-right'
					});
				}else{
					this.$forceUpdate(this.basket);
					this.$vm2.open('basket_modal'); 
					//console.log('products:',this.products);
					//console.log('partner:',this.partner);
					//console.log('basket:',this.basket);
				}
			},
			priceSet(size,product_name){
				this.size_for_price = {
					price_category_id: this.partner.price_category_id,
					name: size.name,
					size_id: size.id,
					product_name: product_name,
					product_id: size.product_id,
				};
				this.$vm2.open('price_set_modal'); 
				//console.log(this.size_for_price);
			},
			bundlePriceSet(bundle){
				this.bundle_for_price = {
					price_category_id: this.partner.price_category_id,
					name: bundle.name,
					bundle_id: bundle.id,
				};
				this.$vm2.open('bundle_price_set_modal'); 
				//console.log(this.size_for_price);
			},
			addPrice(){
				Price.add(this.size_for_price)
				.then(response => {
					this.products[this.products.map(data => data.id).indexOf(this.size_for_price.product_id)].sizes[this.products[this.products.map(data => data.id).indexOf(this.size_for_price.product_id)].sizes.map(data => data.id).indexOf(this.size_for_price.size_id)].prices.push(response.data);
					this.$vm2.close('price_set_modal');
				});
			},
			addBundlePrice(){
				Price.add(this.bundle_for_price)
				.then(response => {
					this.bundles[this.bundles.map(b => b.id).indexOf(this.bundle_for_price.bundle_id)].prices.push(response.data);
					this.$vm2.close('bundle_price_set_modal');
				});
			},
			finishSelling(){
				this.loading = true;
				if(this.basket.items.length != 0){
					var basket_submit = {
						partner_id: this.basket.partner_id,
						items:[]
					}
					this.basket.items.forEach(item => {
						basket_submit.items.push({
							size_id: item.id,
							quantity: item.selling_quantity,
						}); 
					});
					//console.log(this.basket);
					Order.add(basket_submit)
					.then(() => {
						this.$vm2.close('basket_modal');
						this.noPartner();
						//console.log(response.data);
					}).finally(() => {
						this.loading = false;
					})
				}else{
					var basket_submit_bundle = {
						partner_id: this.basket.partner_id,
						bundles:[]
					}
					this.basket.bundles.forEach(item => {
						basket_submit_bundle.bundles.push({
							id: item.id,
							quantity: item.selling_quantity,
						}); 
					});
					Order.addBundle(basket_submit_bundle)
					.then(() => {
						new Promise(resolve => {
							this.$vm2.close('basket_modal');
							resolve('finished');
						}).then(() => {
							this.noPartner();
							//console.log(response.data);
							Bundle.index()
							.then(response => {
								this.bundles = response.data
								this.bundles.forEach(bundle => {
									this.$set(bundle,'selling_quantity',0);
								});
							}).finally(() => {
								this.loading = false;
							});
						})
					})
				}
			},
			deleteFromBasket(id){
				this.basket.items.splice(id,1);
				this.basket.bundles.splice(id,1);
				if(this.basket.items.length == 0 && this.basket.bundles.length == 0){
					this.$vm2.close('basket_modal');
				}
			}
		},
		beforeRouteLeave (to, from , next) {
			new Promise(resolve => {
				this.$vm2.close('basket_modal');
				resolve();
			}).then(() => {
				next();
			})
		}
	};
</script>

<style>
</style>