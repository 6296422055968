import Api from "./Api";
import Csrf from "./Csrf";

export default {
	
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/prices", form);
  },
	
	async index() {
    await Csrf.getCookie();

    return Api.get("/prices");
  },
	
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/prices/" + id);
  },  
	
  async update(id, array) {
    await Csrf.getCookie();

    return Api.patch("/prices/" + id, array);
  },  
};