<template>
	<div>
		<PageHeader title="Csomagolt termék napló">
			<h5 class="mt-1">
				<i class="fa fa-info-circle"></i> 
				<b> A csomagolást nem igénylő termékek nem kerülnek be a naplóba</b>
			</h5>
		</PageHeader>
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="packagings === false">
								<div class="col-12 text-center">
									<h3>Csomagolt termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="packagings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek csomagolt termékek
									</h3>
									<small>hozzon létre csomagolt terméket</small>
								</div>
							</div>
							<div class="table-responsive" v-if="packagings.length != 0 && packagings !== false">
								<vue-good-table
									styleClass="vgt-table striped" 
									rowStyleClass="fs-12"
									:columns="columns" 
									:rows="packagings" 
									:search-options="{ enabled: false, placeholder: 'Keresés...' }" 
									:pagination-options="this.$vueGTPaginationOptions"
								>
									<template slot="table-row" slot-scope="props">
										<span v-if="props.column.field == 'actions'">
											<button class="btn btn-success mx-1 d-inline" title="napló" @click="openModal(props.row)"><i class="fa fa-book-open"></i></button>
											<button class="btn btn-warning mx-1 d-inline" title="nyomonkövetés" @click="openTracking(props.row)"><i class="fa fa-history" aria-hidden="true"></i>
</button>
										</span>
										<span v-else-if="props.column.field == 'factory_cost'">
											{{props.row.factory_cost.toLocaleString('hu-HU')}} Ft
										</span>
										<span v-else>
											{{props.formattedRow[props.column.field]}}
										</span>
									</template>
								</vue-good-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal" >
			<modal-vue modalSize="xl" @on-close="modalClose()" name="packaging_modal" :headerOptions="{ title: packaging.name ? packaging.name : (packaging.product_name+'-'+packaging.size_name) + ' napló', }" :footerOptions="{
			btn1: 'Bezár',
			disableBtn2: true,
			btn1OnClick: () => {
				$vm2.close('packaging_modal');
			},
		}">
				<div align="center" class="col-12">
					<div class="row" v-if="histories === false">
						<div class="col-12 text-center">
							<h3>Napló betöltése</h3>
							<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
						</div>
					</div>
					<div class="row" v-if="histories !== false">
						<div class="col-md-5">
							<h5>Adatok:</h5>
							<table class="table">
								<thead class="thead-light">
									<tr>
										<th>Gyártási szám</th>
										<th>Megnevezés</th>
										<th>Csomagolva</th>
										<th>Lejárat</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{packaging.production_number}}</td>
										<td>{{packaging.product_name+'-'+packaging.size_name}}</td>
										<td>{{packaging.created_date | moment('YYYY-MM-DD')}}</td>
										<td>{{packaging.filled_product.filling.intermediate_product_wt.best_before}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-7">
							<h5>Csomagolt termék:</h5>
							<table class="table table-striped">
								<thead class="thead-light">
									<tr>
										<th>Kiszerelés</th>
										<th>Tervezett mennyiség</th>
										<th>Csomagolt mennyiség</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{histories.packaging.size_name}}</td>
										<td>{{histories.packaging.expected_quantity}}</td>
										<td>{{histories.packaging.actual_quantity}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-12">
							<button class="btn btn-primary btn-outline" type="button" :class="{'active' : active == 1}" @click="active = 1">Napló</button>
							<button class="btn btn-primary btn-outline" type="button" :class="{'active' : active == 2}" @click="active = 2">Műveletek</button>
							<button class="btn btn-primary btn-outline" type="button" :class="{'active' : active == 3}" @click="active = 3">Töltési műveletek</button>
							<div class="table-responsive" v-if="this.active == 2" style="height:200px">
							<table class="table table-striped mt-4">
								<thead class="thead-dark">
									<tr>
										<th>Időpont</th>
										<th>Esemény</th>
										<th>Mennyiség</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(history,id) in histories.histories" :key="id">
										<td>{{history.created_date}}</td>
										<td>{{history.event_type}}</td>
										<td>{{history.quantity}}</td>
									</tr>
								</tbody>
							</table>
							</div>
							<div class="table-responsive" v-if="this.active == 1" style="height:200px">
								<table class="table table-striped mt-4">
									<thead class="thead-dark">
										<tr>
											<th>Időpont</th>
											<th>Esemény</th>
											<th>Felhasználó</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(log,id) in histories.logs" :key="id">
											<td>{{log.created_date}}</td>
											<td>{{log.description}}</td>
											<td>{{log.user_name}}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="table-responsive" v-if="this.active == 3" style="height:200px">
								<table class="table table-striped mt-4">
									<thead class="thead-dark">
										<tr>
											<th>Időpont</th>
											<th>Esemény</th>
											<th>Göngyöleg</th>
											<th>Mennyiség</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(wph,id) in histories.wrappingHistories" :key="id"> <!--  v-show="histories.packaged_products[histories.packaged_products.map(packaged_product => packaged_product.packaging_id).indexOf(wph.related_model_id)].show" -->
											<td>{{wph.created_date}}</td>
											<td>{{wph.event_type}}</td>
											<td>{{wph.name}}</td>
											<td>{{wph.quantity}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</modal-vue>
		</div>
		<div id="modal" >
			<modal-vue modalSize="xl" @on-close="trackingClose()" name="tracking_modal" :headerOptions="{ title: packaging.name ? packaging.name + ' nyomonkövetés' : (packaging.product_name+'-'+packaging.size_name) + ' nyomonkövetés', }" :footerOptions="{
				btn1: 'Bezár',
				disableBtn2: true,
				btn1OnClick: () => {
					$vm2.close('tracking_modal');
				},
			}">
				<div class="px-2">
					<div class="text-center" v-if="!tracking_histories"><i class="fa fa-cog fa-spin fa-3x"></i><br><b>Nyomonkövetés betöltése...</b></div>
					<div v-else>
						<div class="row">
							<div class="col-12">
								<div class="table-responsive">
									<table class="table">
										<thead>
											<tr>
												<th>Csomagolás időpontja</th>
												<th>Töltés időpontja</th>
												<th>Gyártási szám</th>
												<th>Lejárat</th>
												<th>Gyártás időpontja</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{{tracking_histories.packaging_data.created_at | moment('YYYY-MM-DD')}}</td>
												<td>{{tracking_histories.packaging_data.filled_at | moment('YYYY-MM-DD')}}</td>
												<td>{{tracking_histories.packaging_data.production_number}}</td>
												<td>{{tracking_histories.packaging_data.best_before}}</td>
												<td>{{tracking_histories.packaging_data.finished_at | moment('YYYY-MM-DD')}}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<h5 class="text-center">Gyártáshoz használt alapanyagok:</h5>
								<div class="tableFixHead">
									<table class="table">
										<thead class="thead-dark">
											<tr>
												<th>Megnevezés</th>
												<th>Vonalkód</th>
												<th>felhasznált mennyiség</th>
												<th>lejárat</th>
												<th>Beszállító</th>
												<th>Batch szám</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(container,id) in tracking_histories.production" :key="id">
												<td>{{container.material_name}}</td>
												<td>{{container.barcode}}</td>
												<td>{{container.used_quantity}}</td>
												<td>{{container.best_before}}</td>
												<td>{{container.partner_name}}</td>
												<td>{{container.batch_number}}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Log from "../../apis/Log";

export default {
    components: { PageHeader },
    data() {
        return {
					packagings: false,
					packaging: {},
					errors: [],
					histories:false,
					tracking_histories:false,
					active: 1,
					columns:[
						{
							label:'Megnevezés',
							field:'name',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Csomagolva',
							field:'created_date',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'1 db ára',
							field:'factory_cost',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label: 'Műveletek',
							field: 'actions',
							width: '15%',
							sortable: false,
							thClass: 'text-right fs-12',
							tdClass: 'text-right'
						}
					]
        }
    },
	
    created() {
			Log.packaged_product()
			.then(response => {
				this.packagings = response.data;
			});	
    },
    methods: {
			openModal(packaging) {
				this.packaging = packaging
				this.$vm2.open('packaging_modal')
				Log.log({type:'packaged_product',id:packaging.id})
				.then(response => {
					this.packaging = response.data.packaging;
					this.histories = response.data;
					if(this.histories.packaged_products){
						this.histories.packaged_products.forEach(packaged_product => {
							this.$set(packaged_product,'show',true);
						});
					}
					//console.log('histories:',this.histories);
					//console.log('packaging:',this.packaging);
				});
			},
			modalClose() {
				this.$vm2.close('packaging_modal');
				this.histories = false;
			},
			trackingClose() {
				this.$vm2.close('tracking_modal');
				this.histories = false;
			},
			openTracking(packaging){
				this.packaging = packaging
				this.$vm2.open('tracking_modal')
				Log.log({type:'tracking',id:packaging.id})
				.then(response => {
					this.tracking_histories = response.data;
				});
			}
    }
};
</script>

<style>
</style>