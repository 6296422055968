<template>
	<div>
		<PageHeader title="Bevételezés" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6 col-md-12">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Alapanyagok</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="materials === false">
								<div class="col-12 text-center">
									<h4>Alapanyagok betöltése</h4>
									<i class="fa fa-cog fa-spin fa-2x fa-fw"></i>
								</div>
							</div>
							<div class="widget  p-lg text-center" v-if="materials.length == 0">
								<div class="m-b-md">
										<i class="fa fa-balance-scale fa-4x"></i>
										<h1 class="m-xs"><i class="fa fa-times"></i></h1>
										<h3 class="font-bold no-margins">
												Nincsenek alapanyagok
										</h3>
										<small>Adjon hozzá alapanyagokat hogy bevételezhessen</small><br>
										<router-link :to="'/materials'" class="btn btn-xs btn-primary mt-1"><i class="fa fa-location-arrow"></i> alapanyagokhoz</router-link>
								</div>
							</div>
							<div class="table-responsive" v-if="materials !== false && materials.length != 0">
								<input type="text" v-model="materialSearch" placeholder="keresés.."/>
								<table class="table table-striped w-100">
									<thead>
										<th>Megnevezés</th>
										<th class="d-none d-sm-none d-md-table-cell">INCI</th>
										<th class="text-center">
											<span class="d-none d-sm-table-cell">Aktuális készlet</span>
											<span class="d-table-cell d-sm-none">Készlet</span>
										</th>										
										<th class="text-center d-none d-sm-none d-md-table-cell"><span class="d-none d-sm-block">Mennyiségi egység</span></th>
										<th class="d-none d-sm-table-cell text-center">Raktári hely</th>
										<th class="text-center">műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(material, id) in filteredMaterials" :key="id" >
											<td>
												{{material.name}}
											</td>
											<td class="d-none d-sm-none d-md-table-cell">
												{{material.inci}}
											</td>
											<td class="text-right">
												{{material.quantity}}
											</td>
											<td class="text-right d-none d-sm-none d-md-table-cell">
												{{material.unit}}
											</td>
											<td class="text-right d-none d-sm-table-cell">
												{{material.storage_place_name}}
											</td>
											<td class="text-center">
												<router-link :to="'material_containers/'+material.id+'/create'" class="btn btn-success btn-xs mx-1 d-inline" title="bevételezés"><i class="fa fa-cubes"></i><span class="d-none"> Bevételezés</span></router-link>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-md-12">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Göngyölegek</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="wrappings === false">
								<div class="col-12 text-center">
									<h4>Göngyölegek betöltése</h4>
									<i class="fa fa-cog fa-spin fa-2x fa-fw"></i>
								</div>
							</div>
							<div class="widget  p-lg text-center" v-if="wrappings.length == 0">
								<div class="m-b-md">
										<i class="fa fa-gift fa-4x"></i>
										<h1 class="m-xs"><i class="fa fa-times"></i></h1>
										<h3 class="font-bold no-margins">
												Nincsenek göngyölegek
										</h3>
										<small>Adjon hozzá göngyölegeket hogy bevételezhessen</small><br>
										<router-link :to="'/wrappings'" class="btn btn-xs btn-primary mt-1"><i class="fa fa-location-arrow"></i> göngyölegekhez</router-link>
								</div>
							</div>
							<div class="table-responsive" v-if="wrappings !== false && wrappings.length != 0">
								<input type="text" v-model="wrappingSearch" placeholder="keresés.."/>
								<table class="table table-striped w-100">
									<thead>
										<th>Megnevezés</th>
										<th class="d-none d-lg-table-cell">Leírás</th>
										<th class="text-right">
											<span class="d-none d-sm-block">Aktuális készlet</span>
											<span class="d-block d-sm-none">Készlet</span>
										</th>
										<th class="text-center">műveletek</th>
									</thead>
									<tbody>
										<tr v-for="(wrapping, id) in filteredWrappings" :key="id">
											<td>
												{{wrapping.name}}
											</td>
											<td class="d-none d-lg-table-cell">
												{{wrapping.description}}
											</td>
											<td class="text-right">
												{{wrapping.quantity}}
											</td>
											<td class="text-right d-none">
												{{wrapping.size}}
											</td>
											<td class="text-center">
												<button class="btn btn-xs btn-success mx-1 d-inline" title="bevételezés" @click="openModal(wrapping.id)"><i class="fa fa-cubes"></i> <span class="d-none">Bevételezés</span></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal" >
			<modal-vue modalSize="l" @on-close="modalClose" name="wrapping_modal" :headerOptions="{ title: wrapping.name + ' bevételezése', }" :footerOptions="{
			btn1: 'Mégse',
			btn2: 'Bevételezés',
			btn2OnClick: () => {
				addWrapping();
			},
			btn1OnClick: () => {
				$vm2.close('wrapping_modal');
			},
		}">
				<div align="center" class="col-12">
						<div class="row" v-if="partners === false">
							<div class="col-12 text-center">
								<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
							</div>
						</div>
						<div class="widget p-lg text-center" v-if="partners.length == 0">
							<div class="m-b-md">
								<i class="fa fa-user fa-4x"></i>
								<h1 class="m-xs"><i class="fa fa-times"></i></h1>
								<h3 class="font-bold no-margins">
									Nincsenek partnerek
								</h3>
								<small>Csak akkor bevételezhet göngyöleget ha van legalább 1 beszállító partner</small>
							</div>
						</div>
						<form @submit.prevent="addWrapping" v-if="partners !== false && partners.length != 0">
							<div class="form-group">
								<label>Mennyiség</label>
								<input type="text" class="form-control" v-model="formWrapping.quantity">
								<span class="label label-danger" v-if="errors.quantity">
										{{ errors.quantity[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Teljes mennyiség nettó ára</label>
								<div class="input-group">
									<input type="text" class="form-control" v-model="formWrapping.price">
									<span class="input-group-addon">
											<span>Ft</span>
									</span>
								</div>
								<span class="label label-danger" v-if="errors.price">
										{{ errors.price[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Szállítási díj</label>
								<div class="input-group">
									<input type="text" class="form-control" v-model="formWrapping.shipping_price">
									<span class="input-group-addon">
											<span>Ft</span>
									</span>
								</div>
								<span class="label label-danger" v-if="errors.shipping_price">
										{{ errors.shipping_price[0] }}
								</span>
							</div>
							<div class="form-group">
								<label>Beszállító</label>
								<v-select v-model="formWrapping.partner_id" :from="partners" as="name:id:id" tagging></v-select>
								<span class="label label-danger" v-if="errors.partner_id">
										{{ errors.partner_id[0] }}
								</span>
							</div>
						</form>
					</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Material from "../../apis/Material";
import Wrapping from "../../apis/Wrapping";
import Partner from "../../apis/Partner";
import { vSelect } from '@desislavsd/vue-select'

export default {
    components: { PageHeader , vSelect},
    data() {
        return {
            materials: false,
						wrappings: false,
						wrapping: {},
						formWrapping: {
							partner_id: null,
							shipping_price: 0,
						},
						partners: false,
						errors: [],
						wrappingSearch: '',
						materialSearch: '',
        }
    },
		computed: {
			filteredWrappings: function(){
				return this.wrappings.filter(wrapping => {
					return wrapping.name.toLowerCase().includes(this.wrappingSearch.toLowerCase())
				})
			},
			filteredMaterials: function(){
				return this.materials.filter(material => {
					return material.name.toLowerCase().includes(this.materialSearch.toLowerCase())
				})
			},
		},
    created() {
        Material.index()
			.then(response => {
				this.materials = response.data;
			}).then(() => {
					//console.log(this.materials);
				});
			Wrapping.index()
			.then(response => {
				this.wrappings = response.data;
				//console.log(this.wrappings);
			});
			Partner.index(1,2)
			.then(response => {
				this.partners = response.data;
			})

    },
    methods: {
			openModal(id) {
				Wrapping.show(id)
				.then(response => {
					this.wrapping = response.data
				}).then(() => {
					//console.log(this.wrapping);
					this.$set(this.formWrapping,'price',this.wrapping.last_price);
					this.$set(this.formWrapping,'partner_id',this.wrapping.last_partner_id);
					//console.log(this.formWrapping);
					this.$vm2.open('wrapping_modal')
				});
			},
			addWrapping() {
				//console.log(this.formWrapping);
				if(this.formWrapping.price > 2147483647 || this.formWrapping.quantity > 2147483647 || this.formWrapping.price <= 0 || this.formWrapping.quantity <= 0){
					this.$toast.open({
						message: '1 és 2147483647 számokat adhat meg',
						position: 'top-right',
						type:'error'
					})
				}else{
					Wrapping.supply(this.wrapping.id, this.formWrapping)
					.then(() => {
						let i = this.wrappings.map(data => data.id).indexOf(this.wrapping.id);
						this.wrappings[i].quantity = parseInt(this.wrappings[i].quantity) + parseInt(this.formWrapping.quantity);
						//this.wrappings[i].quantity += this.formWrapping.quantity.parseInt();
						this.modalClose();
					})
					.catch(error => {
							this.errors = error.response.data.errors;
					})
				}
				
			},
			modalClose() {
				this.$vm2.close('wrapping_modal');
					this.formWrapping = {};
			}
    }
};
</script>

<style>
</style>