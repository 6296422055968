<template>
	<div>
		<PageHeader :title="product.name + ': ' + product.size_name + ' csomagolás'" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-content">
							<div v-if="polcform">
								<h2>Vegye el az alábbi termékeket a raktárhelyekről</h2>
								<div class="table-responsive">
									<table class="table">
										<thead>
											<tr>
												<th>Megnevezés</th>
												<th>Raktárhely</th>
												<th>darabszám</th>
												<th class="text-right">megvan</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(wrapping, id) in wrappings" :key="id">
												<td>{{wrapping.name}}</td>
												<td>{{wrapping.place_name}}</td>
												<td class="text-center">{{wrapping.quantity}}</td>
												<td class="text-right"><input @change="wrappingFound(wrapping)"  type="checkbox" v-model="wrapping.status"></td>
											</tr>
										</tbody>
									</table>							
								</div>
								<div class="text-center" v-if="button_show">
									<button class="btn btn-primary" @click="showFillForm"> tovább a csomagolásra</button>
								</div>
							</div>
							<div v-if="fill_form">
								<form @submit.prevent="FinishPackaging">
									<div class="table-responsive">
										<table class="table">
											<thead>
												<tr>
													<th>megnevezés</th>
													<th>várt</th>
													<th class="text-right">felhasznált</th>
												</tr>
											</thead>
											<tbody>
												<tr class="bg-primary">
													<td>{{product.name}}: {{product.size_name}}</td>
													<td>{{product.expected_quantity}}</td>
													<td>
														<div class="input-group m-b" style="min-width: 100px;">
															<input type="number" step="1" class="form-control form-control-sm text-right text-dark" v-model="product.used_quantity">
															<div class="input-group-append">
																<span class="input-group-addon text-dark">db</span>
															</div>
														</div>
													</td>
												</tr>
												<tr class="bg-muted" v-for="(wrapping, id) in wrappings" :key="id">
													<td>{{wrapping.name}}</td>
													<td>{{wrapping.quantity}}</td>
													<td class="mw-2">
														<div class="input-group m-b ">
															<input type="number" step="1" class="form-control form-control-sm text-right" v-model="wrapping.used_quantity">
															<div class="input-group-append">
																<span class="input-group-addon">db</span>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div class="text-center">
										<loading-button :loading="loading"><i class="fa fa-check"></i> Befejezés</loading-button>
									</div>
								</form>
							</div>
							<div v-if="placing_guide">
								<div class="widget  p-lg text-center">
									<div class="m-b-md">
										<i class="fa fa-check-circle fa-4x"></i>
										<h2 class="m-xs">Helyezze a csomagolt</h2>
										<h2> {{product.name}} - {{product.size_name}}</h2>
										<h2 class="font-bold no-margins">
											{{product.place_name}}
										</h2>
										<h3>raktárhelyre</h3>
										<router-link :to="'/packaging'" class="btn btn-primary m-sm"><i class="fa fa-check"></i> Befejezés</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Packaging from "../../apis/Packaging";

export default {
    components: { PageHeader },
    data() {
        return {
					wrappings: [],
					polcform: true,
					button_show: false,
					fill_form: false,
					placing_guide: false,
					product: {},
					error: 0,
					loading: false,
        }
    },
		computed: {
			to_do_counter: function () {
				return this.wrappings.filter( obj => {
					return obj.status != true
				}).length
			}
		},
	
    created() {
					Packaging.show(this.$route.params.id)
					.then(response => {
						//console.log('RESp',response.data);
						this.product = {
							name: response.data.product_name,
							place_name: response.data.product_place_name,
							size_name: response.data.size_name,
							expected_quantity: response.data.expected_quantity,
							used_quantity: response.data.expected_quantity,
						}
						this.wrappings = response.data.wrappings;
						this.wrappings.forEach(wrapping => {
							wrapping.used_quantity = wrapping.quantity;
						});
					}).then(() => {
						//console.log(this.wrappings);
						if(this.to_do_counter == 0){
							this.polcform = false;
							this.fill_form = true;
						}else{
							if(this.to_do_counter < this.wrappings.length){
								this.polcform = true;
							}
							this.button_show = false;
						}
						//console.log('wrepp', this.wrappings);
					});
    },
    methods: {
			wrappingFound(wrapping){
				//console.log(wrapping);
				if(this.to_do_counter == 0){
					//console.log(this.to_do_counter);
					this.button_show = true;
				}else{
					this.button_show = false;
				}
				if(wrapping.status == true){
					Packaging.createHistory({packaging_id:parseInt(this.$route.params.id),wrapping_id:wrapping.wrapping_id,quantity:wrapping.quantity});
				}else{
					Packaging.deleteHistory({packaging_id:parseInt(this.$route.params.id),wrapping_id:wrapping.wrapping_id,quantity:wrapping.quantity});
				}
			},
			
			showFillForm(){
				this.polcform = false;
				this.fill_form = true;
			},
			
			FinishPackaging(){
				//let submit_array = [];
				this.loading = true;
				if(this.product.used_quantity > this.product.expected_quantity || this.product.expected_quantity < 0 ){
					this.error = 1;
					this.$toast.open({
						message : '0 és ' + this.product.expected_quantity + ' közötti számú terméket csomagolhat',
						type: 'error',
						position: 'top-right', 
					});
					this.loading = false;
				}else {
					this.wrappings.forEach(wrapping => {
						if(wrapping.used_quantity > wrapping.stock_quantity || wrapping.used_quantity < this.product.used_quantity){
							this.error = 1;
							this.$toast.open({
								message : this.product.used_quantity + ' és ' + wrapping.stock_quantity + ' közötti számú ' + wrapping.name + ' csomagolható',
								type: 'error',
								position: 'top-right', 
							});
							this.loading = false;
						}
					});
				}
				if(this.error != 1){
					//console.log(this.product);
					let post_array = {
						packaging_id: this.$route.params.id,
						quantity: this.product.used_quantity,
						wrappings: [],
					}
					
					this.wrappings.forEach(wrapping => {
						post_array.wrappings.push({
							id: wrapping.wrapping_id,
							quantity: wrapping.used_quantity
						});
					});
					
					Packaging.finish(post_array)
					.then(() => {
							this.fill_form = false;
							this.placing_guide = true;
					}).finally(() => {
						this.loading = false;
					});
				}
				this.error = 0;
			}
    }
};
</script>

<style>
</style>