<template>
	<div>
		<PageHeader title="Külsős Töltés" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-title">
							<h5>Tölthető félkész termékek</h5>
						</div>
						<div class="ibox-content">
							<div class="w-100 text-center" v-if="!products">
								<h3>Félkész termékek betöltése</h3>
								<i class="fa fa-cog fa-spin fa-2x"></i>
							</div>
							<div class="widget  p-lg text-center" v-else-if="products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-compass fa-4x"></i><br>
									<i class="fa fa-times fa-2x my-3"></i>
									<h1 class="m-xs">Nincs tölthető félkész termék</h1>
									<h3 class="font-bold no-margins">
											Nem található olyan félkész termék amely rendelkezne külsős töltésre küldhető kiszereléssel
									</h3>
								</div>
							</div>
							<div v-for="(product, id) in products" :key="id" v-else>
								<h3>{{product.name}}:</h3>
								<div class="table-responsive">
									<table class="table">
										<thead>
											<tr>
												<th><i class="fa fa-barcode"></i></th>
												<th>Vonalkód</th>
												<th>Lejárati dátum</th>
												<th>Mennyiség</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(intermediate_product, id) in product.containers" :key="id">
												<td><button @click="printBarcode(intermediate_product,product.name)" class="btn btn-primary" :title="intermediate_product.barcode+' újranyomtatása'"><i class="fa fa-barcode"></i></button></td>
												<td>{{intermediate_product.barcode}}</td>
												<td>{{intermediate_product.best_before}}</td>
												<td>{{intermediate_product.quantity}}</td>
												<td>
													<button @click="selectToFill(intermediate_product)" class="btn btn-success" title="küldés töltésre" ><i class="fa fa-compass"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div id="container_barcode" class="d-none">
									<div style="width:100%; text-align:center">
										<span style="font-size:0.6em">{{ barcode_div.name }}</span>
										<br>
										<div style="display:inline-block;width:30px;height:74px;clear:both;background-color:white;"><span style="color:white">....</span></div>
										<barcode v-bind:value="barcode_div.barcode" width="1" height="35" fontSize="7" style="display:inline-block;" marginTop="1">
											Hiba történt a vonalkód generálás során
										</barcode>
										<div style="display:inline-block;width:30px;height:74px;clear:both;background-color:white;"><span style="color:white">....</span></div>
										<div class="print-invisible">
											<button onclick="window.print();" class="btn mx-auto" style="color: #fff; background-color: #1ab394; border-color:#1ab394;">Újranyomtatás</button>
											<button onclick="window.close();" class="btn mx-auto" style="color: #fff; background-color: #ed5565; border-color: #ed5565;">Bezár</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="ibox">
						<div class="ibox-title">
							<h5>Folyamatban lévő töltések</h5>
						</div>
						<div class="ibox-content">
							<div class="w-100 text-center" v-if="!fillings">
								<h3>Töltések betöltése</h3>
								<i class="fa fa-cog fa-spin fa-2x"></i>
							</div>
							<div class="widget  p-lg text-center" v-else-if="fillings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-compass fa-4x"></i><br>
									<i class="fa fa-times fa-2x my-3"></i>
									<h1 class="m-xs">Nincs tölthető félkész termék</h1>
									<h3 class="font-bold no-margins">
											Nem található olyan félkész termék amely rendelkezne külsős töltésre küldhető kiszereléssel
									</h3>
								</div>
							</div>
							<div class="table-responsive" v-else>
								<table class="table">
									<thead>
										<tr>
											<th>Termék</th>
											<th>Kiküldve</th>
											<th>Mennyiség</th>
											<th>Vonalkód</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(filling,id) in fillings" :key="id">
											<td>{{filling.product_name}}</td>
											<td>{{filling.created_at}}</td>
											<td>{{filling.expected_quantity}}</td>
											<td>{{filling.intermediate_product_wt.barcode}}</td>
											<td>
												<button class="btn btn-primary" @click="finishFilling(filling)"><i class="fa fa-flag"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="select_modal" class="custom-modal">
			<modal-vue modalSize="md" @on-close="closeModal" name="select_modal" :headerOptions="{ title: 'Küldés külső töltésre'}" :footerOptions="{
			btn1: 'Vissza',
			btn2: 'Küldés',
			disableBtn2: loading,
			btn2OnClick: () => {
				$refs.sendfillform.click();
			},
			btn1OnClick: () => {
				closeModal();
			},
		}">
				<div class="text-center"  v-if="!intermediate_to_send">
					<i class="fa fa-cog fa-spin fa-3x"></i>
				</div>
				<div align="center" class="col-10 mx-auto" v-else>
					<form @submit.prevent="sendToFill()">
						<label class="form-label">Adja meg a töltésre küldésre felhasznált mennyiséget:</label>
						<input type="number" min="1" :max="intermediate_to_send.quantity" class="form-control" v-model="intermediate_to_send.quantity_to_use" required>
						<button type="submit" class="d-none" ref="sendfillform"></button>
					</form>
				</div>
			</modal-vue>
		</div>
		<div id="finish_modal" class="custom-modal">
			<modal-vue modalSize="lg" @on-close="closeFinishModal" name="finish_modal" :headerOptions="{ title: 'Külső töltés befejezése'}" :footerOptions="{
			btn1: 'Vissza',
			btn2: 'Befejezés',
			disableBtn2: loading,
			btn2OnClick: () => {
				$refs.sendfinsishform.click();
			},
			btn1OnClick: () => {
				closeFinishModal();
			},
		}">
				<div class="text-center"  v-if="!filling_to_finish">
					<i class="fa fa-cog fa-spin fa-3x"></i>
				</div>
				<div align="center" class="col-12 mx-auto" v-else style="max-height:400px;overflow:auto;">
					<h5>visszakapott kiszerelések:</h5>
					<div class="table-responsive">
						<form @submit.prevent="sendFinish()">
						<table class="table">
							<thead>
								<tr>
									<th>Kiszerelés</th>
									<th>Töltési tömeg</th>
									<th>Mennyiség</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(size, id) in filling_to_finish.product.sizes.filter(s => s.is_third_party_filling == 1)" :key="id">
									<td>{{size.name}}</td>
									<td>{{size.size}}</td>
									<td>
											<input type="number" class="form-control" v-model="size.quantity" required min="0">
									</td>
								</tr>
								<button class="d-none" type="submit" ref="sendfinsishform"></button>
							</tbody>
						</table>
						</form>
					</div>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import IntermediateProduct from "../../apis/IntermediateProduct";
import VueBarcode from 'vue-barcode';

export default {
    components: { PageHeader, 'barcode' : VueBarcode },
    data() {
        return {
			products:false,
			fillings:false,
			barcode_div:{
				name:'',
				barcode:'',
			},
			intermediate_to_send:false,
			filling_to_finish:false,
			loading:false,
        }
    },
	
    created() {
			this.initializer();
    },
    methods: {
			sendFinish(){
				this.loading = true;
				let summed = this.filling_to_finish.product.sizes.filter(s => s.is_third_party_filling == 1).reduce((a,o) => {
					return a + (o.size*o.quantity);
				}, 0);
				if(summed == 0){
					this.$toast.open({
						message: 'minimum 1 et adjon meg az egyik méretből!',
						type: 'error',
						position: 'top-right'
					});
					this.loading = false;
				}else if(summed > this.filling_to_finish.expected_quantity){
					this.$toast.open({
						message: 'A megadott mennyiség meghaladja az elküldött mennyiséget!',
						type: 'error',
						position: 'top-right'
					});
					this.loading = false;
				}else{
					IntermediateProduct.thirdPartyFillingFinish(this.filling_to_finish.id,{
						sizes: this.filling_to_finish.product.sizes.filter(s => s.is_third_party_filling == 1).map(r => ({
							size_id: r.id,
							quantity:r.quantity
						}))
					}).then(() => {
						this.closeFinishModal();
						this.initializer();
					}).finally(() => {
						this.loading = false;
					});
				}
				//console.log(summed,this.filling_to_finish.expected_quantity);
			},
			initializer(){
				IntermediateProduct.thirdPartyIndex()
				.then(response => {
					this.products = response.data;
				})
				IntermediateProduct.thirdPartyFillings()
				.then(response => {
					this.fillings = response.data;
				})
			},
			sendToFill(){
				this.loading = true;
				IntermediateProduct.thirdPartyFilling(this.intermediate_to_send.id,{quantity: this.intermediate_to_send.quantity_to_use})
				.then(() => {
					this.closeModal();
					this.initializer();
				}).finally(() => {
					this.loading = false;
				});
			},
			closeModal(){
				this.$vm2.close('select_modal');
				this.intermediate_to_send = false;
			},
			closeFinishModal(){
				this.$vm2.close('finish_modal');
				this.filling_to_finish = false;
			},
			selectToFill(ip){
				this.intermediate_to_send = JSON.parse(JSON.stringify(ip));
				this.intermediate_to_send.quantity_to_use = this.intermediate_to_send.quantity;
				this.$vm2.open('select_modal');
			},
			finishFilling(filling){
				this.filling_to_finish = JSON.parse(JSON.stringify(filling));
				this.filling_to_finish.product.sizes.forEach(s => {
					s.quantity = 0;
				})
				this.$vm2.open('finish_modal');
			},
			printBarcode(intermediate_product,product_name){
				this.setBarcodeDiv(product_name,intermediate_product.barcode).then(() =>{
					let printData = document.getElementById("container_barcode").innerHTML;
					let newTab = window.open();

					newTab.document.body.innerHTML = printData+'<style>.btn {padding:1em; font-size:2em; margin:2em; display:block;}.btn:focus { box-shadow: none;} @media print {.print-invisible{display:none;}}.mx-auto {  margin-right: auto !important;margin-left: auto !important;}</style>';
					newTab.print();
				})
			},
			setBarcodeDiv(name,barcode){
				let vue = this;
				return new Promise(function(resolve) { 
					vue.$set(vue.barcode_div,'name',name);
					vue.$set(vue.barcode_div,'barcode',barcode);
					resolve('finished')
				}); 
			},
    }
};
</script>

<style>
</style>