<template>
	<div>
		<PageHeader title="Göngyöleg selejtezés" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5><i class="fa fa-gift"></i> Göngyöleg raktár</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="wrappings === false">
								<div class="col-12 text-center">
									<h3>Göngyölegek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="wrappings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek göngyölegek
									</h3>
									<small>hozzon létre göngyöleget</small>
								</div>
							</div>
							<div v-if="wrappings.length != 0 && wrappings !== false">
								<input type="text" v-model="search" placeholder="keresés.." />
								<div class="tableFixHeadLarge">
									<table class="table table-striped w-100">
										<thead class="thead-dark">
											<th @click="sort('name')" class="text-nowrap pointer">
												Megnevezés
												<i v-if="currentSort == 'name' && currentSortDir == 'asc'"
													class="fa fa-sort-alpha-down"></i>
												<i v-if="currentSort == 'name' && currentSortDir == 'desc'"
													class="fa fa-sort-alpha-up-alt"></i>
											</th>
											<th class="d-none d-sm-none d-md-table-cell">Leírás</th>
											<th class="text-center d-none d-sm-none d-md-table-cell">Raktárhely</th>
											<th class="text-center d-none d-sm-none d-md-table-cell">Típus</th>
											<th class="text-center d-none d-sm-none d-md-table-cell">Aktuális készlet
											</th>
											<th class="text-center">Műveletek</th>
										</thead>
										<tbody>
											<tr v-for="(wrapping, id) in filteredWrappings" :key="id">
												<td>
													{{ wrapping.name }}
												</td>
												<td class="ellipsis-150 d-none d-sm-none d-md-table-cell">
													{{ wrapping.description }}
												</td>
												<td class="text-center d-none d-sm-none d-md-table-cell">
													{{ wrapping.place_name }}
												</td>
												<td class="text-center d-none d-sm-none d-md-table-cell">
													{{ wrapping.type_name }}
												</td>
												<td class="text-right d-none d-sm-none d-md-table-cell">
													{{ wrapping.quantity }}
												</td>
												<td class="text-center buttons-wide-3">
													<button class="btn btn-danger mx-1 d-inline" title="selejtezés"
														@click="openModal(wrapping)"><i
															class="fa fa-trash-alt"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5><i class="fa fa-undo"></i> Selejtezések</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="discardings === false">
								<div class="col-12 text-center">
									<h3>Selejtezések betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="discardings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-undo fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek selejtezések
									</h3>
								</div>
							</div>
							<div
								v-if="discardings.length != 0 && discardings !== false && wrappings.length != 0 && wrappings !== false">
								<input type="text" v-model="search2" placeholder="keresés.." />
								<div class="tableFixHeadLarge">
									<table class="table table-striped w-100">
										<thead class="thead-dark">
											<th>Megnevezés</th>
											<th>Leírás</th>
											<th>Dátum</th>
											<th class="text-center">Mennyiség</th>
											<th class="text-center">Műveletek</th>
										</thead>
										<tbody>
											<tr v-for="(discarding, id) in filteredDiscardings" :key="id">
												<td>{{
													wrappings.map(w => w.id).indexOf(discarding.wrapping_id) == -1 ?
														'törölt göngyöleg' : wrappings[wrappings.map(wrapping =>
															wrapping.id).indexOf(discarding.wrapping_id)].name
												}}</td>
												<td>{{
													eventlogs[eventlogs.map(log =>
														log.history_id).indexOf(discarding.id)].user_description
												}}</td>
												<td>{{ discarding.created_date }}</td>
												<td class="text-right">{{ discarding.quantity }}</td>
												<td class="text-right"><button class="btn btn-warning"
														title="visszavonás" @click="openRestoreModal(discarding)"><i
															class="fa fa-undo"></i></button></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="modal">
			<modal-vue modalSize="l" @on-close="modalClose" name="wrapping_modal"
				:headerOptions="{ title: wrapping.name + ' selejtezése', }" :footerOptions="{
					btn1: 'Mégse',
					btn2: 'Selejtezés',
					btn2OnClick: () => {
						discardWrapping();
					},
					btn1OnClick: () => {
						$vm2.close('wrapping_modal');
					},
				}">
				<div align="center" class="col-12">
					<form @submit.prevent="discardWrapping">
						<div class="form-group">
							<label>Adja meg a selejtezni kívánt mennyiséget:</label>
							<div class="input-group m-b">
								<div class="input-group-prepend">
									<span class="input-group-addon">összes <input type="checkbox"
											v-model="all_select"></span>
								</div>
								<input type="text" class="form-control text-right" v-model="formWrapping.quantity"
									:disabled="all_select">
							</div>
							<span class="label label-danger" v-if="errors.quantity">
								{{ errors.quantity[0] }}
							</span>
							<label>Megjegyzés:</label>
							<textarea v-model="formWrapping.user_description" class="form-control"></textarea>
						</div>
					</form>
				</div>
			</modal-vue>
		</div>

		<div id="restore_modal" v-if="discarding">
			<modal-vue modalSize="l" @on-close="$vm2.close('restore_modal');" name="restore_modal"
				:headerOptions="{ title: discarding.name + ' selejtezés visszavonása', }" :footerOptions="{
					btn1: 'Mégse',
					btn2: 'Visszavonás',
					btn2OnClick: () => {
						discardingRestore();
					},
					btn1OnClick: () => {
						$vm2.close('restore_modal');
					},
				}">
				<div align="center" class="col-12">
					<form @submit.prevent="discardingRestore">
						<div class="form-group">
							<label>Megjegyzés:</label>
							<textarea v-model="discarding.user_description" class="form-control" required></textarea>
							<span class="label label-danger" v-if="errors.user_description">
								{{ errors.user_description[0] }}
							</span>
						</div>
					</form>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Discard from "../../apis/Discard";

export default {
	components: { PageHeader },
	data() {
		return {
			wrappings: false,
			discardings: false,
			eventlogs: false,
			wrapping: {},
			discarding: false,
			formWrapping: {
				type: 'wrapping',
			},
			errors: [],
			all_select: false,
			search: '',
			search2: '',
			currentSort: 'name',
			currentSortDir: 'asc'
		}
	},
	watch: {
		all_select: function (all_select) {
			if (all_select == true) {
				this.$set(this.formWrapping, 'quantity', this.wrapping.quantity);
			}
		}
	},
	created() {
		Discard.getDiscardable('wrapping')
			.then(response => {
				this.wrappings = response.data;
			}).then(() => {
				Discard.getDiscardings('wrapping')
					.then(response => {
						this.eventlogs = response.data.eventLogs;
						this.discardings = response.data.data;
					});
			});
	},
	computed: {
		filteredWrappings: function () {
			return this.wrappings.filter(wrapping => {
				return wrapping.name.toLowerCase().includes(this.search.toLowerCase())
			}).sort((a, b) => {
				let modifier = 1;
				if (this.currentSortDir === 'desc') modifier = -1;
				if (a[this.currentSort][0].localeCompare(b[this.currentSort][0], 'hu-HU') < 0) return -1 * modifier;
				if (a[this.currentSort][0].localeCompare(b[this.currentSort][0], 'hu-HU') > 0) return 1 * modifier;
				return 0;
			});
		},
		filteredDiscardings() {
			if (this.discardings) {
				return this.discardings.filter(d => this.wrappings.filter(w => w.name.toLowerCase().includes(this.search2.toLowerCase())).map(w => w.id).includes(d.wrapping_id));
			} else {
				return [];
			}
		}
	},
	methods: {
		sort: function (s) {
			//if s == current sort, reverse
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
		},
		openModal(wrapping) {
			if (wrapping.quantity <= 0) {
				this.$toast.open({
					message: 'nincs készlet amiből selejtezhetne',
					type: 'error',
					position: 'top-right'
				});
			} else {
				this.wrapping = wrapping
				this.formWrapping.id = this.wrapping.id;
				this.$vm2.open('wrapping_modal')
			}
		},
		discardWrapping() {
			if (this.formWrapping.quantity < 1 || this.formWrapping.quantity > this.wrapping.quantity || !this.formWrapping.quantity) {
				this.$toast.open({
					message: '1 és ' + this.wrapping.quantity + ' közti mennyiséget selejtezhet',
					type: 'error',
					position: 'top-right'
				});
			} else {
				this.$confirm({
					message: `Biztos selejtezi a kiválasztott mennyiséget?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					/**
					* Callback Function
					* @param {Boolean} confirm
					*/
					callback: confirm => {
						if (confirm) {
							Discard.discard(this.formWrapping)
								.then(() => {
									this.$set(this.wrappings[this.wrappings.map(wrapping => wrapping.id).indexOf(this.wrapping.id)], 'quantity', this.wrapping.quantity - this.formWrapping.quantity);
									this.modalClose();
									Discard.getDiscardings('wrapping')
										.then(response => {
											this.eventlogs = response.data.eventLogs;
											this.discardings = response.data.data;
										});
								})
						}
					}
				})
			}

		},
		modalClose() {
			this.$vm2.close('wrapping_modal');
			this.formWrapping = { type: 'wrapping', };
			this.all_select = false;
		},
		openRestoreModal(discarding) {
			let vue = this;
			new Promise(function (resolve) {
				vue.discarding = discarding;
				vue.discarding.name = vue.wrappings[vue.wrappings.map(wrapping => wrapping.id).indexOf(discarding.wrapping_id)].name;
				resolve('finished')
			}).then(() => {
				this.$vm2.open('restore_modal')
			});
		},
		discardingRestore() {
			//console.log(this.discarding);
			if (this.discarding.user_description != null && this.discarding.user_description.length != 0) {
				Discard.restore('wrapping', { id: this.discarding.id, user_description: this.discarding.user_description })
					.then(() => {
						this.$set(this.wrappings[this.wrappings.map(wrapping => wrapping.id).indexOf(this.discarding.wrapping_id)], 'quantity', this.wrappings[this.wrappings.map(wrapping => wrapping.id).indexOf(this.discarding.wrapping_id)].quantity + this.discarding.quantity);
						this.discardings.splice(this.discardings.map(discarding => discarding.id).indexOf(this.discarding.id), 1);
					});
				delete this.errors.user_description;
				this.$forceUpdate(this.errors);
				this.$vm2.close('restore_modal');
			} else {
				this.$set(this.errors, 'user_description', ['töltse ki a megjegyzés mezőt']);
			}
		}
	}
};
</script>

<style>

</style>