<template>
	<div>
		<PageHeader title="Alapanyag selejtezés"/>
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5><i class="fa fa-balance-scale"></i> Alapanyag raktár</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="materials === false">
								<div class="col-12 text-center">
									<h3>Alapanyagok betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="materials.length == 0">
								<div class="m-b-md">
									<i class="fa fa-gift fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek alapanyagok
									</h3>
									<small>hozzon létre alapanyagot</small>
								</div>
							</div>
							<div v-if="materials.length != 0 && materials !== false">
								<input type="text" v-model="search" placeholder="keresés.."/>
								<div class="tableFixHeadLarge">
									<table class="table table-striped w-100">
										<thead class="thead-dark">
											<th @click="sort('name')" class="text-nowrap pointer">
													Megnevezés 
													<i v-if="currentSort == 'name' && currentSortDir == 'asc'" class="fa fa-sort-alpha-down"></i>
													<i v-if="currentSort == 'name' && currentSortDir == 'desc'" class="fa fa-sort-alpha-up-alt"></i>
											</th>
											<th class="d-none d-sm-none d-md-table-cell">INCI</th>
											<th class="text-center d-none d-sm-none d-md-table-cell">Aktuális készlet</th>
											<th class="d-none d-sm-table-cell text-center">Mennyiségi egység</th>
											<th class="d-none d-sm-table-cell text-center">Raktári hely</th>
											<th class="text-center">műveletek</th>
										</thead>
										<tbody>
											<tr v-for="(material, id) in filteredMaterials" :key="id" >
												<td>
													{{material.name}}
												</td>
												<td class="d-none d-sm-none d-md-table-cell">
													{{material.inci}}
												</td>
												<td class="text-right d-none d-sm-none d-md-table-cell">
													{{material.quantity}}
												</td>
												<td class="text-right d-none d-sm-table-cell">
													{{material.unit}}
												</td>
												<td class="text-right d-none d-sm-table-cell">
													{{material.storage_place_name}}
												</td>
												<td class="text-center buttons-wide-3">
													<button class="btn btn-danger mx-1 d-inline" @click="openModal(material)" title="selejtezés"><i class="fa fa-trash-alt"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5><i class="fa fa-undo"></i> Selejtezések</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="discardings === false">
								<div class="col-12 text-center">
									<h3>Selejtezések betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="discardings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-undo fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek selejtezések
									</h3>
								</div>
							</div>
							<div v-if="discardings.length != 0 && discardings !== false">
								<input type="text" v-model="search2" placeholder="keresés.."/>
								<div class="tableFixHeadLarge">
									<table class="table table-striped w-100">
										<thead class="thead-dark">
											<th>Tároló</th>
											<th>Leírás</th>
											<th>Dátum</th>
											<th class="text-center">Mennyiség</th>
											<th class="text-center">Műveletek</th>
										</thead>
										<tbody>
											<tr v-for="(discarding,id) in filteredDiscardings" :key="id">
												<td>{{discarding.material_name}} - {{discarding.barcode}}</td>
												<td>{{eventlogs[eventlogs.map(log => log.history_id).indexOf(discarding.id)] ? eventlogs[eventlogs.map(log => log.history_id).indexOf(discarding.id)].user_description : 'hiányzó leírás'}}</td>
												<td>{{discarding.created_date}}</td>
												<td class="text-right">{{discarding.quantity}}</td>
												<td class="text-right">
													<button class="btn btn-warning" title="visszavonás" @click="openRestoreModal(discarding)" v-if="discarding.expired !== true"><i class="fa fa-undo"></i></button>
													<button v-else disabled style="cursor:default;" class="btn btn-danger" title="lejárt tárolót nem állíthat vissza"><i class="fa fa-exclamation-triangle"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
			<div id="modal" >
				<modal-vue modalSize="xl" @on-close="modalClose" name="material_modal" :headerOptions="{ title: material.name + ' selejtezése', }" :footerOptions="{
        btn1: 'Mégse',
				disableBtn2: true,
        btn1OnClick: () => {
          $vm2.close('material_modal');
        },
      }">
					<div align="center" class="col-12">
						<div class="row" v-if="containers === false">
							<div class="col-12 text-center">
								<h3>Tárolók betöltése</h3>
								<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
							</div>
						</div>
						<div class="row" v-if="containers !== false">
							<div class="col">
							<h3 class="mx-4">Tárolók:</h3><br>
							<div class="tableFixHead mx-4">
								<table class="table table-striped">
									<thead class="thead-dark">
										<tr>
											<th>Vonalkód</th>
											<th class="d-none d-sm-none d-md-table-cell">Batch szám</th>
											<th class="d-none d-sm-none d-md-table-cell">Raktárhely</th>
											<th class="d-none d-sm-none d-md-table-cell">Lejárati dátum</th>
											<th class="d-none d-sm-none d-md-table-cell">Beszállító</th>
											<th class="d-none d-sm-none d-md-table-cell">Bevételezve</th>
											<th class="d-none d-sm-none d-md-table-cell">Mennyiség</th>
											<th>Selejtezés</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(container,id) in containers" :key="id">
											<td>{{container.barcode}}</td>
											<td class="d-none d-sm-none d-md-table-cell">{{container.batch_number}}</td>
											<td class="d-none d-sm-none d-md-table-cell">{{container.storage_place_name}}</td>
											<td class="d-none d-sm-none d-md-table-cell">{{container.best_before}}</td>
											<td class="d-none d-sm-none d-md-table-cell">{{container.partner_name}}</td>
											<td class="d-none d-sm-none d-md-table-cell">{{container.supplied_at}}</td>
											<td class="text-right d-none d-sm-none d-md-table-cell">{{container.quantity}}</td>
											<td>
												<div class="input-group m-b">
													<div class="input-group-prepend">
														<span class="input-group-addon">összes <input type="checkbox" v-model="container.all_select" @click="allSelect(container)"></span>
													</div>
													<input type="text" class="form-control text-right" v-model="container.discard_quantity" :disabled="container.all_select">
													<div class="input-group-append">
														<loading-button class="btn btn-danger bg-danger input-group-addon" title="Selejtezés"  @click.native="discardMaterial(container)" :loading="loading"><i class="fa fa-trash-alt"></i></loading-button>
													</div>
												</div>
												<textarea v-model="container.user_description" class="form-control" placeholder="megjegyzés"></textarea>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						</div>
					</div>
				</modal-vue>
			</div>
			<div id="restore_modal" v-if="discarding">
				<modal-vue modalSize="l" @on-close="$vm2.close('restore_modal');" name="restore_modal" :headerOptions="{ title: discarding.material_name + ' selejtezés visszavonása', }" :footerOptions="{
				btn1: 'Mégse',
				btn2: 'Visszavonás',
				btn2OnClick: () => {
					discardingRestore();
				},
				btn1OnClick: () => {
					$vm2.close('restore_modal');
				},
			}">
					<div align="center" class="col-12">
						<form @submit.prevent="discardingRestore">
							<div class="form-group">
								<label>Megjegyzés:</label>
								<textarea v-model="discarding.user_description" class="form-control" required></textarea>
								<span class="label label-danger" v-if="errors.user_description">
										{{ errors.user_description[0] }}
								</span>
							</div>
						</form>
					</div>
				</modal-vue>
			</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Discard from "../../apis/Discard";
import Material from "../../apis/Material";

export default {
    components: { PageHeader },
    data() {
        return {
            materials: false,
			discardings: false,
			eventlogs: false,
			discarding: false,
			material: {},
			containers: false,
			errors: [],
			search:'',
			search2:'',
			currentSort:'name',
			currentSortDir:'asc',
			loading:false,
        }
    },
    created() {
			Discard.getDiscardable('raw_material')
			.then(response => {
				this.materials = response.data;
			}).then(() => {
				Discard.getDiscardings('raw_material_container')
				.then(response => {
					this.eventlogs = response.data.eventLogs;
					this.discardings = response.data.data;
				});
			});
    },
		computed: {
			filteredMaterials: function(){
				return this.materials.filter(material => {
					return material.name.toLowerCase().includes(this.search.toLowerCase())
				}).sort((a,b) => {
					let modifier = 1;
					if(this.currentSortDir === 'desc') modifier = -1;
					if(a[this.currentSort][0].localeCompare(b[this.currentSort][0],'hu-HU') < 0) return -1 * modifier;
					if(a[this.currentSort][0].localeCompare(b[this.currentSort][0],'hu-HU') > 0) return 1 * modifier;
					return 0;
				});
			},
			filteredDiscardings(){
				if(this.discardings){
					return this.discardings.filter(d => d.material_name.toLowerCase().concat('',d.barcode).includes(this.search2.toLowerCase()));
				}else{
					return [];
				}
			}
		},
    methods: {
			sort:function(s) {
				//if s == current sort, reverse
				if(s === this.currentSort) {
					this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
				}
				this.currentSort = s;
			},
			openModal(material) {
					if(material.quantity <= 0){
						this.$toast.open({
							message: 'nincs készlet amiből selejtezhetne',
							type: 'error',
							position: 'top-right'
						});
					}else{
						this.material = material
						this.$vm2.open('material_modal')
						Material.get_containers(material.id)
						.then(response => {
							this.containers = response.data;
							this.containers.forEach(container => {
								container.user_description = '';
							});
							//console.log(this.containers);
						})
					}
			},
			getdiscardable(){
				Discard.getDiscardable('raw_material')
				.then(response => {
					this.$set(this,'materials',response.data);
				})
			},
			getdiscards(){
				Discard.getDiscardings('raw_material_container')
				.then(response => {
					this.eventlogs = response.data.eventLogs;
					this.discardings = response.data.data;
				});
			},
			discardMaterial(container) {
				this.loading = true;
				if(container.discard_quantity < 1 || container.discard_quantity > container.quantity || !container.discard_quantity){
					this.toast.open({
						message:'1 és ' + container.quantity + 'közti értéket selejtezhet',
						type:'error',
						position:'top-right'
					});
					this.loading = false;
				}else{
					Discard.discard({type:'raw_material_container',id: container.id, quantity: container.discard_quantity, user_description:container.user_description})
					.then(() => {
						if(container.quantity - container.discard_quantity == 0){
							this.containers.splice(this.containers.findIndex(c => c.id == container.id),1);
						}else{
							this.$set(container,'quantity',container.quantity - container.discard_quantity)
						}
						this.$set(this.materials[this.materials.map(material => material.id).indexOf(this.material.id)],'quantity',this.materials[this.materials.map(material => material.id).indexOf(this.material.id)].quantity - container.discard_quantity)
						this.modalClose();
						this.getdiscards();
					}).finally(() => {
						this.loading = false;
					});
				}
			},
			modalClose() {
				this.$vm2.close('material_modal');
				this.containers = false;
			},
			allSelect(container) {
				if(!container.all_select){
					this.$set(container,'discard_quantity',container.quantity);
				}
			},
			openRestoreModal(discarding) {
				let vue = this;
				new Promise(function(resolve) {
					vue.discarding = discarding;
					resolve('finished')
				}).then(() => {
					this.$vm2.open('restore_modal')
				});
			},
			discardingRestore() {
				//console.log(this.discarding);
				if(this.discarding.user_description != null && this.discarding.user_description.length != 0){
					Discard.restore('raw_material_container',{id: this.discarding.id, user_description: this.discarding.user_description})
					.then(() => {
						this.discardings.splice(this.discardings.map(discarding => discarding.id).indexOf(this.discarding.id),1);
						this.getdiscardable();
					});
					delete this.errors.user_description;
					this.$forceUpdate(this.errors);
					this.$vm2.close('restore_modal');
				}else{
					this.$set(this.errors,'user_description',['töltse ki a megjegyzés mezőt']);
				}
			}
    }
};
</script>

<style>
</style>