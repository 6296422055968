<template>
	<div>
		<PageHeader title="Értékesítés napló" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-4 col-md-8 col-12">
					<form @submit.prevent="downloadCsv">
						<div class="input-group">
							<div class="input-group-prepend">
							</div>
							<input type="date" class="form-control" v-model="excel_data.start_date" required>
							<span class="form-control text-center" style="max-width:10px !important;"> - </span>
							<input type="date" class="form-control" v-model="excel_data.end_date" required>
							<div class="input-group-append">
								<button type="submit" class="btn btn-success">Adatok letöltése</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="partners === false">
								<div class="col-12 text-center">
									<h3>Partnerek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="partners.length == 0">
								<div class="m-b-md">
									<i class="fa fa-user fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek partnerek
									</h3>
									<small>hozzon létre partnereket</small>
								</div>
							</div>
							<div class="table-responsive" v-if="partners.length != 0 && partners !== false">
								<vue-good-table
									styleClass="vgt-table striped" 
									rowStyleClass="fs-12"
									:columns="columns" 
									:rows="partners" 
									:search-options="{ enabled: false, placeholder: 'Keresés...' }" 
									:pagination-options="this.$vueGTPaginationOptions"
								>
									<template slot="table-row" slot-scope="props">
										<span v-if="props.column.field == 'order_sum_price'">
											{{props.row.order_sum_price.toLocaleString('hu-HU')}} Ft
										</span>
										<span v-else-if="props.column.field == 'order_avg_price'">
											{{props.row.order_avg_price.toLocaleString('hu-HU')}} Ft
										</span>
										<span v-else-if="props.column.field == 'actions'">
											<button class="btn btn-success mx-1 d-inline" title="napló" @click="openModal(props.row)"><i class="fa fa-book-open"></i></button>
										</span>
										<span v-else>
											{{props.formattedRow[props.column.field]}}
										</span>
									</template>
								</vue-good-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal" >
			<modal-vue modalSize="xl" @on-close="modalClose()" name="partner_modal" :headerOptions="{ title: partner.name + ' értékesítési napló', }" :footerOptions="{
			btn1: 'Bezár',
			disableBtn2: true,
			btn1OnClick: () => {
				$vm2.close('partner_modal');
			},
		}">
				<div align="center" class="col-12" v-if="histories.orders != null && histories.orders.length >= 1">
					<h5>Értékesítések</h5>
					<div class="tableFixHead">
						<table class="table table-hover">
							<thead class="thead-dark">
								<tr>
									<th>Dátum</th>
									<th>Értékesítő</th>
									<th>Termékek száma</th>
									<th>Összérték</th>
									<th>Terméklista</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(order, id) in histories.orders" :key="id">
									<td>{{order.created_date}}</td>
									<td>{{order.user_name}}</td>
									<td v-if="order.items.length > 1" class="text-center">{{order.items.reduce(function(a,b){
											return a + b.quantity;
										},0)}}</td>
									<td v-else class="text-center">{{ order.items[0] ? order.items[0].quantity : 0}}</td>
									<td v-if="order.items.length > 1" class="text-center">{{order.items.reduce(function(a,b){
											return a + b.price;
										},0).toLocaleString('hu-HU')}} Ft</td>
									<td v-else class="text-center">{{order.items[0] ? (order.items[0].price).toLocaleString('hu-HU') : 0}} Ft</td>
									<td><button class="btn" :class="{'btn-warning': order.id == active_order.id,'btn-success': order.id != active_order.id}" @click="setActiveOrder(order)"><i class="fa fa-list"></i> termékek</button></td>
								</tr>
							</tbody>
						</table>
					</div>
					<h5>Tételek</h5>
					<div class="tableFixHead" v-if="active_order">
						<table class="table table-hover">
							<thead class="thead-dark">
								<tr>
									<th>Termék</th>
									<th>Kiszerelés</th>
									<th>Egységár</th>
									<th>Mennyiség</th>
									<th>Érték</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, id) in active_order.items" :key="id">
									<td>{{item.bundle_id ? item.bundle_name : item.product_name}}</td>
									<td>{{item.bundle_id ? 'csomag' : item.size_name}}</td>
									<td class="text-center">{{(item.price/item.quantity).toLocaleString('hu-HU')}} Ft</td>
									<td class="text-center">{{item.quantity}}</td>
									<td>{{(item.price).toLocaleString('hu-HU')}} Ft</td>
								</tr>
							</tbody>
						</table>
					</div>
					<h2 v-else class="mt-4">Válasszon értékesítést!</h2>
				</div>
				<div class="widget  p-lg text-center" v-else>
					<div class="m-b-md">
							<i class="fa fa-exclamation-triangle fa-4x"></i>
							<h1 class="m-xs">Nincs</h1>
							<h3 class="font-bold no-margins">
									megjeleníthető értékesítés
							</h3>
							<small>ennél a partnernél</small>
					</div>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Log from "../../apis/Log";
import moment from 'moment';

export default {
    components: { PageHeader },
    data() {
        return {
					partners: false,
					partner: {},
					active_order: false,
					errors: [],
					histories:false,
					active: false,
					columns:[
						{
							label:'Megnevezés',
							field:'name',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Kategória',
							field:'category',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12'
						},
						{
							label:'Értesítések száma',
							field:'order_sum',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12 text-right',
							tdClass: 'text-right'
						},
						{
							label:'Utolsó vásárlás',
							field:'last_order',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12 text-right',
							tdClass: 'text-right'
						},
						{
							label:'Vásárlások összértéke',
							field:'order_sum_price',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12 text-right',
							tdClass: 'text-right'
						},
						{
							label:'Vásárlások összértéke',
							field:'order_sum_price',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12 text-right',
							tdClass: 'text-right'
						},
						{
							label:'Vásárlások átlagos értéke',
							field:'order_avg_price',
							filterOptions : {
								enabled:true,
								placeholder: 'Keresés',
							},
							thClass: 'fs-12 text-right',
							tdClass: 'text-right'
						},
						{
							label: 'Műveletek',
							field: 'actions',
							width: '15%',
							sortable: false,
							thClass: 'text-right fs-12',
							tdClass: 'text-right'
						}
					],
					excel_data:{
						start_date:null,
						end_date:null,
					}
        }
    },
	
    created() {
			Log.order()
			.then(response => {
				this.partners = response.data;
			});	
    },
    methods: {
			openModal(partner) {
				this.partner = partner
				this.$vm2.open('partner_modal')
				Log.log({type:'order',id:partner.id})
				.then(response => {
					this.histories = response.data;
					//console.log('histories:',this.histories);
					//console.log('partner:',this.partner);
				});
			},
			modalClose() {
				this.$vm2.close('partner_modal');
				this.histories = false;
				this.active_order = false;
			},
			setActiveOrder(order){
				this.active_order = order;
			},
			downloadCsv(){
				//var blob = new Blob([response.data]);
				var link = document.createElement('a');
				link.href = process.env.VUE_APP_PRINT_URL+'/api/log/order_log_excel/'+this.excel_data.start_date+'/'+this.excel_data.end_date;
				link.download = moment(this.excel_data.start_date).format('YYYY-MM-DD')+'-'+moment(this.excel_data.end_date).format('YYYY-MM-DD')+'_ertekesitesi_adatok.xlsx';
				link.click();
				/*Log.order_log_excel(this.excel_data)
					.then(response => {
					})*/
			}
    },
		
};
</script>

<style>
</style>