<template>
	<div>
		<PageHeader title="Csomag szerkesztése" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h3>Alapadatok</h3>
						</div>
						<div class="ibox-content">
							<form @submit.prevent="updateBundle">
								<div class="form-group">
									<label>Megnevezés:</label>
									<input type="text" class="form-control" v-model="bundle.name">
									<span class="text-danger" v-if="errors.name">
										{{ errors.name[0] }}
									</span>
								</div>
								<router-link to="/bundles" class="btn btn-danger">Vissza</router-link>
								<button class="btn btn-primary float-right">Mentés</button>
							</form>
						</div>
					</div>
					<div class="ibox">
						<div class="ibox-title">
							<h5>Árak</h5>
						</div>
						<div class="ibox-content">
							<form @submit.prevent="addPrice" class=" row" v-if="price_categories.length > 0">
								<div class="col-sm-4">
									<select v-model="price.price_category_id" class="form-control">
										<option :value="undefined" disabled>Válassz kategóriát</option>
										<option v-for="price_category in price_categories" :value="price_category.id" :key="price_category.id">
											{{ price_category.name }}
										</option>
									</select>
								</div>
								<div class="col-sm-3">
									<div class="input-group">
										<input type="number" @wheel="$event.target.blur()" max="2147483647" v-model="price.price" class="form-control text-right" placeholder="nettó ár">
										<div class="input-group-append">
											<span class="input-group-addon">Ft</span>
										</div>
									</div>
								</div>
								<div class="col-sm-1 text-center">
									<button class="btn btn-primary" type="submit"><i class="fa fa-plus"></i><span class="d-block d-sm-none"> Hozzáadás</span></button>
								</div>
							</form>
							<div class="widget style1 yellow-bg" v-if="price_categories.length <= 0">
								<div class="row vertical-align">
									<div class="col-3">
										<i class="fa fa-exclamation-triangle fa-2x"></i>
									</div>
									<div class="col-9 text-right">
										<h3>Csak akkor hozhat létre árakat ha vannak árkategóriák!</h3>
									</div>
								</div>
							</div>
							<div class="table-responsive" v-if="bundle">
								<table class="table" >
									<thead>
										<tr>
											<th>kategória</th>
											<th>nettó ár</th>
											<th>törlés</th>
										</tr>
									</thead>
									<tbody>
											<tr v-for="(price, id) in bundle.prices" :key="id">
												<td>{{price.category_name}}</td>
												<td class="text-right">{{price.price.toLocaleString('hu-HU')}} Ft</td>
												<td class="text-right">
													<button class="btn btn-danger btn-xs" @click="deletePrice(price.id)"><i class="fa fa-trash"></i></button>
												</td>
											</tr>
									</tbody>
								</table>
							</div>
							<div v-else>
								<div class="widget  p-lg text-center">
									<div class="m-b-md">
										<i class="fa fa-dollar-sign fa-4x"></i>
										<h1 class="m-xs"><i class="fa fa-times"></i></h1>
										<h3 class="font-bold no-margins"> A termékhez nem tartoznak árak </h3>
										<small>adjon hozzá árat</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h3>Termékek</h3>
						</div>
						<div class="ibox-content" v-if="bundle">
							<form @submit.prevent="addSize" v-if="products">
								<h5>Termék hozzáadása a csomaghoz:</h5>
								<span class="badge badge-info mb-2"><i class="fa fa-info-circle"></i> Külsős partner által gyártott termékek nem választhatóak</span>
								<div class="row">
									<div class="col-md-5">
										<v-select v-model="size.product_id" :from="products.filter(p => p.sizes && p.sizes.length > 0)" as="name:id:id" tagging placeholder="Válassz terméket..."></v-select>
									</div>
									<div class="col-md-5">
										<v-select v-if="size.product_id" v-model="submit_object.product_size_id" :from="products[products.map(p => p.id).indexOf(size.product_id)].sizes" as="name:id:id" tagging placeholder="Válassz kiszerelést..."></v-select>
									</div>
									<div class="col-md-2" v-if="submit_object.product_size_id">
										<button class="btn btn-primary"><i class="fa fa-plus"></i></button>
									</div>
								</div>
							</form>
							<span v-else> <i class="fa fa-cog fa-spin fa-3x fa-fw"></i> Hozzáadható termékek betöltése</span>
							<div class="table-responsive" v-if="bundle != false">
								<table class="table table-hover" v-if="bundle.size_ids.length > 0">
									<tr>
										<th>Név</th>
										<th>Műveletek</th>
									</tr>
									<tr v-for="(size,id) in bundle.size_ids" :key="id">
										<td>{{size.product_full_name}}</td>
										<td>
											<button class="btn btn-xs btn-danger" @click="deleteSize(size.id)"><i class="fa fa-trash"></i></button>
										</td>
									</tr>
								</table>
							</div>
							<div class="widget style1" v-if="bundle.size_ids.length == 0">
								<div class="row vertical-align">
									<div class="col-3">
										<i class="fa fa-cube fa-2x"></i>
									</div>
									<div class="col-9 text-right">
										<h3>Adjon hozzá termékeket a csomaghoz</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Bundle from "../../apis/Bundle";
import PriceCategory from "../../apis/PriceCategory";
import Price from "../../apis/Price";
import { vSelect } from '@desislavsd/vue-select'

export default {
    components: { PageHeader, vSelect},
    data() {
        return {
			bundle: false,
			errors: [],
			price_categories: false,
			price:{
				bundle_id: this.$route.params.id
			},
			products: false,
			size: {},
			submit_object:{}
        }
    },
	
    created() {
			Bundle.show(this.$route.params.id)
			.then(response => {
				this.bundle = response.data;
			});
			PriceCategory.index()
			.then(response => {
				this.price_categories = response.data;
			});
			Bundle.avaibleProducts()
			.then(response => {
				this.products = response.data;
			});
    },
    methods: {
			updateBundle() {
				Bundle.update(this.$route.params.id,this.bundle)	
				.then(() => {
					this.$router.push({path:'/bundles'});
				})
				.catch(error => {
						this.errors = error.response.data.errors;
				})
			},
			addPrice(){
				Price.add(this.price)
				.then(response => {
					let priceIndex = this.bundle.prices.map(price => price.price_category_id).indexOf(response.data.price_category_id);
					var price = this.bundle.prices[priceIndex];
					//console.log(price);
					if(typeof price != 'undefined'){
						this.bundle.prices.splice(priceIndex,1);
					}
					this.bundle.prices.unshift(response.data);
				})
			},
			addSize(){
				Bundle.addSize(this.$route.params.id, this.submit_object)
				.then(response => {
					this.bundle = response.data
				})
			},
			deleteSize(id){
				Bundle.deleteSize(this.$route.params.id, {product_size_id:id})
				.then(() => {
					this.bundle.size_ids.splice(this.bundle.size_ids.map(s => s.id).indexOf(id),1);
				});
			},
			deletePrice(id){
				Price.delete(id).then(() => {
					this.bundle.prices.splice(this.bundle.prices.map(s => s.id).indexOf(id),1);
				});
			}
    },
		watch: {
			'size.product_id': function(){
				this.submit_object = {};
			}
		}
};
</script>

<style>
</style>