<template>
	<div>
		<PageHeader title="Profil módosítása" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-6">
					<div class="ibox">
						<div class="ibox-title">
							<h3>
								Felhasználó alapadatai
							</h3>
						</div>						
						<div class="ibox-content">
							<form @submit.prevent="saveUserData()" class="form">
								<div class="form-group">
									<input type="text" v-model="user.name" class="form-control">
								</div>
								<div class="form-group">
									<button type="submit" class="btn btn-primary">Mentés</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div class="col-6">
					<div class="ibox">
						<div class="ibox-title">
							<h3>
								Profilkép
							</h3>
						</div>						
						<div class="ibox-content">
							<div class="alert alert-info">
                                <i class="fa fa-info-circle"></i> A lista első eleme lesz mindig a megjelenő profilkép
                            </div>
							<ImageUpload :parent_model="parent_model" :model_id="this.$root.$data.user.id" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import ImageUpload from '../../components/ImageUpload';
import User from "../../apis/User";

export default {
    components: { PageHeader, ImageUpload },
    data() {
        return {
			user: this.$root.$data.user,
			parent_model: 'User',
        }
    },
	methods: {
		saveUserData(){
			//this.$root.$data.user = this.user;
			//this.$root.$data.profile_image = this.user;
			
			
			
			User.update(this.user)
				.then(response => {
					this.$root.$data.user = response.data;
				});
		}
	}
		
};
</script>

<style>
</style>