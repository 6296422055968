import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/product_sizes");
  },
    
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/product_sizes", form);
  },
    
  async show(id) {
    await Csrf.getCookie();

    return Api.get("/product_sizes/" + id);
  },
    
  async update(id, form) {
    await Csrf.getCookie();

    return Api.patch("/product_sizes/" + id, form);      
      
  },
    
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/product_sizes/" + id);      
      
  }
    
};