import Api from "./Api";
import Csrf from "./Csrf";

export default {
	
  async add(array) {
    await Csrf.getCookie();

    return Api.post("/orders", array);
  },
  async addBundle(array) {
    await Csrf.getCookie();

    return Api.post("/orders/bundle_store", array);
  },
  async addCommission(array) {
    await Csrf.getCookie();

    return Api.post("/orders/create_order", array);
  },
	async showPartnerCommission(id) {
		await Csrf.getCookie();
		
		return Api.get('/orders/order_show/'+id);
	},
	async updateStatus(id,array) {
		await Csrf.getCookie();
		
		return Api.post('/orders/order_status_update/'+id, array);
	},
	async updateItemQuantity(id,array) {
		await Csrf.getCookie();
		
		return Api.post('/orders/order_item_update/'+id, array);
	},
	async getCommissions() {
		await Csrf.getCookie();
		
		return Api.get('/orders/order_show');
	}
};