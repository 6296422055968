import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async index() {
    await Csrf.getCookie();

    return Api.get("/wrappings");
  },
    
  async add(form) {
    await Csrf.getCookie();

    return Api.post("/wrappings", form);
  },
    
  async show(id) {
    await Csrf.getCookie();

    return Api.get("/wrappings/" + id);
  },
    
  async update(id, form) {
    await Csrf.getCookie();

    return Api.patch("/wrappings/" + id, form);      
      
  },
    
  async delete(id) {
    await Csrf.getCookie();

    return Api.delete("/wrappings/" + id);      
      
  },
	
	async supply(id, form) {
		await Csrf.getCookie();
		
		return Api.post("/wrappings/" + id + "/supply", form);
	},
	
	async takeOut(id,form) {
    await Csrf.getCookie();

    return Api.post("wrappings/"+id+"/take_out", form);
  },
    
};