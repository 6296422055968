<template>
		<div>
				<PageHeader title="Alapanyag módosítása" />
				<div class="row">
						<div class="col-lg-6">
								<div class="ibox">
										<div class="ibox-content">
												<form @submit.prevent="updateMaterial">
														<div class="form-group">
																<label>Megnevezés</label>
																<input type="text" class="form-control" v-model="material.name">
																<span class="label label-danger" v-if="errors.name">
																		{{ errors.name[0] }}
																</span>
														</div>
														<div class="form-group" v-if="material.name && material.name.length > 20">
																<label>Vonalkódon szereplő név:</label>
																<input type="text" maxlength="20" class="form-control" v-model="material.short_name" required>
																<span class="label label-danger" v-if="errors.short_name">
																		{{ errors.short_name[0] }}
																</span>
														</div>
														<div class="form-group">
																<label>INCI</label>
																<input type="text" class="form-control" v-model="material.inci">
																<span class="label label-danger" v-if="errors.inci">
																		{{ errors.inci[0] }}
																</span>
														</div>
														<div class="form-group">
																<label>Mennyiségi egység</label>
																<select class="form-control no-cut" v-model="material.unit">
																	<option v-for="unit in units" :value="unit.value" :key="unit.value">
																		{{ unit.text }}
																	</option>
																</select>
																<span class="label label-danger" v-if="errors.unit">
																		{{ errors.unit[0] }}
																</span>
														</div>
														<div class="form-group">
															<label>Lejárati figyelmeztetés</label>
															<div class="input-group">
																<div class="input-group-prepend">
																	<span class="input-group-addon">lejárat elött</span>
																</div>
																<input type="number" max="180" v-model="material.warning_at" class="form-control text-right">
																<div class="input-group-append">
																	<span class="input-group-addon">nappal</span>
																</div>
															</div>
														</div>
													<div class="form-group">
														<label>Készlethiány figyelmeztetés</label>
														<div class="input-group">
															<div class="input-group-prepend">
																<span class="input-group-addon">ha a készlet eléri a </span>
															</div>
															<input type="number" @wheel="$event.target.blur()" v-model="material.warning_quantity" class="form-control text-right">
															<div class="input-group-append">
																<span class="input-group-addon">egységet</span>
															</div>
														</div>
														<span class="label label-danger" v-if="errors.warning_quantity">
															{{ errors.warning_quantity[0] }}
														</span>
													</div>
													<div class="form-group">
														<label>Gyártási eltérés tűrés</label>
														<div class="input-group">
															<input type="number" @wheel="$event.target.blur()" min="0" max="100" v-model="material.production_treshold" class="form-control text-right">
															<div class="input-group-append">
																<span class="input-group-addon">%</span>
															</div>
														</div>
														<span class="label label-danger" v-if="errors.production_treshold">
															{{ errors.production_treshold[0] }}
														</span>
													</div>
														<button type="submit" class="btn btn-primary">Mentés</button>
														<router-link :to="{path:'/materials'}" class="btn btn-danger mx-2">Mégsem</router-link>			
												</form>
										</div>
								</div>
						</div>
				</div>
		</div>
</template>
 
<script>
import PageHeader from '../components/PageHeader';
import Material from "../apis/Material";
import Storage from "../apis/Storage";
import Config from "../apis/Config"; 

	export default {
		components: {PageHeader},
		data() {
			return {
				units: false,
				storage_places: false,
				material: {},
				errors: []
			}
		},	
		created() {
			Material.show(this.$route.params.id)
			.then(response => {
					this.material = response.data;
			});

			Config.getConstant('units')
			.then(response => {
				this.units = response.data;
			})
			Storage.places(1)
			.then(response => {
				this.storage_places = response.data;
			})
		},
		methods: {
			updateMaterial() {
				Material.update(this.$route.params.id, this.material)
					.then(() => {
						this.$router.push({path: '/materials' })
					})
					.catch(error => {
						this.errors = error.response.data.errors;
					})
			}
		}
	}
</script>