import Api from "./Api";
import Csrf from "./Csrf";

export default {
  async discard(array) {
    await Csrf.getCookie();

    return Api.post("/discarding",array);
  },
  async getDiscardings(type) {
    await Csrf.getCookie();

    return Api.get("/discarding/" + type + "/find_discarding");
  },
  async restore(type,array) {
    await Csrf.getCookie();

    return Api.post("/discarding/" + type + "/restore_discarding",array);
  },
	async getDiscardable(type){
		await Csrf.getCookie();
		
		return Api.get("/discarding/" + type);
	}
    
};