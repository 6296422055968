import Api from "./Api";
import Csrf from "./Csrf";

export default {
	
  async close_to_expire() {
    await Csrf.getCookie();

    return Api.get("/dashboard/close_to_expire");
  },
	
  async bad_document_type() {
    await Csrf.getCookie();

    return Api.get("/dashboard/bad_document_type");
  },
	
	async update_document_type(id) {
		await Csrf.getCookie();

    return Api.get("/dashboard/document_type_update/"+id);
	},
	
	async missing_document() {
		await Csrf.getCookie();

    return Api.get("/dashboard/missing_document");
	},
	
	async low_intermediate_product_inventory() {
		await Csrf.getCookie();

		return Api.get('/dashboard/low_intermediate_product_inventory');
	},
	
	async low_rawmaterial_inventory() {
		await Csrf.getCookie();

		return Api.get('/dashboard/low_rawmaterial_inventory');
	},
    
};