<template>
	<div>
		<PageHeader title="Leltározás" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-12">
					<div class="ibox">
						<div class="ibox-content">
							<div class="row" v-if="!storages">
								<div class="col-12 text-center">
									<h3>Raktárhelyek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="row d-flex justify-content-center" v-else>
								<div class="col-lg-4 col-sm-6" v-show="![6].includes(storage.id)"
									v-for="(storage, id) in storages" :key="id">
									<div class="widget p-lg text-center"
										:class="{ 'navy-bg': !storage.active, 'yellow-bg': storage.active }">
										<div class="m-b-md">
											<i class="fa fa-search fa-4x"></i>
											<h1 class="m-xs">{{ storage.name }}</h1>
											<h3 class="font-bold no-margins">
												leltározás
											</h3>
											<button class="btn btn-default btn-lg mt-1" @click="selectStorage(storage)"	v-if="!storage.active && !hasOwnActive" :disabled="nowloading">
												<i class="fa fa-play text-dark" v-if="!nowloading"></i>
												<i class="fa fa-cog fa-spin text-dark" v-else-if="(nowloading == storage.id)"></i>
												<i class="fa fa-ban text-dark" v-else></i>
											</button>
											<router-link :to="'/inventories/' + storage.active" class="btn btn-default text-dark mt-3" v-if="storage.active && typeof storage.active == 'number'"><i class="fa fa-play text-dark"></i> Folytatás</router-link>
											<h5 v-if="storage.active"><i class="fa fa-info-circle"></i> aktív leltár</h5>
											<h5 v-if="storage.active && typeof storage.active == 'string'"><i class="fa fa-user"></i> {{storage.active}}</h5>
											<h3 v-if="!storage.active && hasOwnActive"><i class="fa fa-exclamation-triangle fa-2x mt-1"></i></h3>
											<small v-if="!storage.active && hasOwnActive">van aktív	leltárad!</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="modal" >
			<modal-vue modalSize="lg" @on-close="modalReset" name="inventory_parameters" :headerOptions="{ title: storage.name+' - leltár indítás' }" :footerOptions="{
				btn1: 'Mégse',
				btn2: 'Indítás',
				btn1OnClick: () => {
					$vm2.close('inventory_parameters');
				},
				btn2OnClick: () => {
					startInventory();
				},
			}" :noFooter="loading">
				<div class="p-lg text-center" v-if="loading">
					<i class="fa fa-cog fa-spin fa-2x"></i> Folyamatban...
				</div>	
				<div class="px-4 h-200" v-else>
					<div class="form-group">
						<label class="form-label">Leltár típusa:</label>
						<v-select :from="inventory_types" placeholder="Válassz típust" as="name:id:id" v-model="modal_parameters.only_one_item" class="form-select"></v-select>
					</div>	
					<div class="form-group mt-2" v-if="modal_parameters.only_one_item">
						<label class="form-label">Leltározandó tétel:</label>
						<v-select v-if="storage && storage.items" placeholder="Válassz tételt" :from="storage.items" as="name:id:id" v-model="modal_parameters.item_id" class="form-select"></v-select>
					</div>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Storage from '../../apis/Storage';
import Inventory from '../../apis/Inventory';
import { vSelect } from '@desislavsd/vue-select'

export default {
	components: {
		PageHeader, vSelect
	},
	data() {
		return {
			storages: null,
			storage:{},
			nowloading: false,
			loading: false,
			modal_parameters:{
				only_one_item:false,
				item_id:null
			},
			inventory_types: [
				{ id: false, name: 'Teljes leltár' },
				{ id: true, name: 'Egyetlen tétel' }
			]
		}
	},
	watch: {
		'modal_parameters.only_one_item': function(){
			this.modal_parameters.item_id = null;
		}
	},
	computed: {
		hasOwnActive(){
			if(this.storages && this.storages.filter(s => typeof s.active == 'number').length != 0){
				return true;
			}else{
				return false;
			}
		},
		submit_object(){
			if(this.storage){
				if(this.modal_parameters.only_one_item === false){
					return {
						storage_id: this.storage.id
					}
				}else if(this.modal_parameters.only_one_item === true){
					return {
						storage_id: this.storage.id,
						item_id: this.modal_parameters.item_id
					}
				}else{
					return {};
				}
			}else{
				return {};
			}
		}
	},
	created() {
		Storage.index()
			.then(response => {
				this.storages = response.data;
			});
	},
	methods: {
		modalReset(){
			this.$vm2.close('inventory_parameters');
			this.storage = {},
			this.modal_parameters = {
				only_one_item:false,
				item_id:null
			};
		},
		selectStorage(storage) {
			this.loading = true;
			this.storage = storage;
			Storage.items(storage.id).then(response => {
				this.$set(this.storage,'items',response.data);
				this.loading = false;
			});
			this.$vm2.open('inventory_parameters');
		},
		startInventory(){
			this.nowloading = this.storage.id;
			this.loading = true;
			if(typeof this.modal_parameters.only_one_item == 'undefined'){
				this.$toast.open({
					message:'Válasszon típust!',
					type:'error',
					position:'top-right'
				});
				this.nowloading = false;
				this.loading = false;
			}
			else if(this.modal_parameters.only_one_item && !this.modal_parameters.item_id){
				this.$toast.open({
					message:'Válasszon tételt!',
					type:'error',
					position:'top-right'
				});
				this.nowloading = false;
				this.loading = false;
			}else{
				Inventory.add(this.submit_object)
				.then(response => {
					this.$vm2.close('inventory_parameters');
					setTimeout(() => this.$router.push({ path: response.headers['content-location'] }), 200);
				})
				.finally(() => {
					this.nowloading = false;
					this.loading = false;
				})
			}
		}
	}
};
</script>

<style>

</style>