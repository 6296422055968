<template>
  <div class="home col-10 col-md-5 mx-auto py-5 mt-5">
		<div class="vsm--header" style="min-height:120px;margin-bottom:50px;"></div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="login">
            <div class="form-group">
              <label for="email">E-mail:</label>
              <input
                type="email"
                v-model="form.email"
                class="form-control"
                id="email"
              />
              <span class="text-danger" v-if="errors.email">
                {{ errors.email[0] }}
              </span>
            </div>
            <div class="form-group">
              <label for="password">Jelszó:</label>
              <input
                type="password"
                v-model="form.password"
                class="form-control"
                id="password"
              />
              <span class="text-danger" v-if="errors.password">
                {{ errors.password[0] }}
              </span>
						<a :href="reset+'/password/reset'" class="btn btn-xs btn-link float-right"><i class="fa fa-key"></i> Elfelejtett jelszó</a>
            </div>
            <button type="submit" class="btn btn-primary btn-block mt-2">
              Belépek
            </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import User from "../../apis/User";
export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
			reset:'',
      errors: []
    };
  },
	created(){
		this.reset = process.env.VUE_APP_PRINT_URL
	},
  methods: {
    login() {
      User.login(this.form)
        .then(() => {
          this.$root.$emit("login", true);
          localStorage.setItem("auth", "true");
          this.$router.push({path:'/dashboard'});
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    }
  }
};
</script>

