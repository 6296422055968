import Api from "./Api";
import Csrf from "./Csrf";

export default {
	
	async show(id) {
    await Csrf.getCookie();
    return Api.get("/productions/" +id);
  },
	
	async canContinue(id) {
    await Csrf.getCookie();
    return Api.get("/productions/" +id+"/can_continue");
  },
  
	async index() {
    await Csrf.getCookie();
    return Api.get("/productions");
  },
	
  async store(form) {
    await Csrf.getCookie();
    return Api.post("/productions/store", form);
  },

  async remaining(id,form) {
    await Csrf.getCookie();
    return Api.post("productions/" +id+ "/remaining",form);
  },

  async extra_container(id,form) {
    await Csrf.getCookie();
    return Api.post("productions/" +id+ "/extra_container",form);
  },
	
	async finish(id,form) {
    await Csrf.getCookie();
    return Api.post("productions/" +id+ "/finish",form);
  },
	
	async update(id,form) {
    await Csrf.getCookie();
    return Api.patch("productions/" +id,form); 
  },
	
	async pdf(id) {
    await Csrf.getCookie();
    return Api.get("productions/"+id+"/pdf",{'responseType': 'blob'}); 
  },
	
	async zip(id) {
    await Csrf.getCookie();
    return Api.get("productions/"+id+"/zip",{'responseType': 'blob'}); 
  },
	
	async delete_zip(id) {
    await Csrf.getCookie();
    return Api.get("productions/"+id+"/delete_zip"); 
  },
	
	async parameters(id) {
    await Csrf.getCookie();
    return Api.get("productions/"+id+"/parameters"); 
  },
	
	async destroy(id,form) {
    await Csrf.getCookie();
    return Api.post("productions/"+id+"/destroy",form); 
  },
	
	async RawMaterialsCollected(id) {
    await Csrf.getCookie();
    return Api.get("productions/"+id+"/raw_materials_collected"); 
  },
	
	async PrintRecipeDescription(id) {
    await Csrf.getCookie();
    return Api.get("productions/"+id+"/print_recipe_description",{'responseType': 'blob'}); 
  },
	
	async validateQuantities(data) {
		await Csrf.getCookie();
		
		return Api.post('productions/validate_quantities',data);
	}
	
};