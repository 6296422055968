<template>
	<div>
		<PageHeader title="Félkész termék selejtezés" />
		<div class="wrapper wrapper-content animated fadeInRight">
			<div class="row">
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5><i class="fa fa-industry"></i> Félkész termék raktár</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="products === false">
								<div class="col-12 text-center">
									<h3>Tölthető termékek betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget  p-lg text-center" v-if="products.length == 0">
								<div class="m-b-md">
									<i class="fa fa-tint fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek félkész termékek
									</h3>
									<small>gyártson félkész terméket</small><br>
									<router-link :to="'/productions'" class="btn btn-xs btn-primary mt-1"><i
											class="fa fa-location-arrow"></i> gyártáshoz</router-link>
								</div>
							</div>
							<div v-if="products.length != 0 && products !== false">
								<button @click="sort('name')" class="btn btn-white">
									rendezés
									<i v-if="currentSort == 'name' && currentSortDir == 'asc'"
										class="fa fa-sort-alpha-down"></i>
									<i v-if="currentSort == 'name' && currentSortDir == 'desc'"
										class="fa fa-sort-alpha-up-alt"></i>
								</button>
								<input type="text" v-model="search" placeholder="keresés.." />
								<div class="tableFixHeadLarge">
									<table class="table">
										<thead class="thead-dark">
											<tr>
												<th>Vonalkód</th>
												<th class="d-none d-sm-none d-md-table-cell">Lejárati dátum</th>
												<th>Mennyiség</th>
												<th class="text-end">Műveletek</th>
											</tr>
										</thead>
										<tbody v-for="(product, id) in filteredProducts" :key="id">
											<tr class="bg-gray-200">
												<td colspan="4"><b>{{ product.name }}</b></td>
											</tr>
											<tr v-for="(intermediate_product, id) in product.containers" :key="id">
												<td>{{ intermediate_product.barcode }}</td>
												<td class="d-none d-sm-none d-md-table-cell">
													{{ intermediate_product.best_before }}</td>
												<td>{{ intermediate_product.quantity }}</td>
												<td class="text-end">
													<button @click="openModal(intermediate_product, product)"
														class="btn btn-danger" title="Selejtezés"><i
															class="fa fa-trash-alt"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="ibox">
						<div class="ibox-title">
							<h5><i class="fa fa-undo"></i> Selejtezések</h5>
						</div>
						<div class="ibox-content">
							<div class="row" v-if="discardings === false">
								<div class="col-12 text-center">
									<h3>Selejtezések betöltése</h3>
									<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
								</div>
							</div>
							<div class="widget p-lg text-center" v-if="discardings.length == 0">
								<div class="m-b-md">
									<i class="fa fa-undo fa-4x"></i>
									<h1 class="m-xs"><i class="fa fa-times"></i></h1>
									<h3 class="font-bold no-margins">
										Nincsenek selejtezések
									</h3>
								</div>
							</div>
							<div v-if="discardings.length != 0 && discardings !== false">
								<input type="text" v-model="search2" placeholder="keresés.." />
								<div class="tableFixHeadLarge">
									<table class="table table-striped w-100">
										<thead class="thead-dark">
											<th>Megnevezés</th>
											<th>Leírás</th>
											<th>Dátum</th>
											<th class="text-center">Mennyiség</th>
											<th class="text-center">Műveletek</th>
										</thead>
										<tbody>
											<tr v-for="(discarding, id) in filteredDiscardings" :key="id">
												<td>{{ discarding.product_name }} - {{ discarding.barcode }}</td>
												<td>-</td>
												<td>{{ discarding.created_date }}</td>
												<td class="text-right">{{ discarding.quantity }}</td>
												<td class="text-right">
													<button class="btn btn-warning" title="visszavonás"
														@click="openRestoreModal(discarding)"
														v-if="discarding.expired !== true"><i
															class="fa fa-undo"></i></button>
													<button v-else disabled style="cursor:default;" class="btn btn-danger"
														title="lejárt terméket nem állíthat vissza"><i
															class="fa fa-exclamation-triangle"></i></button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="modal">
			<modal-vue modalSize="l" @on-close="modalClose" name="intermediate_product_modal"
				:headerOptions="{ title: product.name + ' selejtezése', }" :footerOptions="{
					btn1: 'Mégse',
					btn2: 'Selejtezés',
					btn2OnClick: () => {
						discardIntermediateProduct();
					},
					btn1OnClick: () => {
						$vm2.close('intermediate_product_modal');
					},
				}">
				<div align="center" class="col-12">
					<form @submit.prevent="discardIntermediateProduct">
						<div class="form-group">
							<label>Adja meg a selejtezni kívánt mennyiséget:</label>
							<div class="input-group m-b">
								<div class="input-group-prepend">
									<span class="input-group-addon">összes <input type="checkbox"
											v-model="all_select"></span>
								</div>
								<input type="text" class="form-control text-right"
									v-model="formIntermediateProduct.quantity" :disabled="all_select">
							</div>
							<span class="label label-danger" v-if="errors.quantity">
								{{ errors.quantity[0] }}
							</span>
						</div>
						<label>Megjegyzés:</label>
						<textarea v-model="formIntermediateProduct.user_description" class="form-control"></textarea>
					</form>
				</div>
			</modal-vue>
		</div>
		<div id="restore_modal" v-if="discarding">
			<modal-vue modalSize="l" @on-close="$vm2.close('restore_modal');" name="restore_modal"
				:headerOptions="{ title: discarding.product_name + '-' + discarding.barcode + ' selejtezés visszavonása', }"
				:footerOptions="{
					btn1: 'Mégse',
					btn2: 'Visszavonás',
					btn2OnClick: () => {
						discardingRestore();
					},
					btn1OnClick: () => {
						$vm2.close('restore_modal');
					},
				}">
				<div align="center" class="col-12">
					<form @submit.prevent="discardingRestore">
						<div class="form-group">
							<label>Megjegyzés:</label>
							<textarea v-model="discarding.user_description" class="form-control" required></textarea>
							<span class="label label-danger" v-if="errors.user_description">
								{{ errors.user_description[0] }}
							</span>
						</div>
					</form>
				</div>
			</modal-vue>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/PageHeader';
import Discard from "../../apis/Discard";

export default {
	components: { PageHeader },
	data() {
		return {
			products: false,
			discardings: false,
			discarding: false,
			eventlogs: false,
			intermediate_product: {},
			product: {},
			formIntermediateProduct: {
				type: 'intermediate_product',
			},
			errors: [],
			all_select: false,
			search: '',
			search2: '',
			currentSort: 'name',
			currentSortDir: 'asc'
		}
	},
	watch: {
		all_select: function (all_select) {
			if (all_select == true) {
				this.$set(this.formIntermediateProduct, 'quantity', this.intermediate_product.quantity);
			}
		}
	},
	created() {
		this.getData();
	},
	computed: {
		filteredProducts: function () {
			//console.log(this.products);
			return Object.values(this.products).filter(product => {
				return product.name.toLowerCase().includes(this.search.toLowerCase())
			}).sort((a, b) => {
				let modifier = 1;
				if (this.currentSortDir === 'desc') modifier = -1;
				if (a[this.currentSort][0].localeCompare(b[this.currentSort][0], 'hu-HU') < 0) return -1 * modifier;
				if (a[this.currentSort][0].localeCompare(b[this.currentSort][0], 'hu-HU') > 0) return 1 * modifier;
				return 0;
			});
		},
		filteredDiscardings(){
			if(this.discardings){
				return this.discardings.filter(d => d.product_name.toLowerCase().concat('',d.barcode.toLowerCase()).includes(this.search2));
			}else{
				return [];
			}
		}
	},
	methods: {
		getData() {
			Discard.getDiscardable('intermediate_product')
				.then(response => {
					this.products = response.data;
					//console.log(this.products);
				}).then(() => {
					Discard.getDiscardings('intermediate_product')
						.then(response => {
							this.eventlogs = response.data.eventLogs;
							this.discardings = response.data.data;
						});
				});
		},
		sort: function (s) {
			//if s == current sort, reverse
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
		},
		openModal(intermediate_product, product) {
			this.intermediate_product = intermediate_product
			this.product = product
			this.formIntermediateProduct.id = this.intermediate_product.id;
			this.$vm2.open('intermediate_product_modal')
		},
		discardIntermediateProduct() {
			if (this.formIntermediateProduct.quantity < 1 || this.formIntermediateProduct.quantity > this.intermediate_product.quantity || !this.formIntermediateProduct.quantity) {
				this.$toast.open({
					message: '1 és ' + this.intermediate_product.quantity + ' közti mennyiséget selejtezhet',
					type: 'error',
					position: 'top-right'
				});
			} else {
				this.$confirm({
					message: `Biztos selejtezi a kiválasztott mennyiséget?`,
					button: {
						no: 'Nem',
						yes: 'Igen'
					},
					/**
					* Callback Function
					* @param {Boolean} confirm
					*/
					callback: confirm => {
						if (confirm) {
							Discard.discard(this.formIntermediateProduct)
								.then(() => {
									this.getData();
									this.modalClose();
								})
						}
					}
				})
			}
		},
		modalClose() {
			this.$vm2.close('intermediate_product_modal');
			this.formIntermediateProduct = {
				type: 'intermediate_product',
			};
			this.all_select = false;
		},
		openRestoreModal(discarding) {
			let vue = this;
			new Promise(function (resolve) {
				vue.discarding = discarding;
				resolve('finished')
			}).then(() => {
				this.$vm2.open('restore_modal')
			});
		},
		discardingRestore() {
			//console.log(this.discarding);
			if (this.discarding.user_description != null && this.discarding.user_description.length != 0) {
				Discard.restore('intermediate_product', { id: this.discarding.id, user_description: this.discarding.user_description })
					.then(() => {
						this.discardings.splice(this.discardings.map(discarding => discarding.id).indexOf(this.discarding.id), 1);
						Discard.getDiscardable('intermediate_product')
							.then(response => {
								this.products = response.data;
								//console.log(this.products);
							})
					});
				delete this.errors.user_description;
				this.$forceUpdate(this.errors);
				this.$vm2.close('restore_modal');
			} else {
				this.$set(this.errors, 'user_description', ['töltse ki a megjegyzés mezőt']);
			}
		}
	}
};
</script>

<style>

</style>