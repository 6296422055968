import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSlideUpDown from 'vue-slide-up-down'
import VueToast from 'vue-toast-notification'
import VueConfirmDialog from "vue-confirm-dialog";
import moment from 'moment'
import Modal from "@burhanahmeed/vue-modal-2";
import VueGoodTablePlugin from 'vue-good-table';

import './plugins/axios'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/fontawesome-free-5.15.4-web/css/all.min.css'
import './assets/css/animate.css'
import './assets/css/style.css'
import './assets/css/admin.css'
import 'vue-toast-notification/dist/theme-sugar.css';
import '@desislavsd/vue-select/dist/vue-select.css'
import 'vue-good-table/dist/vue-good-table.css'

import { BootstrapVue } from 'bootstrap-vue'
//custom plugins
import Help from './components/Help';
import LoadingButton from './components/LoadingButton';

Vue.use(VueGoodTablePlugin);

Vue.prototype.$vueGTPaginationOptions = {
	enabled: true, 
	position: 'bottom',
	firstLabel : 'Első Lap',
	lastLabel : 'Utolsó Lap',
	nextLabel: 'következő',
	prevLabel: 'előző',
	rowsPerPageLabel: 'Sor oldalanként',
	ofLabel: '/',
	allLabel: 'Összes',
	infoFn: (params) => `első sorszám: ${params.firstRecordOnPage}`, 
};

Vue.use(VueToast);

Vue.use(Modal, {
  componentName: "ModalVue"
});

Vue.use(BootstrapVue)
Vue.use(VueConfirmDialog);

Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.component('vue-slide-up-down', VueSlideUpDown)
Vue.component('moment', moment)
Vue.component('help', Help);
Vue.component('loading-button', LoadingButton);

Vue.config.productionTip = false

var data = {user: {name:''}};

Vue.filter('moment', function (date,format) {
	return moment(date).format(format);
})

window.Vue = new Vue({
  router,
  data,
  render: h => h(App),
}).$mount('#app')